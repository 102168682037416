import { createApi } from "@reduxjs/toolkit/query/react";
import type { Post } from "@app/types/post";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type {
  CreatePostPayload,
  FetchPostPayload,
  LikePayload,
  CommentPayload,
  UpdatePostPayload,
  UpdateCommentPayload,
} from "./types";

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    fetchPost: builder.query<CommonResponse<Post[]>, FetchPostPayload>({
      query: (params) => {
        return {
          url: "post",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: Post) => ({ type: "Post" as const, id })), "Post"]
          : ["Post"],
    }),
    createPost: builder.mutation<CommonResponse<Post>, CreatePostPayload>({
      query(body) {
        return {
          url: `post`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Post"],
    }),
    like: builder.mutation<CommonResponse<Post>, LikePayload>({
      query(body) {
        return {
          url: `post/like`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Post"],
    }),
    dislike: builder.mutation<CommonResponse<Post>, LikePayload>({
      query(body) {
        return {
          url: `post/dislike`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Post"],
    }),
    comment: builder.mutation<CommonResponse<Post>, CommentPayload>({
      query(body) {
        return {
          url: `comment`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Post"],
    }),
    removeComment: builder.mutation<CommonResponse<Post>, number>({
      query(id) {
        return {
          url: `comment/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Post"],
    }),
    updateComment: builder.mutation<CommonResponse<Post>, UpdateCommentPayload>({
      query({ id, ...body }) {
        return {
          url: `comment/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Post"],
    }),
    updatePost: builder.mutation<CommonResponse<Post>, UpdatePostPayload>({
      query({ id, ...body }) {
        return {
          url: `post/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Post", id: arg.id }],
    }),
    removePost: builder.mutation<CommonResponse<Post>, number>({
      query(id: number) {
        return {
          url: `post/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Post"],
    }),
  }),
});

export const {
  useCreatePostMutation,
  useFetchPostQuery,
  useLikeMutation,
  useDislikeMutation,
  useCommentMutation,
  useRemoveCommentMutation,
  useUpdateCommentMutation,
  useUpdatePostMutation,
  useRemovePostMutation,
} = postApi;
