import { Typography } from "@app/components/atoms";

export default function AboutUs() {
  return (
    <>
      <div className="container-layout">
        <div className="px-4 md:px-0">
          <div className=" md:py-8 py-4">
            <div className="md:grid md:grid-cols-5 place-content-center justify-items-center md:mb-8 mb-4">
              <div className="md:col-start-2 md:col-span-3">
                <Typography tag="h2" className="pb-2 text-center">
                  About Blist
                </Typography>
                <Typography className="text-center">{""}</Typography>
              </div>
            </div>
            {/* <div className="grid grid-cols-11 md:gap-5 gap-2">
              <div className="col-span-2 flex flex-row items-center">
                <img
                  src="/imgs/image-10.jpg"
                  alt="aboutus 10"
                  width={193}
                  height={205}
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-2 flex flex-col justify-center">
                <img
                  src="/imgs/image-11.png"
                  width={193}
                  height={205}
                  alt="aboutus 11"
                  className="rounded-10 w-full md:mb-5 mb-2"
                />
                <img
                  src="/imgs/image-12.png"
                  width={193}
                  height={205}
                  alt="aboutus 12"
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-3">
                <img src="/imgs/image-13.jpg" alt="aboutus 13" width={318} height={481} />
              </div>
              <div className="col-span-2 flex flex-col justify-center">
                <img
                  src="/imgs/image-14.jpg"
                  width={193}
                  height={205}
                  alt="aboutus 14"
                  className="rounded-10 w-full  md:mb-5 mb-2"
                />
                <img
                  src="/imgs/image-15.jpg"
                  width={193}
                  height={205}
                  alt="aboutus 15"
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-2 flex flex-row items-center">
                <img
                  src="/imgs/image-16.jpg"
                  alt="aboutus 16"
                  width={193}
                  height={205}
                  className="rounded-10 w-full"
                />
              </div>
            </div> */}
            <Typography tag="h4" className="text-center max-w-1350 mx-auto mb-3">
              Welcome to BlistNow, the platform created for the people, by the people, where every
              every experience is an invitation to explore beyond the expected.
            </Typography>
            <Typography className="text-center">
              Founded by Zaid, a seasoned traveler with a passion for uncovering unique experiences
              across the globe, Blist was born from the idea that the best adventures come from
              personal connections and genuine discoveries. Our mission is to build a community of
              creators who design one-of-a-kind experiences for others to enjoy—each experience
              crafted to reflect the passions and insights of its creator, offering you a fresh and
              personalized way to explore the world.
            </Typography>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-0" style={{ background: "#F4F4F4" }}>
        <div className="container-layout">
          <div className=" lg:py-8 py-4">
            <div className="grid lg:grid-cols-7 xl:gap-x-32">
              <div className="lg:col-span-3 md:pt-4 pb-6 md:pb-0">
                <Typography tag="h2" className="md:pb-8 pb-2">
                  Why Blist?
                </Typography>
                <Typography className="pb-2 text-justify">
                  At Blist, we’re more than a booking site. We’re a vibrant network of creators
                  sharing what they love most, offering experiences that span a variety of
                  categories, each as unique as the individual who created it. Our platform goes
                  beyond the ordinary and cliché; it allows users to broaden their horizons and
                  expand their taste for different types of experiences that truly capture the
                  essence of a place. From hidden dining spots and local art shows to undiscovered
                  trails and exclusive events, Blist is your gateway to the
                  extraordinary—experiences that aren’t just listed but are created by individuals
                  with a genuine passion for showcasing what they love most. These are moments
                  crafted with intention, experiences you won’t find on any other booking website.
                </Typography>
                {/* <div className="grid grid-cols-7 gap-x-10">
                  <div className="col-span-6 grid grid-cols-3">
                    <div>
                      <Typography tag="h2" className="text-primary">
                        11
                      </Typography>
                      <Typography>Years</Typography>
                    </div>
                    <div>
                      <Typography tag="h2" className="text-primary">
                        275+
                      </Typography>
                      <Typography>Users</Typography>
                    </div>
                    <div>
                      <Typography tag="h2" className="text-primary">
                        100+
                      </Typography>
                      <Typography>Customers</Typography>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="lg:col-span-4 lg:mt-0 mt-10px">
                <Typography tag="h2" className="md:pb-8 pb-2">
                  How Blist Works
                </Typography>
                <Typography className="pb-2 text-justify">
                  On Blist, you’ll discover an ever-evolving selection of creator-curated
                  experiences that reflect the diversity and creativity of our community. Here’s
                  what you can expect:
                  <ul className="list-disc ml-6 text-justify mt-4 mb-4">
                    <li>
                      <b>Personalized Discoveries:</b> Explore experiences across multiple
                      categories, each tailored to reflect the unique vision of its creator.
                    </li>
                    <li>
                      <b>Easy Booking Integration:</b> Book experiences seamlessly through platforms
                      like Meetup or EventBrite, fully integrated with Blist.
                    </li>
                    <li>
                      <b>Join and Share:</b> Become a part of the Blist community by sharing your
                      own favorite spots, events, and hidden gems, helping others expand their own
                      tastes and explore beyond the mainstream.
                    </li>
                  </ul>
                  Our community grows with every new creator and experience added, allowing us all
                  to enjoy a broader range of authentic, meaningful moments. Whether you’re here to
                  find your next adventure or to create one for others, Blist is your platform for
                  bringing unique experiences to life.
                </Typography>
                {/* <img
                  className="rounded-10 w-full"
                  src="/imgs/image-17.jpg"
                  height={460}
                  width={664}
                  alt="aboutus 17"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-layout">
        <div
          className="lg:bg-cover md:pt-28 md:pb-80 md:px-0 pb-44 md:bg-top bg-center bg-no-repeat py-6 px-4"
          style={{ backgroundImage: "url(/imgs/image-18.jpg)" }}
        >
          <div className="">
            <div className="grid grid-cols-11 xl:gap-x-32">
              <div className="md:col-start-6 md:col-span-5 col-start-1 col-span-10">
                <Typography tag="h2" className="md:pb-8 mb-2">
                  Want to Join the Team?
                </Typography>
                <Typography className="pb-2 text-justify">
                  If you’re as passionate as we are about building a community of unique
                  experiences, we’d love to have you on board! Reach out to us at{" "}
                  <a className="text-primary" href="mailto:joinblist@gmail.com">
                    joinblist@gmail.com
                  </a>{" "}
                  to learn about opportunities to become part of the Blist team and make a lasting
                  impact.
                </Typography>
                <Typography className="pb-2 text-justify">
                  Join us at Blist, where every experience is an original creation, crafted to show
                  the world something unforgettable.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
