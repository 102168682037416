import clsx from "clsx";
import { ReactNode, useRef } from "react";
import { IconButton } from "../atoms";

type Props = {
  header?: ReactNode;
  expandIcon?: ReactNode;
  content?: ReactNode;
  headerClass?: string;
};

export const Accordion = ({ header, expandIcon, content, headerClass }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const onExpand = () => {
    if (!contentRef.current || !iconRef.current) {
      return;
    }
    iconRef.current.classList.toggle("rotate-180");
    contentRef.current.classList.toggle("hidden");
  };

  return (
    <div>
      <div
        className={clsx("flex flex-row justify-between items-center cursor-pointer", headerClass)}
        onClick={onExpand}
      >
        {header}
        {expandIcon && (
          <div ref={iconRef}>
            <IconButton onClick={onExpand}>{expandIcon}</IconButton>
          </div>
        )}
      </div>
      <div className="hidden" ref={contentRef}>
        {content}
      </div>
    </div>
  );
};
