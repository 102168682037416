import { Control, useController } from "react-hook-form";
import { Select } from "../atoms";
import type { SelectProps } from "../atoms";

interface Props extends SelectProps {
  control: Control<any>;
  name: string;
}

export const HSelect = ({ control, name, defaultValue, ...props }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const value = props.isMulti
    ? field.value.map((item: any) => props.options?.find((eq: any) => eq.value === item))
    : props.options?.find((eq: any) => eq.value === field.value);

  return (
    <Select
      name={field.name}
      onChange={(data: any) =>
        field.onChange(props.isMulti ? data.map((eq: any) => eq.value) : data.value)
      }
      value={value}
      error={error?.message}
      // ref={ref as any}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
