import clsx from "clsx";
import { useState } from "react";
import { DislikeSvg, LikeSvg, Typography } from "../atoms";
import { useGetApprenticeQuery } from "@app/store/services/apprentice";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { apprenticeId } = useParams<{ apprenticeId: string }>();
  const { data } = useGetApprenticeQuery(apprenticeId || "", { skip: !apprenticeId });
  return (
    <div className="rounded-20 border border-border8 px-8 py-6">
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          About Me
        </Typography>
        <Typography className="text-lightGrey">{data?.introduce}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Job Description
        </Typography>
        <Typography className="text-lightGrey">{data?.job_description}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Daily Life
        </Typography>
        <Typography className="text-lightGrey">{data?.daily_life}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Likes/Dislikes
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <LikeSvg className="mr-3 inline-block" />
          <b>Likes</b> : {data?.likes}
        </Typography>
        <Typography className="text-lightGrey">
          <DislikeSvg className="mr-3 inline-block" />
          <b>Dislikes</b> : {data?.dislikes}
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          How I Got Here
        </Typography>
        <Typography className="text-lightGrey">{data?.got_here}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Top 3 Qualities for Success
        </Typography>
        {data?.qualities?.map((item, index) => (
          <Typography key={index} className="text-lightGrey mb-4">
            <span className="w-21px h-21px bg-blue text-white text-12 rounded-full inline-flex items-center justify-center mr-2">
              {index + 1}
            </span>
            <b>Patience</b>: {item}
          </Typography>
        ))}
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Hardest Part vs. Easiest Part of My Career
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <b>Hardest Part</b>: {data?.hardest_part}
        </Typography>
        <Typography className="text-lightGrey">
          <b>Easiest Part</b>: {data?.easiest_part}
        </Typography>
      </div>
      <div>
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Salary
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <b>Expected Salary Range</b>: {data?.expect}
        </Typography>
      </div>
    </div>
  );
};

const Experience = () => {
  const { apprenticeId } = useParams<{ apprenticeId: string }>();
  const { data } = useGetApprenticeQuery(apprenticeId || "", { skip: !apprenticeId });
  return (
    <div className="rounded-20 border border-border8 px-8 py-6">
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Where You Will Go
        </Typography>
        <Typography className="text-lightGrey">{data?.where_will_go}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          What You Can Expect
        </Typography>
        <Typography className="text-lightGrey">{data?.expect}</Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          What to Bring
        </Typography>
        <Typography className="text-lightGrey">{data?.what_to_bring}</Typography>
      </div>
      <div className="">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          What You Will Learn
        </Typography>
        <Typography className="text-lightGrey">{data?.what_will_learn}</Typography>
      </div>
    </div>
  );
};

const Review = () => {
  return (
    <div className="rounded-20 border border-border8 px-8 py-6">
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          About Me
        </Typography>
        <Typography className="text-lightGrey">
          Hi, I'm <b>Patricia Sanders</b>, a passionate woodworker with a deep love for crafting
          beautiful pieces from timber. My journey into woodworking began at a young age when I
          helped my grandfather build his first wooden chair. Since then, I've dedicated myself to
          honing my craft and turning my love for wood into a career.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Job Description
        </Typography>
        <Typography className="text-lightGrey">
          As a woodworker, my main role is to design and create custom furniture pieces and wooden
          art. I specialize in crafting unique, hand-carved wooden tables and chairs.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Daily Life
        </Typography>
        <Typography className="text-lightGrey">
          My daily life revolves around the soothing hum of saws and the scent of freshly cut wood.
          I spend hours in my workshop, meticulously crafting pieces that bring joy and
          functionality to people's lives.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Likes/Dislikes
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <LikeSvg className="mr-3 inline-block" />
          <b>Likes</b> : The smell of sawdust, the satisfaction of seeing a piece come together, the
          creativity involved in woodworking.
        </Typography>
        <Typography className="text-lightGrey">
          <DislikeSvg className="mr-3 inline-block" />
          <b>Dislikes</b> : Wood that warps unexpectedly, dealing with heavy machinery breakdowns.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          How I Got Here
        </Typography>
        <Typography className="text-lightGrey">
          My woodworking journey began with a carpentry apprenticeship, followed by formal training
          in furniture design. Over the years, I've honed my skills by working on various projects
          and collaborating with experienced craftsmen.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Top 3 Qualities for Success
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <span className="w-21px h-21px bg-blue text-white text-12 rounded-full inline-flex items-center justify-center mr-2">
            1
          </span>
          <b>Patience</b>: Woodworking requires precision and attention to detail.
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <span className="w-21px h-21px bg-blue text-white text-12 rounded-full inline-flex items-center justify-center mr-2">
            2
          </span>
          <b>Creativity</b>: Designing unique pieces sets you apart.
        </Typography>
        <Typography className="text-lightGrey">
          <span className="w-21px h-21px bg-blue text-white text-12 rounded-full inline-flex items-center justify-center mr-2">
            3
          </span>
          <b>Adaptability</b>: Being open to new techniques and tools is essential.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Hardest Part vs. Easiest Part of My Career
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <b>Hardest Part</b>: Dealing with unpredictable wood behavior.
        </Typography>
        <Typography className="text-lightGrey">
          <b>Easiest Part</b>: Seeing the joy on clients' faces when they receive their custom
          pieces.
        </Typography>
      </div>
      <div className="border-b border-border-8 pb-8 mb-6">
        <Typography tag="title" className="!font-inter font-medium mb-4">
          Salary
        </Typography>
        <Typography className="text-lightGrey mb-4">
          <b>Expected Salary Range</b>: $40,000 - $60,000 annually (varies by project and location).
        </Typography>
      </div>
    </div>
  );
};

export const ProfileDetail = () => {
  const [tab, setTab] = useState(1);
  return (
    <div className="md:mx-0 mx-4">
      <div className="border-b border-border2 mb-4">
        <button
          className={clsx(
            "pb-2 border-b-3 md:text-22 text-16 mr-5",
            tab === 1 ? "border-primary text-primary" : "border-transparent text-blue/[.55]",
          )}
          onClick={() => setTab(1)}
        >
          Profile
        </button>
        <button
          className={clsx(
            "pb-2 border-b-3 md:text-22 text-16 mr-5",
            tab === 2 ? "border-primary text-primary" : "border-transparent text-blue/[.55]",
          )}
          onClick={() => setTab(2)}
        >
          My Apprentice Experience
        </button>
        <button
          className={clsx(
            "pb-2 border-b-3 md:text-22 text-16",
            tab === 3 ? "border-primary text-primary" : "border-transparent text-blue/[.55]",
          )}
          onClick={() => setTab(2)}
        >
          Reviews
        </button>
      </div>
      {tab === 1 && <Profile />}
      {tab === 2 && <Experience />}
      {tab === 3 && <Review />}
    </div>
  );
};
