import { createSlice } from "@reduxjs/toolkit";
import { Category } from "@app/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { categoryApi } from "../services/category";

export interface CategoryState {
  list: Category[];
}

const initialState: CategoryState = {
  list: [],
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(categoryApi.endpoints.fetchCategories.matchFulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCategories } = categorySlice.actions;

export default categorySlice.reducer;
