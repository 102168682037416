import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../";
import { setCategories, CategoryState } from "../reducers/category";
import { Category } from "@app/types";

interface CategoryProps extends CategoryState {
  setCategories: (payload: Category[]) => void;
}

export const useCategory = (): CategoryProps => {
  const allState = useSelector((state: RootState) => state.category);
  const dispatch = useDispatch();

  return {
    ...allState,
    setCategories: (data: Category[]) => dispatch(setCategories(data)),
  };
};
