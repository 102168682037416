import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "../atoms";
import { HTextField } from "../hookform";
import { useUpdateWishlistMutation } from "@app/store/services/wishlist";
import { UpdateWishlistPayload } from "@app/store/services/wishlist/types";
import { Wishlist } from "@app/types/wishlist";

type Props = {
  defaultValues?: Partial<Wishlist>;
};

export const WishlistForm = ({ defaultValues }: Props) => {
  const [update, { isLoading: isUpdating }] = useUpdateWishlistMutation();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<UpdateWishlistPayload>({
    defaultValues,
  });

  const onUpdate: SubmitHandler<UpdateWishlistPayload> = async (data) => {
    if (!defaultValues?.id) {
      return;
    }

    update({ ...data, id: defaultValues?.id })
      .unwrap()
      .then(() => {
        navigate("/admin/blist-board");
      })
      .catch(() => {});
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <HTextField
        control={control}
        name="content"
        variant="outlined-black"
        border="full"
        label="Content"
        placeholder="Type here"
        className="mb-4"
      />
      <Button type="submit" variant="primary" layout="rounded" isLoading={isUpdating}>
        Update Now
      </Button>
    </form>
  );
};
