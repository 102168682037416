import { useFieldArray, Control } from "react-hook-form";
import { AddCircleSvg } from "../atoms";
import { RowSelection } from "../common";
import { HTextArea } from "./HTextArea";

interface Props {
  control: Control<any>;
  name: string;
}

export const HNotes = ({ control, name }: Props) => {
  const { fields, append } = useFieldArray({
    control,
    name,
  });

  return (
    <div>
      <RowSelection
        color="primary"
        label="Add Notes"
        startIcon={<AddCircleSvg />}
        onClick={() => {
          append("");
        }}
        type="button"
      />
      {fields.map((field, index) => (
        <HTextArea key={field.id} name={`${name}.${index}`} control={control} variant="outlined" />
      ))}
    </div>
  );
};
