import { SearchNormalSvg, TextField } from "../atoms";
import { AdminUserMenu } from "../molecules";

export const AdminMenu = () => {
  return (
    <div className="flex md:flex-row-reverse flex-col md:justify-between md:items-center items-end w-full px-4 pd:pt-8 pt-2 md:pb-4 pb-2">
      <AdminUserMenu isBoardButton={false} />
      <div className="md:max-w-500 w-full md:mt-0 mt-4">
        {/* <TextField
          variant="outlined-black"
          border="full"
          name="id"
          placeholder="Search here..."
          endIcon={<SearchNormalSvg className="stroke-black w-25 h-25 ml-2" />}
          layout="static"
        /> */}
      </div>
    </div>
  );
};
