import { useState, useEffect, useMemo } from "react";
import { MessageTab, NotificationItem } from "@app/components/molecules";
import {
  MessageList,
  UserChatList,
  ExperienceChatList,
  MemberDetail,
} from "@app/components/organisms";
import { where, onSnapshot, query } from "firebase/firestore";
import { alertCollection, messageCollection } from "@app/utils";
import { useAuth } from "@app/store/hooks";
import { Role } from "@app/enums";
import { Alert, Conversation } from "@app/types";
import { useSearchParams } from "react-router-dom";
import { useFetchUserQuery } from "@app/store/services/user";
import { useLazyGetBookingQuery } from "@app/store/services/booking";
import { keys } from "lodash";

export default function Messages() {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get("tab") || "message");
  const [data, setData] = useState<Conversation[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [alerts, setAlert] = useState<Alert[]>([]);
  const { profile } = useAuth();
  const [bookingId, setBookingId] = useState<number>();
  const [getBooking] = useLazyGetBookingQuery();
  const conversation: Conversation | undefined = data.find((item) => item.id === selected);
  const { data: users } = useFetchUserQuery(
    {
      user_ids: keys(conversation?.user_ids).map((eq) => Number(eq)),
    },
    {
      skip: !conversation?.user_ids,
    },
  );

  useEffect(() => {
    const queryRef = query(
      messageCollection,
      where(`user_ids.${profile.id}`, "==", true),
      where("isAdmin", "==", false),
    );

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      const mapData = snapshot.docs.map((doc) => {
        const snapshotData: Conversation = doc.data() as any;
        return {
          ...snapshotData,
          id: doc.id,
        };
      });
      setData(mapData);

      if (!selected && searchParams.get("userId") && searchParams.get("role")) {
        data && setSelected(searchParams.get("userId"));
      }
    });

    const queryAlertRef = query(
      alertCollection,
      where(profile.role === Role.creator ? "creatorId" : "userId", "==", profile.id),
      where("role", "==", profile.role),
    );

    const unsubscribeAlert = onSnapshot(queryAlertRef, (snapshot) => {
      const mapdata = snapshot.docs.map((doc) => {
        const snapshotData: Alert = doc.data() as any;
        return {
          ...snapshotData,
          id: doc.id,
        };
      });
      setAlert(mapdata);
    });

    return () => {
      unsubscribeAlert();
      unsubscribe();
    };
  }, []);

  const onSetBooking = (id: number) => {
    setBookingId(id);
    getBooking(id);
  };

  const message = useMemo(() => {
    if (!selected) return null;
    const sender = users?.data?.find((item) => item.id !== profile.id);

    return (
      <>
        <div className="border-border3/[0.1] rounded-10 border bg-white xl:col-span-3 md:col-span-4 flex flex-col md:mb-0 mb-4">
          <MemberDetail
            name={`${sender?.first_name} ${sender?.last_name}`}
            avatar={sender?.avatar}
          />
          <div className="bg-gradient-to-r from-primary h-1px mx-5"></div>
          <MessageList
            messages={conversation?.messages || []}
            conversationId={conversation?.id}
            onSetBookingId={(id: number) => onSetBooking(id)}
          />
        </div>
        <div className="border-border3/[0.1] xl:col-span-2 md:col-span-7 rounded-10 border bg-white md:mb-0 mb-4 overflow-y-auto custom-scroll">
          <ExperienceChatList id={bookingId} conversationId={conversation?.id} />
        </div>
      </>
    );
  }, [selected, data, profile, bookingId, users]);

  return (
    <div className="md:px-0 px-4 py-6 flex-1 flex flex-col">
      <div className=" md:grid md:grid-cols-7 gap-5 overscroll-none py-6 flex-1">
        <div className="border-border3/[0.1] xl:col-span-2 md:col-span-3 rounded-10 border bg-white md:mb-0 mb-4">
          <MessageTab tab={tab} onSetTab={(data: string) => setTab(data)} />
          {tab === "message" && (
            <UserChatList
              data={data}
              onSelected={(id: string) => {
                setBookingId(undefined);
                setSelected(id);
              }}
              selected={selected}
            />
          )}
          {tab === "notification" && (
            <div className="flex-1 admin-scroll overflow-auto">
              {alerts.map((item, key) => (
                <div key={key}>
                  <NotificationItem
                    id={item.id}
                    title={item.title}
                    link={item.link}
                    markAsRead={item.markAsRead}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {message}
      </div>
    </div>
  );
}
