import { useEffect, useState } from "react";
import { Button, TrashSvg, Typography, Modal } from "../atoms";
import { doc, deleteDoc, onSnapshot, query, where } from "firebase/firestore";
import { Article } from "../common";
import { last, range } from "lodash";
import { alertCollection, db, messageCollection } from "@app/utils";
import { Alert, Conversation } from "@app/types";
import clsx from "clsx";
import { NotificationItem } from "../molecules";
import { useSearchParams } from "react-router-dom";
import { Role } from "@app/enums";

type MessageItemProps = {
  item: Conversation;
};

const MessageItem = ({ item }: MessageItemProps) => {
  const user = item.messages.find((item) => !item.isAdmin);
  const message = last(item.messages);
  const [isShow, setIsShow] = useState(false);

  const menuList = [
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => setIsShow(true),
    },
  ];

  const remove = async () => {
    setIsShow(false);
    await deleteDoc(doc(db, "message", item.id));
  };

  return (
    <>
      <Modal show={isShow} onClose={() => setIsShow(false)}>
        <div className="py-3 px-8">
          <Typography tag="h2" className="text-center mb-6">
            Are you sure you want to remove this message?
          </Typography>
          <div className="flex flex-row">
            <Button
              variant="outline-border"
              className="mr-2 flex-1 !border-lightGrey"
              onClick={() => setIsShow(false)}
            >
              No
            </Button>
            <Button variant="primary" className="ml-2 flex-1" onClick={remove}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Article
        author={user?.name}
        content={message?.content || message?.note}
        isEdit
        menuList={menuList}
        className="!border-t-0 !border-r-0 !border-l-0 !rounded-none !bg-transparent"
      />
    </>
  );
};

export const AdminChatList = ({
  onSetMsg,
  id,
}: {
  onSetMsg: (item: Conversation) => void;
  id?: string;
}) => {
  const [searchParams] = useSearchParams();
  const [list, setList] = useState<Conversation[]>([]);
  const [alerts, setAlert] = useState<Alert[]>([]);
  const [tab, setTab] = useState(searchParams.get("tab") || "message");
  useEffect(() => {
    const queryRef = query(messageCollection, where("isAdmin", "==", true));

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const snapshotData: Conversation = doc.data() as any;
        return {
          ...snapshotData,
          id: doc.id,
        };
      });
      setList(data);

      if (!id && searchParams.get("userId") && searchParams.get("role") && !!data.length) {
        const message = data?.find((item) => {
          return !!item.user_ids?.[searchParams.get("userId") as any];
        });
        message && onSetMsg(message);
      }
    });

    const queryAlertRef = query(alertCollection, where("isAdmin", "==", true));

    const unsubscribeAlert = onSnapshot(queryAlertRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const snapshotData: Alert = doc.data() as any;
        return {
          ...snapshotData,
          id: doc.id,
        };
      });
      setAlert(data);
    });

    return () => {
      unsubscribe();
      unsubscribeAlert();
    };
  }, []);

  return (
    <div className="border border-border-2 rounded-10 px-4 py-4 h-full flex flex-col">
      <div className="grid grid-cols-2 gap-4 mb-4">
        <Button
          variant={tab === "message" ? "primary" : "blue"}
          type="button"
          onClick={() => setTab("message")}
        >
          Messages
        </Button>
        <Button
          variant={tab === "notification" ? "primary" : "blue"}
          type="button"
          onClick={() => setTab("notification")}
        >
          Notification
        </Button>
      </div>
      {tab === "message" && (
        <div className="flex-1 admin-scroll overflow-auto">
          {list.map((item, key) => (
            <div
              key={key}
              className={clsx("cursor-pointer", id === item.id && "bg-buttonGrey rounded-20")}
              onClick={() => onSetMsg(item)}
            >
              <MessageItem item={item} />
            </div>
          ))}
        </div>
      )}
      {tab === "notification" && (
        <div className="flex-1 admin-scroll overflow-auto">
          {alerts.map((item, key) => (
            <div key={key}>
              <NotificationItem
                id={item.id}
                title={item.title}
                link={item.link}
                markAsRead={item.markAsRead}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const AdminMessage = () => {
  return (
    <div className="border border-border-2 rounded-10 px-4 py-4 h-full flex flex-col">
      <div>
        <Typography>Ralph Edwards</Typography>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...
        </Typography>
      </div>
      <div className="flex-1 admin-scroll overflow-auto"></div>
    </div>
  );
};
