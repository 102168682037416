import { useState } from "react";
import { Typography } from "../atoms";
import { TrackingForm } from "../molecules";
import { range } from "lodash";

export const TrackingFormSection = () => {
  const [num, setNum] = useState(2);
  return (
    <div className="pb-8">
      <Typography tag="h4" className="text-center mb-6">
        Add emergency contact information
      </Typography>
      <div className="grid md:grid-cols-2">
        {range(num).map((item) => (
          <TrackingForm
            title={`Emergency Contact ${item + 1}`}
            isLeft={item % 2 === 0}
            key={item}
            onAddMore={() => setNum(num + 1)}
          />
        ))}
      </div>
    </div>
  );
};
