import clsx from "clsx";
import { useState } from "react";
import { Button, Typography, Modal } from "../atoms";
import { useRemoveUserMutation, useUpdateUserMutation } from "@app/store/services/user";
import { RemovePopup, DeactivePopup, CustomPopup, VerifyPopup } from "../organisms";

type Props = {
  title?: string;
  url?: string;
  description?: string;
  isActive?: boolean;
  isDeleted?: boolean;
  id: number;
  isNotVerify?: boolean;
};
export const UserItem = ({
  title,
  url,
  description,
  isActive,
  isDeleted,
  id,
  isNotVerify,
}: Props) => {
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const [isShowActive, setIsShowActive] = useState(false);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [removeUser, { isLoading: isRemoving }] = useRemoveUserMutation();
  const [isVerify, setIsVerify] = useState(false);

  const onRemove = () => {
    removeUser(id)
      .unwrap()
      .then(() => {
        setIsShowRemove(false);
      })
      .catch(() => {});
  };

  const onDeactive = () => {
    updateUser({
      id,
      is_active: false,
    })
      .unwrap()
      .then(() => {
        setIsShowDeactive(false);
      })
      .catch(() => {});
  };

  const onActive = () => {
    updateUser({
      id,
      is_active: true,
    })
      .unwrap()
      .then(() => {
        setIsShowDeactive(false);
      })
      .catch(() => {});
  };

  return (
    <div
      className={clsx(
        "flex flex-col justify-center items-center border rounded-10 border-lightGrey/[.1] py-3 px-5",
      )}
    >
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isRemoving}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={title}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isLoading}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={title}
      />
      <CustomPopup
        isShow={isShowActive}
        isLoading={isLoading}
        onClose={() => setIsShowActive(false)}
        onSubmit={onActive}
        question={`Do you want to active ${title}?`}
      />
      <Modal show={isVerify} onClose={() => setIsVerify(false)}>
        <VerifyPopup id={id} onClose={() => setIsVerify(false)} />
      </Modal>
      <img
        src={url || "/imgs/image-3.png"}
        className="rounded-full border border-blue"
        alt={title || "no data"}
        width={78}
        height={78}
      />

      <Typography tag="body1" isColor className="text-blue mt-3 mb-2">
        {title}
      </Typography>
      <Typography tag="body" isColor className="text-lightGrey text-center mb-4">
        {description}
      </Typography>
      {isActive && (
        <Button
          onClick={() => setIsShowDeactive(true)}
          variant="static"
          className="text-lightGrey border border-lightGrey px-3 py-1"
        >
          Deactivate
        </Button>
      )}
      {!isActive && !isDeleted ? (
        <div className="grid grid-cols-2 gap-5 text-center">
          <Button
            onClick={() => setIsShowRemove(true)}
            variant="static"
            className="text-lightGrey border border-lightGrey px-3 py-1"
          >
            Delete
          </Button>
          <Button
            onClick={() => setIsShowActive(true)}
            variant="static"
            className="text-lightGrey border border-lightGrey px-3 py-1"
          >
            Active
          </Button>
        </div>
      ) : null}

      {!isNotVerify && (
        <Button
          onClick={() => setIsVerify(true)}
          variant="static"
          className="text-lightGrey border border-lightGrey px-3 py-1 mt-2"
        >
          Verify Account
        </Button>
      )}
    </div>
  );
};
