import { Button } from "@app/components/atoms";
import {
  NewWeekSection,
  ExperienceSection,
  DownloadApp,
  TagSlide,
} from "@app/components/organisms";

export default function Experiences() {
  return (
    <>
      <div className="px-4 md:px-0">
        <NewWeekSection />
        <TagSlide />
        <ExperienceSection title="BLIST Experiences" />
        <ExperienceSection title="Top Experiences" />
        <ExperienceSection title="New Experiences" />
        <div className="text-center md:mb-8 mb-4">
          <Button variant="rounded" className="text-white bg-lightBlack">
            Load More
          </Button>
        </div>
      </div>
      <DownloadApp />
    </>
  );
}
