import { getDay } from "date-fns";
import { Typography, Radio, Button, Label } from "../atoms";
import {
  HIncreaseField,
  HDatePicker,
  HTimePicker,
  HNotes,
  HCalendar,
  HTimeSlot,
  HRadio,
} from "../hookform";
import { useMemo, useState } from "react";
import { includes, map } from "lodash";
import { daysNumber } from "@app/config";
import moment from "moment";
import { CreateBookingPayload } from "@app/store/services/booking/type";
import { useCreateBookingMutation } from "@app/store/services/booking";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetExperienceQuery } from "@app/store/services/experience";
import { useFetchHolidaysQuery } from "@app/store/services/holiday";
import { bookformValidation } from "@app/validations";

type Props = {
  experienceId?: string;
};

type CreateBookingForm = Omit<
  CreateBookingPayload,
  "experience_id" | "start_date" | "end_date" | "date"
> & {
  date: Date;
  is_request: string;
};

export const BookForm = ({ experienceId }: Props) => {
  const [createBooking, { isLoading }] = useCreateBookingMutation();
  const { handleSubmit, control, watch, ...methods } = useForm<CreateBookingForm>({
    defaultValues: {
      date: moment().toDate(),
      adults: 0,
      children: 0,
      infant: 0,
      notes: [],
      is_request: "No",
    },
    resolver: bookformValidation,
  });
  const errorMessages = Object.keys(methods?.formState?.errors).map(
    (key) => (methods?.formState?.errors as any)?.[key] as any,
  );

  const { data } = useGetExperienceQuery(experienceId || "", {
    skip: !experienceId,
  });
  const { data: holidays } = useFetchHolidaysQuery(
    { user_id: data?.owner.id || 0 },
    { skip: !data?.owner.id },
  );
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<CreateBookingForm> = (data) => {
    if (!experienceId) return;

    const { is_request, date, ...rest } = data;
    createBooking({
      ...rest,
      date: date && moment(date).format("YYYY-MM-DD"),
      experience_id: Number(experienceId),
    })
      .unwrap()
      .then((response) => {
        navigate(`/request-to-book/${response.id}`);
      })
      .catch(() => {});
  };

  const filterDate = (date: Date) => {
    const day = getDay(date);
    const numDays = map(data?.available_days, (item) => daysNumber[item]);
    return includes(numDays, day);
  };

  const excludeDates = useMemo(() => {
    if (!holidays) return [];
    return holidays.map((item) => moment(item.date_time).toDate());
  }, [holidays]);

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit} control={control} watch={watch}>
      <form onSubmit={handleSubmit(onSubmit)} className="shadow-bookform py-5 px-4 rounded-10">
        <Typography tag="h6" className="text-blue">
          Guest
        </Typography>
        <div className="mb-6">
          <div className="border-b border-lineBreak py-4 px-4 -mx-4">
            <HIncreaseField label="Adults" name="adults" control={control} defaultValue={0} />
          </div>
          <div className="border-b border-lineBreak py-4 px-4 -mx-4">
            <HIncreaseField
              label="Children (age 2-12)"
              name="children"
              control={control}
              defaultValue={0}
            />
          </div>
          <div className="py-4">
            <HIncreaseField
              label="Infant (under 2)"
              name="infant"
              control={control}
              defaultValue={0}
            />
          </div>
          <div className="my-4">
            <HCalendar
              control={control}
              name="date"
              filterDate={filterDate}
              excludeDates={excludeDates}
              minDate={moment().toDate()}
            />

            <HTimeSlot
              control={control}
              name="availability_time"
              availabilityTime={data?.availability_time || []}
            />
          </div>
          <div className="mt-2 pb-2">
            <Typography tag="h6" className="pb-2">
              Would you like to request a custom date/time instead?
            </Typography>
            <div className="flex flex-row mb-2">
              <HRadio
                label="Yes"
                control={control}
                name="is_request"
                className="mr-2"
                value="Yes"
              />
              <HRadio label="No" control={control} name="is_request" className="mr-2" value="No" />
            </div>
          </div>
          {watch("is_request") === "Yes" && (
            <div className="mb-4 pb-4 border-b border-lineBreak px-4 -mx-4">
              <div className="mb-2">
                <HDatePicker
                  label="Date Request"
                  variant="outlined-black"
                  layout="rounded"
                  control={control}
                  name="date_request"
                  showYearDropdown
                  layoutType="row"
                  minDate={moment().toDate()}
                />
              </div>
              <div>
                <HTimePicker
                  label="Time Request"
                  variant="outlined-black"
                  layout="rounded"
                  control={control}
                  name="time_request"
                  dateFormat="HH:mm"
                  layoutType="row"
                />
              </div>
            </div>
          )}
          <div>
            <Typography tag="h5">Notes</Typography>
            <HNotes control={control} name="notes" />
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="primary"
            layout="rounded"
            className="w-full"
            isLoading={isLoading}
          >
            Submit
          </Button>
          {errorMessages.map((it, key) => (
            <Label variant="error" key={key}>
              {it.message}
            </Label>
          ))}
        </div>
      </form>
    </FormProvider>
  );
};
