import { CreatorType, Role } from "@app/enums";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const createUserValidation = yupResolver(
  yup.object({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    creator_type: yup.string().oneOf([CreatorType.individualCreator, CreatorType.venue]).nullable(),
    email_address: yup.string().required(),
    role: yup.string().oneOf([Role.admin, Role.creator, Role.user]).required(),
  }),
);
