import { useForm, SubmitHandler } from "react-hook-form";
import { Typography, UploadImage, Button, TextField, Check2, BookingLoading } from "../atoms";
import { HSelect, HTextField, HUpload } from "../hookform";
import { verifyCardValidation } from "@app/validations";
import { cardTypeOptions } from "@app/config";
import {
  useGetVerifyAccountQuery,
  useSendEmailMutation,
  useSendPhoneMutation,
  useSubmitVerifyMutation,
} from "@app/store/services/verify-account";
import { useAuth } from "@app/store/hooks";
import { SubmitVerifyPayload } from "@app/store/services/verify-account/types";
import { VerifyCardType } from "@app/enums";
import { useState } from "react";

type Props = {
  onClick?: (next: number, data: Partial<SubmitVerifyPayload>) => void;
};

type VerifyCardData = {
  front_side: string;
  back_side: string;
  type: VerifyCardType;
};

export const VerifyCard = ({ onClick }: Props) => {
  const { profile } = useAuth();
  const { data, isSuccess } = useGetVerifyAccountQuery(profile.id, { skip: !profile.id });

  const { handleSubmit, control } = useForm({
    resolver: verifyCardValidation,
    defaultValues: data,
  });

  const onSubmit: SubmitHandler<VerifyCardData> = (data) => {
    onClick?.(2, data);
  };

  if (!isSuccess) return <BookingLoading />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography
        tag="static"
        className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-2"
      >
        Verify Your ID
      </Typography>
      <div className="border-2 rounded-5 border-border5 p-4 mb-4">
        <div className="grid md:grid-cols-2 gap-5">
          <HSelect
            control={control}
            name="type"
            label="Document Type"
            layout="column"
            variant="outlineRectangled"
            options={cardTypeOptions}
            className="z-30"
            getOptionValue={(option: any) => option.value}
          />
          <div />
          <HUpload control={control} name="front_side" imageClass="h-120 md:h-300">
            <UploadImage text="Front Side" />
          </HUpload>
          <HUpload control={control} name="back_side" imageClass="h-120 md:h-300">
            <UploadImage text="Back Side" />
          </HUpload>
        </div>
        <div className="pt-12 flex justify-end">
          <Button type="submit" layout="rectangled" variant="primary" className="px-2">
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

type VerifyEmailData = {
  code?: string;
};

export const VerifyEmail = ({ onClick }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [send, { isLoading }] = useSendEmailMutation();

  const { handleSubmit, control } = useForm();

  const onSubmit = (data: VerifyEmailData) => {
    onClick?.(3, {
      email_code: data.code,
    });
  };

  const sendEmail = () => {
    send(email);
  };

  return (
    <div>
      <Typography
        tag="static"
        className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-2"
      >
        Verify Your Email
      </Typography>
      <div className="border-2 rounded-5 border-border5 p-4 mb-4">
        <Typography
          tag="static"
          className="md:text-18 md:leading-18 text-16 leading-18 text-blue/[.9] mb-2"
        >
          Email
        </Typography>
        <div className="md:flex md:flex-row items-center">
          <TextField
            name="email"
            placeholder="Your email"
            variant="outlined-grey1"
            className="mr-6"
            layout="vertical"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            layout="rectangled"
            variant="outline-primary"
            className="md:px-2 py md:mt-0 mt-4"
            isLoading={isLoading}
            onClick={sendEmail}
          >
            Send Code
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="border-2 rounded-5 border-border5 p-4">
        <Typography
          tag="static"
          className="md:text-18 md:leading-18 text-16 leading-18 text-blue/[.72] mb-6"
        >
          We have sent a verification code to your email
        </Typography>
        <div className="md:w-1/2 grid grid-cols-4 gap-6">
          <HTextField control={control} name="code" maxLength={4} inputClass="text-center w-full" />
        </div>
        <div className="pt-6 flex justify-end">
          <Button type="submit" layout="rectangled" variant="primary" className="px-2">
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

type VerifyPhoneType = Props & {
  payload: SubmitVerifyPayload;
};

export const VerifyPhone = ({ onClick, payload }: VerifyPhoneType) => {
  const [phone, setPhone] = useState<string>("");
  const [send, { isLoading }] = useSendPhoneMutation();
  const [submitVerify, { isLoading: isSubmiting }] = useSubmitVerifyMutation();

  const { handleSubmit, control } = useForm();

  const onSubmit = (data: VerifyEmailData) => {
    submitVerify({
      ...payload,
      phone_code: data.code || "",
    })
      .unwrap()
      .then(() => {
        onClick?.(4, {
          phone_code: data.code,
        });
      })
      .catch(() => {});
  };

  const sendPhone = () => {
    send(phone);
  };

  return (
    <div>
      <Typography
        tag="static"
        className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-2"
      >
        Verify Your Phone Number
      </Typography>
      <div className="border-2 rounded-5 border-border5 p-4 mb-4">
        <Typography
          tag="static"
          className="md:text-18 md:leading-18 text-16 leading-18 text-blue/[.9] mb-2"
        >
          Contact
        </Typography>
        <div className="md:flex md:flex-row items-center">
          <TextField
            name="phone"
            placeholder="Your phone number"
            variant="outlined-grey1"
            className="mr-6"
            layout="vertical"
            onChange={(e) => setPhone(e.target.value)}
          />
          <Button
            onClick={sendPhone}
            layout="rectangled"
            variant="outline-primary"
            className="md:px-2 py md:mt-0 mt-4"
            isLoading={isLoading}
          >
            Send Code
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="border-2 rounded-5 border-border5 p-4">
        <Typography
          tag="static"
          className="md:text-18 md:leading-18 text-16 leading-18 text-blue/[.72] mb-6"
        >
          We have sent a verification code to your Phone Number
        </Typography>
        <div className="md:w-1/2 grid grid-cols-4 gap-6">
          <HTextField control={control} name="code" maxLength={4} inputClass="text-center w-full" />
        </div>
        <div className="pt-6 flex justify-end">
          <Button
            type="submit"
            layout="rectangled"
            variant="primary"
            className="px-2"
            isLoading={isSubmiting}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export const VerificationPending = ({ onClick }: Props) => {
  return (
    <div className="border border-grey10 rounded-20 max-w-900px bg-white flex flex-col justify-center items-center px-8 py-12 box-border">
      <div className="w-105 h-105 flex justify-center items-center bg-blue rounded-full mb-6">
        <Check2 className="text-white w-60 h-60" />
      </div>
      <Typography
        tag="static"
        className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-10"
      >
        Verification pending
      </Typography>
      <Typography
        tag="static"
        className="md:text-18 md:leading-22 text-16 leading-18 font-medium text-blue"
      >
        In or to protect yor identity, will be confirming your email address through Blist web
        services.
      </Typography>
      <Typography
        tag="static"
        className="md:text-18 md:leading-22 text-16 leading-18 font-medium text-blue mb-10"
      >
        Simply click on the link in the email with 24 hours.
      </Typography>
      <Button variant="primary" onClick={() => onClick?.(5, {})}>
        Close
      </Button>
    </div>
  );
};

export const TermConditions = ({ onClick }: Props) => {
  return (
    <div className="border border-grey10 rounded-20 max-w-900px bg-white flex flex-col justify-center items-center px-8 py-12 box-border">
      <Typography tag="h2">Term & Conditions</Typography>
      <Button variant="primary">I Agreed</Button>
    </div>
  );
};
