import { Typography, IconButton } from "../atoms";

type Props = {
  title?: string;
};

export const DownloadApp = ({ title = "Ready to get started?" }: Props) => {
  return (
    <div className="bg-downloadBg px-4 md:px-0 flex flex-col justify-center text-center items-center md:py-8 py-4">
      <Typography tag="h2">{title}</Typography>
      <Typography tag="p" className="mt-2 mb-4 md:w-96">
        {" "}
      </Typography>
      <div className="flex flex-row items-center">
        <IconButton className="mx-2">
          <img
            src="/imgs/ios-download.png"
            width={162}
            height={51}
            alt="ios dowload"
            className="rounded-full mr-2"
          />
        </IconButton>
        <IconButton className="mx-2">
          <img
            src="/imgs/google-download.png"
            width={162}
            height={51}
            alt="ios dowload"
            className="rounded-full"
          />
        </IconButton>
      </div>
    </div>
  );
};
