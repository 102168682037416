import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { StripePrice } from "@app/types/stripe-price";

export const stripePriceApi = createApi({
  reducerPath: "stripePriceApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["StripePrice"],
  endpoints: (builder) => ({
    fetchPrice: builder.query<CommonResponse<StripePrice[]>, {}>({
      query: (params) => {
        return {
          url: "stripe-price",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: StripePrice) => ({ type: "StripePrice" as const, id })),
              "StripePrice",
            ]
          : ["StripePrice"],
    }),
  }),
});

export const { useFetchPriceQuery } = stripePriceApi;
