import { Control, useController } from "react-hook-form";
import { MultiSelect } from "../atoms";
import type { MultiSelectProps } from "../atoms";

interface Props extends MultiSelectProps {
  control: Control<any>;
  name: string;
}

export const HMultiSelect = ({ control, name, defaultValue, ...props }: Props) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <MultiSelect
      name={field.name}
      onChange={(data) => field.onChange(data)}
      value={field.value}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
