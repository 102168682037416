import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateFavoritePayload, FetchFavoritePayload } from "./types";
import { FavoriteItem } from "@app/types";

export const favoriteApi = createApi({
  reducerPath: "favoriteApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Favorite"],
  endpoints: (builder) => ({
    fetchFavorite: builder.query<CommonResponse<FavoriteItem[]>, FetchFavoritePayload>({
      query: (params) => {
        return {
          url: "favorite",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: FavoriteItem) => ({ type: "Favorite" as const, id })),
              "Favorite",
            ]
          : ["Favorite"],
    }),
    addToFavorite: builder.mutation<CommonResponse<FavoriteItem>, CreateFavoritePayload>({
      query(body) {
        return {
          url: `favorite`,
          method: "post",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({ show: true, type: "success", message: "Added to your favorite list." }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Favorite"],
    }),
    removeFavorite: builder.mutation<CommonResponse<FavoriteItem>, number>({
      query(id: number) {
        return {
          url: `favorite/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Favorite"],
    }),
  }),
});

export const { useFetchFavoriteQuery, useAddToFavoriteMutation, useRemoveFavoriteMutation } =
  favoriteApi;
