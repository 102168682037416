import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useCreateCategoryMutation, useUpdateCategoryMutation } from "@app/store/services/category";
import { CreateCategoryPayload } from "@app/store/services/category/types";
import { createCategoryValidate } from "@app/validations/category";
import { Category } from "@app/types/category";
import { categoryDefaultValue } from "@app/validations/category";

import { Button, UploadFile } from "../atoms";
import { HTextField, HUpload, HCategorySlider } from "../hookform";

type CreateCategoryData = CreateCategoryPayload & {};

type Props = {
  defaultValues?: Partial<Category>;
};

export const CategoryForm = ({ defaultValues }: Props) => {
  const [createCategory, { isLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isUpdating }] = useUpdateCategoryMutation();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<CreateCategoryData>({
    resolver: createCategoryValidate,
    defaultValues: {
      ...categoryDefaultValue,
      ...defaultValues,
      sliders: !!defaultValues?.sliders?.length
        ? defaultValues.sliders
        : categoryDefaultValue?.sliders,
    },
  });

  const onCreate: SubmitHandler<CreateCategoryData> = async (data) => {
    createCategory(data)
      .unwrap()
      .then(() => {
        navigate("/admin/category");
      })
      .catch(() => {});
  };

  const onUpdate: SubmitHandler<CreateCategoryData> = async (data) => {
    if (!defaultValues?.id) {
      return;
    }

    updateCategory({ ...data, id: defaultValues?.id })
      .unwrap()
      .then(() => {
        navigate("/admin/category");
      })
      .catch(() => {});
  };

  return (
    <form
      onSubmit={handleSubmit(defaultValues ? onUpdate : onCreate)}
      className="grid md:grid-cols-3 gap-5"
    >
      <div>
        <HUpload control={control} name="icon">
          <UploadFile />
        </HUpload>
      </div>
      <div className="col-span-2">
        <HTextField
          control={control}
          name="name"
          variant="outlined-black"
          border="full"
          label="Category Name"
          placeholder="Type here"
          className="mb-4"
        />
      </div>
      <div className="col-span-3">
        <div className="mb-2">
          <HCategorySlider control={control} name="sliders" maxRows={3} />
        </div>
        <Button
          type="submit"
          variant="primary"
          layout="rounded"
          isLoading={isLoading || isUpdating}
        >
          {defaultValues ? "Update Now" : "Create Now"}
        </Button>
      </div>
    </form>
  );
};
