import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const bookformValidation = yupResolver(
  yup.object({
    adults: yup.number().min(1).required(),
    date: yup.date().required(),
    children: yup.number().required(),
    infant: yup.number().required(),
    is_request: yup.string().required(),
    availability_time: yup.string().when("is_request", (is_request) => {
      if (is_request?.[0] === "No") {
        return yup.string().required();
      }
      return yup.string().nullable();
    }),
    date_request: yup.string(),
    time_request: yup.string(),
    notes: yup.array(yup.string().required()).required(),
  }),
);
