import { useController, Control } from "react-hook-form";
import { ChipButton } from "../atoms";
import type { ChipButtonProps } from "../atoms";
import clsx from "clsx";

interface Props extends Omit<ChipButtonProps, "value"> {
  control: Control<any>;
  name: string;
  isMulti?: boolean;
  value?: string | string[] | number | number[];
}

export const HChipButton = ({ control, name, value, className, isMulti, ...props }: Props) => {
  const { field } = useController({
    control,
    name,
  });

  const onClick = () => {
    if (isMulti) {
      if (field?.value?.includes(value)) {
        field.onChange(field.value.filter((item: string) => item !== value));
      } else {
        field.onChange([...(field.value || []), value]);
      }
    } else {
      field.onChange(value);
    }
  };

  return (
    <ChipButton
      onClick={onClick}
      {...props}
      className={clsx(
        className,
        (!isMulti && value === field.value) || (isMulti && field?.value?.includes(value))
          ? "!bg-primary !text-white"
          : "",
      )}
    />
  );
};
