import { BookingStatus } from "@app/enums";

export const bookingMessage = {
  [BookingStatus.ORDERED]: "The user created new booking for",
  [BookingStatus.COMPLETED_PAYMENT]: "The user confirmed to join this experience",
  [BookingStatus.WAITING_PAYMENT]: "The user created new booking for",
  [BookingStatus.USER_REQUEST_MODIFY]: "The user wants to modify this booking",
  [BookingStatus.USER_REQUEST_CANCEL]: "The user wants to cancel this booking",
  [BookingStatus.CREATOR_REQUEST_MODIFY]: "The creator wants to modify this booking",
  [BookingStatus.CREATOR_REQUEST_CANCEL]: "The creator wants to cancel this booking",
  [BookingStatus.CANCEL]: "The booking is cancelled",
  [BookingStatus.APPROVED]: "The creator confirmed this booking",
  [BookingStatus.DECLINED]: "The creator declined this booking",
  [BookingStatus.CHECK_IN]: "The user checked-in completely",
  [BookingStatus.REVIEWED]: "The user reviewed this booking",
};
