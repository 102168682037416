import { VerifyCardType } from "@app/enums";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const verifyCardValidation = yupResolver(
  yup.object({
    front_side: yup.string().required(),
    back_side: yup.string().required(),
    type: yup
      .string()
      .oneOf([VerifyCardType.DRIVER_LICENSE, VerifyCardType.IDENTIFY_CARD])
      .required(),
  }),
);
