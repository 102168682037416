import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreateReviewPayload, QueryReviewParams } from "./type";
import { Paging } from "@app/types";
import { Review } from "@app/types/review";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Review"],
  endpoints: (builder) => ({
    fetchReview: builder.query<CommonResponse<Review[]>, Paging & QueryReviewParams>({
      query: (params) => ({
        url: `review`,
        params,
      }),
      transformResponse: (response: CommonResponse<Review[]>) => response,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: Review) => ({ type: "Review" as const, id })), "Review"]
          : ["Review"],
    }),
    createReview: builder.mutation<CommonResponse<Review>, CreateReviewPayload>({
      query(body) {
        return {
          url: `review`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Review"],
    }),
  }),
});

export const { useFetchReviewQuery, useCreateReviewMutation } = reviewApi;
