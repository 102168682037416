import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Typography, Button, IconButton, MailOutline, Lock, Spinner } from "@app/components/atoms";
import { HTextField } from "@app/components/hookform";
import { authValidation } from "@app/validations";
import {
  useSigninMutation,
  useGoogleAuthMutation,
  useFacebookAuthMutation,
  useAppleAuthMutation,
} from "@app/store/services/auth";
import { SigninPayload } from "@app/store/services/auth/type";
import Styled from "styled-components";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { useInform } from "@app/store/hooks";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { facebookAppId, webUrl } from "@app/config";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import axios from "axios";
import AppleSignin from "react-apple-signin-auth";

const StyledText = Styled(Typography)`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-right: 16px;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-left: 16px;
  }
`;

export default function Signin() {
  const [signin, { isLoading }] = useSigninMutation();
  const [googleAuth, { isLoading: isGoogleVerifying }] = useGoogleAuthMutation();
  const [facebookAuth, { isLoading: isFacebookVerifying }] = useFacebookAuthMutation();
  const [appleAuth, { isLoading: isAppleVerifying }] = useAppleAuthMutation();
  const { handleSubmit, control } = useForm<SigninPayload>({
    resolver: authValidation,
  });
  const { openInform } = useInform();
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SigninPayload> = (data) => {
    signin(data)
      .unwrap()
      .then(() => url && navigate(url))
      .catch();
  };

  const onGoogleLogin = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">,
    ) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`,
        )
        .then((response) => {
          googleAuth(response.data)
            .unwrap()
            .then(() => url && navigate(url))
            .catch();
        });
    },
    onError: () => {
      openInform({
        show: true,
        type: "error",
        message: "We have an error with google authentication",
      });
    },
  });

  const onFacebookLogin = (response: ReactFacebookLoginInfo) => {
    facebookAuth(response.id)
      .unwrap()
      .then(() => url && navigate(url))
      .catch();
  };

  const onAppleLogin = (response: any) => {
    appleAuth(response.authorization.id_token)
      .unwrap()
      .then(() => url && navigate(url))
      .catch();
  };

  return (
    <div className="md:px-0 px-4 my-16">
      <div className="relative">
        {(isGoogleVerifying || isFacebookVerifying) && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner />
          </div>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="md:w-1/2 p-6 mx-auto border rounded-10 border-black/[.07]"
        >
          <div className="-mx-6 border-b border-black/[.07] text-center pb-6">
            <Typography tag="body1" className="font-bold">
              Log in
            </Typography>
          </div>
          <div className="pt-6">
            <Typography tag="h4" className="mb-4">
              Welcome to Blist
            </Typography>
            <HTextField
              name="email_address"
              control={control}
              startIcon={<MailOutline className="text-timeAgo" />}
              placeholder="Email Address"
            />
            <HTextField
              name="password"
              type="password"
              control={control}
              startIcon={<Lock className="text-timeAgo" />}
              placeholder="Password"
            />
          </div>
          <div className="flex items-center justify-between mb-8">
            <Link to="/signup">
              <Typography>Register</Typography>
            </Link>
            <Link to="/forgot-password">
              <Typography>Forgot Password</Typography>
            </Link>
          </div>
          <Button
            type="submit"
            variant="primary"
            layout="rectangled"
            className="w-full"
            isLoading={isLoading}
          >
            Log in
          </Button>
          <StyledText className="text-center my-4 flex flex-row items-center w-full">Or</StyledText>
          <div className="flex flex-col">
            <FacebookLogin
              appId={facebookAppId}
              callback={onFacebookLogin}
              render={({ onClick }: { onClick: () => void }) => (
                <IconButton
                  layout="rectangled"
                  className="border flex flex-row items-center px-3 py-2 mb-4"
                  type="button"
                  onClick={onClick}
                >
                  <img
                    src="/imgs/facebook.svg"
                    width={25}
                    height={25}
                    alt="icon"
                    className="mr-6"
                  />
                  Log in with Facebook
                </IconButton>
              )}
            />

            <IconButton
              layout="rectangled"
              className="border flex flex-row items-center px-3 py-2 mb-4"
              type="button"
              onClick={() => onGoogleLogin()}
            >
              <img src="/imgs/google.svg" width={25} height={25} alt="icon" className="mr-6" />
              Log in with Google
            </IconButton>
            <AppleSignin
              authOptions={{
                clientId: "com.blistnow.auth.sid",
                scope: "email name",
                redirectURI: `${webUrl}/signin`,
                state: "state",
                nonce: "nonce",
                usePopup: true,
              }}
              uiType="dark"
              className="apple-auth-btn"
              noDefaultStyle={false}
              buttonExtraChildren="Continue with Apple"
              onSuccess={(response: any) => onAppleLogin(response)}
              onError={(error: any) => console.error(error)}
              skipScript={false}
              render={(props: any) => (
                <IconButton
                  layout="rectangled"
                  className="border flex flex-row items-center px-3 py-2 mb-4"
                  type="button"
                  {...props}
                >
                  <img src="/imgs/apple.svg" width={25} height={25} alt="icon" className="mr-6" />
                  Log in with Apple
                </IconButton>
              )}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
