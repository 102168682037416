import {
  Typography,
  Button,
  EditSvg,
  CloseCircleSvg,
  TrashSvg,
  TextField,
  LocationSvg,
  WishlistLoading,
} from "../atoms";
import { ExperienceItem } from "../molecules";
import { Article, Pagination } from "../common";
import { RemovePopup, DeactivePopup } from "../organisms";
import {
  useFetchWishlistQuery,
  useRemoveWishlistMutation,
  useUpdateWishlistMutation,
} from "@app/store/services/wishlist";
import {
  useFetchPostQuery,
  useUpdatePostMutation,
  useRemovePostMutation,
} from "@app/store/services/post";
import { Wishlist } from "@app/types/wishlist";
import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { PostSortBy } from "@app/enums";
import clsx from "clsx";
import moment from "moment";
import { News, Post } from "@app/types";
import {
  useFetchNewsQuery,
  useLazyFetchNewsQuery,
  useRemoveNewsMutation,
  useUpdateNewsMutation,
} from "@app/store/services/news";
import { FetchNewsPayload } from "@app/store/services/news/types";

export const AdminWishlist = () => {
  const [content, setContent] = useState<string | undefined>();
  const { data, isLoading: isFetching } = useFetchWishlistQuery({
    content,
  });
  const [updateWishlist, { isLoading: isUpdating }] = useUpdateWishlistMutation();
  const [removeWishlist, { isLoading: isDeleting }] = useRemoveWishlistMutation();
  const [selectedItem, setSelectedItem] = useState<Wishlist | null>(null);
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const navigate = useNavigate();

  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }, 500);

  const onRemove = () => {
    selectedItem?.id &&
      removeWishlist(selectedItem?.id)
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowRemove(false);
        });
  };

  const onDeactive = () => {
    selectedItem?.id &&
      updateWishlist({ id: selectedItem?.id, is_active: false })
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowDeactive(false);
        });
  };

  const menuList = (item: Wishlist) => [
    {
      text: "Edit",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => navigate(`/admin/wishlist/update/${item.id}`),
    },
    {
      text: "Deactivate",
      icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowDeactive(true);
      },
    },
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowRemove(true);
      },
    },
  ];
  return (
    <div className="border-b border-border2 mb-6 pb-6 h-400 admin-scroll overflow-auto">
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={`this post`}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={`this post`}
      />
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Wishlist</Typography>
        <div className="flex md:flex-row flex-col">
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by keyword"
            endIcon={<LocationSvg />}
            className="!mb-0"
            onChange={onSeach}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
        {isFetching ? (
          <WishlistLoading />
        ) : (
          data?.data.map((item, key) => (
            <Article
              key={key}
              isPopular={item.is_popular}
              author={item.owner.first_name}
              content={item.content}
              city=""
              country=""
              state=""
              isEdit
              isDetail
              menuList={menuList(item)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export const AdminForum = () => {
  const [content, setContent] = useState<string | undefined>();
  const [sortBy, setSortBy] = useState<PostSortBy>(PostSortBy.NEW);
  const { data, isLoading: isFetching } = useFetchPostQuery({
    content,
    sortBy,
  });
  const [updateWishlist, { isLoading: isUpdating }] = useUpdatePostMutation();
  const [removeWishlist, { isLoading: isDeleting }] = useRemovePostMutation();
  const [selectedItem, setSelectedItem] = useState<Post | null>(null);
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);

  const onRemove = () => {
    selectedItem?.id &&
      removeWishlist(selectedItem?.id)
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowRemove(false);
        });
  };

  const onDeactive = () => {
    selectedItem?.id &&
      updateWishlist({ id: selectedItem?.id, is_active: false })
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowDeactive(false);
        });
  };

  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }, 500);

  const menuList = (item: Post) => [
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowRemove(true);
      },
    },
  ];
  return (
    <div className="border-b border-border2 mb-6 pb-6 h-400 admin-scroll overflow-auto">
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={`this post`}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={`this post`}
      />
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Forum</Typography>
        <div className="flex md:flex-row flex-col">
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by keyword"
            endIcon={<LocationSvg />}
            className="md:mr-2 md:!mb-0 !mb-2"
            onChange={onSeach}
          />
          <Button
            variant={sortBy === PostSortBy.BEST ? "primary" : "default"}
            className={clsx("md:mr-2 md:mb-0 mb-2")}
            onClick={() => setSortBy(PostSortBy.BEST)}
          >
            Best
          </Button>
          <Button
            variant={sortBy === PostSortBy.HOT ? "primary" : "default"}
            className={clsx("md:mr-2 md:mb-0 mb-2")}
            onClick={() => setSortBy(PostSortBy.HOT)}
          >
            Hot
          </Button>
          <Button
            variant={sortBy === PostSortBy.NEW ? "primary" : "default"}
            className={clsx("md:mr-2")}
            onClick={() => setSortBy(PostSortBy.NEW)}
          >
            New
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
        {isFetching ? (
          <WishlistLoading />
        ) : (
          data?.data.map((item, key) => (
            <div>
              <Article
                isPopular={item.is_popular}
                author={item.owner.first_name}
                content={item.content}
                photos={item.photos}
                city="Miami"
                country="USA"
                state=""
                isAction
                isEdit
                key={key}
                time={moment(item.createdAt).fromNow()}
                likes={item.like}
                comments={item.comment}
                id={item.id}
                menuList={menuList(item)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const AdminBlistUpdate = () => {
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState<string | undefined>();
  const navigate = useNavigate();
  const [params, setParams] = useState<FetchNewsPayload>({
    is_active: true,
  });

  const { data, isLoading } = useFetchNewsQuery({
    page: page,
    limit: 8,
    title,
    ...params,
  });
  const [triggerFetchNew] = useLazyFetchNewsQuery();

  const [update, { isLoading: isUpdating }] = useUpdateNewsMutation();
  const [remove, { isLoading: isDeleting }] = useRemoveNewsMutation();
  const [selectedItem, setSelectedItem] = useState<News | null>(null);
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);

  const onRemove = () => {
    selectedItem?.id &&
      remove(selectedItem?.id)
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowRemove(false);
        });
  };

  const onDeactive = () => {
    selectedItem?.id &&
      update({ id: selectedItem?.id, is_active: false })
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowDeactive(false);
        });
  };

  const onSetParams = (data: FetchNewsPayload) => {
    setParams((prev) => ({ ...prev, ...data }));
    triggerFetchNew({ ...params, ...data, page: page, limit: 8 });
  };

  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }, 500);

  const menuList = (item: News) => [
    {
      text: "Edit",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => navigate(`/admin/news/update/${item.id}`),
    },
    {
      text: "Deactivate",
      icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowDeactive(true);
      },
    },
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowRemove(true);
      },
    },
  ];
  return (
    <div className="h-400 admin-scroll overflow-auto">
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={selectedItem?.title}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={selectedItem?.title}
      />
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Blist Updates</Typography>
        <div className="flex md:flex-row flex-col">
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by keyword"
            endIcon={<LocationSvg />}
            className="md:mr-2 md:!mb-0 !mb-2"
            onChange={onSeach}
          />
          <Button
            variant={params.is_active ? "primary" : "outline-border"}
            className={clsx("md:mx-2 md:mb-0 mb-2", !params.is_active && "border-lightGrey")}
            onClick={() => onSetParams({ is_active: true })}
          >
            Activated
          </Button>
          <Button
            variant={!params.is_active ? "primary" : "outline-border"}
            className={clsx(!!params.is_active && "border-lightGrey")}
            onClick={() => onSetParams({ is_active: false })}
          >
            Deactivated
          </Button>
          <Button variant="primary" className="ml-2" onClick={() => navigate("/admin/news/add")}>
            Create new Article
          </Button>
        </div>
      </div>
      {isLoading ? (
        <WishlistLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-4 mb-6">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.content?.slice(0, 20)}
              url={item.photo}
              menuVariant="blue"
              isEdit
              menuList={menuList(item)}
              key={key}
              link={`news/${item.id}`}
            />
          ))}
        </div>
      )}
      <div className="flex flex-row justify-end">
        <Pagination />
      </div>
    </div>
  );
};
