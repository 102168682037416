import { webUrl } from "@app/config";
import { useAuth } from "@app/store/hooks";
import { useUpdateProfileMutation } from "@app/store/services/auth";
import qs from "qs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function EventBrite() {
  const [update] = useUpdateProfileMutation();
  const { updateProfile } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let url = window.location.href;
    url = url.replace(`${webUrl}/account/integration/event-brite#`, "");

    const params = qs.parse(url);

    if (params.access_token && typeof params.access_token === "string") {
      update({ event_brite_key: params.access_token })
        .unwrap()
        .then((res) => {
          updateProfile(res.data);
          navigate("/account/integration");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return null;
}
