import { ExperienceItem } from "../molecules";
import { Typography, ListItemLoading, Button } from "../atoms";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

type Props = {
  title?: string;
  userId?: string;
  categoryId?: number;
};

export const ExperienceSection = ({ title, userId }: Props) => {
  const { data, isLoading } = useFetchExperienceQuery(
    { user_id: Number(userId) },
    { skip: !userId },
  );
  return (
    <div className=" md:pb-14 pb-6">
      {title && (
        <div className="md:mb-6 mb-3">
          <Typography tag="h2" className="">
            {title}
          </Typography>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const BlistExperienceSection = ({ title, categoryId }: Props) => {
  const { data, isLoading } = useFetchExperienceQuery({
    limit: 5,
    is_blist: true,
    category_ids: categoryId ? [categoryId] : undefined,
    is_available: true,
  });
  return (
    <div className=" md:pb-14 pb-6">
      {title && (
        <div className="md:mb-6 mb-3">
          <Typography tag="h2" className="">
            {title}
          </Typography>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const TopExperienceSection = ({ title, categoryId }: Props) => {
  const { data, isLoading } = useFetchExperienceQuery({
    limit: 5,
    is_ads: true,
    category_ids: categoryId ? [categoryId] : undefined,
    is_available: true,
    is_top: true,
  });

  return (
    <div className=" md:pb-14 pb-6">
      {title && (
        <div className="md:mb-6 mb-3">
          <Typography tag="h2" className="">
            {title}
          </Typography>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const NewExperienceSection = ({ title, categoryId }: Props) => {
  const location = useLocation();
  const [page, setPage] = useState(5);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, isLoading } = useFetchExperienceQuery({
    limit: page,
    category_ids: categoryId ? [categoryId] : undefined,
    ...params,
    is_available: true,
  });

  return (
    <>
      <div className=" md:pb-14 pb-6">
        {title && (
          <div className="md:mb-6 mb-3">
            <Typography tag="h2" className="">
              {title}
            </Typography>
          </div>
        )}
        {isLoading ? (
          <ListItemLoading />
        ) : (
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
            {data?.data.map((item, key) => (
              <ExperienceItem
                title={item.title}
                content={item.summary}
                url={item.photos?.length ? item.photos[0] : undefined}
                key={key}
                id={item.id}
              />
            ))}
          </div>
        )}
      </div>
      <div className="text-center md:mb-8 mb-4">
        {data?.total && data.total > page ? (
          <Button
            variant="rounded"
            className="text-white bg-lightBlack"
            onClick={() => setPage((prev) => prev + 5)}
          >
            Load More
          </Button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
