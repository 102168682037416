import { useRef } from "react";
import { Control, Controller } from "react-hook-form";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import type SunEditorCore from "suneditor/src/lib/core";
import type { SunEditorReactProps } from "suneditor-react/dist/types/SunEditorReactProps";
import { Label, Typography } from "../atoms";
import clsx from "clsx";
import { convertHTML } from "@app/utils";

type Props = SunEditorReactProps & {
  name: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  layout?: "vertical" | "horizontal" | "static";
  maxCharCount?: number;
  subtitle?: string;
  control: Control<any>;
  maxLength?: number;
};

export const HTextEditor = ({
  name = "",
  label,
  defaultValue,
  required,
  layout,
  control,
  maxCharCount,
  subtitle,
  maxLength,
  ...props
}: Props) => {
  const editor = useRef<SunEditorCore>();
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <div
            className={clsx(
              layout === "horizontal" && "mb-4",
              layout === "vertical" && "flex flex-row items-center",
            )}
          >
            {label && (
              <div className={clsx("flex flex-row items-center mb-4")}>
                <Typography tag="h6" className="text-blue mr-2">
                  {label} {required && <span className="text-20 text-primary">*</span>}
                </Typography>
                {subtitle && <p className="text-placeholder1 font-medium">{subtitle}</p>}
              </div>
            )}
            <div className="w-full">
              <SunEditor
                defaultValue={field.value}
                getSunEditorInstance={getSunEditorInstance}
                {...props}
                setOptions={{
                  minHeight: String(250),
                  maxHeight: String(400),
                  plugins,
                  buttonList: [
                    ["undo", "redo"],
                    [],
                    ["bold", "underline", "italic"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list"],
                    ["removeFormat"],
                  ],
                }}
                onChange={(textValue: string) => {
                  const result: string = editor?.current?.getText?.()?.trim() || "";

                  if (maxLength && result?.split(" ").length > maxLength) return;

                  field.onChange(result ? textValue : "");
                }}
              />
              {maxLength && (
                <Typography className="text-right py-1" tag="body">
                  {convertHTML(field?.value).split(" ")?.length
                    ? convertHTML(field?.value).split(" ").length - 1
                    : "0"}
                  /{maxLength}
                </Typography>
              )}
              {error?.message && <Label variant="error">{error.message}</Label>}
            </div>
          </div>
        );
      }}
    />
  );
};
