import { useSettings } from "@app/store/hooks";
import { useEffect } from "react";

export const Geolocation = () => {
  const { setLocation } = useSettings();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  return null;
};
