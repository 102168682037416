import { useState } from "react";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import {
  Typography,
  Button,
  CloseCircleSvg,
  SmsEditSvg,
  CalendarEditSvg,
  ListItemLoading,
} from "../atoms";
import { ExperienceItem } from "../molecules";
import { CreatorType, ExpBusiness, ExpCreatorType } from "@app/enums";
import { useAuth } from "@app/store/hooks";
import clsx from "clsx";

type Props = {
  title?: string;
};

const menuList = [
  {
    text: "Message User",
    icon: <SmsEditSvg className="mr-2 stroke-white w-19 h-19" />,
    action: () => {},
  },
  {
    text: "Reschedule",
    icon: <CalendarEditSvg className="mr-2 stroke-white w-19 h-19" />,
    action: () => {},
  },
  {
    text: "Cancel",
    icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
    action: () => {},
  },
];

export const CalendarExperience = ({ title }: Props) => {
  const [creatorType, setCreatorType] = useState<ExpBusiness>(ExpBusiness.INDIVIDUAL);
  const { profile } = useAuth();
  const { data, isLoading } = useFetchExperienceQuery({
    limit: 5,
    user_id: profile.id,
    business: ExpBusiness.INDIVIDUAL,
  });
  return (
    <div className="md:pb-8 pb-4">
      {title && (
        <div className="md:mb-8 mb-4 flex md:flex-row flex-col md:justify-between md:items-center">
          <Typography tag="h2">{title}</Typography>
          <div className="my-2">
            <Button
              variant={creatorType === ExpBusiness.INDIVIDUAL ? "primary" : "outline-border"}
              className={clsx(
                creatorType !== ExpBusiness.INDIVIDUAL && "border-lightGrey",
                "mr-2 py-10px",
              )}
              onClick={() => setCreatorType(ExpBusiness.INDIVIDUAL)}
            >
              Individual
            </Button>
            <Button
              variant={creatorType === ExpBusiness.VENUE ? "primary" : "outline-border"}
              className={clsx(creatorType !== ExpBusiness.VENUE && "border-lightGrey", "py-10px")}
              onClick={() => setCreatorType(ExpBusiness.VENUE)}
            >
              Venue
            </Button>
          </div>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
              isEdit
              menuList={menuList}
            />
          ))}
        </div>
      )}
    </div>
  );
};
