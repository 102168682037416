import clsx from "clsx";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

type Props = {
  url: string;
  className?: string;
};

export const ShareSocial = ({ url, className }: Props) => {
  return (
    <div className={clsx(className, "grid grid-cols-6 gap-2 bg-white")}>
      <EmailShareButton url={url}>
        <EmailIcon size={18} />
      </EmailShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon size={18} />
      </FacebookShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={18} />
      </LinkedinShareButton>
      <TelegramShareButton url={url}>
        <TelegramIcon size={18} />
      </TelegramShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={18} />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={18} />
      </WhatsappShareButton>
    </div>
  );
};
