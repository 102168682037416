import { useFieldArray, Control } from "react-hook-form";
import { AddSquareSvg, IconButton, MinusSquareSvg } from "../atoms";
import { HTimePicker } from "../hookform";

interface Props {
  control: Control<any>;
  name: string;
  className?: string;
}

export const HAvailabilityTime = ({ control, name, className }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div className={className} key={field.id}>
          <HTimePicker
            variant="outlined-black"
            layout="rounded"
            name={`${name}.${index}`}
            control={control}
            dateFormat="hh:mm a"
          />
          <IconButton type="button" onClick={() => append(new Date())}>
            <AddSquareSvg />
          </IconButton>
          {fields.length > 1 && (
            <IconButton type="button" onClick={() => remove(index)}>
              <MinusSquareSvg />
            </IconButton>
          )}
        </div>
      ))}
    </>
  );
};
