import { Address } from "@app/types";
import {
  CalendarIconSvg,
  ClockIconSvg,
  DollarCircleSvg,
  EditSvg,
  GlobalSvg,
  ProfileSvg,
  Typography,
} from "../atoms";
import { s3Url } from "@app/config";

export const ExperienceHeaderTitle = ({ url, title }: { url?: string; title: string }) => {
  return (
    <div className="flex flex-row">
      <img
        src={url ? `${s3Url}${url}` : "/imgs/default-image.jpg"}
        className="mr-4 w-56 h-56 rounded-full"
        alt="experience"
      />
      <Typography className="text-blue font-semibold">{title}</Typography>
    </div>
  );
};

export const ExperienceChatContent = ({
  datetime,
  addresses,
  summary,
  price,
  guest_bring,
  userAmount,
}: {
  datetime?: string;
  addresses?: Address[];
  summary?: string;
  price?: number;
  guest_bring?: string;
  userAmount?: number;
}) => {
  return (
    <div className="pt-3">
      <div className="flex flex-row border-b border-lineBreak py-2">
        <CalendarIconSvg className="mr-2" />
        <div>
          <Typography tag="description">Date/Time:</Typography>
          <Typography className="text-blue font-medium">{datetime}</Typography>
        </div>
      </div>
      <div className="flex flex-row border-b border-lineBreak py-2">
        <GlobalSvg className="mr-2" />
        <div>
          <Typography tag="description">Location:</Typography>
          {addresses?.map((item, key) => (
            <Typography
              key={key}
              className="text-blue font-medium"
            >{`${item.street_address} ${item.zip_code} ${item.state} ${item.city}, ${item.country}`}</Typography>
          ))}
        </div>
      </div>
      <div className="flex flex-row border-b border-lineBreak py-2">
        <EditSvg className="mr-2 stroke-primary w-19 h-19" />
        <div>
          <Typography tag="description">Summary</Typography>
          <Typography className="text-blue font-medium">{summary}</Typography>
        </div>
      </div>
      <div className="flex flex-row border-b border-lineBreak py-2">
        <DollarCircleSvg className="mr-2 stroke-primary w-19 h-19" />
        <div>
          <Typography tag="description">Price</Typography>
          <Typography className="text-blue font-medium">${price}</Typography>
        </div>
      </div>
      <div className="flex flex-row border-b border-lineBreak py-2">
        <ClockIconSvg className="mr-2 stroke-primary" />
        <div>
          <Typography tag="description">What to bring</Typography>
          <Typography className="text-blue font-medium">{guest_bring}</Typography>
        </div>
      </div>
      <div className="flex flex-row py-2 border-b border-lineBreak mb-2 ">
        <ProfileSvg className="mr-2 stroke-primary w-19 h-19" />
        <div>
          <Typography tag="description">You and x amount of guest:</Typography>
          <Typography className="text-blue font-medium">{userAmount}</Typography>
        </div>
      </div>
    </div>
  );
};

// export const ExperienceChatItem = ({ item }: { item: Booking }) => {
//   return (
//     <div className="pb-4 mb-4 border-b border-lineBreak">
//       <Accordion
//         header={<HeaderTitle url={item.experience.photos?.[0]} title={item.experience.title} />}
//         expandIcon={<ChevronDown className="text-18" />}
//         content={
//           <Content
//             datetime={`${moment(item.date).format("DD MMM YYYY")} ${moment(
//               item.availability_time,
//               "HH:mm:ss+00",
//             ).format("h:mm:ss a")}`}
//             location={item.experience.addresses?.[0]?.address}
//             summary={item.experience.summary}
//             price={item.experience.amount}
//             guest_bring={item.experience.guest_bring}
//             userAmount={item.adults + item.children + item.infant}
//           />
//         }
//       />
//     </div>
//   );
// };
