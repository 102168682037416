import { createApi } from "@reduxjs/toolkit/query/react";
import type { News } from "@app/types";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateNewsPayload, UpdateNewsPayload, FetchNewsPayload } from "./types";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["News"],
  endpoints: (builder) => ({
    fetchNews: builder.query<CommonResponse<News[]>, FetchNewsPayload>({
      query: (params) => {
        return {
          url: "news",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: News) => ({ type: "News" as const, id })), "News"]
          : ["News"],
    }),
    createNews: builder.mutation<CommonResponse<News>, CreateNewsPayload>({
      query(body) {
        return {
          url: `news`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["News"],
    }),
    updateNews: builder.mutation<CommonResponse<News>, UpdateNewsPayload>({
      query({ id, ...body }) {
        return {
          url: `news/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),
    getNews: builder.query<News, number>({
      query: (id: number) => `news/${id}`,
      transformResponse: (response: CommonResponse<News>) => response?.data,
      providesTags: (result, error, id) => [{ type: "News", id }],
    }),
    removeNews: builder.mutation<CommonResponse<News>, number>({
      query(id: number) {
        return {
          url: `news/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["News"],
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useLazyFetchNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useRemoveNewsMutation,
  useGetNewsQuery,
} = newsApi;
