import { useInform } from "@app/store/hooks";
import { IconButton, Typography, OutlineXMark } from "../atoms";
import clsx from "clsx";
import { useEffect } from "react";

export const Inform = () => {
  const state = useInform();

  useEffect(() => {
    if (state.show) {
      const id = setTimeout(() => {
        state.closeInform();
      }, 5000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [state.show]);

  if (!state.show) {
    return null;
  }

  return (
    <div
      className={clsx(
        "rounded-10 fixed w-300 right-4 top-4 px-4 py-2 flex flex-row justify-between z-50",
        state.type === "error" && "bg-chipDangerBg",
        state.type === "success" && "bg-green",
      )}
    >
      <Typography
        className={clsx(
          state.type === "error" && "text-chipDangerText",
          state.type === "success" && "text-white",
        )}
      >
        {state.message}
      </Typography>
      <IconButton onClick={() => state.closeInform()}>
        <OutlineXMark
          className={clsx(state.type === "success" ? "text-white" : "text-chipDangerText")}
        />
      </IconButton>
    </div>
  );
};
