import {
  Typography,
  ChevronCircleLeft,
  IconButton,
  BookingLoading,
  SlideLoading,
} from "@app/components/atoms";
import { ProfileInformation, ProfileMedia, ProfileDetail } from "@app/components/organisms";
import { useGetApprenticeQuery } from "@app/store/services/apprentice";
import { useParams, useNavigate } from "react-router-dom";

export default function ApprenticeProfile() {
  const { apprenticeId } = useParams<{ apprenticeId: string }>();
  const { data, isSuccess } = useGetApprenticeQuery(apprenticeId || "", { skip: !apprenticeId });
  const navigate = useNavigate();

  if (!isSuccess) {
    return (
      <div className="py-6">
        <BookingLoading />
        <div className="py-3">
          <SlideLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="relative flex flex-row items-center justify-center pb-6">
        <IconButton className="absolute z-20 left-0" onClick={() => navigate(-1)}>
          <ChevronCircleLeft className="w-40 h-40 bg-border2 border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
        </IconButton>
        <Typography tag="title">{data?.name}</Typography>
      </div>
      <div className="mb-6">
        <ProfileInformation {...data} />
      </div>
      <div className="mb-6">
        <ProfileMedia />
      </div>
      <div>
        <ProfileDetail />
      </div>
    </div>
  );
}
