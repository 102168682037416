export enum Gender {
  male = "male",
  female = "female",
}

export enum Role {
  creator = "creator",
  user = "user",
  admin = "admin",
}

export enum CreatorType {
  individualCreator = "individual_creator",
  venue = "venue",
}

export enum Notify {
  EMAIL = "email",
  SMS = "sms",
}
