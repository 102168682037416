import { Button, Modal, Typography } from "../atoms";
import { includes, upperFirst, map } from "lodash";
import { Booking, Experience } from "@app/types";
import { s3Url } from "@app/config";
import moment from "moment";
import { BookingStatus, Role } from "@app/enums";
import { useAuth, useInform } from "@app/store/hooks";
import { useState, useMemo } from "react";
import { CustomPopup } from "../organisms";
import { useUpdateBookingMutation } from "@app/store/services/booking";
import { getDay } from "date-fns";
import { HCalendar, HTimeSlot } from "../hookform";
import { daysNumber } from "@app/config";
import { CreateBookingPayload } from "@app/store/services/booking/type";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useFetchHolidaysQuery } from "@app/store/services/holiday";

type ModifyExperienceFormProps = {
  bookingId?: number;
  onClose: () => void;
  experience?: Experience;
};

type CreateBookingForm = Omit<
  CreateBookingPayload,
  "experience_id" | "start_date" | "end_date" | "date"
> & {
  date: Date;
};

export const ModifyExperienceForm = ({
  bookingId,
  onClose,
  experience,
}: ModifyExperienceFormProps) => {
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();
  const { handleSubmit, control, watch, ...methods } = useForm<CreateBookingForm>({
    defaultValues: {
      date: moment().toDate(),
    },
  });
  const { profile } = useAuth();

  const { data: holidays } = useFetchHolidaysQuery(
    { user_id: experience?.user_id || 0 },
    { skip: !experience?.user_id },
  );

  const onSubmit: SubmitHandler<CreateBookingForm> = (data) => {
    if (!bookingId) return;

    updateBooking({
      id: bookingId,
      date_request: data?.date && moment(data?.date).format("YYYY-MM-DD"),
      time_request: `2024-10-10T${data.availability_time}:00.000Z` as string,
      status:
        profile.role === Role.creator
          ? BookingStatus.CREATOR_REQUEST_MODIFY
          : BookingStatus.USER_REQUEST_MODIFY,
    })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  const filterDate = (date: Date) => {
    const day = getDay(date);
    const numDays = map(experience?.available_days, (item) => daysNumber[item]);
    return includes(numDays, day);
  };

  const excludeDates = useMemo(() => {
    if (!holidays) return [];
    return holidays.map((item) => moment(item.date_time).toDate());
  }, [holidays]);

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit} control={control} watch={watch}>
      <form onSubmit={handleSubmit(onSubmit)} className="px-4 rounded-10">
        <div className="mb-6">
          <div className="my-4">
            <HCalendar
              control={control}
              name="date"
              filterDate={filterDate}
              excludeDates={excludeDates}
              minDate={moment().toDate()}
            />

            <HTimeSlot
              control={control}
              name="availability_time"
              availabilityTime={experience?.availability_time || []}
            />
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="primary"
            layout="rectangled"
            isLoading={isLoading}
            className="mr-2"
          >
            Request
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            layout="rectangled"
            isLoading={isLoading}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

type Props = {
  item: Booking;
};
const nights = 1;

export const BookingItem = ({ item }: Props) => {
  const { profile } = useAuth();
  const [isModify, setIsModify] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();
  const { openInform } = useInform();

  const onCancel = () => {
    updateBooking({
      id: item.id,
      status:
        profile.role === Role.creator
          ? BookingStatus.CREATOR_REQUEST_CANCEL
          : BookingStatus.USER_REQUEST_CANCEL,
    })
      .unwrap()
      .then(() => {
        setIsCancel(false);
        openInform({ show: true, type: "success", message: "Confirmed Request" });
      })
      .catch();
  };

  return (
    <div>
      <Modal show={isModify} onClose={() => setIsModify(false)}>
        <ModifyExperienceForm
          onClose={() => setIsModify(false)}
          bookingId={item.id}
          experience={item.experience}
        />
      </Modal>
      <CustomPopup
        isShow={isCancel}
        question="Do you want to request the cancellation for this booking?"
        onClose={() => setIsCancel(false)}
        onSubmit={onCancel}
        isLoading={isLoading}
      />
      <div className="flex md:flex-row flex-col justify-between items-center mb-4">
        <div className="flex md:flex-row flex-col items-center md:mb-0 mb-3">
          <div className="mr-2">
            <img
              src={
                item.customer.avatar
                  ? `${s3Url}${item.customer.avatar}`
                  : "/imgs/default-avatar.png"
              }
              className="w-60 h-60"
              alt={item.customer.first_name}
            />
          </div>
          <Typography tag="h5" className="!font-inter !font-normal">
            <span className="text-primary">{upperFirst(item.status)}</span> -{" "}
            {item.experience.title}
          </Typography>
        </div>
        <Typography tag="body1" className="text-blue/[.6]">
          {moment(item?.date).format("MMM DD")} -{" "}
          {Number(item.adults) + Number(item.children) + Number(item.infant)} Guest -
          <span className="text-blue/[.9] font-bold">
            ${Number(item?.experience.amount || 0) * Number(nights)}
          </span>
        </Typography>
      </div>
      <Typography tag="h5" className="!font-inter !font-normal mb-5">
        {item.notes}
      </Typography>
      {includes([BookingStatus.APPROVED, BookingStatus.COMPLETED_PAYMENT], item.status) && (
        <div>
          <Button
            type="button"
            variant="primary"
            layout="rectangled"
            className="md:mr-3 mr-1"
            onClick={() => setIsModify(true)}
          >
            Modify
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            layout="rectangled"
            className="md:mr-3 mr-1"
            onClick={() => setIsCancel(true)}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};
