import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import type { Booking, BookingCalendar } from "@app/types/booking";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import {
  CreateBookingPayload,
  UpdateBookingPayload,
  QueryBookingPayload,
  QueryCalendarPayload,
} from "./type";
import { Paging } from "@app/types";

export const bookingApi = createApi({
  reducerPath: "bookingApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Booking"],
  endpoints: (builder) => ({
    fetchBooking: builder.query<CommonResponse<Booking[]>, Paging & QueryBookingPayload>({
      query: (params) => ({
        url: `booking`,
        params,
      }),
      transformResponse: (response: CommonResponse<Booking[]>) => response,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: Booking) => ({ type: "Booking" as const, id })), "Booking"]
          : ["Booking"],
    }),
    createBooking: builder.mutation<Booking, CreateBookingPayload>({
      query(body) {
        return {
          url: `booking`,
          method: "POST",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Booking>) => response.data,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Booking"],
    }),
    updateBooking: builder.mutation<Booking, UpdateBookingPayload>({
      query({ id, ...body }) {
        return {
          url: `booking/${id}`,
          method: "PATCH",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Booking>) => response.data,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Booking"],
    }),
    getBooking: builder.query<Booking, number>({
      query: (params) => ({
        url: `booking/${params}`,
      }),
      transformResponse: (response: CommonResponse<Booking>) => response.data,
      providesTags: (result, error, id) => [{ type: "Booking", id }],
    }),
    fetchCalendar: builder.query<BookingCalendar[], QueryCalendarPayload>({
      query: (params) => ({
        url: `booking/calendar`,
        params,
      }),
      transformResponse: (response: CommonResponse<BookingCalendar[]>) => response.data,
    }),
  }),
});

export const {
  useCreateBookingMutation,
  useGetBookingQuery,
  useFetchBookingQuery,
  useUpdateBookingMutation,
  useFetchCalendarQuery,
  useLazyGetBookingQuery,
} = bookingApi;
