import {
  TrackingUserBanner,
  EmergencyContactSection,
  TrackingIntroduce,
} from "@app/components/organisms";
import { useAuth } from "@app/store/hooks";
import { useGetTrackingQuery } from "@app/store/services/tracking";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import NoPermissionPage from "../no-permission-page";
import { Role } from "@app/enums";

export default function Tracking() {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const { profile } = useAuth();

  const isPermission = useMemo(() => {
    // check if you have access
    if (data?.authorized_people?.find((item) => item.id === profile.id)) {
      return true;
    }

    return false;
  }, [data]);

  if (!isPermission) return <NoPermissionPage />;

  return (
    <div className="px-6">
      <div className=" py-8">
        {profile.role !== Role.creator && <TrackingUserBanner />}
        <EmergencyContactSection isNotRemove />
      </div>
      <TrackingIntroduce />
    </div>
  );
}
