import { useNavigate } from "react-router-dom";
import { Typography, Button } from "../atoms";
import { ExpBusiness } from "@app/enums";

export const CreatorIntroduce = () => {
  const navigate = useNavigate();
  return (
    <div className="rounded-20 grid gap-5 md:grid-cols-5 place-items-center border border-border2 bg-headerBg mt-4 p-4 relative">
      <div className="md:col-span-3">
        <Typography tag="h2" className="mb-2">
          Create New Experience
        </Typography>
        <Typography className="text-lightGrey md:mb-8 mb-2" isColor>
          {""}
        </Typography>
        <Typography tag="h4" className="mb-2">
          CREATE NOW AS AN:
        </Typography>
        <div className="flex flex-row items-center">
          <Button
            className="mr-2"
            variant="primary"
            type="button"
            onClick={() => navigate(`/create-experience/${ExpBusiness.INDIVIDUAL}`)}
          >
            {ExpBusiness.INDIVIDUAL}
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => navigate(`/create-experience/${ExpBusiness.VENUE}`)}
          >
            {ExpBusiness.VENUE}
          </Button>
        </div>
      </div>
      <img src="/imgs/image-26.jpg" alt="banner" className="col-span-2 rounded-20" width="100%" />
    </div>
  );
};
