import clsx from "clsx";
import { ReactNode } from "react";
import ReactSelect, { components } from "react-select";
import type { Props, MultiValueGenericProps, MenuProps, MenuListProps } from "react-select";
import { Typography } from "./typography";
import Styled from "styled-components";

const StyledOption = Styled.div`
  color: #fff;
  font-weight: 400;
  .avatar {
    display: none;
  }
  .label {
    color: #fff;
    font-weight: 400;
  }
`;

export interface UserSelectProps extends Props {
  label?: string;
  layout?: "row" | "column";
  variant?: "static" | "underline" | "outlined";
  customLabel?: ReactNode;
  hint?: string;
}

const Menu = ({ children }: MenuProps) => <div className="">{children}</div>;

const MenuList = ({ children }: MenuListProps) => <div>{children}</div>;

const MultiValueLabel = ({ children, ...props }: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props}>
    <StyledOption>{children}</StyledOption>
  </components.MultiValueLabel>
);

const UserFormatOptionLabel = ({ label, avatar }: { label: string; avatar: string }) => {
  return (
    <div className="flex flex-row">
      {avatar && <img src={avatar} className="w-40 h-40 rounded-full mr-4 avatar" alt={label} />}
      <Typography isColor className="text-blue label">
        {label}
      </Typography>
    </div>
  );
};

export const UserSelect = ({
  label,
  layout = "row",
  variant = "outlined",
  defaultValue = null,
  customLabel,
  ...props
}: UserSelectProps) => {
  return (
    <div
      className={clsx(
        "mb-2",
        layout === "column" && "flex flex-col",
        layout === "row" && "flex flex-row justify-between items-center",
      )}
    >
      {label && (
        <Typography
          tag="h6"
          className={clsx(
            "text-blue !self-auto",
            layout === "row" && "mr-4",
            layout === "column" && "mb-2",
          )}
        >
          {label}
        </Typography>
      )}
      {customLabel}
      <div className="border rounded-10 border-border3/[.1] w-full">
        <ReactSelect
          defaultValue={defaultValue}
          defaultMenuIsOpen={true}
          formatOptionLabel={UserFormatOptionLabel as any}
          hideSelectedOptions={false}
          components={{
            IndicatorsContainer: () => <></>,
            Menu,
            MenuList,
            MultiValueLabel,
          }}
          classNames={{
            container: () => "block w-full",
            indicatorsContainer: () => "hidden",
            indicatorSeparator: () => "hidden",
            control: () => "!border-0 rounded-10 !bg-transparent min-h-49",
            multiValue: () =>
              "!bg-blue text-white min-w-100 py-1 font-semibold border-blue !rounded-full px-2",
            multiValueRemove: () => "hover:!bg-transparent hover:!text-white/[.4]",
            option: ({ isSelected }) =>
              clsx("!text-blue hover:!bg-footerBg", isSelected ? "!bg-footerBg" : "!bg-white"),
            valueContainer: () => "min-h-49",
          }}
          {...props}
        />
      </div>
    </div>
  );
};
