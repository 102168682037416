import { useController, Control } from "react-hook-form";
import { Radio } from "../atoms";
import type { RadioProps } from "../atoms";

interface Props extends Omit<RadioProps, "value"> {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  value: string | number | boolean;
}

export const HRadio = ({ control, name, defaultValue, value, ...props }: Props) => {
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onChange = () => {
    field.onChange(value);
  };

  return <Radio onChange={onChange} checked={field.value === value} ref={ref as any} {...props} />;
};
