import clsx from "clsx";
import { Typography, Star3Svg } from "../atoms";

type Props = {
  className?: string;
  text?: string;
  point?: number;
};

export const ExperienceRatingItem = ({ text, className, point = 0 }: Props) => {
  return (
    <div className={className}>
      <div className="flex flex-row justify-between items-center">
        <Typography className="text-grey8 font-medium">{text}</Typography>
        <div className="flex flex-row">
          <Star3Svg className={clsx(point > 0 && "stroke-primary fill-primary")} />
          <Star3Svg className={clsx(point > 1 && "stroke-primary fill-primary")} />
          <Star3Svg className={clsx(point > 2 && "stroke-primary fill-primary")} />
          <Star3Svg className={clsx(point > 3 && "stroke-primary fill-primary")} />
          <Star3Svg className={clsx(point > 4 && "stroke-primary fill-primary")} />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="border-b border border-grey9 flex-1" />
        <Typography className="text-blue pl-2">{point} star</Typography>
      </div>
    </div>
  );
};
