import { useController, Control } from "react-hook-form";
import { Checkbox } from "../atoms";
import type { CheckBoxProps } from "../atoms";

interface Props extends Omit<CheckBoxProps, "value"> {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  value: string | number | boolean;
  isMulti?: boolean;
}

export function HCheckbox({ control, name, defaultValue, value, isMulti, ...props }: Props) {
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onChange = () => {
    if (isMulti) {
      if (field?.value?.includes(value)) {
        field.onChange(field.value.filter((item: string) => item !== value));
      } else {
        field.onChange([...(field.value || []), value]);
      }
    } else {
      field.onChange(value);
    }
  };

  return (
    <Checkbox
      name={name}
      onClick={onChange}
      checked={(!isMulti && field.value === value) || (isMulti && field?.value?.includes(value))}
      ref={ref as any}
      {...props}
    />
  );
}
