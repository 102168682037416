import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../";
import { SettingsState, setLocation } from "../reducers/settings";

interface Props extends SettingsState {
  setLocation: (data: { lat: number; lng: number }) => void;
}

export const useSettings = (): Props => {
  const allState = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();

  return {
    ...allState,
    setLocation: (data: { lat: number; lng: number }) => dispatch(setLocation(data)),
  };
};
