import Styled from "styled-components";
import { Button } from "../../atoms";

export const StyleButtonChip = Styled.div`
  button {
    color: #F86A27;
    padding: 6px 9px;
    font-size: 12px;
    svg {
      fill: #F86A27;
      stroke-width: 0.4px;
      stroke: #F86A27;
    }
    &:hover, &.active {
      svg {
      fill: #fff;
      stroke-width: 0.4px;
      stroke: #fff;
    }
    }
  }
`;

export const StyledButton = Styled(Button)`
  padding: 5px 10px;
  min-width: 75px;
  font-weight:400;
`;
