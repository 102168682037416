import { useController, Control } from "react-hook-form";
import { Calendar, CalendarProps } from "../common";

interface Props extends Omit<CalendarProps, "onChange"> {
  control: Control<any>;
  name: string;
  defaultValue?: Date;
}

export const HCalendar = ({ control, name, defaultValue, ...props }: Props) => {
  const { field } = useController({
    control,
    name,
    defaultValue,
  });

  const onChange = (data: Date | null) => {
    field.onChange(data);
  };

  return <Calendar {...props} onChange={onChange} value={field.value} />;
};
