import { Button } from "@app/components/atoms";
import { useForm, SubmitHandler } from "react-hook-form";
import { HSelect, HTextField } from "@app/components/hookform";
import { useNavigate } from "react-router-dom";
import { CreateUserPayload } from "@app/store/services/user/type";
import { useCreateUserMutation } from "@app/store/services/user";
import { createUserValidation } from "@app/validations";
import { roleOptions } from "@app/config/user";

type Props = {};

export const AdminUserForm = ({}: Props) => {
  const { control, handleSubmit } = useForm<CreateUserPayload>({
    resolver: createUserValidation,
  });
  const navigate = useNavigate();

  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();

  const onCreate: SubmitHandler<CreateUserPayload> = async (payload) => {
    try {
      await createUser(payload).unwrap();

      navigate("/admin/user");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form className="py-3 px-8" onSubmit={handleSubmit(onCreate)}>
      <HTextField
        control={control}
        name="first_name"
        label="First name"
        variant="outlined-black"
        border="full"
      />
      <HTextField
        control={control}
        name="last_name"
        label="Last name"
        variant="outlined-black"
        border="full"
      />
      <HTextField
        control={control}
        name="email_address"
        label="Email"
        variant="outlined-black"
        border="full"
      />
      <HSelect control={control} name="role" label="Role" layout="column" options={roleOptions} />
      <div className="flex flex-row mt-4">
        <Button type="submit" variant="primary" className="ml-2 flex-1" isLoading={isCreating}>
          Create
        </Button>
      </div>
    </form>
  );
};
