import { Link } from "react-router-dom";

import {
  IconButton,
  Typography,
  MoreSvg,
  ChipButton,
  HeartFullSvg,
  HeartOutlineSvg,
} from "../atoms";
import { ReactNode, useRef, forwardRef, LegacyRef, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { s3Url } from "@app/config";
import { useAuth, useFavorite } from "@app/store/hooks";
import { useAddToFavoriteMutation, useRemoveFavoriteMutation } from "@app/store/services/favorite";
import { find, truncate } from "lodash";

type ActionListProps = {
  menuList?: { text: string; icon: ReactNode; action: () => void }[];
  menuClassName?: string;
  menuVariant?: "white" | "blue" | "static";
};

type Props = ActionListProps & {
  title?: string;
  content?: string;
  url?: string;
  isEdit?: boolean;
  customActionList?: ReactNode;
  id?: number;
  link?: string;
};

const ActionList = forwardRef(
  (
    { menuList, menuClassName, menuVariant = "white" }: ActionListProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "absolute hidden flex flex-col justify-start items-start right-6 top-0 rounded-10",
          menuVariant === "white" && " w-150 border bg-white/[0.4] border-white/[0.09]",
          menuVariant === "blue" && " w-200 border bg-blue border-white/[0.09]",
          menuClassName,
        )}
      >
        {menuList?.map((item, key) => (
          <ChipButton
            key={key}
            className={clsx(
              "text-14 border-b border-white/[.1] px-2 py-1 !rounded-none w-full !justify-start",
              menuVariant === "white" && "text-blue",
              menuVariant === "blue" && "text-white",
            )}
            variant="blank"
            startIcon={item.icon}
            onClick={item.action}
          >
            {item.text}
          </ChipButton>
        ))}
      </div>
    );
  },
);

export const ExperienceItem = ({
  title,
  content,
  url,
  isEdit,
  customActionList,
  id,
  link,
  ...props
}: Props) => {
  const { isLoggedIn } = useAuth();
  const { list } = useFavorite();
  const [addToFavorite] = useAddToFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const listRef = useRef<HTMLDivElement>(null);
  const [length, setLength] = useState(80);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.stopPropagation();
      if (listRef.current && !listRef.current.contains(event.target)) {
        listRef.current.classList.add("hidden");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listRef]);

  const onToggle = () => {
    if (!listRef.current) {
      return;
    }
    listRef.current.classList.toggle("hidden");
  };

  const favoriteItem = useMemo(() => {
    if (!isLoggedIn) return null;

    return find(list, (item) => item.experience_id === id);
  }, [list, isLoggedIn]);

  return (
    <div>
      <div className="relative">
        {isLoggedIn &&
          (favoriteItem ? (
            <div className="absolute top-2 right-2 z-10 pt-1 w-34 text-center">
              <IconButton onClick={() => removeFavorite(favoriteItem.id)}>
                <HeartFullSvg />
              </IconButton>
            </div>
          ) : (
            <div className="absolute top-2 right-2 z-10 pt-1 w-34 text-center">
              <IconButton onClick={() => id && addToFavorite({ experience_id: id })}>
                <HeartOutlineSvg className="fill-primary stroke-primary" />
              </IconButton>
            </div>
          ))}

        {isEdit && (
          <div className="absolute top-2 right-2 z-10 bg-blue/[.5] rounded-full pt-1 w-34 text-center">
            <IconButton onClick={onToggle} className="">
              <MoreSvg className="stroke-white fill-white" />
            </IconButton>
            {customActionList ?? <ActionList {...props} ref={listRef} />}
          </div>
        )}
        <Link to={link || `/experiences/${id}`}>
          <div
            style={{
              backgroundImage: `url(${url ? `${s3Url}${url}` : "/imgs/default-image.jpg"})`,
            }}
            className={clsx("rounded-10 w-full relative h-200 bg-no-repeat bg-center bg-cover")}
          />
        </Link>
      </div>
      <Link to={link || `/experiences/${id}`}>
        <Typography className="mt-3 mb-1">{title}</Typography>
      </Link>
      <Typography tag="body">
        {content?.length && content.length > 80 ? (
          <>
            {truncate(content, { length: length, separator: " " })}
            {length === 80 && (
              <a onClick={() => setLength(999)} className="cursor-pointer text-blue">
                read more
              </a>
            )}
          </>
        ) : (
          content
        )}
      </Typography>
    </div>
  );
};
