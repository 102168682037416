import { Typography } from "@app/components/atoms";
import { useGetNewsQuery } from "@app/store/services/news";
import { useParams } from "react-router-dom";

export default function NewsDetail() {
  const { newsId } = useParams<{ newsId: string }>();
  const { data } = useGetNewsQuery(Number(newsId), { skip: !newsId });
  return (
    <div className="container-layout">
      <div className="px-4 md:px-0">
        <div className="md:py-8 py-4 text-center">
          <Typography tag="h1">{data?.title}</Typography>
        </div>
        {data?.content && <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>}
      </div>
    </div>
  );
}
