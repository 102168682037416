import {
  BlistExperienceSection,
  CategorySlide,
  TopExperienceSection,
  NewExperienceSection,
} from "@app/components/organisms";
import { Role } from "@app/enums";
import { useAuth } from "@app/store/hooks";
import { Category } from "@app/types";
import { useState } from "react";
import { Navigate } from "react-router-dom";

export default function CategoryPage() {
  const { profile } = useAuth();
  const [selected, setSelected] = useState<null | { item: Category; index: number }>(null);

  if (profile.role === Role.creator) {
    return <Navigate to="/creator-dashboard" />;
  }

  if (profile.role === Role.admin) {
    return <Navigate to="/admin" />;
  }

  return (
    <>
      <CategorySlide selected={selected} setSelected={setSelected} />
      <div className="px-4 md:px-0">
        <BlistExperienceSection
          title="BLIST Experiences"
          categoryId={!selected?.item.is_all ? selected?.item?.id : undefined}
        />
        <div className=" border-b border-border2 mb-2" />
        <TopExperienceSection
          title="Top Experiences"
          categoryId={!selected?.item.is_all ? selected?.item?.id : undefined}
        />
        <div className=" border-b border-border2 mb-2" />
        <NewExperienceSection
          title="New Experiences"
          categoryId={!selected?.item.is_all ? selected?.item?.id : undefined}
        />
      </div>
    </>
  );
}
