import { Typography } from "@app/components/atoms";
import { AccountDetailLayout } from "@app/components/common";
import { eventBriteKey, webUrl } from "@app/config";
import { useAuth } from "@app/store/hooks";

export default function BlistIntegration() {
  const { profile } = useAuth();

  return (
    <AccountDetailLayout title="Invoices">
      <Typography tag="h3">Hi, welcome to our integration</Typography>
      <table className="table-fixed border-collapse border border-slate-500 w-full mt-4">
        <thead>
          <tr>
            <th className="border border-slate-600">Platform</th>
            <th className="border border-slate-600">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-slate-600 px-3">EventBrite</td>
            <td className="border border-slate-600 px-3 py-2">
              {!profile.event_brite_key ? (
                <a
                  href={`https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=${eventBriteKey}&redirect_uri=${webUrl}/account/integration/event-brite`}
                  className="bg-white text-primary border border-primary hover:text-white hover:bg-primary md:min-w-120 md:py-10px min-w-100 py-10px inline-block text-center rounded-full"
                >
                  Connect
                </a>
              ) : (
                <>Connected</>
              )}
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td className="border border-slate-600 px-3">Meetup</td>
            <td className="border border-slate-600 px-3 py-2">Not Support</td>
          </tr>
        </tbody>
      </table>
    </AccountDetailLayout>
  );
}
