import {
  Typography,
  CertSvg,
  FbSvg,
  InsSvg,
  TwiSvg,
  Button,
  Star,
  ShareSvg,
  HeartOutlineSvg,
  HeartFullSvg,
  Modal,
  Spinner,
} from "@app/components/atoms";
import {
  SimilarSection,
  MapReview,
  RatingSection,
  BookForm,
  ShareSocial,
} from "@app/components/organisms";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetExperienceQuery } from "@app/store/services/experience";
import { s3Url } from "@app/config";
import { find, join, toLower, includes, some, drop, take, cloneDeep } from "lodash";
import moment from "moment";

import { useFetchReviewQuery } from "@app/store/services/review";
import { totalPoint } from "@app/utils";
import { useAuth, useFavorite } from "@app/store/hooks";
import { useMemo, useState } from "react";
import { useAddToFavoriteMutation, useRemoveFavoriteMutation } from "@app/store/services/favorite";
import Styled from "styled-components";
import ImageGallery from "react-image-gallery";
import { useFetchHolidaysQuery } from "@app/store/services/holiday";
import { Role } from "@app/enums";
import { useCreateBookingMutation } from "@app/store/services/booking";
import { YouTubeEmbed, InstagramEmbed, TikTokEmbed, XEmbed } from "react-social-media-embed";

const StyledShare = Styled.div`
  position: relative;
  .share-wrapper {
    display: none;
    margin-right: 10px;
  }
  &:hover .share-wrapper {
    display: grid;
  }
`;

export default function Experiences() {
  const { experienceId } = useParams<{ experienceId: string }>();
  const navigate = useNavigate();
  const [createBooking, { isLoading: isCreating }] = useCreateBookingMutation();
  const { isLoggedIn, profile } = useAuth();
  const { data, isLoading } = useGetExperienceQuery(experienceId || "");
  const { data: reviews } = useFetchReviewQuery(
    { experience_id: Number(experienceId || "") },
    { skip: !experienceId },
  );
  const { data: holidays } = useFetchHolidaysQuery(
    { user_id: data?.owner.id || 0 },
    { skip: !data?.owner.id },
  );
  const [addToFavorite] = useAddToFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const { list } = useFavorite();
  const [isSlider, setIsSlider] = useState(false);

  const favoriteItem = useMemo(() => {
    if (!isLoggedIn) return null;

    return find(list, (item) => item.experience_id === Number(experienceId));
  }, [list, isLoggedIn, experienceId]);

  const nextDate: { date: moment.Moment; price: number }[] = useMemo(() => {
    // with no date;
    let dates: any[] = [];
    let current = moment();
    let endDate = moment().add(30, "day");
    let current_start_date = moment();

    // if we have is date
    if (data?.is_date) {
      const start_date = moment(data.start_date);
      const end_date = moment(data.end_date);
      current = current.isBefore(start_date) ? current : start_date;
      current_start_date = current_start_date.isBefore(start_date)
        ? current_start_date
        : start_date;
      endDate = endDate.isBefore(endDate) ? endDate : end_date;
    }

    while (current.isBefore(endDate)) {
      if (
        includes(data?.available_days, toLower(current.format("ddd"))) &&
        !some(holidays, (item) => moment(item.date_time).isSame(current, "date"))
      ) {
        dates = [
          ...dates,
          ...(data?.availability_time || [])
            .filter((item) => {
              const splitItem = item.split(":");
              const dateCurrentTime = cloneDeep(
                current.set("hour", Number(splitItem[0])).set("minute", Number(splitItem[1])),
              );
              if (current_start_date.isAfter(dateCurrentTime)) {
                return false;
              }

              return true;
            })
            .map((item) => {
              const splitItem = item.split(":");
              return {
                date: cloneDeep(
                  current.set("hour", Number(splitItem[0])).set("minute", Number(splitItem[1])),
                ),
                price: data?.amount || 0,
              };
            }),
        ];
      }

      if (dates.length >= 3) {
        break;
      }
      current.add(1, "days");
    }

    return dates;
  }, [data, holidays]);

  const classCalculate = useMemo(() => {
    const photoLength = drop(data?.photos || []).length;
    if (photoLength === 3) {
      return "grid grid-cols-1 grid-rows-1 h-full";
    }

    if (photoLength === 2) {
      return "grid grid-cols-1 grid-rows-2 gap-y-6 h-full";
    }

    if (photoLength === 3) {
      return "grid grid-cols-1 grid-rows-3 gap-y-6 h-full";
    }

    if (photoLength === 4) {
      return "grid grid-cols-2 grid-rows-2 gap-6 h-full";
    }

    return "grid grid-cols-3 gap-6 h-full";
  }, [data?.photos]);

  const onQuickBook = (item: moment.Moment) => {
    if (!isLoggedIn) {
      navigate("/signin?url=/experiences/2");
      return;
    }
    createBooking({
      experience_id: +(experienceId || 0),
      adults: 1,
      children: 0,
      infant: 0,
      date: item.format("YYYY-MM-DD"),
      availability_time: item.format("HH:mm"),
      date_request: undefined,
      time_request: undefined,
      notes: [],
    })
      .unwrap()
      .then((response) => {
        navigate(`/request-to-book/${response.id}`);
      })
      .catch(() => {});
  };

  return (
    <div>
      <Modal show={isSlider} size="big" onClose={() => setIsSlider(false)}>
        <ImageGallery
          items={(data?.photos || []).map((item) => ({
            original: `${s3Url}${item}`,
            thumbnail: `${s3Url}${item}`,
          }))}
        />
      </Modal>
      <div className="px-4 md:px-0">
        <div className="">
          <div className="text-center mt-2">
            <Typography tag="h2">{data?.title}</Typography>
          </div>
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="flex flex-row items-center">
              <Typography tag="body" isColor className="font-medium flex flex-row text-black">
                <Star className="mr-2" /> {totalPoint(reviews?.data)}
              </Typography>
              <Typography tag="body" isColor className="font-medium underline ml-4 text-black">
                {reviews?.total} reviews
              </Typography>
            </div>
            <div className="flex flex-row items-center">
              <StyledShare>
                <Typography
                  tag="body"
                  isColor
                  className="font-medium underline flex flex-row items-center ml-4 text-black"
                >
                  <ShareSocial className="share-wrapper" url="123" />
                  <ShareSvg className="mr-2" /> Share
                </Typography>
              </StyledShare>
              {isLoggedIn &&
                (favoriteItem ? (
                  <div className="cursor-pointer" onClick={() => removeFavorite(favoriteItem.id)}>
                    <Typography
                      tag="body"
                      isColor
                      className="font-medium underline flex flex-row items-center ml-4 text-black"
                    >
                      <HeartFullSvg className="w-19 h-17 mr-2" /> remove
                    </Typography>
                  </div>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      experienceId && addToFavorite({ experience_id: Number(experienceId) })
                    }
                  >
                    <Typography
                      tag="body"
                      isColor
                      className="font-medium underline flex flex-row items-center ml-4 text-black"
                    >
                      <HeartOutlineSvg className="mr-2 fill-black" /> Save
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          <div
            className="grid grid-cols-6 grid-rows-1 gap-x-6 mt-2 cursor-pointer"
            onClick={() => setIsSlider(true)}
          >
            <div className="lg:col-span-4 col-span-6 row-span-2">
              {data?.photos?.[0] && (
                <div
                  className="rounded-20 relative md:h-400 h-300 bg-cover bg-center"
                  style={{ backgroundImage: `url(${s3Url}${data?.photos?.[0]})` }}
                />
              )}
            </div>
            <div className="lg:col-span-2 col-span-3">
              <div className={classCalculate}>
                {drop(data?.photos || []).map((it) => (
                  <div
                    className="rounded-20 relative md:h-full bg-cover bg-center"
                    style={{ backgroundImage: `url(${s3Url}${it})` }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 pt-2 md:mb-8 mb-6">
            <div className="lg:col-span-2 lg:pr-8">
              {!!data?.category_ids.length && (
                <div className="md:flex md:flex-row flex-wrap items-center border-b border-lineBreak md:pb-8 md:mb-8 pb-4 mb-4">
                  {data?.category_ids.map((item) => (
                    <Button type="button" variant="secondary" className="mr-4 !mb-4">
                      {item.name}
                    </Button>
                  ))}
                </div>
              )}
              <div className="border-b border-lineBreak pb-4 mb-10px">
                <div className="relative">
                  {isCreating && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  <div className="flex flex-row flex-wrap lg:flex-nowrap lg:justify-between pb-1 md:pb-2 pt-0 lg:w-[90%]">
                    {take(nextDate, 3).map((item, key) => (
                      <div
                        key={key}
                        className="border border-solid border-[rgba(51, 51, 50, 0.1)] mb-1 mr-2 px-3 py-1 rounded-10 cursor-pointer bg-white"
                        onClick={() => onQuickBook(item.date)}
                      >
                        <Typography tag="body">{item.date.format("hh:mm, DD MMM")}</Typography>
                        <Typography className="text-20 md:text-18 leading-22 font-medium mt-2 text-blue">
                          <span className="text-primary">${item.price}</span>
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
                <Typography className="text-subtitle">{data?.summary}</Typography>
              </div>

              <div className="border-b border-lineBreak pb-4 pt-1 mb-10px">
                <Typography tag="h4" className="text-28 text-blue mb-4">
                  Description
                </Typography>
                {data?.description && (
                  <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                )}
              </div>
              <div className="border-b border-lineBreak pb-4 pt-1">
                <Typography tag="h4" className="leading-none text-28 text-blue mb-4">
                  Atmosphere and Location
                </Typography>
                {data?.atmosphere && (
                  <div dangerouslySetInnerHTML={{ __html: data?.atmosphere }}></div>
                )}
              </div>
              <div className="border-b border-lineBreak pb-4 pt-2">
                <Typography tag="h4" className="text-28 text-blue mb-6">
                  Important Detail
                </Typography>
                <div className="grid grid-cols-3 gap-x-4">
                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Activity Level
                    </Typography>
                    <Typography tag="p">{data?.level_expected}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Private/ Group/Both
                    </Typography>
                    <Typography tag="p">{data?.type}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Duration
                    </Typography>
                    <Typography tag="p">{data?.duration}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      No of Guest
                    </Typography>
                    <Typography tag="p">{`${data?.guest_min} to ${data?.guest_max}`}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Language offered
                    </Typography>
                    <Typography tag="p">{join(data?.language_offered, ", ")}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Age Range
                    </Typography>
                    <Typography tag="p">
                      {data?.age_min} to {data?.age_max}
                    </Typography>
                  </div>
                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Handicap Accessible
                    </Typography>
                    <Typography tag="p">{data?.handicap_accessible ? "Yes" : "No"}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Pet Allowed
                    </Typography>
                    <Typography tag="p">{data?.pet_allowed ? "Yes" : "No"}</Typography>
                  </div>

                  <div className="mb-10px">
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Creator Present
                    </Typography>
                    <Typography tag="p">{data?.creator_presence ? "Yes" : "No"}</Typography>
                  </div>
                  <div>
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Experience Mode
                    </Typography>
                    <Typography tag="p">{join(data?.kind, ", ")}</Typography>
                  </div>
                  <div>
                    <Typography tag="h6" className="text-base md:text-lg text-blue mb-2">
                      Address
                    </Typography>
                    {data?.addresses.map((item, key) => (
                      <Typography tag="p" key={key}>
                        {`${item.state} ${item.city}, ${item.country}`}
                      </Typography>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <Typography tag="h4" className="text-28 pt-4 text-blue mb-4">
                  Willing to Travel
                </Typography>
                <Typography className="text-subtitle">{data?.willing_to_travel}</Typography>
              </div>
              <div className="mb-4">
                <Typography tag="h4" className="leading-none text-28 text-blue mb-4">
                  Clothing Recommendation
                </Typography>
                <Typography className="text-subtitle">{data?.clothing_recommendation}</Typography>
              </div>
              <div className="md:border-b md:border-lineBreak md:pb-6 pb-4">
                <Typography tag="h4" className="text-28 text-blue mb-4">
                  Cancellation Policy
                </Typography>
                <Typography className="text-subtitle">{data?.cancellation_policy}</Typography>
              </div>
              <div className="md:border-b md:border-lineBreak md:pb-6 pb-4">
                <Typography tag="h4" className="text-28 pt-4 text-blue mb-4">
                  Socials
                </Typography>
                <div className="grid grid-cols-1 gap-5">
                  <div>
                    <YouTubeEmbed
                      url={data?.youtube_url || "https://www.youtube.com/@BlistNoww"}
                      width="100%"
                      height={450}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-5 pt-6">
                  <div>
                    <TikTokEmbed
                      url={data?.tiktok_url || "https://www.tiktok.com/@blistnoww"}
                      width="100%"
                      height={721}
                    />
                  </div>
                  <div>
                    <InstagramEmbed
                      url={data?.instagram_url || "https://www.instagram.com/blistnow/"}
                      width="100%"
                      height={721}
                    />
                  </div>
                  <div>
                    <XEmbed
                      width="100%"
                      height={721}
                      url={
                        data?.x_url ||
                        "https://twitter.com/PixelAndBracket/status/1356633038717923333"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="border border-lightWhite rounded-10 mb-4 relative flex flex-col justify-center items-center md:py-14 py-3 md:px-24 px-6 text-center cursor-pointer"
                onClick={() => navigate(`/user/${data?.owner.id}`)}
              >
                {data?.owner.identity_verified && (
                  <CertSvg className="absolute right-6 top-6 md:top-14" />
                )}
                <div className="rounded-full mb-4 relative" style={{ height: 117, width: 117 }}>
                  <img
                    src={
                      data?.owner.avatar
                        ? `${s3Url}${data?.owner.avatar}`
                        : "/imgs/default-avatar.png"
                    }
                    alt={data?.owner.first_name}
                  />
                </div>
                <Typography tag="h6">
                  Experience hosted by {`${data?.owner.first_name} ${data?.owner.last_name}`}
                </Typography>
                <Typography tag="p" className="font-medium text-timeAgo mt-2 mb-2">
                  {moment(data?.createdAt).fromNow()}
                </Typography>
                <div className="flex flex-row items-center">
                  <FbSvg className="fill-primary" />
                  <InsSvg className="fill-primary mx-2" />
                  <TwiSvg className="fill-primary" />
                </div>
              </div>
              {profile.role === Role.user ? (
                <BookForm experienceId={experienceId} />
              ) : (
                <Link to="/signin?url=/experiences/2" className="text-blue2">
                  Please sign in to book this experience.
                </Link>
              )}
            </div>
          </div>
        </div>
        <MapReview position={data?.addresses} />
        <RatingSection />
        {!isLoading && data?.category_ids?.length ? (
          <SimilarSection
            title="Similar Experiences"
            categoryIds={data?.category_ids.map((item) => item.id)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
