import { Button, Typography, LocationSvg, ArrowSwapSvg, QuestionCircleSvg } from "../atoms";
import Styled from "styled-components";
import { HCheckbox, HTextField, HGoogleAddress, HRangeSlider, HStripePrice } from "../hookform";
import { useForm, SubmitHandler } from "react-hook-form";
import { useCreatePromoteMutation } from "@app/store/services/promote";
import { PromoteGender } from "@app/enums";
import { CreatePromotePayload } from "@app/store/services/promote/type";

const StyledText = Styled(Typography)`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-right: 16px;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-left: 16px;
  }
`;

type CreatePromoteForm = Omit<CreatePromotePayload, "experience_id" | "location" | "address"> & {
  radius: number[];
  location: {
    lat: number;
    lng: number;
    street_address: string;
    zip_code: string;
    country: string;
    state: string;
    city: string;
  };
};

type Props = {
  onClose?: () => void;
  experienceId?: number;
};

export const PromoteForm = ({ onClose, experienceId }: Props) => {
  const { handleSubmit, control } = useForm<CreatePromoteForm>();
  const [createPromote, { isLoading }] = useCreatePromoteMutation();

  const onSubmit: SubmitHandler<CreatePromoteForm> = (data) => {
    if (!experienceId) return;

    const { radius, location, ...payload } = data;

    createPromote({
      ...payload,
      address: `${location.street_address}, ${location.city} ${location.state},${location.country} ${location.zip_code}`,
      location: {
        lat: location.lat,
        lng: location.lng,
      },
      experience_id: experienceId,
      radius_max: radius?.[1],
      radius_min: radius?.[0],
    })
      .unwrap()
      .then((res) => {
        window.open(res.data.payment_url || "", "_blank");
        onClose?.();
      })
      .catch(() => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-6">
      <div className="px-8">
        <Typography tag="h2" className="mb-4">
          Promote Experience
        </Typography>
        <Typography className="text-lightGrey mb-6" isColor>
          you can promote and pay via website for target advertisement on website
        </Typography>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue mb-4">
          Select Target Demographics
        </Typography>

        <div className="flex flex-row justify-between items-center">
          <HTextField
            type="number"
            label="From"
            variant="outlined-black"
            border="full"
            placeholder="Age"
            name="age_min"
            className="flex-1"
            customLabel={
              <Typography className="text-blue mb-4" isColor>
                From
              </Typography>
            }
            control={control}
          />
          <ArrowSwapSvg className="mx-6 mt-4" />
          <HTextField
            type="number"
            label="To"
            variant="outlined-black"
            border="full"
            placeholder="Age"
            name="age_max"
            className="flex-1"
            customLabel={
              <Typography className="text-blue mb-4" isColor>
                To
              </Typography>
            }
            control={control}
          />
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue">
          Select Gender
        </Typography>
        <div className="flex flex-row items-center justify-between mt-4">
          <HCheckbox
            label="Male"
            control={control}
            name="gender"
            value={PromoteGender.MALE}
            isMulti
          />
          <HCheckbox
            label="Female"
            control={control}
            name="gender"
            value={PromoteGender.FEMALE}
            isMulti
          />
          <HCheckbox
            label="Them/They"
            control={control}
            name="gender"
            value={PromoteGender.THEM}
            isMulti
          />
        </div>
      </div>
      <div className="pt-6 pb-2 px-8">
        <HGoogleAddress
          variant="outlined-black"
          border="full"
          name="location"
          placeholder="Enter location here"
          types={["(cities)"]}
          endIcon={<LocationSvg />}
          customLabel={
            <Typography tag="h6" className="text-blue mb-4 inline-flex flex-row">
              Add Location
              <QuestionCircleSvg />
            </Typography>
          }
          control={control}
        />
      </div>
      <StyledText className="text-center mt-2 flex flex-row items-center w-full">Or</StyledText>
      <div className="border-border2 py-8 px-8">
        <Typography tag="h6" className="text-blue">
          Experience Radius Target
        </Typography>
        <div className="mb-12 ">
          <HRangeSlider
            minText="0"
            maxText="1000"
            min={0}
            max={1000}
            name="radius"
            control={control}
            suffix=" miles"
          />
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <HStripePrice control={control} name="stripe_price_id" />
      </div>
      <div className="px-8">
        <Button type="submit" isLoading={isLoading} variant="primary" className="w-full">
          Proceed
        </Button>
      </div>
    </form>
  );
};
