import { bookingMessage, s3Url } from "@app/config";
import { Button, Typography } from "../atoms";
import { User } from "@app/types";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { clsx } from "clsx";
import { includes } from "lodash";
import { BookingStatus, Role } from "@app/enums";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@app/store/hooks";

type Props = {
  isOwner?: boolean;
  content?: string;
  note?: string;
  media?: string;
  sender?: User;
  receiver?: User;
  date: Timestamp;
  bookingId?: number;
  onSetBookingId: (id: number) => void;
  isAdmin?: boolean;
  name?: string;
  avatar?: string;
};

const MediaContent = ({ url, isOwner }: { url: string; isOwner?: boolean }) => {
  return (
    <div className={clsx("p-2 rounded-10 bg-grey4", isOwner && "text-right")}>
      <img src={`${s3Url}${url}`} alt={url} />
    </div>
  );
};

const MessageContent = ({ content, isOwner }: { content: string; isOwner?: boolean }) => {
  return (
    <div>
      <Typography
        tag="body"
        className={clsx("text-grey3 leading-22", isOwner && "text-right")}
        isColor
      >
        {content}
      </Typography>
    </div>
  );
};

export const ChatRow = ({
  isOwner,
  content,
  note,
  media,
  date,
  bookingId,
  onSetBookingId,
  name,
  isAdmin,
  avatar,
}: Props) => {
  const navigate = useNavigate();
  const { profile } = useAuth();

  if (isAdmin) {
    return (
      <div className="flex flex-row w-4/5 mb-5">
        <div className="mr-2">
          <div className="bg-primary rounded-full w-34 h-34 flex justify-center items-center">
            <img src="/imgs/logo-white.svg" width={34} alt="avatar" />
          </div>
        </div>
        <div className="flex-1">
          <Typography className="text-blue font-semibold">
            Blist system{" "}
            <span className="text-grey3 text-14 font-normal">
              {moment(date.seconds * 1000).format("HH:mm")}
            </span>
          </Typography>
          {note && <MessageContent content={note} />}
          {content && <MessageContent content={content} />}
          {media && <MediaContent url={media} />}
          <div className="flex">
            {includes(note, bookingMessage[BookingStatus.COMPLETED_PAYMENT]) &&
            profile.role === Role.creator ? (
              <Button variant="primary" onClick={() => navigate("/request-inquiries")}>
                Request & Inquiries
              </Button>
            ) : null}
            {bookingId && (
              <Button variant="primary" onClick={() => onSetBookingId(bookingId)}>
                View
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (isOwner) {
    return (
      <div className="flex flex-row w-4/5 ml-auto mb-5">
        <div className="flex-1">
          <Typography className="text-blue font-semibold text-right">
            {name}{" "}
            <span className="text-grey3 text-14 font-normal">
              {moment(date.seconds * 1000).format("HH:mm")}
            </span>
          </Typography>
          {note && <MessageContent content={note} isOwner />}
          {content && <MessageContent content={content} isOwner />}
          {media && <MediaContent url={media} isOwner />}
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-row w-4/5 mb-5">
      <div className="mr-2">
        <img
          src={avatar ? `${s3Url}${avatar}` : "/imgs/default-avatar.png"}
          width={34}
          height={34}
          alt="avatar"
        />
      </div>
      <div className="flex-1">
        <Typography className="text-blue font-semibold">
          {name}{" "}
          <span className="text-grey3 text-14 font-normal">
            {moment(date.seconds * 1000).format("HH:mm")}
          </span>
        </Typography>
        {note && <MessageContent content={note} />}
        {content && <MessageContent content={content} />}
        {media && <MediaContent url={media} />}
      </div>
    </div>
  );
};
