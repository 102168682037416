import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
  current_location?: {
    lat: number;
    lng: number;
  };
}

const initialState: SettingsState = {
  current_location: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLocation: (
      state,
      action: PayloadAction<{
        lat: number;
        lng: number;
      }>,
    ) => {
      state.current_location = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocation } = settingsSlice.actions;

export default settingsSlice.reducer;
