import { useState } from "react";
import {
  Typography,
  Button,
  EditSvg,
  CloseCircleSvg,
  TrashSvg,
  AnnouncementSvg,
  PlusStaticSvg,
  SlideLoading,
} from "@app/components/atoms";
import { AdminCategoryIconItem } from "@app/components/molecules";
import { RemovePopup, DeactivePopup } from "@app/components/organisms";
import { s3Url } from "@app/config";
import {
  useFetchCategoriesQuery,
  useLazyFetchCategoriesQuery,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useSortCategoryMutation,
} from "@app/store/services/category";
import { useNavigate } from "react-router-dom";
import { Category } from "@app/types/category";
import clsx from "clsx";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useCategory } from "@app/store/hooks";
import { findIndex } from "lodash";

export default function AdminCategory() {
  const [removeCategory, { isLoading: isDeleting }] = useRemoveCategoryMutation();
  const [updateCategory, { isLoading: isUpdating }] = useUpdateCategoryMutation();
  const [sortCategory] = useSortCategoryMutation();
  const navigate = useNavigate();
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Category | null>(null);
  const [is_active, setIsActive] = useState(true);
  const { isLoading } = useFetchCategoriesQuery({ is_active, is_count: true });
  const [triggerFetchCategories] = useLazyFetchCategoriesQuery();
  const { list, setCategories } = useCategory();
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const menuList = (item: Category) => {
    return [
      {
        text: "Edit Category",
        icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
        action: () => navigate(`/admin/category/update/${item.id}`),
      },
      {
        text: "Deactivate",
        icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
        action: () => {
          setSelectedItem(item);
          setIsShowDeactive(true);
        },
      },
      {
        text: "Promote",
        icon: <AnnouncementSvg className="mr-2 stroke-white w-19 h-19" />,
        action: () => {},
      },
      {
        text: "Delete",
        icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
        action: () => {
          setSelectedItem(item);
          setIsShowRemove(true);
        },
      },
    ];
  };

  const onRemove = () => {
    selectedItem?.id &&
      removeCategory(selectedItem?.id)
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowRemove(false);
        });
  };

  const onDeactive = () => {
    selectedItem?.id &&
      updateCategory({ id: selectedItem?.id, is_active: false })
        .unwrap()
        .catch(() => {})
        .finally(() => {
          setIsShowDeactive(false);
        });
  };

  const handleDragStart = (event: DragStartEvent) => {
    console.log(event.active?.id);
    // setActiveCategory(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = findIndex(list, (it) => it.id === active.id);
      const newIndex = findIndex(list, (it) => it.id === over?.id);
      const sortedList = arrayMove(list, oldIndex, newIndex);
      setCategories(sortedList);
      sortCategory({ orders: sortedList.map((it, key) => ({ id: it.id, order: key })) });
    }

    setActiveCategory(null);
  };

  const handleDragCancel = () => {
    setActiveCategory(null);
  };

  return (
    <>
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={selectedItem?.name}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={selectedItem?.name}
      />

      <div className="md:mb-8 mb-4 flex md:flex-row flex-col md:justify-between md:items-center">
        <Typography tag="h2">Categories</Typography>
        <div>
          <Button
            variant={is_active ? "primary" : "outline-border"}
            className={clsx(
              "mr-2",
              !is_active ? "border-lightGrey" : "hover:!bg-primary hover:!text-white",
            )}
            onClick={() => {
              triggerFetchCategories({ is_active: true });
              setIsActive(true);
            }}
          >
            Activated
          </Button>
          <Button
            variant={!is_active ? "primary" : "outline-border"}
            className={clsx(is_active ? "border-lightGrey" : "hover:!bg-primary hover:!text-white")}
            onClick={() => {
              triggerFetchCategories({ is_active: false });
              setIsActive(false);
            }}
          >
            Deactivated
          </Button>
        </div>
      </div>
      {isLoading ? (
        <SlideLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-4">
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <SortableContext items={list} strategy={rectSortingStrategy}>
                {list?.map((item, key) => (
                  <AdminCategoryIconItem
                    title={item.name}
                    icon={<img src={`${s3Url}${item.icon}`} width={50} height={50} />}
                    total={item.experience_ids?.length || 0}
                    isEdit
                    menuList={menuList(item)}
                    key={key}
                    id={item.id}
                  />
                ))}
              </SortableContext>
              <DragOverlay adjustScale={true}>
                {activeCategory ? (
                  <AdminCategoryIconItem
                    title={activeCategory.name}
                    icon={<img src={`${s3Url}${activeCategory.icon}`} width={50} height={50} />}
                    total={activeCategory.experience_ids?.length || 0}
                    key={activeCategory.id}
                    id={activeCategory.id}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>

            <button
              className="flex flex-col justify-center items-center bg-blue rounded-10 md:py-0 py-6 min-h-250"
              onClick={() => navigate("/admin/category/add")}
            >
              <PlusStaticSvg className="stroke-white w-40 h-40" />
              <p className="md:text-22 text-20 leading-22 text-white mt-4">Add New Category</p>
            </button>
          </>
        </div>
      )}
    </>
  );
}
