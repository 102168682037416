import { Marker, InfoWindow } from "@react-google-maps/api";
import { Typography } from "../atoms";
import { s3Url } from "@app/config";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Address } from "@app/types";

type Props = {
  title?: string;
  amount?: number;
  addresses: Address[];
  image?: string;
  id: number;
};

export const ExperienceMarker = ({ title, amount, addresses = [], image, id }: Props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  if (!addresses.length) return null;

  return (
    <>
      {addresses.map((item, key) => (
        <Marker
          icon="/imgs/marker-2.png"
          position={{ lat: item.lat, lng: item.lng }}
          onClick={() => setOpen(true)}
          key={key}
          label={amount ? `${amount}$` : "Free"}
        >
          {open && (
            <InfoWindow position={item} onCloseClick={() => setOpen(false)}>
              <div
                className="flex flex-col w-240 cursor-pointer"
                onClick={() => navigate(`/experiences/${id}`)}
              >
                <img
                  src={image ? `${s3Url}${image}` : "/imgs/default-image.jpg"}
                  className="h-120 w-full"
                  alt={title}
                />
                <div className="pt-1">
                  <Typography tag="h6">{title}</Typography>
                  <Typography>{`${item.state} ${item.city}, ${item.country}`}</Typography>
                  <Typography>{amount ? `${amount}$` : "Free"}</Typography>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </>
  );
};
