import React, { ReactNode } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { googleApiKey } from "@app/config";

const containerStyle = {
  width: "100%",
  height: "100%",
};

type Props = {
  center?: { lat: number; lng: number };
  children?: ReactNode;
  zoom?: number;
};

const Map = ({
  center = {
    lat: 37.09024,
    lng: -95.712891,
  },
  children,
  zoom = 10,
}: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export const MapView = React.memo(Map);
