import clsx from "clsx";
import { Typography, ThreeDotSvg } from "../atoms";
import { Conversation, Message, User } from "@app/types";
import { s3Url } from "@app/config";
import moment from "moment";
import { useAuth } from "@app/store/hooks";

type Props = {
  isSelected?: boolean;
  conversation: Conversation;
  latest: Message;
  onSelected: () => void;
};

export const UserChat = ({ isSelected, conversation, latest, onSelected }: Props) => {
  const { profile } = useAuth();
  const user = conversation.messages.find((item) => item.userId !== profile.id);
  return (
    <div
      className={clsx(
        "flex flex-row p-2 mb-2 cursor-pointer",
        isSelected ? "border bg-headerBg rounded-10" : "border-y",
      )}
      onClick={onSelected}
    >
      <div>
        <div className="relative">
          <img
            src={user?.avatar ? `${s3Url}${user?.avatar}` : "/imgs/default-avatar.png"}
            alt="user"
            className="mr-2 rounded-full w-46 h-46"
          />
          <div className="w-10px h-10px rounded-full absolute bg-green absolute bottom-1 right-2" />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-row justify-between items-center">
          <Typography className="text-blue font-semibold">{user?.name}</Typography>
          {isSelected ? (
            <ThreeDotSvg />
          ) : (
            <p className="text-8 text-grey3">
              {moment(latest.createdAt.seconds * 1000).format("MMM DD, YYYY")}
            </p>
          )}
        </div>
        <Typography tag="description">{latest.content || latest.note}</Typography>
      </div>
    </div>
  );
};
