import { createApi } from "@reduxjs/toolkit/query/react";
import type { Topic } from "@app/types";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateTopicPayload, UpdateTopicPayload, FetchTopicPayload } from "./types";

export const topicApi = createApi({
  reducerPath: "topicApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Topic"],
  endpoints: (builder) => ({
    fetchTopic: builder.query<CommonResponse<Topic[]>, FetchTopicPayload>({
      query: (params) => {
        return {
          url: "topic",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: Topic) => ({ type: "Topic" as const, id })), "Topic"]
          : ["Topic"],
    }),
    createTopic: builder.mutation<CommonResponse<Topic>, CreateTopicPayload>({
      query(body) {
        return {
          url: `topic`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Topic"],
    }),
    updateTopic: builder.mutation<CommonResponse<Topic>, UpdateTopicPayload>({
      query({ id, ...body }) {
        return {
          url: `topic/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Topic", id: arg.id }],
    }),
    getTopic: builder.query<Topic, number>({
      query: (id: number) => `topic/${id}`,
      transformResponse: (response: CommonResponse<Topic>) => response?.data,
      providesTags: (result, error, id) => [{ type: "Topic", id }],
    }),
    removeTopic: builder.mutation<CommonResponse<Topic>, number>({
      query(id: number) {
        return {
          url: `topic/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Topic"],
    }),
  }),
});

export const {
  useFetchTopicQuery,
  useLazyFetchTopicQuery,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useRemoveTopicMutation,
  useGetTopicQuery,
} = topicApi;
