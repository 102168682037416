import { TextField, Send } from "../atoms";

export const ContactUsContent = () => {
  return (
    <div className="flex flex-col justify-between">
      {/* <ChatRow /> */}
      <div className="mt-24">
        <TextField
          name="chat"
          variant="filled"
          border="full"
          placeholder="Type a Message..."
          endIcon={
            <div className="bg-primary rounded-full md:p-2 p-1 md:mr-2 mr-1">
              <Send className="text-white" />
            </div>
          }
        />
      </div>
    </div>
  );
};
