import clsx from "clsx";
import { ProfileImageSlide, ProfileVideoSlide } from "../molecules";
import { useState } from "react";
export const ProfileMedia = () => {
  const [tab, setTab] = useState(1);
  return (
    <div className="md:mx-0 mx-4">
      <div className="border-b border-border2 mb-4">
        <button
          className={clsx(
            "pb-2 border-b-3 md:text-22 text-16 mr-5",
            tab === 1 ? "border-primary text-primary" : "border-transparent text-blue/[.55]",
          )}
          onClick={() => setTab(1)}
        >
          Pictures
        </button>
        <button
          className={clsx(
            "pb-2 border-b-3 md:text-22 text-16",
            tab === 2 ? "border-primary text-primary" : "border-transparent text-blue/[.55]",
          )}
          onClick={() => setTab(2)}
        >
          Videos
        </button>
      </div>
      {tab === 1 && <ProfileImageSlide />}
      {tab === 2 && <ProfileVideoSlide />}
    </div>
  );
};
