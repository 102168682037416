import Slider from "react-slick";
import { useRef, useState, ChangeEvent, useMemo } from "react";
import {
  Typography,
  Button,
  IconButton,
  SearchSvg,
  ChipButton,
  RocketSvg,
  FireSvg,
  RoundedUpSvg,
  ImageSvg,
  WishlistLoading,
  ListItemLoading,
} from "../../atoms";
import { Article } from "../../common";
import { useForm, SubmitHandler } from "react-hook-form";
import { HTextArea, HTextField, HUpload } from "../../hookform";
import { SlideTitle, NewWeekItem } from "../../molecules";
import { StyleButtonChip, StyledButton } from "./styles";
import { useCreatePostMutation, useFetchPostQuery } from "@app/store/services/post";
import { CreatePostPayload } from "@app/store/services/post/types";
import moment from "moment";
import { useCreateWishlistMutation, useFetchWishlistQuery } from "@app/store/services/wishlist";
import { wishlistValidation } from "@app/validations";
import { CreateWishlistPayload } from "@app/store/services/wishlist/types";
import { debounce, map } from "lodash";
import { PostSortBy } from "@app/enums";
import clsx from "clsx";
import { useFetchNewsQuery } from "@app/store/services/news";
import { useAuth } from "@app/store/hooks";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import { s3Url } from "@app/config";

const BlistStock = () => {
  const { data, isLoading } = useFetchExperienceQuery({ limit: 7 });

  const images = useMemo(() => {
    let thumbs = [
      "/imgs/wl-banner-1.jpg",
      "/imgs/wl-banner-2.jpg",
      "/imgs/wl-banner-3.jpg",
      "/imgs/wl-banner-5.jpg",
      "/imgs/wl-banner-6.jpg",
      "/imgs/wl-banner-4.jpg",
      "/imgs/wl-banner-7.jpg",
    ];

    thumbs = map(thumbs, (item, key) => {
      const exp = data?.data[key];

      if (!exp?.photos?.length) return item;

      return `${s3Url}${exp.photos[0]}`;
    });
    return thumbs;
  }, [data]);

  if (isLoading) {
    return (
      <div className="hidden md:block lg:order-last">
        <div className="flex flex-row justify-end items-end mb-2">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="bg-slate-700 rounded h-75px"></div>
            </div>
          </div>
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="bg-slate-700 rounded h-90"></div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <div className="flex flex-col items-end">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="bg-slate-700 rounded h-136px"></div>
              </div>
            </div>
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="bg-slate-700 rounded h-143px"></div>
              </div>
            </div>
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="bg-slate-700 rounded h-166px"></div>
              </div>
            </div>
          </div>
          <div className="ml-2">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="bg-slate-700 rounded h-170px"></div>
              </div>
            </div>
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="bg-slate-700 rounded h-166px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="hidden md:block lg:order-last">
      <div className="flex flex-row justify-end items-end mb-2">
        <img src={images[0]} alt="wl-banner-1" className="rounded-8 h-75px" />
        <img src={images[1]} alt="wl-banner-1" className="ml-2 rounded-8 h-90" />
      </div>
      <div className="flex flex-row justify-end">
        <div className="flex flex-col items-end">
          <img src={images[2]} alt="wl-banner-1" className="mb-2 rounded-8 h-136px" />
          <div className="flex flex-row">
            <img src={images[3]} alt="wl-banner-1" className="rounded-8 h-143px" />
            <img src={images[4]} alt="wl-banner-1" className="ml-2 rounded-8 h-166px" />
          </div>
        </div>
        <div className="ml-2">
          <img src={images[5]} alt="wl-banner-1" className="mb-2 rounded-8 h-170px" />
          <img src={images[6]} alt="wl-banner-1" className="rounded-8 h-166px" />
        </div>
      </div>
    </div>
  );
};

export const WishListBanner = () => {
  const { control, handleSubmit, reset } = useForm<CreateWishlistPayload>({
    resolver: wishlistValidation,
  });
  const [createWishlist, { isLoading }] = useCreateWishlistMutation();
  const { data, isLoading: isFetching } = useFetchWishlistQuery({});

  const onSubmit: SubmitHandler<CreateWishlistPayload> = (payload) => {
    createWishlist({ content: payload.content, is_popular: false })
      .unwrap()
      .then(() => reset({ content: "" }))
      .catch(() => {});
  };

  return (
    <div className="pt-8 mx-4">
      <div className=" rounded-10 border-dashed border-2 border-primary pt-8 px-6 md:px-8 pb-6">
        <Typography tag="h1" className="text-primary text-center">
          Wishlist
        </Typography>
        <div className="grid lg:grid-cols-2 mb-6 gap-5">
          <BlistStock />
          <div>
            <Typography tag="h2" className="flex flex-row">
              Don’t see an
              <img src="/imgs/shapes.svg" alt="shapes" className="ml-4 -mb-4" />
            </Typography>
            <Typography tag="h2">experience you want?</Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="pt-8 mb-4 md:mb-0">
              <HTextArea
                className="border border-black/[0.1] shadow-3 mb-4 md:mb-8 h-120"
                placeholder="Type it here , maybe someone will create it"
                control={control}
                name="content"
              />
              <Button variant="primary" isLoading={isLoading}>
                Create
              </Button>
            </form>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-5 max-h-320 scroll-smooth overflow-auto custom-scroll pt-4">
          {isFetching ? (
            <WishlistLoading />
          ) : (
            data?.data.map((item, key) => (
              <Article
                isPopular={item.is_popular}
                author={item.owner.first_name}
                content={item.content}
                city=""
                country=""
                state=""
                isDetail
                key={key}
                time={moment(item.createdAt).fromNow()}
                id={item.id}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

type CreatePostForm = Omit<CreatePostPayload, "photos"> & {
  photos: string;
};

const CreatePostSection = () => {
  const [createPost, { isLoading }] = useCreatePostMutation();
  const { control, handleSubmit, reset, setError, watch } = useForm<CreatePostForm>({});

  const onSubmit: SubmitHandler<CreatePostForm> = (payload) => {
    if (!payload.content && !payload.links && !payload.photos) {
      setError("content", { type: "custom", message: "You need to post a content, photo or link" });
      return;
    }
    createPost({
      ...payload,
      photos: [payload.photos],
    })
      .unwrap()
      .then(() => reset({ content: "", photos: "", links: [] }))
      .catch(() => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
      <div className="md:w-3/4 mx-auto rounded-full border border-primary flex flex-row items-center py-1 pl-2 pr-2 md:pr-4 mt-6 mb-8">
        <div className="min-w-[43px] max-w-[43px] md:min-w-[58px]  md:h-14 h-[42px] bg-white2 rounded-full p-1 mr-1 md:mr-4 flex justify-center items-center">
          <img
            src="/imgs/logo.svg"
            width={49}
            height={25}
            alt="logo bg"
            className="h-fit w-12 md:min-w-48 min-w-35 p-0.5"
          />
        </div>
        <HTextField
          type="text"
          inputClass="!placeholder:text-greyDeep2 text-14 md:text-16 mt-2 md:pb-2 mr-2"
          className="!w-full"
          placeholder="What is happening?"
          control={control}
          name="content"
        />
        {!watch("photos") && (
          <HUpload name="photos" control={control}>
            <IconButton
              type="button"
              className="md:w-40 md:min-w-40 md:h-40 w-[32px] min-w-32 h-[32px] bg-white2 rounded-full p-1 mr-1 md:mr-2 flex justify-center items-center"
            >
              <ImageSvg className="fill-primary w-17" />
            </IconButton>
          </HUpload>
        )}
        <StyledButton type="submit" variant="primary" className="py-3 pb-1" isLoading={isLoading}>
          Post
        </StyledButton>
      </div>
      {watch("photos") && (
        <div className="mx-auto w-200">
          <HUpload name="photos" control={control}>
            <IconButton
              type="button"
              className="md:w-40 md:min-w-40 md:h-40 w-[32px] min-w-32 h-[32px] bg-white2 rounded-full p-1 mr-1 md:mr-2 flex justify-center items-center"
            >
              <ImageSvg className="fill-primary w-17" />
            </IconButton>
          </HUpload>
        </div>
      )}
    </form>
  );
};

export const WislistSearching = () => {
  const [content, setContent] = useState<string | undefined>();
  const [sortBy, setSortBy] = useState<PostSortBy>(PostSortBy.NEW);
  const { isLoggedIn } = useAuth();
  const { data, isLoading: isFetching } = useFetchPostQuery({
    content,
    sortBy,
  });

  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }, 500);

  return (
    <div className="">
      <div className="w-12 h-1.5 mx-auto mt-12 mb-6 bg-blue" />
      <div className="px-6">
        <div className="md:w-1/2 mx-auto">
          <div className="xl:col-span-6 md:col-span-3 grid-cols-2 relative lg:block mb-4">
            <input
              type="text"
              className="w-full bg-grey/[.1] rounded-full color-blue pl-14 py-4 placeholder:text-blue text-14"
              placeholder="Search here..."
              onChange={onSeach}
            />
            <IconButton className="absolute z-10 left-5 inset-y-0">
              <SearchSvg className="fill-black" />
            </IconButton>
          </div>
          <StyleButtonChip>
            <ChipButton
              className={clsx(sortBy === PostSortBy.BEST && "!bg-primary !text-white active")}
              onClick={() => setSortBy(PostSortBy.BEST)}
              startIcon={<RocketSvg className="mr-2 fill-primary" />}
            >
              Best
            </ChipButton>
            <ChipButton
              className={clsx(sortBy === PostSortBy.HOT && "!bg-primary !text-white active")}
              onClick={() => setSortBy(PostSortBy.HOT)}
              startIcon={<FireSvg className="mr-2 fill-primary" />}
            >
              Hot
            </ChipButton>
            <ChipButton
              className={clsx(sortBy === PostSortBy.NEW && "!bg-primary !text-white active")}
              onClick={() => setSortBy(PostSortBy.NEW)}
              startIcon={<RoundedUpSvg className="mr-2 fill-primary" />}
            >
              New
            </ChipButton>
          </StyleButtonChip>
        </div>
        <CreatePostSection />
      </div>
      <div className="px-8">
        <div className="custom-scroll grid md:grid-cols-3 gap-5 max-h-680 overflow-auto">
          {isFetching ? (
            <WishlistLoading />
          ) : (
            data?.data.map((item, key) => (
              <div>
                <Article
                  isPopular={item.is_popular}
                  author={item.owner.first_name}
                  content={item.content}
                  photos={item.photos}
                  city=""
                  country=""
                  state=""
                  isAction
                  key={key}
                  time={moment(item.createdAt).fromNow()}
                  likes={item.like}
                  comments={item.comment}
                  id={item.id}
                  isComment={isLoggedIn}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export const WishListSection = () => {
  const sliderRef = useRef<Slider>(null);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useFetchNewsQuery({
    limit: 9,
    page: page,
  });

  if (data?.data?.length || 0 < 4) {
    return (
      <div className="px-4 md:px-0">
        <div className=" md:py-8 pt-4 pb-4">
          <Typography tag="h2">Blist Updates</Typography>
          <div className="pt-10 grid grid-cols-3 gap-4">
            {data?.data?.map((item, key) => (
              <NewWeekItem
                title={item.title}
                url={item.photo ? `${s3Url}${item.photo}` : undefined}
                key={key}
                id={item.id}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 md:px-0">
      <div className=" md:py-8 pt-4 pb-4">
        <SlideTitle
          title="Blist Updates"
          onNext={() => sliderRef?.current?.slickNext()}
          onPrevious={() => sliderRef?.current?.slickPrev()}
        />
        {isLoading ? (
          <ListItemLoading />
        ) : (
          <div className="pt-10">
            <Slider
              ref={sliderRef}
              dots={false}
              infinite
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              centerMode
              centerPadding={"60"}
              arrows={false}
              responsive={[
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    centerPadding: "10",
                  },
                },
              ]}
            >
              {data?.data?.map((item, key) => (
                <NewWeekItem
                  title={item.title}
                  url={item.photo ? `${s3Url}${item.photo}` : undefined}
                  key={key}
                  id={item.id}
                />
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};
