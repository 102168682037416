export enum BookingStatus {
  ORDERED = "ordered", // submit booking form
  COMPLETED_PAYMENT = "completed payment", // confirm payment on Confirm page

  WAITING_PAYMENT = "waiting payment",
  USER_REQUEST_MODIFY = "user request modify", // user request to modify
  USER_REQUEST_CANCEL = "user request cancel", // user request to cancel
  CREATOR_REQUEST_MODIFY = "creator request modify", // user request to modify
  CREATOR_REQUEST_CANCEL = "creator request cancel", // user request to cancel
  CANCEL = "cancel", // creator confirm this booking
  APPROVED = "approved", // creator confirm this booking
  DECLINED = "declined", // creator declined this booking
  CHECK_IN = "check-in", // user check-in in the place that the experience starts
  REVIEWED = "reviewed",
}
