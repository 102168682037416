import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateWishlistPayload, FetchWishlistPayload, UpdateWishlistPayload } from "./types";
import { Wishlist } from "@app/types/wishlist";

export const wishlistApi = createApi({
  reducerPath: "wishlistApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Wishlist"],
  endpoints: (builder) => ({
    fetchWishlist: builder.query<CommonResponse<Wishlist[]>, FetchWishlistPayload>({
      query: (params) => {
        return {
          url: "wishlist",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: Wishlist) => ({ type: "Wishlist" as const, id })),
              "Wishlist",
            ]
          : ["Wishlist"],
    }),
    createWishlist: builder.mutation<CommonResponse<Wishlist>, CreateWishlistPayload>({
      query(body) {
        return {
          url: `wishlist`,
          method: "post",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Thanks for the idea!" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Wishlist"],
    }),
    updateWishlist: builder.mutation<
      CommonResponse<Wishlist>,
      Partial<UpdateWishlistPayload> & { id: number }
    >({
      query({ id, ...body }) {
        return {
          url: `wishlist/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Wishlist", id: arg.id }],
    }),
    removeWishlist: builder.mutation<CommonResponse<Wishlist>, number>({
      query(id: number) {
        return {
          url: `wishlist/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Wishlist"],
    }),
    getWishlist: builder.query<Wishlist, number>({
      query: (id: number) => `wishlist/${id}`,
      transformResponse: (response: CommonResponse<Wishlist>) => response?.data,
      providesTags: (result, error, id) => [{ type: "Wishlist", id }],
    }),
  }),
});

export const {
  useCreateWishlistMutation,
  useFetchWishlistQuery,
  useRemoveWishlistMutation,
  useUpdateWishlistMutation,
  useGetWishlistQuery,
} = wishlistApi;
