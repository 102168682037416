import { ReactElement, useMemo, useState } from "react";
import { Control, useController } from "react-hook-form";
import { s3Url } from "@app/config";
import { isString, isArray, map, slice } from "lodash";
import clsx from "clsx";
import { isImage } from "@app/utils";
import { useUploadMediasMutation } from "@app/store/services/media";
import { CloseSquareSvg, IconButton, Plus, Spinner, UploadCloudSvg, UploadHover } from "../atoms";
import { useInform } from "@app/store/hooks";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Props {
  control: Control<any>;
  name: string;
  children?: ReactElement;
  defaultValue?: string;
  multiple?: boolean;
  imageClass?: string;
  maxFile?: number;
}

const ReviewImages = ({
  src,
  className,
  onClose,
  id,
}: {
  src: string;
  className?: string;
  onClose?: () => void;
  id: number;
}) => {
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="relative">
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <img src={src} className={clsx("w-full h-full", className)} alt={`${id}`} />
      </div>
      <IconButton
        type="button"
        onClick={onClose}
        className="absolute z-40 top-1 right-1 bg-white border border-black"
      >
        <CloseSquareSvg />
      </IconButton>
    </div>
  );
};

const ReviewVideos = ({
  src,
  className,
  onClose,
  id,
}: {
  src: string;
  className?: string;
  disabled?: boolean;
  onClose?: () => void;
  id: number;
}) => {
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="relative">
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <video src={src} className={clsx("w-full h-full", className)} />
      </div>
      <IconButton type="button" onClick={onClose} className="absolute z-40 top-1 right-1">
        <CloseSquareSvg />
      </IconButton>
    </div>
  );
};

const ReviewImage = ({
  src,
  className,
  disabled,
  onClose,
}: {
  src: string;
  className?: string;
  disabled?: boolean;
  onClose?: () => void;
}) => {
  return (
    <UploadHover disabled={disabled} onClose={onClose}>
      <img src={src} className={clsx("w-full h-full", className)} />
    </UploadHover>
  );
};

const ReviewVideo = ({
  src,
  className,
  disabled,
  onClose,
}: {
  src: string;
  className?: string;
  disabled?: boolean;
  onClose?: () => void;
}) => {
  return (
    <UploadHover disabled={disabled} onClose={onClose}>
      <video src={src} className={clsx("w-full h-full", className)} />
    </UploadHover>
  );
};

export const HUpload = ({
  control,
  name,
  children,
  defaultValue,
  multiple,
  imageClass,
  maxFile,
}: Props) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    defaultValue,
    control,
  });
  const [uploadImage, { isLoading }] = useUploadMediasMutation();
  const { openInform } = useInform();
  const [activeImage, setActiveImage] = useState<number | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!multiple && event?.target?.files?.[0]) {
      const response = await uploadImage([event?.target?.files?.[0]]).unwrap();

      onChange(response[0]);
    } else if (multiple && event?.target?.files?.length) {
      const files = Array.from(event?.target?.files);
      if (maxFile && files.length + (value ?? []).length > maxFile) {
        openInform({ type: "error", show: true, message: `The max file is ${maxFile}` });
        return;
      }

      const response = await uploadImage(files).unwrap();
      onChange([...(value || []), ...response]);
    }
  };

  const onClose = (index: number) => {
    const leftArray = slice(value ?? [], 0, index);
    const rightArray = slice(value ?? [], index + 1, (value ?? []).length);
    onChange([...leftArray, ...rightArray]);
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveImage(event.active.id as number);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const sortedList = arrayMove(value, active?.id as number, over?.id as number);
      onChange(sortedList);
    }

    setActiveImage(null);
  };

  const handleDragCancel = () => {
    setActiveImage(null);
  };

  const Render = useMemo(() => {
    if (!value) {
      return (
        <>
          {children}
          <input
            type="file"
            multiple={multiple}
            ref={ref}
            className="absolute inset-0 opacity-0 cursor-pointer z-20 w-full"
            onChange={onUpload}
          />
        </>
      );
    }

    if (isString(value)) {
      if (isImage(value)) {
        return (
          <>
            <ReviewImage src={`${s3Url}${value}`} className={clsx("w-full h-full", imageClass)} />
            <input
              type="file"
              multiple={multiple}
              ref={ref}
              className="absolute inset-0 opacity-0 cursor-pointer z-20 w-full"
              onChange={onUpload}
            />
          </>
        );
      }
      return (
        <>
          <ReviewVideo src={`${s3Url}${value}`} className={clsx("w-full h-full", imageClass)} />
          <input
            type="file"
            multiple={multiple}
            ref={ref}
            className="absolute inset-0 opacity-0 cursor-pointer z-20 w-full"
            onChange={onUpload}
          />
        </>
      );
    }

    return (
      isArray(value) && (
        <div className="grid grid-cols-3 gap-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={value} strategy={rectSortingStrategy}>
              {map(value, (it, key) => {
                if (isImage(it)) {
                  return (
                    <ReviewImages
                      key={key}
                      id={key}
                      src={`${s3Url}${it}`}
                      className={clsx("w-full h-full", imageClass)}
                      onClose={() => onClose(key)}
                    />
                  );
                }

                return (
                  <ReviewVideos
                    key={key}
                    id={key}
                    src={`${s3Url}${it}`}
                    className={clsx("w-full h-full", imageClass)}
                    onClose={() => onClose(key)}
                  />
                );
              })}
              <DragOverlay adjustScale={true}>
                {activeImage ? (
                  <img
                    src={`${s3Url}${value[activeImage]}`}
                    className={clsx("w-full h-full", imageClass)}
                    alt="overlay"
                  />
                ) : null}
              </DragOverlay>
            </SortableContext>
          </DndContext>
          <div className={clsx(isArray(value) && "relative")}>
            <div className="border-dashed border-border2 border rounded-10 h-full flex items-center justify-center">
              <UploadCloudSvg className="mb-4" />
            </div>
            <input
              type="file"
              multiple={multiple}
              ref={ref}
              className="absolute inset-0 opacity-0 cursor-pointer z-20"
              onChange={onUpload}
            />
          </div>
        </div>
      )
    );
  }, [value, activeImage]);

  return (
    <div className="relative alert-hover z-0">
      {isLoading && (
        <div className="absolute inset-0 z-30 bg-blue opacity-30 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {Render}
    </div>
  );
};
