import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { FetchInvoicePayload } from "./types";
import { Invoice, Paging } from "@app/types";

export const invoiceApi = createApi({
  reducerPath: "invoiceApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Invoice"],
  endpoints: (builder) => ({
    fetchInvoice: builder.query<CommonResponse<Invoice[]>, Paging & FetchInvoicePayload>({
      query: (params) => {
        return {
          url: "invoice",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: Invoice) => ({ type: "Invoice" as const, id })), "Invoice"]
          : ["Invoice"],
    }),
  }),
});

export const { useFetchInvoiceQuery } = invoiceApi;
