import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "@app/store/hooks";
import { Role } from "@app/enums";
import { StickMessage, LeftMenu, UploadProfile } from "../molecules";
import { Header, Footer, LeftSideBar, AdminMenu } from "../organisms";
import { Typography } from "../atoms";

export const PrivateLayout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  return (
    <div className="flex flex-col min-h-100vh">
      <Header />
      <div className="container-layout flex-1 flex flex-col">{children}</div>
      <StickMessage />
      <Footer />
    </div>
  );
};

export const CreatorPrivateLayout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, profile } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  if (isLoggedIn) {
    if (profile.role === Role.admin) {
      return <Navigate to="/admin" />;
    }

    if (profile.role === Role.user) {
      return <Navigate to="/" />;
    }
  }

  return (
    <div className="flex flex-col">
      <Header />
      <div className="container-layout min-h-75vh">{children}</div>
      <StickMessage />
      <Footer />
    </div>
  );
};

export const UserPrivateLayout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, profile } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  if (isLoggedIn) {
    if (profile.role === Role.admin) {
      return <Navigate to="/admin" />;
    }

    if (profile.role === Role.creator) {
      return <Navigate to="/creator-dashboard" />;
    }
  }

  return (
    <div className="flex flex-col">
      <Header />
      <div className="container-layout min-h-75vh">{children}</div>
      <StickMessage />
      <Footer />
    </div>
  );
};

export const PublicLayout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, profile } = useAuth();
  if (isLoggedIn) {
    if (profile.role === Role.admin) {
      return <Navigate to="/admin" />;
    }

    if (profile.role === Role.user) {
      return <Navigate to="/" />;
    }

    if (profile.role === Role.creator) {
      return <Navigate to="/creator-dashboard" />;
    }
  }

  return (
    <div className="flex flex-col min-h-100vh">
      <Header />
      <div className="container-layout min-h-75vh flex-1">{children}</div>
      <Footer />
    </div>
  );
};

export const CommonLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col min-h-100vh">
      <Header />
      <div className="flex-1 container-layout min-h-75vh">{children}</div>
      <Footer />
    </div>
  );
};

export const FullwidthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export const AdminLayout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, profile } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  if (isLoggedIn) {
    if (profile.role === Role.creator) {
      return <Navigate to="/creator-dashboard" />;
    }

    if (profile.role === Role.user) {
      return <Navigate to="/" />;
    }
  }

  return (
    <div className="flex flex-row min-h-screen">
      <LeftSideBar />
      <div className="flex-1 flex flex-col">
        <AdminMenu />
        <div className="flex-1 px-4 pt-6 pb-4 overflow-auto flex flex-col">{children}</div>
      </div>
    </div>
  );
};

export const AccountDetailLayout = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="my-6">
      <div className="grid md:grid-cols-4 gap-6 md:mx-0 mx-4">
        <Typography tag="title" className="text-blue self-center">
          {title}
        </Typography>
        <div className="md:col-span-3">
          <UploadProfile />
        </div>
      </div>
      <div className="grid md:grid-cols-4 gap-6 md:mx-0 mx-4">
        <div>
          <LeftMenu />
        </div>
        <div className="md:col-span-3">{children}</div>
      </div>
    </div>
  );
};
