import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { GetLast7DaysResponse, GetLast30DaysResponse, GetLast365DaysResponse } from "./types";
import { Review } from "@app/types/review";

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Analytics"],
  endpoints: (builder) => ({
    getLast7Days: builder.query<CommonResponse<GetLast7DaysResponse>, {}>({
      query: (params) => {
        return {
          url: "analytics/last-seven-days",
          params,
        };
      },
    }),
    getLast30Days: builder.query<CommonResponse<GetLast30DaysResponse[]>, {}>({
      query: (params) => {
        return {
          url: "analytics/last-month",
          params,
        };
      },
    }),
    getLast365Days: builder.query<CommonResponse<GetLast365DaysResponse[]>, {}>({
      query: (params) => {
        return {
          url: "analytics/last-year",
          params,
        };
      },
    }),
    getReviewAnalytics: builder.query<CommonResponse<Review[]>, {}>({
      query: (params) => {
        return {
          url: "analytics/review-analytics",
          params,
        };
      },
    }),
    getSimilarExperience: builder.query<CommonResponse<number>, {}>({
      query: (params) => {
        return {
          url: "analytics/similar-experience",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetLast30DaysQuery,
  useGetLast7DaysQuery,
  useGetLast365DaysQuery,
  useGetReviewAnalyticsQuery,
  useGetSimilarExperienceQuery,
} = analyticsApi;
