import { ReactNode } from "react";
import { IconButton } from "./Button";
import { CloseSquareSvg } from "./icons";

type Props = {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  onClose?: () => void;
};

export const UploadHover = ({ children, disabled, onClose }: Props) => {
  return (
    <div className="relative">
      {children}
      {!disabled && (
        <div className="alert bg-blue text-white absolute flex items-center justify-center opacity-0 z-10 inset-0">
          Please click on this area to update
        </div>
      )}
      {onClose && (
        <IconButton type="button" onClick={onClose} className="absolute z-40 top-1 right-1">
          <CloseSquareSvg />
        </IconButton>
      )}
    </div>
  );
};
