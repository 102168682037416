import clsx from "clsx";
import { DetailedHTMLProps, LabelHTMLAttributes } from "react";

interface Props extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  variant?: "default" | "error";
}

export const Label = ({ htmlFor, children, className, variant = "default", ...props }: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        "block mb-2",
        variant === "default" && "text-sm font-medium text-gray-900",
        variant === "error" && "text-12 text-chipDangerText",
        className,
      )}
      {...props}
    >
      {children}
    </label>
  );
};
