import { Controller, Control } from "react-hook-form";
import { BookingLoading } from "../atoms";
import type { SelectPhoneCountryProps } from "../atoms";
import { useFetchPriceQuery } from "@app/store/services/stripe-price";
import clsx from "clsx";

interface Props extends SelectPhoneCountryProps {
  control: Control<any>;
  name: string;
}

export const HStripePrice = ({ control, name, ...props }: Props) => {
  const { data, isLoading: isFetchingPrice } = useFetchPriceQuery({});

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <div className="grid grid-cols-3 gap-4">
          {isFetchingPrice ? (
            <BookingLoading />
          ) : (
            data?.data.map((item) => (
              <div
                onClick={() => field.onChange(item.stripe_price_id)}
                className={clsx(
                  "cursor-pointer rounded-10 border text-center py-2",
                  field.value === item.stripe_price_id && "bg-primary text-white",
                )}
              >
                {item.description}
                <br />
                Price: {item.amount}
              </div>
            ))
          )}
        </div>
      )}
    />
  );
};
