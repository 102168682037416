import { Typography, Button, EditSvg, TrashSvg, WishlistLoading } from "../atoms";
import { Article, Pagination } from "../common";
import { RemovePopup } from "../organisms";
import { useFetchTopicQuery, useRemoveTopicMutation } from "@app/store/services/topic";
import { Topic } from "@app/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AdminTopic = () => {
  const { data, isLoading: isFetching } = useFetchTopicQuery({});
  const [selectedItem, setSelectedItem] = useState<Topic | null>(null);
  const [isShowRemove, setIsShowRemove] = useState(false);
  const navigate = useNavigate();
  const [removeTopic, { isLoading: isRemoving }] = useRemoveTopicMutation();

  const menuList = (item: Topic) => [
    {
      text: "Edit",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => navigate(`/admin/topic/update/${item.id}`),
    },
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelectedItem(item);
        setIsShowRemove(true);
      },
    },
  ];

  return (
    <div className="border-b border-border2 mb-6 pb-6">
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isRemoving}
        onClose={() => setIsShowRemove(false)}
        onSubmit={() => removeTopic(selectedItem?.id || 0)}
        name={`this topic`}
      />
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Topic</Typography>
        <div className="flex md:flex-row flex-col">
          <Button variant="primary" onClick={() => navigate(`/admin/topic/add`)}>
            New
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
        {isFetching ? (
          <WishlistLoading />
        ) : (
          data?.data.map((item, key) => (
            <Article
              key={key}
              author={item.title}
              content={item.content}
              city=""
              country=""
              state=""
              isEdit
              isDetail
              menuList={menuList(item)}
            />
          ))
        )}
      </div>
    </div>
  );
};
