import Slider from "react-slick";
import { useRef } from "react";
import { useFetchCategoriesQuery } from "@app/store/services/category";
import { SlideTitle, CategoryItem } from "../molecules";
import { SlideLoading } from "../atoms";

export const SlideSection = () => {
  const { data, isLoading } = useFetchCategoriesQuery({});
  const sliderRef = useRef<Slider>(null);

  return (
    <div
      className="px-4 md:px-0"
      style={{
        background: "url(/imgs/image-29.png) no-repeat center #EFEFEF",
        backgroundSize: "cover",
      }}
    >
      <div className=" md:py-8 py-4">
        <SlideTitle
          title="Blist Categories"
          onNext={() => sliderRef?.current?.slickNext()}
          onPrevious={() => sliderRef?.current?.slickPrev()}
        />
        <div className="pt-10">
          {isLoading ? (
            <SlideLoading />
          ) : (
            <Slider
              ref={sliderRef}
              dots={false}
              infinite
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              centerMode
              centerPadding={"60"}
              arrows={false}
              responsive={[
                {
                  breakpoint: 976,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    centerPadding: "20",
                  },
                },
              ]}
            >
              {data?.map((item, index) => (
                <CategoryItem key={index} isSlide title={item.name} url={item.image} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};
