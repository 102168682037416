import { Controller, Control } from "react-hook-form";
import { MaskInput, MaskInputProps } from "../atoms";

interface Props extends MaskInputProps {
  control: Control<any>;
  name: string;
}

export const HMaskInput = ({ control, name, ...props }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <MaskInput
          onChange={field.onChange}
          value={field.value}
          ref={ref as any}
          error={error?.message}
          {...props}
        />
      )}
    />
  );
};
