import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreateTrackingPayload } from "./type";
import { Tracking } from "@app/types";

export const trackingApi = createApi({
  reducerPath: "trackingApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Tracking"],
  endpoints: (builder) => ({
    getTracking: builder.query<Tracking, string | number>({
      query: (bookingId) => ({
        url: `tracking/${bookingId}`,
      }),
      transformResponse: (response: CommonResponse<Tracking>) => response.data,
      providesTags: ["Tracking"],
    }),
    createTracking: builder.mutation<CommonResponse<Tracking>, CreateTrackingPayload>({
      query(body) {
        return {
          url: `tracking`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Tracking"],
    }),
  }),
});

export const { useGetTrackingQuery, useCreateTrackingMutation } = trackingApi;
