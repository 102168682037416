import { ReactNode } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

type Props = {
  content?: string;
  children: ReactNode;
  className?: string;
  id: string;
};

export const Tooltip = ({ content, children, className, id }: Props) => {
  return (
    <>
      <ReactTooltip id={id} />
      <div data-tooltip-id={id} data-tooltip-html={content} className={className}>
        {children}
      </div>
    </>
  );
};
