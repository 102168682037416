export enum ExpKind {
  ONLINE = "online",
  INPERSION = "in-person",
  VR = "vr",
  METAVERSE = "metaverse",
}

export enum ExpType {
  PRIVATE = "private",
  GROUP = "group",
  BOTH = "both",
}

export enum ExpLevel {
  NONE = "none",
  MODERATE = "moderate",
  STRENUOUS = "strenuous",
  EXTREME = "extreme",
  LIGHT = "light",
  NOT_AVAILABLE = "na",
}

export enum ExpCreatorType {
  INDIVIDUAL = "Individual",
  COLLABORATING = "Collaborating",
}

export enum CreatorPresence {
  ENJOY_ALONE = "enjoy_alone",
  PRESENT = "present",
}

export enum Language {
  ENGLISH = "english",
  CATALONIA = "Catalonia",
  SPAIN = "spain",
  FRANCE = "france",
  ITALY = "italy",
  CHINA = "china",
  JAPAN = "japan",
  PORTUGAL = "portugal",
}

export enum ExpBusiness {
  INDIVIDUAL = "Individual",
  VENUE = "Venue",
}
