import { CallSvg, ChipButton, SmsSvg, TrashSvg, Typography } from "../atoms";

type Props = {
  name?: string;
  phone?: string;
  email: string;
  onRemove?: () => void;
  isNotRemove?: boolean;
};

export const EmercyContact = ({ name, phone, email, onRemove, isNotRemove }: Props) => {
  return (
    <div className="border rounded-10 border-border-2 px-5 py-3">
      <div className="flex flex-row justify-between items-center border-b border-lightGrey pb-2">
        <Typography>{name}</Typography>
        {!isNotRemove && (
          <ChipButton
            className="text-primary text-16 font-weight"
            size="normal"
            variant="static"
            startIcon={<TrashSvg className="stroke-primary mr-2" />}
            onClick={() => onRemove?.()}
          >
            Remove
          </ChipButton>
        )}
      </div>
      <div className="md:flex flex-row justify-between items-center pt-2">
        <div className="md:border-r md:border-lightGrey md:w-2/3">
          <ChipButton
            className="text-lightGrey text-16 font-weight "
            layout="static"
            size="normal"
            variant="static"
            startIcon={<SmsSvg className="mr-2" />}
            onClick={() => (window.location.href = `mailto:${email}`)}
          >
            {email}
          </ChipButton>
        </div>
        <div className="md:w-1/3 text-right">
          <ChipButton
            className="text-lightGrey text-16 font-weight"
            size="normal"
            variant="static"
            startIcon={<CallSvg className="mr-2" />}
            onClick={() => (window.location.href = `tel:${phone}`)}
          >
            {phone}
          </ChipButton>
        </div>
      </div>
    </div>
  );
};
