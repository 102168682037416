import { useRef } from "react";
import Slider from "react-slick";
import {
  IconButton,
  ChevronCircleRight,
  ChevronCircleLeft,
  ExpListItemLoading,
} from "@app/components/atoms";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import { useAuth } from "@app/store/hooks";
import { s3Url } from "@app/config";

export const AnalyticsSlide = () => {
  const { profile } = useAuth();
  const { data, isLoading } = useFetchExperienceQuery({ user_id: profile.id });
  const sliderRef = useRef<Slider>(null);

  if (isLoading) return <ExpListItemLoading />;

  if ((data?.data.length || 0) < 4) {
    return (
      <div className="grid grid-cols-3 md:mb-6 mb-4">
        {data?.data.map((item, key) => (
          <div className="px-2" key={key}>
            <img
              src={item.photos?.[0] ? `${s3Url}${item.photos?.[0]}` : "/imgs/default-image.jpg"}
              className="h-170px w-full"
              alt="19"
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center justify-between md:mb-6 mb-4 w-full relative">
      <IconButton onClick={() => sliderRef?.current?.slickPrev()} className="absolute z-20">
        <ChevronCircleLeft className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
      <div className="w-full px-10">
        <Slider
          ref={sliderRef}
          dots={false}
          infinite
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          arrows={false}
          responsive={[
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {data?.data.map((item, key) => (
            <div className="px-2" key={key}>
              <img
                src={item.photos?.[0] ? `${s3Url}${item.photos?.[0]}` : "/imgs/default-image.jpg"}
                className="h-170px w-full"
                alt="19"
              />
            </div>
          ))}
        </Slider>
      </div>
      <IconButton onClick={() => sliderRef?.current?.slickNext()} className="absolute z-20 right-0">
        <ChevronCircleRight className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
    </div>
  );
};
