import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../";
import { closeInform, InformState, openInform } from "../reducers/inform";

interface UseInformProps extends InformState {
  closeInform: () => void;
  openInform: (payload: InformState) => void;
}

export const useInform = (): UseInformProps => {
  const allState = useSelector((state: RootState) => state.inform);
  const dispatch = useDispatch();

  return {
    ...allState,
    closeInform: () => dispatch(closeInform()),
    openInform: (payload: InformState) => dispatch(openInform(payload)),
  };
};
