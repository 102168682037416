import type { ChangeEvent, DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import { Label } from "./Label";
import { Typography } from ".";
import clsx from "clsx";

export interface TextareaProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  value?: string;
  border?: "full" | "static" | "rectangled" | "circle";
  variant?: "static" | "underline" | "outlined";
  subtitle?: string;
  error?: string;
}

export const Textarea = ({
  label,
  variant = "static",
  border = "static",
  className,
  subtitle,
  error,
  onChange,
  maxLength,
  ...props
}: TextareaProps) => {
  const onChangeCustom = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength && e.target.value.split(" ").length > maxLength) return;
    onChange?.(e);
  };

  return (
    <div className="mb-4">
      {label && (
        <div className="flex flex-row items-center mb-4">
          <Typography tag="h6" className="text-blue mr-2">
            {label}
          </Typography>
          {subtitle && <p className="text-placeholder1 font-medium">{subtitle}</p>}
        </div>
      )}
      <div>
        <textarea
          className={clsx(
            "resize-none w-full min-h-150 md:text-16 text-14 font-medium placeholder:text-placeholder1 placeholder:font-medium p-4",
            border === "full" && "rounded-full",
            border === "circle" && "rounded-20",
            variant === "static" && "bg-white rounded-10 overflow-hidden py-2 md:px-4 px-2",
            variant === "underline" && "border-t-0 border-l-0 border-r-0 border-timeAgo border-b",
            variant === "outlined" && "border border-lightGrey",
            className,
          )}
          {...props}
          onChange={onChangeCustom}
        />
        {maxLength && (
          <Typography className="text-right py-1" tag="body">
            {props?.value?.split(" ")?.length || "0"}/{maxLength}
          </Typography>
        )}
        {error && <Label variant="error">{error}</Label>}
      </div>
    </div>
  );
};
