import { createApi } from "@reduxjs/toolkit/query/react";
import type { CategoryTopic } from "@app/types";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type {
  CreateCategoryTopicPayload,
  UpdateCategoryTopicPayload,
  FetchCategoryTopicPayload,
} from "./types";

export const categoryTopicApi = createApi({
  reducerPath: "categoryTopicApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["CategoryTopic"],
  endpoints: (builder) => ({
    fetchCategoryTopic: builder.query<CommonResponse<CategoryTopic[]>, FetchCategoryTopicPayload>({
      query: (params) => {
        return {
          url: "category-topic",
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: CategoryTopic) => ({
                type: "CategoryTopic" as const,
                id,
              })),
              "CategoryTopic",
            ]
          : ["CategoryTopic"],
    }),
    createCategoryTopic: builder.mutation<
      CommonResponse<CategoryTopic>,
      CreateCategoryTopicPayload
    >({
      query(body) {
        return {
          url: `category-topic`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["CategoryTopic"],
    }),
    updateCategoryTopic: builder.mutation<
      CommonResponse<CategoryTopic>,
      UpdateCategoryTopicPayload
    >({
      query({ id, ...body }) {
        return {
          url: `category-topic/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "CategoryTopic", id: arg.id }],
    }),
    getCategoryTopic: builder.query<CategoryTopic, number>({
      query: (id: number) => `category-topic/${id}`,
      transformResponse: (response: CommonResponse<CategoryTopic>) => response?.data,
      providesTags: (result, error, id) => [{ type: "CategoryTopic", id }],
    }),
    removeCategoryTopic: builder.mutation<CommonResponse<CategoryTopic>, number>({
      query(id: number) {
        return {
          url: `category-topic/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["CategoryTopic"],
    }),
  }),
});

export const {
  useFetchCategoryTopicQuery,
  useLazyFetchCategoryTopicQuery,
  useCreateCategoryTopicMutation,
  useUpdateCategoryTopicMutation,
  useRemoveCategoryTopicMutation,
  useGetCategoryTopicQuery,
} = categoryTopicApi;
