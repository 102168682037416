import { Typography } from "@app/components/atoms";

export default function Messages() {
  return (
    <div className="px-6">
      <div className=" py-8">
        <Typography tag="h4" className="text-center mb-4">
          Transaction History
        </Typography>
      </div>
    </div>
  );
}
