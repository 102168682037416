import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import type { User } from "@app/types/user";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreateUserPayload, FetchUserPayload, UpdateUserPayload } from "./type";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    fetchUser: builder.query<CommonResponse<User[]>, FetchUserPayload>({
      query: (params) => {
        return {
          url: "user",
          params,
        };
      },
      transformResponse: (response: CommonResponse<User[]>) => response,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }: User) => ({ type: "User" as const, id })), "User"]
          : ["User"],
    }),
    updateUser: builder.mutation<CommonResponse<User>, Partial<UpdateUserPayload>>({
      query({ id, ...body }) {
        return {
          url: `user/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    removeUser: builder.mutation<CommonResponse<User>, number>({
      query(id: number) {
        return {
          url: `user/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["User"],
    }),
    getUser: builder.query<User, number>({
      query: (id) => `user/${id}`,
      transformResponse: (response: CommonResponse<User>) => response.data,
    }),
    createUser: builder.mutation<CommonResponse<User>, Partial<CreateUserPayload>>({
      query({ ...body }) {
        return {
          url: `user`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useFetchUserQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useLazyFetchUserQuery,
  useCreateUserMutation,
} = userApi;
