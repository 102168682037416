import { Button, Typography, UploadFile } from "@app/components/atoms";
import { AccountDetailLayout } from "@app/components/common";
import { HRadio, HSelectPhoneCountry, HTextField, HUpload } from "@app/components/hookform";
import { Notify } from "@app/enums";
import { useAuth } from "@app/store/hooks";
import { useUpdateProfileMutation } from "@app/store/services/auth";
import { UpdateProfilePayload } from "@app/store/services/auth/type";
import { useForm, SubmitHandler } from "react-hook-form";

export default function AccountProfile() {
  const [update, { isLoading }] = useUpdateProfileMutation();
  const { profile, updateProfile } = useAuth();
  const { handleSubmit, control } = useForm<UpdateProfilePayload>({
    defaultValues: {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email_address: profile.email_address,
      address: profile.address,
      phone_code: profile.phone_code,
      phone: profile.phone,
      banner: profile.banner,
      facebook_url: profile.facebook_url,
      twitter_url: profile.twitter_url,
      instagram_url: profile.instagram_url,
      youtube_url: profile.youtube_url,
      linkedin_url: profile.linkedin_url,
      snapchat_url: profile.snapchat_url,
      notify: profile.notify || Notify.EMAIL,
    },
  });
  const onSubmit: SubmitHandler<UpdateProfilePayload> = (data) => {
    update(data)
      .unwrap()
      .then((res) => {
        updateProfile(res.data);
      })
      .catch(() => {});
  };
  return (
    <AccountDetailLayout title="Account Detail">
      <div className="border-2 rounded-5 border-border5 p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="grid md:grid-cols-2 gap-x-6 gap-y-2">
          <HUpload control={control} name="banner">
            <UploadFile />
          </HUpload>
          <div>
            <HTextField
              name="first_name"
              label="First Name"
              variant="outlined-grey"
              control={control}
            />
            <HTextField
              name="last_name"
              label="Last Name"
              variant="outlined-grey"
              control={control}
            />
          </div>
          <div className="md:col-span-2">
            <HTextField
              name="email_address"
              label="Email"
              variant="outlined-grey"
              control={control}
            />
          </div>
          <div className="md:col-span-2">
            <HTextField name="address" label="Address" variant="outlined-grey" control={control} />
          </div>
          <div className="md:col-span-2">
            <HTextField
              label="Contact Number"
              name="phone"
              variant="outlined-grey"
              startIcon={<HSelectPhoneCountry name="phone_code" control={control} />}
              control={control}
            />
          </div>
          <HTextField
            name="facebook_url"
            label="Facebook"
            variant="outlined-grey"
            control={control}
          />
          <HTextField
            name="twitter_url"
            label="Twitter"
            variant="outlined-grey"
            control={control}
          />
          <HTextField
            name="instagram_url"
            label="Instagram"
            variant="outlined-grey"
            control={control}
          />
          <HTextField
            name="youtube_url"
            label="Youtube"
            variant="outlined-grey"
            control={control}
          />
          <HTextField
            name="linkedin_url"
            label="Linkedin"
            variant="outlined-grey"
            control={control}
          />
          <HTextField
            name="snapchat_url"
            label="Linkedin"
            variant="outlined-grey"
            control={control}
          />
          <div className="mb-3">
            <Typography tag="h6" className="mb-3">
              Notification setting
            </Typography>
            <div className="flex justify-between">
              <HRadio label="Email" name="notify" value="email" control={control} />
              <HRadio label="SMS" name="notify" value="sms" control={control} />
            </div>
          </div>
          <div />
          <div>
            <Button isLoading={isLoading} type="submit" layout="rectangled" variant="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </AccountDetailLayout>
  );
}
