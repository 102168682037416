import {
  useGetReviewAnalyticsQuery,
  useGetSimilarExperienceQuery,
} from "@app/store/services/analytics";
import { Typography } from "../atoms";
import { ExperienceRatingItem } from "../molecules";
import { useMemo } from "react";
import { reduce, map, max, min } from "lodash";
import { ReviewGender } from "@app/enums";
import { useFetchFavoriteQuery } from "@app/store/services/favorite";
import { useAuth } from "@app/store/hooks";

export const DemographicData = () => {
  const { profile } = useAuth();
  const { data } = useGetSimilarExperienceQuery({});
  const { data: reviews, isSuccess: isReviewSuccess } = useGetReviewAnalyticsQuery({});
  const { data: favoriteList } = useFetchFavoriteQuery({ owner_id: profile.id });

  const ageRange = useMemo(() => {
    if (!isReviewSuccess) {
      return "No analytic";
    }

    const data = {
      maxAge: max(map(reviews.data, (item) => item.age)),
      minAge: min(map(reviews.data, (item) => item.age)),
    };

    return `From ${data.minAge} to ${data.maxAge} years`;
  }, [reviews, isReviewSuccess]);

  const genderPercent = useMemo(() => {
    if (!isReviewSuccess) {
      return {
        m: 0,
        f: 0,
        they: 0,
      };
    }

    const sumGender = reduce(
      reviews.data,
      (prev, next) => {
        return {
          m: prev.m + (next.gender === ReviewGender.MALE ? 1 : 0),
          f: prev.f + (next.gender === ReviewGender.FEMALE ? 1 : 0),
          they: prev.they + (next.gender === ReviewGender.THEM_THEY ? 1 : 0),
        };
      },
      {
        m: 0,
        f: 0,
        they: 0,
      },
    );

    return {
      m: (sumGender.m / reviews.data.length) * 100,
      f: (sumGender.f / reviews.data.length) * 100,
      they: (sumGender.they / reviews.data.length) * 100,
    };
  }, [reviews, isReviewSuccess]);

  return (
    <div>
      <Typography tag="h4" className="text-blue mb-4">
        Demographic Data
      </Typography>
      <div className="grid md:grid-cols-2 gap-5">
        <div className="rounded-t-10 bg-bg3 md:py-6 py-3 md:px-8 px-4">
          <Typography tag="h5" className="text-blue mb-3">
            Age Range
          </Typography>
          <Typography className="text-grey7">{ageRange}</Typography>
        </div>
        <div className="rounded-t-10 bg-bg3 md:py-6 py-3 md:px-8 px-4">
          <Typography tag="h5" className="text-blue mb-3">
            Gender Specifics
          </Typography>
          <div className="flex flex-row items-center">
            <div className="pr-8 ">
              <Typography className="text-grey7">M: {genderPercent.m}%</Typography>
              <Typography className="text-grey7">F: {genderPercent.f}%</Typography>
            </div>
            <Typography className="text-grey7 font-semibold pl-4 border-l border-grey7">
              Them/They: {genderPercent.they}%
            </Typography>
          </div>
        </div>
        <div className="rounded-t-10 bg-bg3 md:py-6 py-3 md:px-8 px-4">
          <Typography tag="h5" className="text-blue mb-3">
            Similar Experiences
          </Typography>
          <div className="flex flex-row">
            <div className="md:pr-8 pr-3 border-r border-grey7">
              <Typography className="text-grey7 font-semibold">25</Typography>
              <Typography className="text-grey7">{data?.data}</Typography>
            </div>
            <div className="md:px-8 px-3 border-r border-grey7">
              <Typography className="text-grey7 font-semibold">25</Typography>
              <Typography className="text-grey7">{data?.data}</Typography>
            </div>
            <div className="md:px-8 px-3">
              <Typography className="text-grey7 font-semibold">25</Typography>
              <Typography className="text-grey7">{data?.data}</Typography>
            </div>
            <Typography className="text-grey7 !self-auto">miles</Typography>
          </div>
        </div>
        <div className="rounded-t-10 bg-bg3 md:py-6 py-3 md:px-8 px-4">
          <Typography tag="h5" className="text-blue mb-3">
            Favorites Total
          </Typography>
          <Typography className="text-grey7">{favoriteList?.data.length || 0}</Typography>
        </div>
      </div>
    </div>
  );
};

export const ExperienceRating = () => {
  const { data, isSuccess } = useGetReviewAnalyticsQuery({});

  const sumReview = useMemo(() => {
    if (!isSuccess) {
      return {
        communication: 0,
        accuracy: 0,
        value: 0,
        atmosphere: 0,
        location: 0,
        presentation: 0,
        creativity: 0,
        uniqueness: 0,
      };
    }

    return reduce(
      data.data,
      (prev, next) => {
        return {
          communication: prev.communication + (next.communication || 0),
          accuracy: prev.accuracy + (next.accuracy || 0),
          value: prev.value + (next.value || 0),
          atmosphere: prev.atmosphere + (next.atmosphere || 0),
          location: prev.location + (next.location || 0),
          presentation: prev.presentation + (next.presentation || 0),
          creativity: prev.creativity + (next.creativity || 0),
          uniqueness: prev.uniqueness + (next.uniqueness || 0),
        };
      },
      {
        communication: 0,
        accuracy: 0,
        value: 0,
        atmosphere: 0,
        location: 0,
        presentation: 0,
        creativity: 0,
        uniqueness: 0,
      },
    );
  }, [data, isSuccess]);

  return (
    <div className="rounded-t-10 bg-bg3 py-4 md:px-8 px-4 min-h-full">
      <Typography tag="h5" className="!font-inter mb-6">
        Experience Ratings
      </Typography>
      <div>
        <ExperienceRatingItem
          text="Communication"
          className="mb-2"
          point={sumReview.communication}
        />
        <ExperienceRatingItem text="Accuracy" className="mb-2" point={sumReview.accuracy} />
        <ExperienceRatingItem text="Value" className="mb-2" point={sumReview.value} />
        <ExperienceRatingItem text="Atmosphere" className="mb-2" point={sumReview.atmosphere} />
        <ExperienceRatingItem text="Location" className="mb-2" point={sumReview.location} />
        <ExperienceRatingItem text="Presentation" className="mb-2" point={sumReview.presentation} />
        <ExperienceRatingItem text="Creativity" className="mb-2" point={sumReview.creativity} />
        <ExperienceRatingItem text="Uniqueness" point={sumReview.uniqueness} />
      </div>
    </div>
  );
};
