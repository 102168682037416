import { s3Url } from "@app/config";
import { Typography } from "../atoms";

type Props = {
  author?: string;
  content?: string;
  date?: string;
  url?: string;
};

export const RatingItem = ({ author, content, date, url }: Props) => {
  return (
    <div className="rounded-10 border border-black/[0.07] py-6 px-6">
      <div className="flex flex-row mb-3">
        <img
          src={url ? `${s3Url}${url}` : "/imgs/default-avatar.png"}
          className="mr-4 h-fit"
          width={56}
          height={56}
          alt="rating 28"
        />
        <div>
          <Typography tag="h5">{author}</Typography>
          <Typography className="text-grey2">{date}</Typography>
        </div>
      </div>
      <Typography>{content}</Typography>
    </div>
  );
};
