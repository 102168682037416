import { Typography } from "@app/components/atoms";
import { FeedbackForm } from "@app/components/organisms";

export const Feedback = () => {
  return (
    <div className="pt-4">
      <Typography tag="h2" className="text-center mb-2">
        Share your feedback
      </Typography>
      <Typography
        tag="body"
        className="leading-normal py-2 md:py-6 text-center mb-4 max-w-2xl mx-auto"
      >
        {""}
      </Typography>
      <FeedbackForm />
    </div>
  );
};
