import {
  BannerDetail,
  UserExperience,
  UserIntroduce,
  ExperienceSection,
} from "@app/components/organisms";
import { useGetUserQuery } from "@app/store/services/user";
import { useParams } from "react-router-dom";
import { useAuth } from "@app/store/hooks";

export default function UserDetail() {
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserQuery(Number(userId), { skip: !userId });
  const { profile } = useAuth();

  return (
    <div className="md:px-0 px-4">
      <div className=" bg-greyLayout">
        <BannerDetail banner={data?.banner} />
        <UserIntroduce
          avatar={data?.avatar}
          name={`${data?.first_name} ${data?.last_name}`}
          isVerify={data?.identity_verified}
          email={data?.email_address}
          facebook_url={data?.facebook_url}
          twitter_url={data?.twitter_url}
          instagram_url={data?.instagram_url}
          youtube_url={data?.youtube_url}
          linkedin_url={data?.linkedin_url}
          snapchat_url={data?.snapchat_url}
        />
        <UserExperience />
        <ExperienceSection title={`Experiences By ${profile.first_name}`} userId={userId} />
      </div>
    </div>
  );
}
