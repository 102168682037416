import { Typography, BookingLoading } from "@app/components/atoms";
import { Pagination } from "@app/components/common";
import { UserItem } from "@app/components/molecules";
import { useState } from "react";
import { limitItem, s3Url } from "@app/config";
import { useFetchInvoiceQuery } from "@app/store/services/invoice";
import { InvoiceStatus } from "@app/enums";

export default function AdminUser() {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useFetchInvoiceQuery({
    limit: limitItem,
    page,
  });
  return (
    <>
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Payment</Typography>
      </div>
      <div className="mb-6">
        {isLoading ? (
          <>
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
          </>
        ) : (
          <table className="table-fixed w-full">
            <thead className="text-gray-400 uppercase bg-gray-700">
              <tr className="text-left">
                <th className="px-6 py-3">User</th>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Experience</th>
                <th className="px-6 py-3">Amount</th>
                <th className="px-6 py-3">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white border-b">
              {data?.data?.map((item, key) => (
                <tr key={key}>
                  <td className="px-6 py-4">{`${item.user.first_name} ${item.user.last_name}`}</td>
                  <td className="px-6 py-4">{item.user.email_address}</td>
                  <td className="px-6 py-4">{item.promote.experience.title}</td>
                  <td className="px-6 py-4">{item.amount}$</td>
                  <td className="px-6 py-4">{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex flex-row justify-end">
        <Pagination setPage={setPage} page={page} total={data?.total} />
      </div>
    </>
  );
}
