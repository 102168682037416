import { useMemo, useState, useCallback } from "react";
import {
  Typography,
  IconButton,
  MsgNotifSvg,
  ChartSvg,
  Select,
  Toggle,
  BookingLoading,
} from "../atoms";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
import { BookingCalendar, Option } from "@app/types";
import { useFetchBookingQuery, useFetchCalendarQuery } from "@app/store/services/booking";
import { BookingItem } from "../molecules";
import {
  useCreateHolidayMutation,
  useFetchHolidaysQuery,
  useRemoveHolidayMutation,
} from "@app/store/services/holiday";
import { useAuth, useInform } from "@app/store/hooks";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type Props = {
  data?: BookingCalendar[];
  date: { date: number; start_date: string; end_date: string } | null;
  setDate?: (date: { date: number; start_date: string; end_date: string }) => void;
  year?: string;
};

const MonthView = ({ data, date, setDate, year }: Props) => {
  const [month, setMonth] = useState<number>(0);
  const { profile } = useAuth();
  const { data: holidays } = useFetchHolidaysQuery(
    {
      user_id: profile.id,
    },
    {
      skip: !profile.id,
    },
  );

  const currentDate = (day: number) => {
    const item = data?.find((it) => String(it.group) === String(day));

    if (!item) {
      return 0;
    }

    return item.count;
  };

  const onClick = (date: number) => {
    if (!year || !setDate) return;
    const start_date = moment()
      .set({ date, month, year: Number(year) })
      .format("YYYY-MM-DD");
    const end_date = moment()
      .set({ date, month, year: Number(year) })
      .format("YYYY-MM-DD");

    setDate({
      date,
      start_date,
      end_date,
    });
  };

  const label = useMemo(() => {
    if (!date) return "Please choose a day";

    return `${date.start_date} - ${date.end_date}`;
  }, [date]);

  const days = useMemo(() => {
    if (!year || !_.isNumber(month)) return 0;

    return moment(new Date())
      .set({ month: month, year: Number(year) })
      .daysInMonth();
  }, [year, month]);

  const dateStatus = useCallback(
    (day: number) => {
      if (day === date?.date) return "selected";

      if (
        _.some(
          holidays,
          (item) =>
            item.date_time ===
            `${moment(`${year}-${month + 1}-${day}`, "YYYY-M-D").format(
              "YYYY-MM-DD",
            )}T00:00:00.000Z`,
        )
      )
        return "blocked";

      if (moment(new Date()).isAfter(moment(`${year}-${month + 1}-${day}`, "YYYY-M-D"), "date"))
        return "past";

      return "future";
    },
    [date, holidays, month, year],
  );

  return (
    <div>
      <Typography tag="h2" className="text-center">
        {label}
      </Typography>
      <div className="rounded-10 border border-border2 my-25">
        <div className="flex flex-row items-center overflow-x-scroll custom-scroll pt-3 pb-2 px-1 mx-2 ">
          {months.map((item, key) => (
            <div key={key} className={clsx("cursor-pointer")} onClick={() => setMonth(key)}>
              <div
                className={clsx("text-20 mx-12", key === month ? "text-primary" : "text-lightGrey")}
              >
                {item}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row overflow-x-scroll custom-scroll pb-1 pt-3">
          {days > 0 &&
            _.range(1, days + 1).map((item, key) => {
              if (!currentDate(item)) return null;
              return (
                <div
                  key={key}
                  className={clsx(
                    "flex flex-col items-center pt-1 pb-3 px-1 mx-2 cursor-pointer",
                    dateStatus(item) === "selected" && "bg-blue rounded-full",
                  )}
                  onClick={() => onClick(item)}
                >
                  <div
                    className={clsx(
                      "border rounded-full border-lightGrey text-20 font-semibold w-40 h-40 flex justify-center items-center mb-2",
                      dateStatus(item) === "selected" && "!bg-white rounded-full",
                      dateStatus(item) === "past" && "!bg-grey rounded-full",
                      dateStatus(item) === "blocked" && "!bg-chipDangerText rounded-full",
                    )}
                  >
                    {item}
                  </div>
                  <div
                    className={clsx(
                      "text-16 text-blue",
                      dateStatus(item) === "selected" && "!text-white",
                    )}
                  >
                    {currentDate(item)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const YearView = ({ data, date, setDate, year }: Props) => {
  const currentDate = (day: number) => {
    const item = data?.find((it) => String(it.group) === String(day));

    if (!item) {
      return 0;
    }

    return item.count;
  };

  const onClick = (date: number) => {
    if (!year || !setDate) return;
    const start_date = moment()
      .set({ month: date - 1, year: Number(year) })
      .startOf("month")
      .format("YYYY-MM-DD");
    const end_date = moment()
      .set({ month: date - 1, year: Number(year) })
      .endOf("month")
      .format("YYYY-MM-DD");
    setDate({
      date,
      start_date,
      end_date,
    });
  };

  const label = useMemo(() => {
    if (!date) return "Please choose a month";

    return `${date.start_date} - ${date.end_date}`;
  }, [date]);

  return (
    <div>
      <Typography tag="h2" className="text-center">
        {label}
      </Typography>
      <div className="rounded-10 border border-border2 my-25">
        <div className="flex flex-row overflow-x-scroll custom-scroll py-8">
          {months.map((item, key) => {
            if (!currentDate(key + 1)) return null;
            return (
              <div
                key={key}
                className={clsx("flex flex-col items-center pt-1 pb-3 px-1 mx-2 cursor-pointer")}
                onClick={() => onClick(key + 1)}
              >
                <div
                  className={clsx(
                    "text-20 mx-12",
                    key + 1 === date?.date ? "text-primary" : "text-lightGrey",
                  )}
                  key={key}
                >
                  {item}
                </div>
                <div className={clsx("text-16 text-blue")}>{currentDate(key + 1)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CalendarSelectDate = () => {
  const { profile } = useAuth();
  const { openInform } = useInform();
  const { data: holidays, isSuccess: isSuccessHoliday } = useFetchHolidaysQuery(
    {
      user_id: profile.id,
    },
    {
      skip: !profile.id,
    },
  );
  const [createHoliday] = useCreateHolidayMutation();
  const [removeHoliday] = useRemoveHolidayMutation();
  const [params, setParams] = useState<{
    block?: boolean;
    year?: Option;
    view?: Option;
  }>({
    year: { value: "2024", label: "2024" },
    view: { value: "month", label: "Month View" },
  });
  const [date, setDate] = useState<null | { date: number; start_date: string; end_date: string }>(
    null,
  );

  const { data: bookings, isLoading: isFetching } = useFetchBookingQuery(
    {
      start_date: date?.start_date,
      end_date: date?.end_date,
    },
    { skip: !date },
  );

  const { data, isLoading } = useFetchCalendarQuery({
    view: params.view?.value || "month",
    year: params.year?.value || "2024",
  });

  const onSetParams = (key: string, value: boolean) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const blockChecked = useMemo(() => {
    if (!isSuccessHoliday) return false;

    return _.some(holidays, (item) => item.date_time === `${date?.start_date}T00:00:00.000Z`);
  }, [date, isSuccessHoliday, holidays]);

  const onBlock = () => {
    if (!date?.start_date) return;

    if (params.view?.value === "year") {
      openInform({ show: true, type: "error", message: "You can't block a month " });
      return;
    }

    const holiday = _.find(
      holidays,
      (item) => item.date_time === `${date?.start_date}T00:00:00.000Z`,
    );

    if (!holiday) {
      createHoliday({ date_time: date?.start_date });
    } else {
      removeHoliday(holiday.id);
    }
  };

  return (
    <div className="mb-6 py-10px">
      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end mb-4">
        <Typography tag="h2">Calendar</Typography>
        <div className="flex lg:justify-between md:flex-row flex-col lg:items-end">
          <Toggle
            firstLabel="Unblock"
            label="Block"
            className="mr-2 md:mb-0 mb-2"
            name="block"
            value="block"
            checked={blockChecked}
            onChange={onBlock}
          />
          <div className="flex flex-row items-center md:mb-0 mb-2">
            <Select
              placeholder="Select Year"
              variant="smOutlined"
              className="!mb-0 mr-2"
              value={params.year}
              options={[
                { value: "2023", label: "2023" },
                { value: "2024", label: "2024" },
                { value: "2025", label: "2025" },
              ]}
              onChange={(e: any) => {
                onSetParams("year", e);
              }}
            />
            <Select
              placeholder="Day View"
              variant="smOutlined"
              className="!mb-0 mr-2"
              value={params.view}
              options={[
                { value: "month", label: "Month View" },
                { value: "year", label: "Year View" },
              ]}
              onChange={(e: any) => {
                onSetParams("view", e);
                setDate(null);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end">
        <div className="w-10px h-10px border bg-chipDangerText rounded-full mr-1" />{" "}
        <div className="mr-3">Blocked day</div>
        <div className="w-10px h-10px border bg-grey rounded-full mr-1" />{" "}
        <div className="mr-3">Past day</div>
        <div className="w-10px h-10px border bg-blue rounded-full mr-1" />{" "}
        <div className="mr-3">Selected day</div>
        <div className="w-10px h-10px border border-blue bg-white rounded-full mr-1" />{" "}
        <div>Future day</div>
      </div>
      {isLoading ? (
        <BookingLoading />
      ) : (
        <>
          {params.view?.value === "month" && (
            <MonthView data={data} date={date} setDate={setDate} year={params.year?.value} />
          )}
          {params.view?.value === "year" && (
            <YearView data={data} date={date} setDate={setDate} year={params.year?.value} />
          )}
        </>
      )}
      {date && (
        <div>
          {isFetching ? (
            <BookingLoading />
          ) : (
            bookings?.data.map((item, key) => (
              <div className="border-b border-border6 pb-6 mb-6" key={key}>
                <BookingItem item={item} />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
