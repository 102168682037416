import { Typography } from "@app/components/atoms";

export default function User() {
  return (
    <div className="px-6">
      <div className=" py-8">
        <Typography tag="h4" className="text-center mb-4">
          User
        </Typography>
      </div>
    </div>
  );
}
