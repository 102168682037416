import { CreatorBanner, CreatorExperience, CreatorWishlist } from "@app/components/organisms";
import { useAuth } from "@app/store/hooks";

export default function CreatorDashboard() {
  const { profile } = useAuth();
  return (
    <div className="md:mx-0 mx-4 pb-8">
      <div className=" bg-greyLayout">
        <CreatorBanner />
        <div className="border-b border-border2 mb-14 mt-8">
          <CreatorExperience title={`Experiences By ${profile.first_name}`} />
        </div>
        <CreatorWishlist />
      </div>
    </div>
  );
}
