import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Chip,
  Typography,
  IconButton,
  MoreSvg,
  ChipButton,
  EditUnderlineSvg,
  TrashSvg,
  Modal,
  Button,
} from "../atoms";
import { ReactNode, useRef, forwardRef, LegacyRef, useEffect, useState } from "react";
import { s3Url } from "@app/config";
import { Like } from "@app/types/like";
import { Comment } from "@app/types/comment";
import {
  useCommentMutation,
  useDislikeMutation,
  useLikeMutation,
  useRemoveCommentMutation,
  useUpdateCommentMutation,
} from "@app/store/services/post";
import { useAuth } from "@app/store/hooks";
import { CommentPayload } from "@app/store/services/post/types";
import { HTextField } from "../hookform";

type ActionListProps = {
  menuList?: { text: string; icon: ReactNode; action: () => void }[];
  menuClassName?: string;
  menuVariant?: "white" | "blue" | "static";
};

type Props = ActionListProps & {
  author?: string;
  content?: string;
  photos?: string[];
  city?: string;
  country?: string;
  state?: string;
  isAction?: boolean;
  isDetail?: boolean;
  isPopular?: boolean;
  isEdit?: boolean;
  customActionList?: ReactNode;
  className?: string;
  time?: string;
  likes?: Like[];
  comments?: Comment[];
  id?: number;
  isComment?: boolean;
};

const ActionList = forwardRef(
  (
    { menuList, menuClassName, menuVariant = "blue" }: ActionListProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "absolute hidden flex flex-col justify-start items-start right-6 top-0 rounded-10",
          menuVariant === "white" && " w-150 border bg-white/[0.4] border-white/[0.09]",
          menuVariant === "blue" && " w-200 border bg-blue border-white/[0.09]",
          menuClassName,
        )}
      >
        {menuList?.map((item, key) => (
          <ChipButton
            key={key}
            className="text-white text-14 border-b border-white/[.1] px-2 py-1 !rounded-none w-full !justify-start"
            variant="blank"
            startIcon={item.icon}
            onClick={item.action}
          >
            {item.text}
          </ChipButton>
        ))}
      </div>
    );
  },
);

export const CommentForm = ({ post_id }: { post_id: number }) => {
  const [comment, { isLoading }] = useCommentMutation();
  const { handleSubmit, control, reset } = useForm<CommentPayload>();
  const onSubmit: SubmitHandler<CommentPayload> = (data) => {
    comment({ ...data, post_id });
    reset({ content: "" });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row items-center">
      <div className="flex-1">
        <HTextField control={control} name="content" />
      </div>
    </form>
  );
};

type CommentActionProps = {
  item: Comment;
};

const CommentAction = ({ item }: CommentActionProps) => {
  const { isLoggedIn, profile } = useAuth();
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const [update, { isLoading }] = useUpdateCommentMutation();
  const [remove] = useRemoveCommentMutation();
  const { handleSubmit, control } = useForm<CommentPayload>({
    defaultValues: {
      content: item.content,
    },
  });
  const onSubmit: SubmitHandler<CommentPayload> = (data) => {
    update({ id: item.id, content: data.content });
    setIsShowUpdate(false);
  };

  const onRemove = () => {
    remove(item.id);
    setIsShow(false);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Modal show={isShow} onClose={() => setIsShow(false)}>
          <div className="py-3 px-8">
            <Typography tag="h2" className="text-center mb-6">
              Are you sure you want to remove this comment?
            </Typography>
            <div className="flex flex-row">
              <Button
                variant="outline-border"
                className="mr-2 flex-1 !border-lightGrey"
                onClick={() => setIsShow(false)}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="ml-2 flex-1"
                isLoading={isLoading}
                onClick={onRemove}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        <Typography tag="body1">
          {item.user.first_name} {item.user.last_name}
        </Typography>
        {isLoggedIn && profile.id === item.user_id ? (
          <div className="flex flex-row justify-between items-center">
            <IconButton
              type="submit"
              layout="rectangled"
              className="bg-primary w-30 text-center py-1"
              onClick={() => setIsShow(true)}
            >
              <TrashSvg className="fill-white mx-auto" />
            </IconButton>

            <IconButton
              type="submit"
              layout="rectangled"
              className="bg-primary ml-2 w-30 text-center py-1"
              onClick={() => setIsShowUpdate(!isShowUpdate)}
            >
              <EditUnderlineSvg className="text-white stroke-white mx-auto" />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isShowUpdate ? (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row items-center">
          <div className="flex-1">
            <HTextField control={control} name="content" />
          </div>
        </form>
      ) : (
        <Typography tag="body">{item.content}</Typography>
      )}
    </>
  );
};

export const Article = ({
  author,
  content,
  city,
  country,
  state,
  isAction,
  isPopular,
  isEdit,
  isDetail,
  customActionList,
  className,
  time,
  photos,
  likes,
  comments,
  id,
  isComment,
  ...props
}: Props) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [onLike, { isLoading: isLiking }] = useLikeMutation();
  const [onDislike, { isLoading: isDisliking }] = useDislikeMutation();
  const { profile, isLoggedIn } = useAuth();
  const [isShowComment, setIsShowComment] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.stopPropagation();
      if (listRef.current && !listRef.current.contains(event.target)) {
        listRef.current.classList.add("hidden");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listRef]);

  const onToggle = () => {
    if (!listRef.current) {
      return;
    }
    listRef.current.classList.toggle("hidden");
  };

  const onClickLike = () => {
    if (!id || isLiking || isDisliking) return;
    const likeElement = likes?.find((it) => it.user_id === profile.id);
    if (likeElement) {
      onDislike({ post_id: id });
    } else {
      onLike({ post_id: id });
    }
  };

  return (
    <div
      className={clsx(
        "rounded-10 shadow-2 px-4 py-3 relative max-h-400 custom-scroll overflow-auto",
        !isPopular && "border border-black/[.08]",
        isPopular ? "bg-blue" : "bg-white",
        className,
      )}
    >
      <div className="flex flex-row justify-between items-center mb-1">
        <div className="flex flex-row items-center">
          <Typography tag="body1" className={clsx("mr-4", isPopular ? "text-white" : "text-blue")}>
            {author}
          </Typography>
          <Typography
            tag="body"
            className={clsx(isPopular ? "opacity-70 text-white" : "text-blue")}
            isColor
          >
            {time}
          </Typography>
        </div>
        {isAction && (
          <div className="flex flex-row items-center">
            <div
              className="flex flex-row items-center mr-4 cursor-pointer"
              onClick={() => setIsShowComment((prev) => !prev)}
            >
              <img src="/imgs/chat-bubble.svg" alt="chat" />
              <Typography tag="body" className="text-primary ml-2">
                {(comments || []).length}
              </Typography>
            </div>
            <div className="flex flex-row items-center mr-4 cursor-pointer" onClick={onClickLike}>
              <img src="/imgs/like-fb.svg" alt="chat" />
              <Typography tag="body" className="text-primary ml-2">
                {(likes || []).length}
              </Typography>
            </div>
          </div>
        )}
      </div>

      {photos?.length && (
        <div className="grid grid-cols-1 mb-1">
          {photos?.map((item, key) => <img src={`${s3Url}${item}`} key={key} />)}
        </div>
      )}
      <Typography
        tag="body"
        className={clsx("leading-22", isPopular ? "opacity-70 text-white" : "text-blue")}
        isColor
      >
        {content}
      </Typography>
      {isDetail && (
        <div className={clsx("grid grid-cols-3 gap-4", isPopular && "opacity-70")}>
          {city && (
            <Typography tag="body" className="text-grepDeep leading-22" isColor>
              City: {city}
            </Typography>
          )}
          {country && (
            <Typography tag="body" className="text-grepDeep leading-22" isColor>
              Country: {country}
            </Typography>
          )}
          {state && (
            <Typography tag="body" className="text-grepDeep leading-22" isColor>
              State: {state}
            </Typography>
          )}
        </div>
      )}

      {isPopular && (
        <Chip
          text="Popular Idea"
          className="border-2 border-primary bg-white text-14 absolute -top-4 right-4"
        />
      )}
      {isEdit && (
        <div className="absolute top-2 right-2">
          <IconButton onClick={onToggle}>
            <MoreSvg
              className={clsx(isPopular ? "stroke-white fill-white" : "stroke-blue fill-blue")}
            />
          </IconButton>
          {customActionList ?? <ActionList {...props} ref={listRef} />}
        </div>
      )}
      {isAction && (
        <>
          {isShowComment && (
            <div className="mb-2 mt-2">
              {comments?.map((it, key) => (
                <div key={key} className="border-b pb-2 mb-2">
                  <CommentAction item={it} />
                </div>
              ))}
            </div>
          )}
          {isComment && <CommentForm post_id={id || 0} />}
        </>
      )}
    </div>
  );
};
