import { Typography, Button } from "@app/components/atoms";
import { AccountDetailLayout } from "@app/components/common";
export default function AccountMembership() {
  return (
    <AccountDetailLayout title="Membership">
      <div className="border-2 rounded-5 border-border5 p-4 mb-4">
        <div className="mb-8">
          <Typography
            tag="static"
            className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-2"
          >
            Membership status
          </Typography>
          <Typography
            tag="static"
            className="md:text-26 md:leading-31 text-22 leading-22 text-grey10"
          >
            Classic builder club membership
          </Typography>
        </div>
        <div>
          <Typography
            tag="static"
            className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-medium mb-2"
          >
            Expiration date
          </Typography>
          <Typography
            tag="static"
            className="md:text-26 md:leading-31 text-22 leading-22 text-primary"
          >
            Expires on 02/10/2023.
          </Typography>
        </div>
        <div className="pt-12">
          <Button layout="rectangled" variant="primary" className="px-2">
            Change Membership
          </Button>
        </div>
      </div>
    </AccountDetailLayout>
  );
}
