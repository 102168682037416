import { useLazyFetchTopicQuery } from "@app/store/services/topic";
import { Typography, ChevronDown, Spinner } from "../atoms";
import { Accordion } from "../common";
import { useEffect } from "react";

type Props = {
  tab: number;
  keywork?: string;
};

export const HelpContent = ({ tab, keywork }: Props) => {
  const [fetch, { isLoading, data }] = useLazyFetchTopicQuery();

  useEffect(() => {
    if (tab) {
      fetch({
        title: keywork,
        category_topic_id: tab,
      });
    }
  }, [tab, keywork]);

  return (
    <div className="my-10 grid md:grid-cols-2 md:gap-5 lg:gap-10">
      {isLoading && <Spinner />}
      {data?.data.map((eq) => (
        <Accordion
          header={
            <Typography tag="body1" isColor className="text-blue">
              {eq.title}
            </Typography>
          }
          headerClass="border-b border-border2 py-4 mb-3"
          expandIcon={<ChevronDown className="text-18" />}
          content={
            <Typography tag="body" isColor className="mb-6 xl:pr-20 leading-normal text-lightGrey">
              {eq.content}
            </Typography>
          }
        />
      ))}
    </div>
  );
};
