import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { Conversation } from "@app/types";
import { SendMessagePayload, SendAdminDto, AdminReplyDto, AuthorizedPersonPayload } from "./type";

export const messageApi = createApi({
  reducerPath: "messageApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Message"],
  endpoints: (builder) => ({
    sendMessage: builder.mutation<Conversation, SendMessagePayload>({
      query({ id, ...body }) {
        return {
          url: `message/${id}`,
          method: "PATCH",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Conversation>) => response.data,
    }),
    sendAdmin: builder.mutation<Conversation, SendAdminDto>({
      query(body) {
        return {
          url: `message/send/admin`,
          method: "POST",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Conversation>) => response.data,
    }),
    adminReply: builder.mutation<Conversation, AdminReplyDto>({
      query({ id, ...body }) {
        return {
          url: `message/admin/reply/${id}`,
          method: "PATCH",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Conversation>) => response.data,
    }),
    authorizedPerson: builder.mutation<Conversation, AuthorizedPersonPayload>({
      query(body) {
        return {
          url: `message/authorized-people`,
          method: "POST",
          body,
        };
      },
      transformResponse: (response: CommonResponse<Conversation>) => response.data,
    }),
  }),
});

export const {
  useSendMessageMutation,
  useAdminReplyMutation,
  useSendAdminMutation,
  useAuthorizedPersonMutation,
} = messageApi;
