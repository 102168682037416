import { Typography, SlideLoading } from "@app/components/atoms";
import { useFetchFeedbackQuery } from "@app/store/services/feedback";

export default function AdminFeedback() {
  const { data, isLoading } = useFetchFeedbackQuery({});

  return (
    <>
      <div className="md:mb-8 mb-4 flex md:flex-row flex-col md:justify-between md:items-center">
        <Typography tag="h2">Feedback</Typography>
      </div>
      {isLoading ? (
        <SlideLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <div key={key} className="rounded-10 border p-2">
              <Typography tag="body1">{item.title}</Typography>
              <Typography tag="body">{item.content}</Typography>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
