import { Typography } from "@app/components/atoms";
import { ContactUsContent } from "@app/components/organisms";

export default function Messages() {
  return (
    <div className="px-6">
      <div className=" py-8">
        <Typography tag="h4" className="text-center mb-4">
          Contact us
        </Typography>
        <ContactUsContent />
      </div>
    </div>
  );
}
