import { Controller, Control, useController } from "react-hook-form";
import { DatePicker, TimePicker } from "../atoms";
import type { DateTimePickerProps } from "../atoms";
import { isArray } from "lodash";

interface Props extends Omit<DateTimePickerProps, "value" | "onChange"> {
  control: Control<any>;
  name: string;
  defaultValue?: Date;
}

export const HDatePicker = ({ control, name, defaultValue, ...props }: Props) => {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({
    defaultValue,
    control,
    name,
  });

  const onDateChange = (date: Date | null | [Date | null, Date | null]) => {
    if (isArray(date)) {
      onChange({
        start: date[0],
        end: date[1],
      });
    } else {
      onChange(date);
    }
  };

  return (
    <DatePicker
      {...props}
      onChange={onDateChange}
      value={value?.start ? value.start : value}
      startDate={value?.start ? value.start : undefined}
      endDate={value?.end ? value.end : undefined}
      error={error?.message}
      ref={ref as any}
    />
  );
};

export const HTimePicker = ({ control, name, ...props }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TimePicker
          onChange={(date: Date | null | [Date | null, Date | null]) => field.onChange(date)}
          value={field.value}
          error={error?.message}
          ref={ref as any}
          {...props}
        />
      )}
    />
  );
};
