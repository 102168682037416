import type { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import phone_codes from "@app/config/phoneCode.json";
import { find } from "lodash";

export type SelectPhoneCountryProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {};

export const SelectPhoneCountry = ({ value, onChange }: SelectPhoneCountryProps) => {
  return (
    <div className="border-r border-blue flex flex-row pr-1 pl-2">
      {find(phone_codes, (item) => item.dial_code === value)?.emoji}
      <select value={value} onChange={onChange}>
        {phone_codes.map((item, key) => (
          <option key={key} value={item.dial_code}>
            {item.dial_code}
          </option>
        ))}
      </select>
    </div>
  );
};
