import { Control, useController } from "react-hook-form";
import { RangeSlider, Typography } from "../atoms";
import type { RangeSliderProps } from "../atoms";

type Props = Omit<RangeSliderProps, "onChange"> & {
  control: Control<any>;
  name: string;
  prefix?: string;
  suffix?: string;
};

export const HRangeSlider = ({ control, name, prefix, suffix, ...props }: Props) => {
  const { field } = useController({
    name,
    control,
    defaultValue: [props.min, props.max],
  });

  return (
    <>
      <div className="relative">
        <img src="/imgs/range-bg.svg" alt="rangebg" className="w-full" />
        <Typography className="text-blue absolute bottom-0 inset-x-0 text-center">
          {`${prefix || ""}${field.value?.[0] || 0}${suffix || ""}`} To{" "}
          {`${prefix || ""}${field.value?.[1] || 0}${suffix || ""}`}
        </Typography>
      </div>
      <RangeSlider {...props} value={field.value} onChange={(value) => field.onChange(value)} />
    </>
  );
};
