import { useState } from "react";
import { Typography } from "@app/components/atoms";
import { InquiryRequestList } from "@app/components/organisms";
import { BookingStatus } from "@app/enums";
import clsx from "clsx";

export default function RequestAndInquiries() {
  const [tab, setTab] = useState<BookingStatus>(BookingStatus.COMPLETED_PAYMENT);
  return (
    <div className="px-4 md:px-0 my-8">
      <div className="">
        <Typography tag="h2" className="pb-2 mb-6">
          Request & Inquiries
        </Typography>

        <div>
          <div className="">
            <button
              onClick={() => setTab(BookingStatus.COMPLETED_PAYMENT)}
              className={clsx(
                "px-5 py-2 border inline-block -mb-1px cursor-pointer",
                tab === BookingStatus.COMPLETED_PAYMENT
                  ? "border-grey9 border-b-white"
                  : "border-white border-b-grey9",
              )}
            >
              Request
            </button>
            <button
              onClick={() => setTab(BookingStatus.APPROVED)}
              className={clsx(
                "px-5 py-2 border inline-block -mb-1px cursor-pointer",
                tab === BookingStatus.APPROVED
                  ? "border-grey9 border-b-white"
                  : "border-white border-b-grey9",
              )}
            >
              Approved
            </button>
            <button
              onClick={() => setTab(BookingStatus.DECLINED)}
              className={clsx(
                "px-5 py-2 border inline-block -mb-1px cursor-pointer",
                tab === BookingStatus.DECLINED
                  ? "border-grey9 border-b-white"
                  : "border-white border-b-grey9",
              )}
            >
              Declined
            </button>
          </div>
          <div className="border border-grey9">
            <InquiryRequestList tab={tab} />
          </div>
        </div>
      </div>
    </div>
  );
}
