import {
  Typography,
  FillCircleFill,
  Button,
  LocationFillSvg,
  VerifiedSvg,
  InfoSvg,
  Rating,
} from "@app/components/atoms";
import { s3Url } from "@app/config";
import { useFetchReviewQuery } from "@app/store/services/review";
import { Apprentice } from "@app/types/apprentice";
import { totalPoint } from "@app/utils";
import { CircularProgressbar } from "react-circular-progressbar";

type Props = Partial<Apprentice>;

export const ProfileInformation = ({ avatar, user, salary, location, age }: Props) => {
  const { data } = useFetchReviewQuery({ user_id: user?.id }, { skip: !user?.id });
  return (
    <div className="shadow-profile rounded-20 py-8 md:px-6 px-4 grid md:grid-cols-2 md:divide-x">
      <div className="pr-5">
        <div className="md:flex md:flex-row items-center">
          <div className="relative inline-block mr-6">
            <img
              src={avatar ? `${s3Url}${avatar}` : "/imgs/default-image.png"}
              className="lg:w-140px lg:h-140px w-100 h-100"
              alt="profile"
            />
            <FillCircleFill className="absolute right-0 md:right-3 bottom-4 w-15 h-15 text-16 rounded-full" />
          </div>
          <div className="flex-1">
            <div className="md:flex md:flex-row md:justify-between mb-3">
              <div className="md:mb-0 mb-3">
                <Typography tag="h4" className="!font-inter font-semibold flex flex-row mb-4">
                  {`${user?.first_name} ${user?.last_name}`} <VerifiedSvg className="ml-2" />
                </Typography>
                <Typography
                  tag="h6"
                  className="!font-normal text-blue/[.7] flex flex-row items-center"
                >
                  <LocationFillSvg className="mr-2" /> Berlin, Germany
                </Typography>
              </div>
              <Typography tag="h4" className="!font-inter !font-light">
                ${salary}/hr
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="md:w-140px md:mr-6" />
          <div>
            <Button
              variant="outline-primary"
              layout="rectangled"
              className="!md:min-w-none !min-w-none mr-4 lg:mb-0 mb-4"
            >
              Contact
            </Button>
            <Button variant="primary" layout="rectangled" className="px-4">
              Request To Book
            </Button>
          </div>
        </div>
      </div>
      <div className="md:pl-5 lg:flex lg:flex-row">
        <div className="md:w-1/4 md:mr-6 lg:mb-0 mb-4">
          <Typography
            tag="body1"
            isColor
            className="text-blue/[.86] flex flex-row items-center mb-3"
          >
            Trust Score <InfoSvg className="ml-2" />
          </Typography>
          <CircularProgressbar
            value={81}
            text={`${81}%`}
            styles={{
              path: {
                stroke: `#0EB73D`,
              },
              text: {
                fontSize: "24px",
                fill: "#19263F",
              },
              trail: {
                stroke: "#D1D1D1",
              },
            }}
          />
        </div>
        <div className="grid grid-cols-2">
          <Typography tag="body1" className="text-blue font-semibold !self-auto">
            Availability:
          </Typography>
          <div>
            <Typography tag="static" className="text-blue/[.7] text-20 flex flex-row items-center">
              {location}
              <span className="inline-block text-10 text-white rounded-full bg-green px-2 ml-2">
                Available
              </span>
            </Typography>
          </div>
          <Typography tag="body1" className="text-blue font-semibold !self-auto">
            Age:
          </Typography>
          <div>
            <Typography tag="static" className="text-blue/[.7] text-20 flex flex-row items-center">
              {age}
            </Typography>
          </div>
          <Typography tag="body1" className="text-blue font-semibold !self-auto">
            Years Experience:
          </Typography>
          <div>
            <Typography tag="static" className="text-blue/[.7] text-20 flex flex-row items-center">
              6
            </Typography>
          </div>
          <Typography tag="body1" className="text-blue font-semibold !self-auto">
            Reviews:
          </Typography>
          <div className="flex flex-row items-center">
            <span className="px-2 py-1 bg-yellow1 text-white text-10 font-semibold inline-block rounded-5 mr-1">
              {totalPoint(data?.data || [])}
            </span>
            <Rating value={totalPoint(data?.data || [])} />
            <span className="text-grey13 text-12 ml-1">({data?.total} reviews)</span>
          </div>
        </div>
      </div>
    </div>
  );
};
