import { useRef } from "react";
import Slider from "react-slick";
import { Button, IconButton, ChevronCircleRight, ChevronCircleLeft } from "@app/components/atoms";
export const TagSlide = () => {
  const sliderRef = useRef<Slider>(null);
  return (
    <div className=" flex flex-row items-center justify-between md:mb-6 mb-4 w-full">
      <IconButton onClick={() => sliderRef?.current?.slickPrev()}>
        <ChevronCircleLeft className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
      <div className="w-11/12 grow">
        <Slider
          ref={sliderRef}
          dots={false}
          infinite
          speed={500}
          slidesToShow={7}
          slidesToScroll={1}
          arrows={false}
          responsive={[
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
              },
            },
          ]}
        >
          <div className="p-1 text-center">
            <Button variant="slide">Dating</Button>
          </div>

          <div className="p-1 text-center">
            <Button variant="slide">Adventure</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Wellness</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Food & Drink</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Wing(wo)men</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">LGBTQ+</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Arts</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Dating</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Adventure</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Wellness</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Food & Drink</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Wing(wo)men</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">LGBTQ+</Button>
          </div>
          <div className="p-1 text-center">
            <Button variant="slide">Arts</Button>
          </div>
        </Slider>
      </div>
      <IconButton onClick={() => sliderRef?.current?.slickNext()}>
        <ChevronCircleRight className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
    </div>
  );
};
