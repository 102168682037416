import clsx from "clsx";
import { IconButton } from "./Button";
import { AddSquareSvg, MinusSquareSvg } from "./icons";
import { Typography } from "./typography";

type Props = {
  label?: string;
  value?: number;
  className?: string;
  onIncrease?: () => void;
  onDecrease?: () => void;
};
export const IncreaseField = ({ value = 0, className, label, onIncrease, onDecrease }: Props) => {
  return (
    <div className={clsx(className, "flex flex-row justify-between")}>
      <Typography>{label}</Typography>
      <div className="flex flex-row">
        <IconButton onClick={onDecrease} type="button">
          <MinusSquareSvg />
        </IconButton>
        <Typography className="mx-4 w-15">{value}</Typography>
        <IconButton onClick={onIncrease} type="button">
          <AddSquareSvg />
        </IconButton>
      </div>
    </div>
  );
};
