import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreatePromotePayload } from "./type";
import { Invoice } from "@app/types";

export const promoteApi = createApi({
  reducerPath: "promoteApi",
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    createPromote: builder.mutation<CommonResponse<Invoice>, CreatePromotePayload>({
      query(body) {
        return {
          url: `promote`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
  }),
});

export const { useCreatePromoteMutation } = promoteApi;
