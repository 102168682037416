import { Typography, IconButton, SearchSvg } from "@app/components/atoms";
import { HelpTab } from "@app/components/molecules";
import { HelpContent } from "@app/components/organisms";
import { useAuth } from "@app/store/hooks";
import { useFetchCategoryTopicQuery } from "@app/store/services/category-topic";
import { debounce } from "lodash";
import { ChangeEvent, useState } from "react";

export const GetHelp = () => {
  const [tab, setTab] = useState<number | null>(null);
  const { profile } = useAuth();
  const { isLoading } = useFetchCategoryTopicQuery({});
  const [keywork, setKeywork] = useState("");

  const onSet = (id: number) => {
    setTab(id);
  };

  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setKeywork(event.target.value);
  }, 500);

  return (
    <div className="mb-6">
      <Typography tag="h2" className="mb-8 text-center">
        Hi {profile.first_name} 👋
        <br />
        how can we help?
      </Typography>
      <div className="items-center mb-6 flex md:flex-row flex-wrap flex-col md:justify-between">
        <HelpTab tab={tab || 0} onSet={onSet} />
        <div className="relative max-w-360 w-full md:mt-0 mt-3">
          <input
            type="text"
            className="w-full rounded-full color-blue pl-3 pr-4 py-10px border border-border2 placeholder:text-lightGrey"
            placeholder="Search here for help..."
            onChange={onSeach}
          />
          <IconButton
            variant="primary"
            className="absolute z-10 right-[6px] top-[4px] md:top-[6px] w-34 h-34"
          >
            <SearchSvg className="fill-white absolute left-[9px] top-[8px]" />
          </IconButton>
        </div>
      </div>
      <HelpContent tab={tab || 0} keywork={keywork} />
    </div>
  );
};
