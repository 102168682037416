import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import type { Experience } from "@app/types/experience";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreateExperiencePayload, QueryExperienceParams, UpdateExperiencePayload } from "./type";
import { Paging } from "@app/types";

export const experienceApi = createApi({
  reducerPath: "experienceApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Experience"],
  endpoints: (builder) => ({
    fetchExperience: builder.query<CommonResponse<Experience[]>, Paging & QueryExperienceParams>({
      query: (params) => ({
        url: `experience`,
        params,
      }),
      transformResponse: (response: CommonResponse<Experience[]>) => response,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: Experience) => ({ type: "Experience" as const, id })),
              "Experience",
            ]
          : ["Experience"],
    }),
    createExperience: builder.mutation<CommonResponse<Experience>, CreateExperiencePayload>({
      query(body) {
        return {
          url: `experience`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Experience"],
    }),
    updateExperience: builder.mutation<
      CommonResponse<Experience>,
      Partial<UpdateExperiencePayload> & { id: number }
    >({
      query({ id, ...body }) {
        return {
          url: `experience/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Experience", id: arg.id }],
    }),
    removeExperience: builder.mutation<CommonResponse<Experience>, number>({
      query(id: number) {
        return {
          url: `experience/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Experience"],
    }),
    getExperience: builder.query<Experience, string>({
      query: (id) => `experience/${id}`,
      transformResponse: (response: CommonResponse<Experience>) => response.data,
    }),
  }),
});

export const {
  useGetExperienceQuery,
  useFetchExperienceQuery,
  useCreateExperienceMutation,
  useUpdateExperienceMutation,
  useRemoveExperienceMutation,
  useLazyGetExperienceQuery,
  useLazyFetchExperienceQuery,
} = experienceApi;
