import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import clsx from "clsx";

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  firstLabel?: string;
  className?: string;
};

export const Toggle = ({ label, firstLabel, className, onChange, ...props }: Props) => {
  return (
    <label className={clsx("inline-flex items-center cursor-pointer", className)}>
      {firstLabel && <span className="mr-3 font-normal text-blue ">{firstLabel}</span>}

      <div className="relative">
        <input {...props} type="checkbox" className="sr-only peer" onChange={onChange} />
        <div className="w-60 h-34 bg-white border-lightGrey border outline-none peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-6 peer-checked:after:border-blue after:content-[''] after:absolute after:top-[5px] after:left-[5px] after:bg-blue after:border-blue after:border after:rounded-full after:h-25 after:w-25 after:transition-all peer-checked:bg-white"></div>
      </div>

      {label && <span className="ml-3 font-normal text-blue ">{label}</span>}
    </label>
  );
};
