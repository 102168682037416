import { Button } from "../atoms";

type Props = {
  tab: string;
  onSetTab: (data: string) => void;
};

export const MessageTab = ({ tab, onSetTab }: Props) => {
  return (
    <div className="grid grid-cols-2 gap-2 px-2 py-2">
      <Button
        layout="rectangled"
        variant={tab === "message" ? "primary" : "blue"}
        className=""
        onClick={() => onSetTab("message")}
      >
        Messages
      </Button>
      <Button
        layout="rectangled"
        variant={tab === "notification" ? "primary" : "blue"}
        className=""
        onClick={() => onSetTab("notification")}
      >
        Notification
      </Button>
    </div>
  );
};
