import clsx from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, Ref } from "react";
import { CheckSquare } from "./icons";

export interface CheckBoxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  title?: string;
  label?: string;
  checked?: boolean;
  className?: string;
}

export const Checkbox = forwardRef(
  (
    { label, checked, className, title, onChange, ...props }: CheckBoxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <div>
        {title && <label className="block mb-2 text-sm font-medium text-gray-900">{title}</label>}
        <label
          className={clsx(
            "flex flex-row items-center font-medium text-blue md:text-16 text-14 cursor-pointer",
            className,
            props.disabled && "opacity-30",
          )}
        >
          {checked ? (
            <CheckSquare className="fill-primary text-primary mr-2 w-30 h-30" />
          ) : (
            <div className="mr-2 w-30 h-30 rounded-4 border border-lightGrey" />
          )}
          <input
            type="radio"
            className="sr-only peer"
            checked={checked}
            ref={ref}
            onChange={onChange}
            {...props}
          />{" "}
          {label}
        </label>
      </div>
    );
  },
);
