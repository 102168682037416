import { Typography } from "../atoms";

export const TrackingIntroduce = () => {
  return (
    <div className="px-4 md:px-0 bg-bg2">
      <div className=" lg:py-8 py-4">
        <div className="grid md:grid-cols-2 md:gap-12 gap-4">
          <div className="">
            <img
              className="rounded-10 w-full"
              src="/imgs/image-30.jpg"
              height={460}
              width={664}
              alt="aboutus 17"
            />
          </div>
          <div className="place-self-center">
            <Typography tag="h2" className="md:pb-8 pb-2">
              Why Safety features is Implemented on Blist
            </Typography>
            <Typography className="pb-2">{""}</Typography>
            <Typography className="pb-8">{""}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
