import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FavoriteItem } from "@app/types";
import { favoriteApi } from "../services/favorite";

export interface FavoriteState {
  list: FavoriteItem[];
}

const initialState: FavoriteState = {
  list: [],
};

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    updateFavoriteList: (state, action: PayloadAction<FavoriteItem[]>) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(favoriteApi.endpoints.fetchFavorite.matchFulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateFavoriteList } = favoriteSlice.actions;

export default favoriteSlice.reducer;
