import { Typography, Button, TextField, LocationSvg } from "@app/components/atoms";
import { AdminChatList, ChatTitle, AdminChatArea } from "@app/components/organisms";
import { Conversation } from "@app/types";
import { useState } from "react";

export default function AdminBlistBoardChat() {
  const [selectedMsg, setSelectedMsg] = useState<Conversation>();

  const onSetMsg = (item: Conversation) => {
    setSelectedMsg(item);
  };

  return (
    <>
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Blist Board Chat</Typography>
        <div className="flex md:flex-row flex-col">
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by keyword"
            endIcon={<LocationSvg />}
            className="md:!mb-0 !mb-2"
          />
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by location"
            endIcon={<LocationSvg />}
            className="md:mx-2 md:!mb-0 !mb-2"
          />
          <Button variant="primary">Search</Button>
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4 md:flex-1 md:overflow-hidden">
        <div className="flex flex-col md:col-span-2 min-h-full rounded-10 border border-border2 h-full overflow-hidden">
          {selectedMsg && <ChatTitle message={selectedMsg} />}
          {selectedMsg && <AdminChatArea id={selectedMsg.id} />}
        </div>
        <div className="min-h-full">
          <AdminChatList id={selectedMsg?.id} onSetMsg={onSetMsg} />
        </div>
      </div>
    </>
  );
}
