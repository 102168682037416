import clsx from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { RadioCheckedSvg, RadioUncheckSvg } from "./icons";

export interface RadioProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  title?: string;
  label?: string;
  checked?: boolean;
  className?: string;
  value?: string;
}
export const Radio = ({ label, checked, className, title, onChange, value }: RadioProps) => {
  return (
    <div>
      {title && <label className="block mb-2 text-sm font-medium text-gray-900">{title}</label>}
      <label
        className={clsx("flex flex-row items-center font-light text-14 cursor-pointer", className)}
      >
        {checked ? <RadioCheckedSvg className="mr-2" /> : <RadioUncheckSvg className="mr-2" />}
        <input
          type="radio"
          className="sr-only peer"
          checked={checked}
          onChange={onChange}
          value={value}
        />{" "}
        {label}
      </label>
    </div>
  );
};
