import { useParams } from "react-router-dom";
import { Typography, Star, Button } from "../atoms";
import { RatingItem } from "../molecules";
import { useFetchReviewQuery } from "@app/store/services/review";
import { totalPoint } from "@app/utils";
import moment from "moment";

export const RatingSection = () => {
  const { experienceId } = useParams<{ experienceId: string }>();
  const { data } = useFetchReviewQuery(
    { experience_id: Number(experienceId || "") },
    { skip: !experienceId },
  );
  return (
    <div className=" mb-8">
      <div className="flex flex-row items-center mb-4">
        <Typography tag="h4" className="text-28 text-blue">
          Reviews
        </Typography>
        <Typography tag="body" isColor className="font-medium ml-8 flex flex-row text-black">
          <Star className="mr-2" /> {totalPoint(data?.data)}
        </Typography>
        <Typography tag="body" isColor className="font-medium underline ml-4 text-black">
          {data?.total} reviews
        </Typography>
      </div>
      <div className="md:grid-cols-2 grid gap-x-8 gap-y-5 mb-8">
        {data?.data.map((item, key) => (
          <RatingItem
            key={key}
            content={item.description}
            date={moment(item.createdAt).format("MMMM YYYY")}
            author={`${item.customer.first_name} ${item.customer.last_name}`}
            url={item.customer.avatar}
          />
        ))}
      </div>
      {!!data?.data.length && (
        <div className="text-center md:mb-8 mb-4">
          <Button type="button" variant="rounded" className="text-white font-light bg-lightBlack">
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};
