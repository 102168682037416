import { CircularProgressbar } from "react-circular-progressbar";
import { Typography, ArrowDownSvg, Spinner } from "../atoms";
import { useGetLast7DaysQuery } from "@app/store/services/analytics";
import { useMemo } from "react";

export const DoughnutChart = () => {
  const { data, isLoading, isSuccess } = useGetLast7DaysQuery({});

  const approved_percent = useMemo(() => {
    if (!isSuccess) return 0;

    return (data.data.approved_list / data.data.total) * 100;
  }, [isSuccess, data]);

  const completed_payment_percent = useMemo(() => {
    if (!isSuccess) return 0;

    return (data.data.completed_payment_list / data.data.total) * 100;
  }, [isSuccess, data]);

  return (
    <div className="bg-bg3 rounded-10 px-5 py-3 h-full flex flex-col">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className="flex flex-row items-center justify-between">
        <Typography className="text-primary font-semibold">Last 7 days</Typography>
        <div className="flex flex-row items-center justify-between">
          <select>
            <option>0$</option>
          </select>
          <ArrowDownSvg className="px-2 box-content" />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5 flex-1">
        <div className="flex flex-col justify-center items-center">
          <CircularProgressbar
            value={approved_percent}
            text={`${approved_percent}%`}
            styles={{
              path: {
                // Path color
                stroke: `#FF9C6D`,
              },
              text: {
                fontSize: "24px",
                fill: "#19263F",
              },
              trail: {
                stroke: "#fff",
              },
            }}
          />
        </div>
        <div className="flex flex-col justify-center items-start">
          <Typography className="text-blue font-medium mb-5 !self-auto">
            {completed_payment_percent}% Left to go
          </Typography>
          <Typography className="text-blue font-medium !self-auto">
            {data?.data.completed_payment_list} Days left
          </Typography>
        </div>
      </div>
    </div>
  );
};
