import { Control, useController } from "react-hook-form";
import { UserSelect } from "../atoms";
import type { UserSelectProps } from "../atoms";

interface Props extends UserSelectProps {
  control: Control<any>;
  name: string;
}

export const HUserSelect = ({ control, name, defaultValue, ...props }: Props) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <UserSelect
      name={field.name}
      onChange={(data) => field.onChange(data)}
      value={field.value}
      // error={error?.message}
      // ref={ref as any}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
