import { CameraFill, ImageFillSvg, Typography, PlusFillSvg } from "../atoms";
import { useUploadMediasMutation } from "@app/store/services/media";
import { useUpdateProfileMutation } from "@app/store/services/auth";
import { useAuth } from "@app/store/hooks";
import { s3Url } from "@app/config";

export const UploadProfile = () => {
  const [uploadImage] = useUploadMediasMutation();
  const [update] = useUpdateProfileMutation();
  const { profile, updateProfile } = useAuth();

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      const response = await uploadImage([event?.target?.files?.[0]]).unwrap();
      update({ avatar: response[0] })
        .unwrap()
        .then((res) => {
          updateProfile(res.data);
        })
        .catch(() => {});
    }
  };

  return (
    <div className="rounded-10 flex flex-row items-center mb-8">
      <div className="relative mr-6">
        <img
          src={profile.avatar ? `${s3Url}${profile.avatar}` : "/imgs/default-avatar.png"}
          width={140}
          height={140}
        />
        <div className="cursor-pointer z-10 bg-white rounded-full w-35 h-35 absolute right-1 bottom-1 shadow-bookform flex justify-center items-center">
          <input
            type="file"
            className="absolute z-10 inset-0 opacity-0 cursor-pointer"
            onChange={onUpload}
          />
          <CameraFill className="text-blue/[.23] w-25 h-25" />
        </div>
      </div>
      <div>
        <Typography
          tag="static"
          className="md:text-26 md:leading-31 text-22 leading-22 font-medium mb-2"
        >
          Profile Picture
        </Typography>
        <Typography
          tag="static"
          className="md:text-22 md:leading-27 text-20 leading-22 text-blue font-light"
        >
          This will be displayed on your profile.
        </Typography>
      </div>
    </div>
  );
};

export const UploadAvatar = ({ preview }: { preview?: string }) => {
  console.log("preview", preview);
  return (
    <div className="rounded-10 inline-block mb-8">
      {preview ? (
        <img src={preview} className="fill-blue w-46 h-46" />
      ) : (
        <>
          <div className="inline-block relative mb-4">
            <div className="w-140px h-140px bg-headerBg rounded-full inline-flex justify-center items-center">
              <ImageFillSvg className="fill-blue w-46 h-46" />
            </div>
            <div className="bg-white rounded-full w-35 h-35 absolute right-1 bottom-1 shadow-bookform flex justify-center items-center">
              <PlusFillSvg />
            </div>
          </div>
          <div>
            <Typography tag="static" className="text-14 leading-16 font-normal text-lightGrey">
              Please upload a profile picture
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
