import clsx from "clsx";
import { Button, ProfileSvg, TextField, SmsSvg, CallSvg, Typography } from "../atoms";
import Styled from "styled-components";
import { HTextField, HSelectPhoneCountry } from "../hookform";
import { useCreateTrackingMutation, useGetTrackingQuery } from "@app/store/services/tracking";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

const StyledText = Styled(Typography)`
  &:after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #6A6A6A;
    margin-left: 16px;
  }
`;

type Props = {
  className?: string;
  title: string;
  isLeft?: boolean;
  onAddMore?: () => void;
};

type FormPayload = {
  name: string;
  email: string;
  phone: string;
  phone_code: string;
};

export const TrackingForm = ({ className, title, isLeft = false, onAddMore }: Props) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data, isLoading } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const [createTracking] = useCreateTrackingMutation();

  const { handleSubmit, control } = useForm<FormPayload>({ defaultValues: { phone_code: "+91" } });

  const onSubmit: SubmitHandler<FormPayload> = (payload) => {
    if (!bookingId) return;

    createTracking({
      booking_id: Number(bookingId),
      emergency_contacts: [...(data?.emergency_contacts || []), payload],
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(isLeft ? "md:pr-8 md:border-r md:border-lightGrey" : "md:pl-8")}
    >
      <StyledText className="text-center my-4 flex flex-row items-center w-full">
        <Typography tag="h5">{title}</Typography>
      </StyledText>
      <div className={className}>
        <HTextField
          label="Name*"
          variant="outlined-black"
          border="full"
          name="name"
          placeholder="Enter your name here"
          endIcon={<ProfileSvg className="stroke-black w-19 h-19" />}
          control={control}
        />
        <HTextField
          label="Email ID*"
          variant="outlined-black"
          border="full"
          name="email"
          placeholder="Enter your name here"
          endIcon={<SmsSvg />}
          control={control}
        />
        <HTextField
          label="Phone*"
          variant="outlined-black"
          border="full"
          name="phone"
          placeholder="Enter your name here"
          endIcon={<CallSvg className="stroke-black" />}
          control={control}
          startIcon={<HSelectPhoneCountry control={control} name="phone_code" />}
        />
        <div className="mb-6">
          <div
            onClick={onAddMore}
            className="cursor-pointer text-16 text-blue underline underline-offset-8 ml-4 inline-block"
          >
            + Add another contact
          </div>
        </div>
        <div>
          <Button
            isLoading={isLoading}
            type="submit"
            variant="primary"
            layout="rounded"
            className="w-1/2"
          >
            Done
          </Button>
        </div>
      </div>
    </form>
  );
};
