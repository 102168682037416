import { useLazyFetchCategoryTopicQuery } from "@app/store/services/category-topic";
import { Button, TextLoading } from "../atoms";
import { useEffect } from "react";

type Props = {
  onSet: (data: number) => void;
  tab: number;
};

export const HelpTab = ({ onSet, tab }: Props) => {
  const [fetch, { isLoading, data }] = useLazyFetchCategoryTopicQuery({});

  useEffect(() => {
    fetch({})
      .unwrap()
      .then((res) => {
        if (!tab) {
          onSet(res.data[0].id);
        }
      })
      .catch((error) => {});
  }, [tab]);

  if (isLoading) return <TextLoading />;

  return (
    <div className="my-4 md:flex md:flex-row grid grid-cols-2 gap-4 justify-between rounded-20">
      {data?.data.map((eq) => (
        <Button
          variant={tab === eq.id ? "primary" : "tab"}
          className="mr-2"
          onClick={() => onSet(eq.id)}
        >
          {eq.name}
        </Button>
      ))}
    </div>
  );
};
