import Slider from "react-slick";
import { useRef, useState, useMemo, Dispatch, SetStateAction } from "react";

import { s3Url } from "@app/config";
import { useFetchCategoriesQuery } from "@app/store/services/category";

import {
  ChipButton,
  ListSvg,
  MapSvg,
  Modal,
  IconButton,
  ArrowLeftSvg,
  SlideAllLoading,
} from "../atoms";
import { HomeMap } from "./HomeMap";
import {
  SlideTitleFilter,
  CategoryIconItem,
  CategoryIntroduce,
  ExperienceFilter,
} from "../molecules";
import { Category } from "@app/types";
import { find, findIndex } from "lodash";
import clsx from "clsx";

export const CategorySlide = ({
  selected,
  setSelected,
}: {
  selected: null | { item: Category; index: number };
  setSelected: Dispatch<
    SetStateAction<{
      item: Category;
      index: number;
    } | null>
  >;
}) => {
  const sliderRef = useRef<Slider>(null);
  const [isShowMap, setIsMap] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const { data, isLoading } = useFetchCategoriesQuery({
    is_active: true,
    is_count: true,
  });

  const selectedCategory: null | { item: Category; index: number } = useMemo(() => {
    if (selected) return selected;

    const item = find(data, (it) => it.is_highlight);

    if (!item) return null;

    const index = findIndex(data, (it) => it.id === item?.id);

    return {
      item,
      index,
    };
  }, [selected, data]);

  return (
    <div className="px-4 md:px-0">
      <Modal show={isFilter} onClose={() => setIsFilter(false)}>
        <ExperienceFilter onClose={() => setIsFilter(false)} />
      </Modal>
      <div className=" pb-8 pt-2">
        <SlideTitleFilter title="Categories" onFilter={() => setIsFilter(true)} />
        <div className="px-4 relative">
          <IconButton
            onClick={() => sliderRef?.current?.slickPrev()}
            className="absolute left-0 z-10 inset-y-0"
          >
            <ArrowLeftSvg className="fill-black stroke-black w-40 h-40" />
          </IconButton>
          <IconButton
            onClick={() => sliderRef?.current?.slickNext()}
            className="absolute right-0 z-10 inset-y-0"
          >
            <ArrowLeftSvg className="rotate-180 fill-black stroke-black w-40 h-40" />
          </IconButton>
          {isLoading ? (
            <SlideAllLoading />
          ) : (
            <Slider
              ref={sliderRef}
              dots={false}
              infinite
              speed={500}
              slidesToShow={6}
              slidesToScroll={1}
              arrows={false}
              responsive={[
                {
                  breakpoint: 976,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                  },
                },
              ]}
            >
              {data?.map((item, key) => (
                <CategoryIconItem
                  key={key}
                  title={item.name}
                  icon={
                    item?.icon && (
                      <img
                        src={`${s3Url}${item?.icon}`}
                        alt={item.name}
                        className={clsx(
                          "h-50",
                          selectedCategory?.item.id === item.id && "image-primary",
                        )}
                      />
                    )
                  }
                  id={item.id}
                  total={item.total || 0}
                  isHighlight={selectedCategory?.item.id === item.id}
                  onClick={() =>
                    setSelected({
                      item,
                      index: key,
                    })
                  }
                />
              ))}
            </Slider>
          )}
        </div>
        <div>
          {isShowMap ? (
            <div className="h-346px relative rounded-20 overflow-hidden">
              <HomeMap category_id={selected?.item?.id} />
            </div>
          ) : (
            selectedCategory && (
              <CategoryIntroduce {...selectedCategory.item} onClose={() => setSelected(null)} />
            )
          )}
          <div className="text-center -mt-6 relative">
            <ChipButton
              layout="rounded"
              className="md:min-w-175 md:py-4 min-w-100 py-2 px-3 hover:text-primary text-white bg-lightBlack"
              variant="static"
              endIcon={!isShowMap ? <MapSvg className="ml-2" /> : <ListSvg className="ml-2" />}
              onClick={() => setIsMap(!isShowMap)}
            >
              {!isShowMap ? "Show Map" : "Show List"}
            </ChipButton>
          </div>
        </div>
      </div>
    </div>
  );
};
