import { Button } from "@app/components/atoms";
import { useCreateTopicMutation, useUpdateTopicMutation } from "@app/store/services/topic";
import { useForm, SubmitHandler } from "react-hook-form";
import { HCreatable, HTextArea, HTextField } from "@app/components/hookform";
import { CreateTopicPayload } from "@app/store/services/topic/types";
import {
  useCreateCategoryTopicMutation,
  useFetchCategoryTopicQuery,
} from "@app/store/services/category-topic";
import { Topic } from "@app/types";
import { useNavigate } from "react-router-dom";

type Props = {
  defaultValues?: Partial<Topic>;
};

export const AdminTopicForm = ({ defaultValues }: Props) => {
  const { control, handleSubmit, watch } = useForm<CreateTopicPayload>({
    defaultValues: {
      title: defaultValues?.title,
      content: defaultValues?.content,
    },
  });
  const navigate = useNavigate();

  const [createTopic, { isLoading: isCreating }] = useCreateTopicMutation();
  const [updateTopic, { isLoading: isUpdating }] = useUpdateTopicMutation();
  const [createCategoryTopic, { isLoading: isCreatingCategory }] = useCreateCategoryTopicMutation();
  const { data, isLoading: isLoadingCategory } = useFetchCategoryTopicQuery({});

  const onCreate: SubmitHandler<CreateTopicPayload> = async (payload) => {
    try {
      let categoryTopicId = payload.category_topic_id;
      const categoryOption = (data?.data || []).find((res) => res.id === payload.category_topic_id);
      if (!categoryOption) {
        const response = await createCategoryTopic({
          name: `${payload.category_topic_id}`,
        }).unwrap();
        categoryTopicId = response.data.id;
      }

      // create Topic
      await createTopic({
        title: payload.title,
        content: payload.content,
        category_topic_id: categoryTopicId,
      }).unwrap();

      navigate("/admin/topic");
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdate: SubmitHandler<CreateTopicPayload> = async (payload) => {
    try {
      if (!defaultValues?.id) {
        return;
      }
      let categoryTopicId = payload.category_topic_id;
      const categoryOption = (data?.data || []).find((res) => res.id === payload.category_topic_id);
      if (!categoryOption) {
        const response = await createCategoryTopic({
          name: `${payload.category_topic_id}`,
        }).unwrap();
        categoryTopicId = response.data.id;
      }

      // create Topic
      await updateTopic({
        id: defaultValues?.id,
        title: payload.title,
        content: payload.content,
        category_topic_id: categoryTopicId,
      }).unwrap();

      navigate("/admin/topic");
    } catch (err) {
      console.log(err);
    }
  };

  console.log(watch());

  return (
    <form className="py-3 px-8" onSubmit={handleSubmit(defaultValues ? onUpdate : onCreate)}>
      <HTextField
        control={control}
        name="title"
        label="Title"
        variant="outlined-black"
        border="full"
      />
      <HCreatable
        control={control}
        name="category_topic_id"
        label="Category"
        variant="outlined"
        layout="column"
        isLoading={isLoadingCategory}
        options={data?.data.map((res) => ({ value: res.id, label: res.name }))}
      />
      <HTextArea
        control={control}
        name="content"
        label="Content"
        variant="outlined"
        border="circle"
      />
      <div className="flex flex-row">
        <Button
          type="submit"
          variant="primary"
          className="ml-2 flex-1"
          isLoading={isCreating || isUpdating || isCreatingCategory}
        >
          {defaultValues ? "Create" : "Update"}
        </Button>
      </div>
    </form>
  );
};
