import { useForm, SubmitHandler } from "react-hook-form";
import { Typography, Button } from "@app/components/atoms";
import { HTextField, HRating, HRadio } from "@app/components/hookform";
import { CreateReviewPayload } from "@app/store/services/review/type";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateReviewMutation } from "@app/store/services/review";
import { BookingStatus, ReviewGender } from "@app/enums";
import { useUpdateBookingMutation } from "@app/store/services/booking";

type FormData = Omit<CreateReviewPayload, "booking_id" | "point">;

export default function Review() {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [createReview, { isLoading }] = useCreateReviewMutation();
  const [upateBooking, { isLoading: isUpdating }] = useUpdateBookingMutation();
  const { handleSubmit, control } = useForm<FormData>();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!bookingId) return;

    const point =
      (data.communication || 0) +
      (data.accuracy || 0) +
      (data.value || 0) +
      (data.atmosphere || 0) +
      (data.location || 0) +
      (data.presentation || 0) +
      (data.creativity || 0) +
      (data.uniqueness || 0);
    createReview({ ...data, booking_id: Number(bookingId || ""), point })
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch(() => {});

    upateBooking({
      id: Number(bookingId),
      status: BookingStatus.REVIEWED,
    });
  };

  return (
    <div className="flex-1">
      <div className="md:px-0 px-4 my-16">
        <div className="">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="md:w-1/2 p-6 mx-auto border rounded-10 border-black/[.07]"
          >
            <div className="-mx-6 border-b border-black/[.07] text-center pb-6">
              <Typography tag="body1" className="font-bold">
                Review your experience
              </Typography>
            </div>
            <div className="pt-6 mb-6">
              <HTextField name="description" control={control} placeholder="Description" />
              <div className="grid grid-cols-3 gap-5 mb-4 pt-4">
                <HRadio label="Male" name="gender" control={control} value={ReviewGender.MALE} />
                <HRadio
                  label="Female"
                  name="gender"
                  control={control}
                  value={ReviewGender.FEMALE}
                />
                <HRadio
                  label="Them/They"
                  name="gender"
                  control={control}
                  value={ReviewGender.THEM_THEY}
                />
              </div>
              <HTextField type="number" name="age" control={control} placeholder="Age" />
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Communication</Typography>
                <HRating name="communication" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Accuracy</Typography>
                <HRating name="accuracy" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Value</Typography>
                <HRating name="value" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Atmosphere</Typography>
                <HRating name="atmosphere" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Location</Typography>
                <HRating name="location" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Presentation</Typography>
                <HRating name="presentation" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Creativity</Typography>
                <HRating name="creativity" control={control} iconClassName="w-50 h-50" />
              </div>
              <div className="grid grid-cols-3 gap-5 mb-4">
                <Typography tag="h6">Uniqueness</Typography>
                <HRating name="uniqueness" control={control} iconClassName="w-50 h-50" />
              </div>
            </div>
            <Button
              type="submit"
              variant="primary"
              layout="rectangled"
              className="w-full"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
