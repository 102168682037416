import { ReactNode } from "react";
import clsx from "clsx";
import { IconButton, ArrowLeftSvg, Typography } from "../atoms";
import { useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  startIcon?: ReactNode;
  color?: "primary" | "blank";
  labelClass?: string;
};

export const BackButton = ({ className }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={clsx("flex flex-row items-center", className)}>
      <IconButton
        className="bg-headerBg mr-3 border border-border2 w-40 h-40 flex items-center justify-center"
        onClick={() => navigate("/admin")}
      >
        <ArrowLeftSvg className="stroke-blue" />
      </IconButton>
      <Typography>Back</Typography>
    </div>
  );
};
