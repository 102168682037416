import { WishListBanner, WishListSection, WislistSearching } from "@app/components/organisms";

export default function BlistBoard() {
  return (
    <>
      <WishListBanner />
      <WislistSearching />
      <WishListSection />
    </>
  );
}
