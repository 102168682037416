export const SlideLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="bg-slate-700 rounded md:h-617 h-150"></div>
      </div>
    </div>
  );
};

export const SlideAllLoading = () => (
  <div className="flex flex-row">
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
    <div className="px-2 flex-1">
      <BookingLoading />
    </div>
  </div>
);

export const BookingLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="bg-slate-700 rounded-10 h-150"></div>
      </div>
    </div>
  );
};

export const ItemLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 py-1">
        <div className="bg-slate-700 rounded md:h-430 h-150 mb-1"></div>
        <div className="bg-slate-700 rounded h-30 mb-1"></div>
        <div className="bg-slate-700 rounded h-30"></div>
      </div>
    </div>
  );
};

export const ExpListItemLoading = () => (
  <div className="grid-cols-3 gap-4">
    <ItemLoading />
    <ItemLoading />
    <ItemLoading />
  </div>
);

export const ListItemLoading = () => (
  <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
    <ItemLoading />
    <ItemLoading />
    <ItemLoading />
    <ItemLoading />
    <ItemLoading />
  </div>
);

export const SlideButtonLoading = () => {
  return (
    <div className="flex justify-between">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="bg-slate-700 rounded-full h-40 w-90"></div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="bg-slate-700 rounded-full h-40 w-90"></div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="bg-slate-700 rounded-full h-40 w-90"></div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="bg-slate-700 rounded-full h-40 w-90"></div>
        </div>
      </div>
    </div>
  );
};

export const ButtonLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="bg-slate-700 rounded-full h-40"></div>
      </div>
    </div>
  );
};

export const TextLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="bg-slate-700 rounded h-25"></div>
      </div>
    </div>
  );
};

export const WishlistLoading = () => {
  return (
    <>
      <BookingLoading />
      <BookingLoading />
      <BookingLoading />
    </>
  );
};
