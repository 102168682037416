import { GetHelp, Feedback } from "@app/components/organisms";

export default function Help() {
  return (
    <div className="px-6">
      <div className=" py-8">
        <GetHelp />
        <Feedback />
      </div>
    </div>
  );
}
