import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import Styled from "styled-components";
import { redirect } from "react-router-dom";

import {
  Typography,
  Button,
  IconButton,
  MailOutline,
  Lock,
  UserAlt,
  RegUser,
  Calendar,
  Spinner,
} from "@app/components/atoms";
import { HTextField, HRadio, HMaskInput } from "@app/components/hookform";
import {
  useSignupMutation,
  useGoogleAuthMutation,
  useFacebookAuthMutation,
  useAppleAuthMutation,
} from "@app/store/services/auth";
import { SignupPayload } from "@app/store/services/auth/type";
import { signupValidation, signupDefaultvalue } from "@app/validations";
import { Role } from "@app/enums";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { useInform } from "@app/store/hooks";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { facebookAppId, webUrl } from "@app/config";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import axios from "axios";
import moment from "moment";
import AppleSignin from "react-apple-signin-auth";

const StyledText = Styled(Typography)`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-right: 16px;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-left: 16px;
  }
`;

export default function Signup() {
  const [signup, { isLoading }] = useSignupMutation();
  const { handleSubmit, control, watch } = useForm({
    resolver: signupValidation,
    defaultValues: signupDefaultvalue,
  });
  const [googleAuth, { isLoading: isGoogleVerifying }] = useGoogleAuthMutation();
  const [facebookAuth, { isLoading: isFacebookVerifying }] = useFacebookAuthMutation();
  const [appleAuth, { isLoading: isAppleVerifying }] = useAppleAuthMutation();
  const { openInform } = useInform();

  const onSubmit: SubmitHandler<SignupPayload> = (data) => {
    console.log(data);
    signup({
      ...data,
      dob: moment(data.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
    })
      .unwrap()
      .then(() => redirect("/login"))
      .catch(() => {});
  };

  const onGoogleLogin = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">,
    ) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`,
        )
        .then((response) => {
          googleAuth(response.data);
        });
    },
    onError: () => {
      openInform({
        show: true,
        type: "error",
        message: "We have an error with google authentication",
      });
    },
  });

  const onFacebookLogin = (response: ReactFacebookLoginInfo) => {
    facebookAuth(response.id);
  };

  const onAppleLogin = (response: any) => {
    appleAuth(response.authorization.id_token);
  };

  return (
    <div className="md:px-0 px-4 my-16">
      <div className="">
        {(isGoogleVerifying || isFacebookVerifying) && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner />
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="md:w-1/2 p-6 mx-auto border rounded-10 border-black/[.07]"
        >
          <div className="-mx-6 border-b border-black/[.07] text-center pb-6">
            <Typography tag="body1" className="font-bold">
              Register
            </Typography>
          </div>
          <div className="pt-6">
            <Typography tag="h4" className="mb-4">
              Welcome to Blist
            </Typography>
            <div className="md:grid md:grid-cols-2 gap-5">
              <HTextField
                name="first_name"
                control={control}
                startIcon={<UserAlt className="text-timeAgo" />}
                placeholder="First Name"
              />
              <HTextField
                name="last_name"
                control={control}
                startIcon={<RegUser className="text-timeAgo" />}
                placeholder="Last Name"
              />
            </div>

            <HTextField
              name="email_address"
              control={control}
              startIcon={<MailOutline className="text-timeAgo" />}
              placeholder="Email Address"
            />
            <div className="md:grid md:grid-cols-2 gap-5 mb-4">
              <HMaskInput
                name="dob"
                label="Date Of Birth: "
                control={control}
                mask="dd/mm/yyyy"
                replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
                showMask
                separate
                variant="static"
                layout="static"
                startIcon={<Calendar className="mr-2" />}
              />
            </div>
            <div className="grid md:grid-cols-2 md:gap-5 gap-2 mb-4">
              <HRadio control={control} name="gender" value="male" label="Male" />
              <HRadio control={control} name="gender" value="female" label="Female" />
            </div>
            <Typography tag="body" className="mb-2">
              Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One
              Special Case Character
            </Typography>
            <HTextField
              name="password"
              type="password"
              control={control}
              startIcon={<Lock className="text-timeAgo" />}
              placeholder="Password"
            />
            <HTextField
              name="confirm_password"
              type="password"
              control={control}
              startIcon={<Lock className="text-timeAgo" />}
              placeholder="Confirm Password"
            />
            <div className="grid md:grid-cols-2 md:gap-5 gap-2 mb-4">
              <HRadio control={control} name="role" value="user" label="User" />
              <HRadio control={control} name="role" value="creator" label="Creator" />
            </div>
            {watch("role") === Role.creator && (
              <div className="grid md:grid-cols-2 md:gap-5 gap-2 border-t border-timeAgo pt-4 mb-4">
                <HRadio
                  control={control}
                  name="creator_type"
                  value="individual_creator"
                  label="Individual Creator"
                />
                <HRadio control={control} name="creator_type" value="venue" label="Venue" />
              </div>
            )}
          </div>
          <div className="flex items-center justify-between mb-8">
            <Link to="/signin">
              <Typography>Login</Typography>
            </Link>
          </div>
          <Button
            type="submit"
            variant="primary"
            layout="rectangled"
            className="w-full"
            isLoading={isLoading}
          >
            Sign up
          </Button>
          <StyledText className="text-center my-4 flex flex-row items-center w-full">Or</StyledText>
          <div className="flex flex-col">
            <FacebookLogin
              appId={facebookAppId}
              callback={onFacebookLogin}
              render={({ onClick }: { onClick: () => void }) => (
                <IconButton
                  layout="rectangled"
                  className="border flex flex-row items-center px-3 py-2 mb-4"
                  type="button"
                  onClick={onClick}
                >
                  <img
                    src="/imgs/facebook.svg"
                    width={25}
                    height={25}
                    alt="icon"
                    className="mr-6"
                  />
                  Log in with Facebook
                </IconButton>
              )}
            />

            <IconButton
              layout="rectangled"
              className="border flex flex-row items-center px-3 py-2 mb-4"
              type="button"
              onClick={() => onGoogleLogin()}
            >
              <img src="/imgs/google.svg" width={25} height={25} alt="icon" className="mr-6" />
              Log in with Google
            </IconButton>
            <AppleSignin
              authOptions={{
                clientId: "com.blistnow.auth.sid",
                scope: "email name",
                redirectURI: `${webUrl}/signup`,
                state: "state",
                nonce: "nonce",
                usePopup: true,
              }}
              uiType="dark"
              className="apple-auth-btn"
              noDefaultStyle={false}
              buttonExtraChildren="Continue with Apple"
              onSuccess={(response: any) => onAppleLogin(response)}
              onError={(error: any) => console.error(error)}
              skipScript={false}
              render={(props: any) => (
                <IconButton
                  layout="rectangled"
                  className="border flex flex-row items-center px-3 py-2 mb-4"
                  type="button"
                  {...props}
                >
                  <img src="/imgs/apple.svg" width={25} height={25} alt="icon" className="mr-6" />
                  Log in with Apple
                </IconButton>
              )}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
