import { ClockIconSvg, Typography, BookingLoading } from "../atoms";
import { PublicPagination } from "../common";
import { InquiryRequestItem } from "../molecules";
import { useFetchBookingQuery } from "@app/store/services/booking";
import { BookingStatus } from "@app/enums";
import { limitItem } from "@app/config";
import { useState } from "react";

type Props = {
  tab: BookingStatus;
};

export const InquiryRequestList = ({ tab }: Props) => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useFetchBookingQuery({
    status: tab,
    limit: limitItem,
    page,
  });

  return (
    <div className="md:px-6 px-2 py-8">
      <div className="flex flex-row border-b border-border6 pb-6 mb-6">
        <ClockIconSvg className="stroke-blue mt-1" />
        <Typography tag="h5" className="ml-2 !font-inter !font-normal text-blue/[.8]">
          You have a 24-hour window to either confirm or decline this reservation; otherwise, it
          will be automatically canceled.{" "}
        </Typography>
      </div>
      {isLoading ? (
        <>
          <BookingLoading />
          <BookingLoading />
          <BookingLoading />
        </>
      ) : (
        data?.data.map((item, key) => (
          <div className="border-b border-border6 pb-6 mb-6" key={key}>
            <InquiryRequestItem item={item} />
          </div>
        ))
      )}
      <div className="flex flex-row justify-end">
        <PublicPagination page={page} setPage={(num: number) => setPage(num)} total={data?.total} />
      </div>
    </div>
  );
};
