import { Typography, Button, Modal, BookingLoading } from "@app/components/atoms";
import { s3Url } from "@app/config";
import { useUpdateUserMutation } from "@app/store/services/user";
import { useGetVerifyAccountQuery } from "@app/store/services/verify-account";

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  isShow?: boolean;
  name?: string;
  question?: string;
};

export const RemovePopup = ({ isLoading, isShow, onSubmit, name, onClose }: Props) => {
  return (
    <Modal show={isShow} onClose={onClose}>
      <div className="py-3 px-8">
        <Typography tag="h2" className="text-center mb-6">
          Are you sure you want to remove {name}?
        </Typography>
        <div className="flex flex-row">
          <Button
            variant="outline-border"
            className="mr-2 flex-1 !border-lightGrey"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            variant="primary"
            className="ml-2 flex-1"
            isLoading={isLoading}
            onClick={onSubmit}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const DeactivePopup = ({ isLoading, isShow, onSubmit, name, onClose }: Props) => {
  return (
    <Modal show={isShow} onClose={onClose}>
      <div className="py-3 px-8">
        <Typography tag="h2" className="text-center mb-6">
          Are you sure you want to deactivate {name}?
        </Typography>
        <div className="flex flex-row">
          <Button
            variant="outline-border"
            className="mr-2 flex-1 !border-lightGrey"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            variant="primary"
            className="ml-2 flex-1"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const CustomPopup = ({ isLoading, isShow, onSubmit, onClose, question }: Props) => {
  return (
    <Modal show={isShow} onClose={onClose}>
      <div className="py-3 px-8">
        <Typography tag="h2" className="text-center mb-6">
          {question}
        </Typography>
        <div className="flex flex-row">
          <Button
            variant="outline-border"
            className="mr-2 flex-1 !border-lightGrey"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            variant="primary"
            className="ml-2 flex-1"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type VerifyPopupProps = {
  onClose: () => void;
  isShow?: boolean;
  id: number;
};

export const VerifyPopup = ({ id, onClose }: VerifyPopupProps) => {
  const { data, isLoading: isFetching } = useGetVerifyAccountQuery(id);
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const onSubmit = () => {
    updateUser({
      id,
      identity_verified: true,
      identify_card: [data?.front_side || "", data?.back_side || ""],
    })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  if (isFetching) return <BookingLoading />;

  return (
    <div className="py-3 px-8">
      <Typography>Type: {data?.type}</Typography>
      <div className="grid grid-cols-2 gap-4 mb-2 mt-2">
        {data?.front_side ? (
          <img src={`${s3Url}${data.front_side}`} alt="front-side" className="h-200" />
        ) : (
          <Typography>This user doesn't upload Front Image</Typography>
        )}
        {data?.back_side ? (
          <img src={`${s3Url}${data.back_side}`} alt="back-side" className="h-200" />
        ) : (
          <Typography>This user doesn't upload Back Image</Typography>
        )}
      </div>
      <div className="flex flex-row">
        <Button
          variant="outline-border"
          className="mr-2 flex-1 !border-lightGrey"
          onClick={onClose}
        >
          No
        </Button>
        <Button variant="primary" className="ml-2 flex-1" onClick={onSubmit} isLoading={isLoading}>
          Verify
        </Button>
      </div>
    </div>
  );
};
