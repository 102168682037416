import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateHolidayPayload, FetchHolidayPayload, UpdateHolidayPayload } from "./types";
import { Holiday } from "@app/types/holiday";

export const holidayApi = createApi({
  reducerPath: "holidayApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Holiday"],
  endpoints: (builder) => ({
    fetchHolidays: builder.query<Holiday[], FetchHolidayPayload>({
      query: (params) => {
        return {
          url: "holiday",
          params,
        };
      },
      transformResponse: (response: CommonResponse<Holiday[]>) => response?.data,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }: Holiday) => ({ type: "Holiday" as const, id })), "Holiday"]
          : ["Holiday"],
    }),
    createHoliday: builder.mutation<CommonResponse<Holiday>, CreateHolidayPayload>({
      query(body) {
        return {
          url: `holiday`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Holiday"],
    }),
    getHoliday: builder.query<Holiday, number>({
      query: (id: number) => `holiday/${id}`,
      transformResponse: (response: CommonResponse<Holiday>) => response?.data,
      providesTags: (result, error, id) => [{ type: "Holiday", id }],
    }),
    updateHoliday: builder.mutation<
      CommonResponse<Holiday>,
      Partial<UpdateHolidayPayload> & { id: number }
    >({
      query({ id, ...body }) {
        return {
          url: `holiday/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Holiday", id: arg.id }],
    }),
    removeHoliday: builder.mutation<CommonResponse<Holiday>, number>({
      query(id: number) {
        return {
          url: `holiday/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Holiday"],
    }),
  }),
});

export const {
  useFetchHolidaysQuery,
  useGetHolidayQuery,
  useCreateHolidayMutation,
  useUpdateHolidayMutation,
  useRemoveHolidayMutation,
} = holidayApi;
