import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { SubmitVerifyPayload } from "./types";
import { VerifyAccount } from "@app/types";

export const verifyAccountApi = createApi({
  reducerPath: "verifyAccountApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["VerifyAccount"],
  endpoints: (builder) => ({
    submitVerify: builder.mutation<CommonResponse<VerifyAccount>, SubmitVerifyPayload>({
      query(body) {
        return {
          url: `verify-account`,
          method: "post",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["VerifyAccount"],
    }),
    sendPhone: builder.mutation<CommonResponse<VerifyAccount>, string>({
      query(phone) {
        return {
          url: `verify-account/send-phone`,
          method: "post",
          body: {
            phone,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({
              show: true,
              type: "success",
              message: "Please check your phone to get code.",
            }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["VerifyAccount"],
    }),
    sendEmail: builder.mutation<CommonResponse<VerifyAccount>, string>({
      query(email) {
        return {
          url: `verify-account/send-email`,
          method: "post",
          body: {
            email,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({
              show: true,
              type: "success",
              message: "Please check your email to get code.",
            }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["VerifyAccount"],
    }),
    getVerifyAccount: builder.query<VerifyAccount, number>({
      query: (id: number) => `verify-account/${id}`,
      transformResponse: (response: CommonResponse<VerifyAccount>) => response?.data,
      providesTags: (result, error, id) => [{ type: "VerifyAccount", id }],
    }),
  }),
});

export const {
  useSubmitVerifyMutation,
  useSendEmailMutation,
  useSendPhoneMutation,
  useGetVerifyAccountQuery,
} = verifyAccountApi;
