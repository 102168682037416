import clsx from "clsx";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Typography, LovelySvg, ChipButton, IconButton, MoreSvg } from "../atoms";
import { ReactNode, useRef, useEffect, forwardRef, LegacyRef } from "react";

type ActionListProps = {
  menuList?: { text: string; icon: ReactNode; action: () => void }[];
};

type CategoryIconItemProps = ActionListProps & {
  title?: string;
  icon?: ReactNode;
  total?: number;
  className?: string;
  isEdit?: boolean;
  customActionList?: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  isHighlight?: boolean;
  id: number;
};

const ActionList = forwardRef(({ menuList }: ActionListProps, ref: LegacyRef<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className="absolute hidden right-6 w-150 top-0 border rounded-10 bg-blue border-white/[0.09] flex flex-col justify-start items-start"
    >
      {menuList?.map((item, key) => (
        <ChipButton
          key={key}
          className="text-white text-14 border-b border-white/[.1] px-2 py-1 w-full rounded-0 !justify-start"
          variant="blank"
          startIcon={item.icon}
          onClick={item.action}
        >
          {item.text}
        </ChipButton>
      ))}
    </div>
  );
});

export const CategoryIconItem = ({
  title,
  icon,
  total,
  className,
  isEdit,
  customActionList,
  isActive,
  onClick,
  isHighlight,
}: CategoryIconItemProps) => {
  return (
    <div
      className={clsx(
        "relative rounded-10 mr-2 py-2 px-4 bg-white mb-3 cursor-pointer",
        "hover:border hover:border-border3/[.1] hover:shadow-5x hover:relative hover:z-10",
        isHighlight && "border border-primary shadow-5x relative z-10",
        isActive && "border border-primary shadow-5x relative z-10",
        className,
      )}
      onClick={onClick}
    >
      <div className="py-2">
        {icon || (
          <LovelySvg className={clsx("h-50", isHighlight ? "stroke-primary" : "stroke-blue")} />
        )}
      </div>
      <Typography tag="h5" className="text-blue mt-4">
        {title}
      </Typography>

      <Typography className="text-primary font-medium">
        {total} <span className="text-lightGrey font-normal">Listings</span>
      </Typography>
    </div>
  );
};

export const AdminCategoryIconItem = ({
  title,
  icon,
  total,
  className,
  isEdit,
  customActionList,
  isActive,
  id,
  ...props
}: CategoryIconItemProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.stopPropagation();
      if (listRef.current && !listRef.current.contains(event.target)) {
        listRef.current.classList.add("hidden");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listRef]);

  const onToggle = () => {
    if (!listRef.current) {
      return;
    }
    listRef.current.classList.toggle("hidden");
  };

  return (
    <div className="relative">
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={clsx(
          "relative rounded-10 py-8 px-4 bg-white border border-border3/[.1]",
          "hover:shadow-5x hover:relative hover:z-10",
          isActive && "shadow-5x relative z-10",
          className,
        )}
      >
        <div className="py-2">{icon}</div>
        <Typography tag="h5" className="text-blue mt-4">
          {title}
        </Typography>

        <Typography className="text-primary font-medium">
          {total} <span className="text-lightGrey font-normal">Listings</span>
        </Typography>
      </div>
      {isEdit && (
        <div className="absolute top-2 right-2 z-50">
          <IconButton onClick={onToggle}>
            <MoreSvg className="stroke-blue fill-blue" />
          </IconButton>
          {customActionList ?? <ActionList {...props} ref={listRef} />}
        </div>
      )}
    </div>
  );
};
