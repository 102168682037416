// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJ0kZCEgC1b9lb6lNNnguKR6wVB_7T6rg",
  authDomain: "blistnow-b85c4.firebaseapp.com",
  projectId: "blistnow-b85c4",
  storageBucket: "blistnow-b85c4.appspot.com",
  messagingSenderId: "510309751424",
  appId: "1:510309751424:web:68b8a8262e43771e1fe465",
  measurementId: "G-Q7B5FBHM7F",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const messageCollection = collection(db, "message");
export const alertCollection = collection(db, "alert");
