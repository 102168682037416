import { ExperienceItem } from "../molecules";
import { Typography, ListItemLoading } from "../atoms";
import { useFetchExperienceQuery } from "@app/store/services/experience";

type Props = {
  title?: string;
  categoryIds?: number[];
};

export const SimilarSection = ({ title, categoryIds = [] }: Props) => {
  const { data, isLoading } = useFetchExperienceQuery(
    {
      limit: 5,
      category_ids: categoryIds,
    },
    {
      skip: !categoryIds,
    },
  );

  return (
    <div className=" pb-8">
      {title && (
        <div className="md:mb-8 mb-6">
          <Typography tag="h2" className="text-center">
            {title}
          </Typography>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};
