import { Label } from "./Label";
import { ChangeEvent, forwardRef, LegacyRef, ReactNode } from "react";
import clsx from "clsx";
import DateTimePicker from "react-datepicker";
import { debounce } from "lodash";
import moment from "moment";

export type DateTimePickerProps = {
  placeholder?: string;
  variant?: "static" | "outlined" | "filled" | "filter" | "outlined-black" | "none";
  layout?: "rounded" | "static" | "rectangled";
  layoutType?: "row" | "column";
  onChange: (date: Date | null | [Date | null, Date | null]) => void;
  value: Date;
  error?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  label?: string;
  disabled?: boolean;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  inputClass?: string;
  selectsRange?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
};

type InputPros = {
  placeHolder?: string;
  value?: string;
  onClick?: () => void;
  variant?: "static" | "outlined" | "filled" | "filter" | "outlined-black" | "none";
  layout?: "rounded" | "static" | "rectangled";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  inputClass?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      placeHolder,
      variant,
      startIcon,
      endIcon,
      layout,
      disabled,
      inputClass,
      ...props
    }: InputPros,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <div
        className={clsx(
          "flex flex-row items-center px-2 py-2",
          layout === "rectangled" && "rounded-10",
          layout === "rounded" && "rounded-full",
          variant === "outlined" && "border border-timeAgo overflow-hidden",
          variant === "static" && "border-t-0 border-l-0 border-r-0 border-timeAgo border-b",
          variant === "filter" && "border border-buttonGrey py-4 px-4",
          variant === "outlined-black" && "border border-lightGrey py-4 px-4",
          inputClass,
        )}
      >
        {startIcon}
        <input
          {...props}
          type="text"
          onClick={onClick}
          ref={ref}
          value={value ? value : undefined}
          placeholder={placeHolder}
          className="w-full font-inter font-extralight md:text-16 text-14"
          disabled={disabled}
        />
        {endIcon}
      </div>
    );
  },
);

export const DatePicker = forwardRef(
  (
    {
      placeholder,
      onChange,
      value,
      error,
      variant = "outlined",
      layout = "rectangled",
      layoutType = "column",
      startIcon,
      endIcon,
      label,
      inputClass,
      ...props
    }: DateTimePickerProps,
    ref,
  ) => {
    return (
      <div className={clsx(layoutType === "column" ? "" : "flex flex-row items-center")}>
        {label && (
          <Label className={clsx(layoutType === "column" ? "mb-4" : "mb-0 flex-1")}>{label}</Label>
        )}
        <div className={clsx(layoutType === "column" ? "" : "w-1/2")}>
          <DateTimePicker
            wrapperClassName="w-full"
            customInput={
              <CustomDateInput
                placeHolder={placeholder}
                variant={variant}
                startIcon={startIcon}
                endIcon={endIcon}
                layout={layout}
                disabled={props.disabled}
                inputClass={inputClass}
              />
            }
            onChange={onChange}
            selected={value}
            ref={ref as any}
            {...props}
          />
          {error && <Label variant="error">{error}</Label>}
        </div>
      </div>
    );
  },
);

const CustomTimeInput = forwardRef(
  (
    { value, onClick, placeHolder, startIcon, endIcon, disabled, layout, variant }: InputPros,
    ref: LegacyRef<HTMLInputElement>,
  ) => (
    <div
      className={clsx(
        "flex flex-row items-center px-2 py-2",
        layout === "rectangled" && "rounded-10",
        layout === "rounded" && "rounded-full",
        variant === "outlined" && "border border-timeAgo overflow-hidden",
        variant === "static" && "border-t-0 border-l-0 border-r-0 border-timeAgo border-b",
        variant === "filter" && "border border-buttonGrey py-4 px-4",
        variant === "outlined-black" && "border border-lightGrey py-4 px-4",
      )}
    >
      {startIcon}
      <input
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={placeHolder}
        className="w-full"
        disabled={disabled}
      />
      {endIcon}
    </div>
  ),
);

CustomTimeInput.displayName = "CustomTimeInput";

export const TimePicker = forwardRef(
  (
    {
      placeholder,
      onChange,
      value,
      error,
      variant = "outlined",
      layout = "rectangled",
      layoutType = "column",
      startIcon,
      endIcon,
      label,
      disabled,
      ...props
    }: DateTimePickerProps,
    ref,
  ) => {
    return (
      <div className={clsx(layoutType === "column" ? "" : "flex flex-row items-center")}>
        {label && (
          <Label className={clsx(layoutType === "column" ? "mb-4" : "mb-0 flex-1")}>{label}</Label>
        )}
        <div className={clsx(layoutType === "column" ? "" : "w-1/2")}>
          <DateTimePicker
            wrapperClassName="w-full"
            showTimeSelect
            showTimeSelectOnly
            customInput={
              <CustomTimeInput
                placeHolder={placeholder}
                variant={variant}
                startIcon={startIcon}
                endIcon={endIcon}
                layout={layout}
                disabled={disabled}
              />
            }
            onChange={onChange}
            selected={value}
            ref={ref as any}
            disabled={disabled}
            {...props}
          />
        </div>
      </div>
    );
  },
);

// export const DateTimePicker = () => {
//   return <></>;
// };
