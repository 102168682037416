import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import { CreateApprenticePayload } from "./type";
import { Apprentice } from "@app/types/apprentice";

export const apprenticeApi = createApi({
  reducerPath: "apprenticeApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Apprentice"],
  endpoints: (builder) => ({
    createApprentice: builder.mutation<CommonResponse<Apprentice>, CreateApprenticePayload>({
      query(body) {
        return {
          url: `apprentice`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({
              show: true,
              type: "success",
              message: "Create your Apprentice profile successfully",
            }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Apprentice"],
    }),
    getApprentice: builder.query<Apprentice, string>({
      query: (id) => `apprentice/${id}`,
      transformResponse: (response: CommonResponse<Apprentice>) => response.data,
    }),
  }),
});

export const { useGetApprenticeQuery, useCreateApprenticeMutation } = apprenticeApi;
