import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { Button, IconHeartSvg, IconButton } from "../atoms";

type CategoryItemProps = {
  title?: string;
  url?: string;
  isSlide?: boolean;
};
export const CategoryItem = ({ title, url, isSlide }: CategoryItemProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx(
        "rounded-10 relative flex flex-col justify-end items-center",
        isSlide && "mr-4",
      )}
    >
      <img
        src={url || "/imgs/image-3.png"}
        className="insect-0 md:h-430 h-240"
        alt={title || "no data"}
        width={350}
        height={432}
      />

      <IconButton className="absolute right-2 top-2 z-10">
        <IconHeartSvg />
      </IconButton>

      {title && (
        <div className="absolute bottom-5 z-10">
          <Button variant="primary" onClick={() => navigate("/experiences")}>
            {title}
          </Button>
        </div>
      )}
    </div>
  );
};
