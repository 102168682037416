import { useGetTopicQuery } from "@app/store/services/topic";
import { Typography } from "@app/components/atoms";
import { Spinner } from "@app/components/atoms/Spinner";
import { BackButton } from "@app/components/common";
import { useParams } from "react-router-dom";
import { AdminTopicForm } from "@app/components/organisms/AdminTopicForm";

export default function TopicActionScreen() {
  const { id } = useParams<{ id?: string }>();
  const { data, isLoading } = useGetTopicQuery(+(id || ""), {
    skip: !id,
  });

  return (
    <>
      <div className="mb-8">
        <BackButton />
        <Typography tag="h2" className="mt-6">
          Topic
        </Typography>
      </div>
      <div className="w-1/2">
        {isLoading ? <Spinner /> : <AdminTopicForm defaultValues={data} />}
      </div>
    </>
  );
}
