import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const createExperienceValidation = yupResolver(
  yup.object({
    category_ids: yup.array(yup.number()),
    creator_ids: yup.array(yup.object()),
    kind: yup.array(yup.string()),
    type: yup.string().required(),
    addresses: yup
      .array(
        yup.object({
          country: yup.string().required(),
          city: yup.string().required(),
        }),
      )
      .min(1, "You need an address."),
    is_willing_to_travel: yup.boolean().required(),
    willing_to_travel: yup.string(),
    language_offered: yup.array(yup.string()),
    is_date: yup.boolean().required(),
    duration: yup.string(),
    available_days: yup.array(yup.string()),
    summary: yup.string().required(),
    description: yup.string().required(),
    atmosphere: yup.string().required(),
    creator_type: yup.string().required(),
    creator_presence: yup.string().required(),
    creator_provide: yup.string().required(),
    guest_bring: yup.string().required(),
    clothing_recommendation: yup.string().required(),
    pet_allowed: yup.boolean().required(),
    handicap_accessible: yup.boolean().required(),
    level_expected: yup.string().required(),
    photos: yup.array(yup.string()).nullable(),
    videos: yup.array(yup.string()).nullable(),
    business: yup.string().nullable(),
    pet_description: yup.string().nullable(),
    handicap_accessible_description: yup.string().nullable(),
    title: yup.string().required(),
    age_min: yup.number().optional(),
    age_max: yup.number().integer().min(0),
    guest_min: yup.number().integer().min(0),
    guest_max: yup.number().integer().min(0),
    is_free: yup.boolean(),
    amount: yup
      .number()
      .nullable()
      .when("is_free", (is_free, schema) => {
        if (!is_free?.[0]) {
          return yup.number().integer().min(1);
        }
        return schema;
      }),
    availability_time: yup.array(yup.date()),
    start_date: yup.date(),
    end_date: yup.date(),
  }),
);
