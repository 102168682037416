import { CalendarExperience, CalendarSelectDate } from "@app/components/organisms";
import { useAuth } from "@app/store/hooks";

export default function CreatorCalendarPage() {
  const { profile } = useAuth();
  return (
    <div className="px-6">
      <div className=" py-8">
        <CalendarSelectDate />
        <CalendarExperience title={`Experiences By ${profile.first_name}`} />
      </div>
    </div>
  );
}
