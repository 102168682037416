import { Typography } from "@app/components/atoms";
import { BackButton } from "@app/components/common";
import { AdminUserForm } from "@app/components/organisms/AdminUserForm";

export default function UserActionScreen() {
  return (
    <>
      <div className="mb-8">
        <BackButton />
        <Typography tag="h2" className="mt-6">
          User
        </Typography>
      </div>
      <div className="w-1/2">
        <AdminUserForm />
      </div>
    </>
  );
}
