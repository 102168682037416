import { ReactNode } from "react";
import clsx from "clsx";
import {
  LeftPagingSvg,
  RightPagingSvg,
  LeftPaging2Svg,
  RightPaging2Svg,
  IconButton,
} from "../atoms";
import range from "lodash/range";

type Props = {
  className?: string;
  startIcon?: ReactNode;
  color?: "primary" | "blank";
  labelClass?: string;
  page?: number;
  setPage?: (num: number) => void;
  total?: number;
};

const limit = 10;

export const Pagination = ({ total = 0, setPage, page = 0, className }: Props) => {
  if (limit >= total) return null;

  const totalPages = total / limit + (total % limit > 0 ? 1 : 0);

  const onClick = (isNext: boolean) => {
    if (!isNext && page > 1) {
      setPage?.(page - 1);
    } else if (isNext && page < totalPages) {
      setPage?.(page + 1);
    }
  };

  return (
    <div className={clsx("flex flex-row items-center", className)}>
      <IconButton onClick={() => onClick(false)}>
        <LeftPagingSvg className="md:w-auto w-25" />
      </IconButton>
      {range(totalPages).map((item, key) => (
        <button
          className={clsx(
            "rounded-10 md:w-50 md:h-50 w-25 h-25 mx-1",
            "bg-blue text-white md:text-18 text-16 font-bold",
            page === item + 1 && "bg-primary text-white md:text-18 text-18",
          )}
          type="button"
          onClick={() => setPage?.(item + 1)}
          disabled={page === item + 1}
          key={key}
        >
          {item + 1}
        </button>
      ))}
      <IconButton onClick={() => onClick(true)}>
        <RightPagingSvg className="md:w-auto w-25" />
      </IconButton>
    </div>
  );
};

export const PublicPagination = ({ className, setPage, page = 0, total = 0 }: Props) => {
  if (limit >= total) return null;

  const totalPages = total / limit + (total % limit > 0 ? 1 : 0);

  const onClick = (isNext: boolean) => {
    if (!isNext && page > 1) {
      setPage?.(page - 1);
    } else if (isNext && page < totalPages) {
      setPage?.(page + 1);
    }
  };

  return (
    <div className={clsx("flex flex-row items-center", className)}>
      <IconButton
        className={clsx(
          "rounded-full w-34 h-34 mx-1",
          "border border-blue/[.13]",
          "bg-white text-blue/[.7] md:text-18 text-18",
          "flex justify-center items-center",
        )}
        type="button"
        onClick={() => onClick(false)}
      >
        <LeftPaging2Svg className="md:w-auto w-25" />
      </IconButton>
      {range(totalPages).map((item, key) => (
        <button
          className={clsx(
            "rounded-full w-34 h-34 mx-1",
            "border border-primary",
            page === item + 1 && "bg-primary text-white md:text-18 text-18",
          )}
          type="button"
          onClick={() => setPage?.(item + 1)}
          disabled={page === item + 1}
        >
          {item + 1}
        </button>
      ))}
      <IconButton
        className={clsx(
          "rounded-full w-34 h-34 mx-1",
          "border border-blue/[.13]",
          "bg-white text-blue/[.7] md:text-18 text-18",
          "flex justify-center items-center",
        )}
        type="button"
        onClick={() => onClick(true)}
      >
        <RightPaging2Svg className="md:w-auto w-25" />
      </IconButton>
    </div>
  );
};
