import { useForm, SubmitHandler } from "react-hook-form";
import {
  Typography,
  DollarCircleSvg,
  UploadMultiFileSecond,
  Button,
  QuestionCircleSvg,
  LikeSvg,
  DislikeSvg,
} from "@app/components/atoms";
import { UploadAvatar } from "@app/components/molecules";
import { HUpload, HTextField, HRadio, HTextArea, HMultiSelect } from "@app/components/hookform";
import { useCreateApprenticeMutation } from "@app/store/services/apprentice";
import { CreateApprenticePayload } from "@app/store/services/apprentice/type";
import { useNavigate } from "react-router-dom";
import { ApprenticeLocation } from "@app/enums";
import { useInform } from "@app/store/hooks";
import { Option } from "@app/types";

const options = [
  { value: "Woodturning", label: "Woodturning" },
  { value: "Joinery", label: "Joinery" },
  { value: "Lathe Work", label: "Lathe Work" },
];

type CreateApprenticeForm = Omit<CreateApprenticePayload, "qualities"> & {
  qualities: Option[];
};

export default function CreateApprenticeProfile() {
  const { openInform } = useInform();
  const [createApprentice, { isLoading }] = useCreateApprenticeMutation();
  const { handleSubmit, control } = useForm<CreateApprenticeForm>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<CreateApprenticeForm> = async (data) => {
    try {
      let { qualities, ...payload } = data;

      await createApprentice({
        ...payload,
        qualities: qualities?.map((item) => item.value),
      }).unwrap();
      navigate(-1);
    } catch (error: unknown) {
      if (!(error instanceof Error)) {
        throw error;
      }
      openInform({ show: true, type: "error", message: error?.message || "" });
    }
  };

  return (
    <div className="md:px-0 px-4 py-6">
      <form onSubmit={handleSubmit(onSubmit)} className=" py-12">
        <Typography tag="h2" className="mb-8">
          Create Apprentice Profile
        </Typography>
        <div className="border-b border-border2 pb-8">
          <div className="mb-6 inline-block">
            <HUpload control={control} name="avatar" imageClass="!w-140px !h-140px">
              <UploadAvatar />
            </HUpload>
          </div>
          <div className="grid md:grid-cols-2 gap-5">
            <HTextField
              variant="outlined-black"
              border="full"
              name="name"
              placeholder="Enter the name"
              label="Name"
              control={control}
            />
            <HTextField
              variant="outlined-black"
              border="full"
              name="age"
              placeholder="30 Years"
              label="Age"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 grid md:grid-cols-2 gap-5 py-4">
          <div>
            <HTextField
              label="Skill"
              variant="outlined-black"
              border="rectangled"
              name="skill"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Please Add Photos/Videos
          </Typography>
          <div className="grid grid-cols-2 gap-5">
            <HUpload control={control} name="photos" multiple maxFile={6}>
              <UploadMultiFileSecond
                text="Please upload a minimum of 6 photos"
                subtext="JPG, PNG or PDF, file size no more than 10MB"
              />
            </HUpload>
            <HUpload control={control} name="videos" multiple maxFile={2}>
              <UploadMultiFileSecond
                text="Please upload a minimum of 2 videos"
                subtext="MP4, MOV or AVI. file size no more than 10MB"
              />
            </HUpload>
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4 flex flex-row items-center">
            Locations <QuestionCircleSvg className="ml-2" />
          </Typography>
          <div className="md:w-1/5 grid grid-cols-2 gap-5">
            <HRadio
              label="Online"
              value={ApprenticeLocation.ONLINE}
              name="location"
              control={control}
            />
            <HRadio
              label="Physical"
              value={ApprenticeLocation.PHYSICAL}
              name="location"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="About me"
              subtitle="(500 words max)"
              name="introduce"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Job Description "
              subtitle="(500 words max)"
              name="job_description"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Daily Life"
              name="daily_life"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2 mb-4">
            <div className="mb-6">
              <HTextField
                customLabel={
                  <Typography tag="h6" className="text-blue mr-2 !self-auto flex mb-4">
                    <LikeSvg className="mr-2" /> Likes
                  </Typography>
                }
                variant="outlined-black"
                border="rectangled"
                name="likes"
                control={control}
              />
            </div>
            <div>
              <HTextField
                customLabel={
                  <Typography tag="h6" className="text-blue mr-2 !self-auto flex mb-4">
                    <DislikeSvg className="mr-2" /> Dislikes
                  </Typography>
                }
                variant="outlined-black"
                border="rectangled"
                name="dislikes"
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="How I got here"
              name="got_here"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2 mb-4">
            <HMultiSelect
              options={options}
              isMulti
              menuIsOpen={true}
              customLabel={
                <Typography tag="h6" className="text-blue mr-2 !self-auto flex mb-4">
                  Top 3 Qualities for success
                </Typography>
              }
              layout="column"
              hint="Maximum 3 qualities"
              name="qualities"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <Typography tag="static" className="text-20 text-blue font-semibold mb-6">
            Hardest part vs Easiest part of my career
          </Typography>
          <div className="md:w-1/2">
            <Typography tag="static" className="text-16 text-blue font-medium mb-3">
              Hardest Part
            </Typography>
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              subtitle="(500 words max)"
              name="hardest_part"
              control={control}
            />
          </div>
          <div className="md:w-1/2">
            <Typography tag="static" className="text-16 text-blue font-medium mb-3">
              Easiest Part
            </Typography>
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              subtitle="(500 words max)"
              name="easiest_part"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextField
              variant="outlined-black"
              border="full"
              name="salary"
              placeholder="Enter Amount Here"
              label="Salary"
              startIcon={<DollarCircleSvg className="stroke-black w-25 h-25 ml-2" />}
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h2">Create Apprentice Profile</Typography>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Where you will go"
              subtitle="(50 words max)"
              name="where_will_go"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="What you can expect"
              subtitle="(500 words max)"
              name="expect"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="What to bring"
              subtitle="(500 words max)"
              name="what_to_bring"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 pt-4 mb-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="What you will learn"
              subtitle="(500 words max)"
              name="what_will_learn"
              control={control}
            />
          </div>
        </div>
        <Button variant="primary" type="submit" isLoading={isLoading}>
          Submit
        </Button>
      </form>
    </div>
  );
}
