import { Typography } from "../atoms";
import { Link } from "react-router-dom";

type Props = {
  title?: string;
  url?: string;
  id: number;
};
export const NewWeekItem = ({ title, url, id }: Props) => {
  return (
    <Link to={`/news/${id}`} className="rounded-10 mr-4 relative block">
      <img src={url || "/imgs/image-19.jpg"} alt={title || "no-data"} className="w-full h-320" />
      {title && (
        <Typography tag="h4" className="text-white z-10 absolute z-20 bottom-8 left-6">
          {title}
        </Typography>
      )}
      <div className="absolute z-10 rounded-10 inset-0 bg-gradient-to-t from-black to-transparent " />
    </Link>
  );
};
