import Styled from "styled-components";
import { IconButton } from "../../atoms";

export const CustomSendButton = Styled(IconButton)`
  @media(max-width: 767px) {
    padding: 0.3rem;
  }
`;

export const SlideWrapper = Styled.div`
  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    li {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 5px;
      padding-right: 5px;
      width: auto;
      &.slick-active {
        button {
          width: 27px;
          height: 10px;
          background-color: #F1F1F1;
        }
      }
      button {
        width: 10px;
        height: 10px;
        background-color: #A1A1A1;
        border-radius: 20px;
        width: 10px;
        height: 10px;
        &:before {
          display: none;
          
        }
      }
    }
  }
`;
