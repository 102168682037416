import { Line } from "react-chartjs-2";
import { Typography } from "../atoms";
import { useGetLast365DaysQuery } from "@app/store/services/analytics";
import { range, find } from "lodash";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    filler: {
      propagate: false,
    },
    title: {
      display: true,
    },
  },
  radius: 0,
  interaction: {
    intersect: false,
  },
  bezierCurve: true,
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: (value: number) => {
          return "$" + value + "K";
        },
      },
      border: {
        display: false,
      },
    },
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

const data = {
  labels: ["Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      label: "Profit",
      data: [1, 5, 10, 15, 20, 25, 30, 20, 5, 10, 15, 20],
      borderColor: "#F86A27",
      backgroundColor: "#F86A27",
      borderWidth: 1,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0,
      fill: true,
      tension: 0.5,
    },
  ],
};

export const AreaChart = () => {
  const { data } = useGetLast365DaysQuery({});

  return (
    <div className="bg-bg3 rounded-10 px-5 py-3">
      <div className="flex flex-row items-center justify-between mb-12">
        <Typography className="text-primary font-semibold">Last 365 days</Typography>
      </div>
      <div>
        <Line
          options={options as any}
          data={{
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ],
            datasets: [
              {
                label: "Profit",
                data: range(1, 13).map((item) => ({
                  x: item,
                  y: Number(
                    find(data?.data ?? [], (it) => Number(it.group) === item)?.total_amount || 0,
                  ),
                })),
                borderColor: "#F86A27",
                backgroundColor: "#F86A27",
                borderWidth: 1,
                pointBackgroundColor: "#fff",
              },
            ],
          }}
          height={320}
        />
      </div>
    </div>
  );
};
