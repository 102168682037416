import { Control, useController } from "react-hook-form";
import { RatingProps, Rating } from "../atoms";

interface Props extends Omit<RatingProps, "value" | "onChange"> {
  control: Control<any>;
  name: string;
}

export const HRating = ({ control, name, ...props }: Props) => {
  const { field } = useController({
    name,
    control,
  });

  return <Rating {...props} onChange={(data) => field.onChange(data)} value={field.value} />;
};
