import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { CreateNewsPayload } from "@app/store/services/news/types";
import { createNewsValidate } from "@app/validations";
import { categoryDefaultValue } from "@app/validations/category";

import { Button, UploadFile } from "../atoms";
import { HTextEditor, HTextField, HUpload } from "../hookform";
import { News } from "@app/types";
import { useCreateNewsMutation, useUpdateNewsMutation } from "@app/store/services/news";

type CreateData = CreateNewsPayload & {};

type Props = {
  defaultValues?: Partial<News>;
};

export const NewsForm = ({ defaultValues }: Props) => {
  const [create, { isLoading }] = useCreateNewsMutation();
  const [update, { isLoading: isUpdating }] = useUpdateNewsMutation();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<CreateData>({
    resolver: createNewsValidate,
    defaultValues: {
      ...categoryDefaultValue,
      ...defaultValues,
    },
  });

  const onCreate: SubmitHandler<CreateData> = async (data) => {
    create(data)
      .unwrap()
      .then(() => {
        navigate("/admin/blist-board");
      })
      .catch(() => {});
  };

  const onUpdate: SubmitHandler<CreateData> = async (data) => {
    if (!defaultValues?.id) {
      return;
    }

    update({ ...data, id: defaultValues?.id })
      .unwrap()
      .then(() => {
        navigate("/admin/blist-board");
      })
      .catch(() => {});
  };

  return (
    <form
      onSubmit={handleSubmit(defaultValues ? onUpdate : onCreate)}
      className="grid md:grid-cols-3 gap-5"
    >
      <div>
        <HUpload control={control} name="photo">
          <UploadFile />
        </HUpload>
      </div>
      <div className="col-span-2">
        <HTextField
          control={control}
          name="title"
          variant="outlined-black"
          border="full"
          label="Title"
          placeholder="Type here"
          className="mb-4"
        />
        <HTextEditor control={control} name="content" label="Content" />
        <div className="mt-2">
          <Button
            type="submit"
            variant="primary"
            layout="rounded"
            isLoading={isLoading || isUpdating}
          >
            {defaultValues ? "Update Now" : "Create Now"}
          </Button>
        </div>
      </div>
    </form>
  );
};
