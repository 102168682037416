import { Controller, Control } from "react-hook-form";
import { Textarea } from "../atoms";
import type { TextareaProps } from "../atoms";

interface Props extends TextareaProps {
  control: Control<any>;
  name: string;
}

export const HTextArea = ({ control, name, defaultValue, ...props }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Textarea
          name={field.name}
          onChange={field.onChange}
          value={field.value}
          error={error?.message}
          ref={ref as any}
          defaultValue={defaultValue}
          {...props}
        />
      )}
    />
  );
};
