import { useCreateTrackingMutation, useGetTrackingQuery } from "@app/store/services/tracking";
import { Button, Select, Typography } from "../atoms";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import { useFetchUserQuery } from "@app/store/services/user";
import { s3Url, webUrl } from "@app/config";
import { useState } from "react";
import { CreateTrackingPayload } from "@app/store/services/tracking/type";
import { find } from "lodash";
import { User } from "@app/types";
import { useAuthorizedPersonMutation } from "@app/store/services/message";
import { useAuth } from "@app/store/hooks";

const UserWrapper = Styled.div`
  .remove-button {
    display: none
  }
  &:hover > .remove-button {
    display: flex
  }
`;

const options = [
  { value: "chocolate", label: "John Doe", avatar: "/imgs/avatar-john.png" },
  { value: "strawberry", label: "Carl D'Souza", avatar: "/imgs/avatar-john.png" },
  { value: "vanilla", label: "Mark Williams", avatar: "/imgs/avatar-john.png" },
];

const UserFormatOptionLabel = ({ label, avatar }: { label: string; avatar: string }) => {
  return (
    <div className="flex flex-row">
      <img src={avatar} className="w-40 h-40 rounded-full mr-4 avatar" alt={label} />
      <Typography isColor className="text-blue label">
        {label}
      </Typography>
    </div>
  );
};

const AuthorizeForm = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [createTracking, { isLoading: isUpdating }] = useCreateTrackingMutation();
  const { data: users, isLoading: isFetching } = useFetchUserQuery({});
  const [payload, setPayload] = useState<{ value: number; label: string; avatar: string }>();
  const { data } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const [authroizedPerson] = useAuthorizedPersonMutation();
  const { profile } = useAuth();

  const onAuthorized = () => {
    if (!bookingId || !payload?.value) return;

    const user_ids = data?.authorized_people?.map((item) => item.id) || [];
    createTracking({
      booking_id: Number(bookingId),
      authorized_people: [...user_ids, payload.value],
    });
    authroizedPerson({
      sender: profile.id,
      receiver: payload.value,
      booking_id: +bookingId,
      content: `The user ${profile.first_name} ${profile.last_name} authorized you in their safety page: ${webUrl}/tracking/${bookingId}`,
    });
  };
  return (
    <div>
      <Select
        layout="column"
        options={users?.data?.map((item) => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
          avatar: item.avatar ? `${s3Url}${item.avatar}` : "/imgs/default-avatar.png",
        }))}
        label="User name"
        formatOptionLabel={UserFormatOptionLabel as any}
        hideSelectedOptions={false}
        classNames={{
          multiValue: () =>
            "!bg-blue text-white min-w-100 py-1 font-semibold border-blue !rounded-full px-2",
          multiValueRemove: () => "hover:!bg-transparent hover:!text-white/[.4]",
          option: ({ isSelected }) => clsx("hover:bg-footerBg", isSelected && "!bg-primary/[.1]"),
        }}
        onChange={(e: any) => setPayload(e)}
        isLoading={isFetching}
      />
      <Button variant="primary" isLoading={isUpdating} onClick={onAuthorized}>
        Done
      </Button>
    </div>
  );
};

type UserItemProps = {
  avatar: string;
  name: string;
  email: string;
  onRemove: () => void;
};

const UserItem = ({ avatar, name, email, onRemove }: UserItemProps) => {
  return (
    <UserWrapper className="rounded-10 bg-white border border-border2 flex flex-row items-center px-2 py-3 relative overflow-hidden">
      <div className="mr-3">
        <img src={avatar} alt="avatar" width={66} height={66} />
      </div>
      <div>
        <Typography tag="body1" isColor className="text-blue">
          {name}
        </Typography>
        <Typography tag="body">{email}</Typography>
      </div>
      <div className="remove-button absolute left-0 right-0 top-0 bottom-0 bg-black/[.7] z-20 justify-center items-center">
        <Button layout="rounded" variant="outline-white" onClick={onRemove}>
          Remove
        </Button>
      </div>
    </UserWrapper>
  );
};

const ListUser = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const [createTracking] = useCreateTrackingMutation();
  const [authroizedPerson] = useAuthorizedPersonMutation();
  const { profile } = useAuth();

  const onRemove = (id: number) => {
    if (!bookingId) return;

    const user_ids = data?.authorized_people?.map((item) => item.id) || [];
    createTracking({
      booking_id: Number(bookingId),
      authorized_people: user_ids.filter((item) => item !== id),
    });

    authroizedPerson({
      sender: profile.id,
      receiver: id,
      booking_id: +bookingId,
      content: `You are removed in the authorized of ${webUrl}/tracking/${bookingId}`,
    });
  };

  return (
    <div className="md:col-span-2 grid md:grid-cols-2 gap-3 border border-border2 rounded-20 bg-headerBg p-4">
      {data?.authorized_people?.map((item, key) => (
        <UserItem
          key={key}
          avatar={item.avatar ? `${s3Url}${item.avatar}` : "/imgs/default-avatar.png"}
          name={`${item.first_name} ${item.last_name}`}
          email={item.email_address}
          onRemove={() => onRemove(item.id)}
        />
      ))}
    </div>
  );
};

export const TrackingAuthorized = () => {
  return (
    <>
      <Typography tag="h4" className="mb-6">
        Authorized People To See Your Location
      </Typography>
      <div className="grid lg:grid-cols-3 gap-5 pb-8">
        <AuthorizeForm />
        <ListUser />
      </div>
    </>
  );
};
