import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "../atoms";
import { HTextArea, HTextField } from "../hookform";
import { useSendFeedbackMutation } from "@app/store/services/feedback";
import { SendFeedbackPayload } from "@app/store/services/feedback/types";

export const FeedbackForm = () => {
  const { control, handleSubmit, reset } = useForm<SendFeedbackPayload>();
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();

  const onSubmit: SubmitHandler<SendFeedbackPayload> = (payload) => {
    sendFeedback(payload)
      .unwrap()
      .then(() => reset({ title: "", content: "" }))
      .catch(() => {});
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid md:grid-cols-2 gap-5 place-items-center"
    >
      <div className="md:block hidden">
        <img src="/imgs/feedback.jpg" alt="feedback" className="w-full" />
      </div>
      <div className="w-full">
        <HTextField
          variant="outlined-black"
          border="full"
          label="What is your feedback about?"
          placeholder="Enter your feedback here"
          className="mb-4"
          control={control}
          name="title"
        />
        <HTextArea
          label="Type Details"
          subtitle="(500 words max)"
          border="circle"
          variant="outlined"
          placeholder="Enter detail here"
          control={control}
          name="content"
        />
        <Button type="submit" variant="primary" layout="rounded" isLoading={isLoading}>
          Submit
        </Button>
      </div>
    </form>
  );
};
