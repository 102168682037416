import { ReactNode, DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import { Typography } from "../atoms";

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  label?: string;
  startIcon?: ReactNode;
  color?: "primary" | "blank";
  labelClass?: string;
  onClick?: () => void;
};

export const RowSelection = ({ label, startIcon, labelClass, onClick, ...props }: Props) => {
  return (
    <button
      {...props}
      className="flex flex-row justify-between items-center w-full py-4"
      onClick={onClick}
    >
      <div className="inline-flex flex-row justify-between items-center">
        {startIcon}
        {label && <Typography className={clsx("ml-2", labelClass)}>{label}</Typography>}
      </div>
    </button>
  );
};
