import { createApi } from "@reduxjs/toolkit/query/react";
import type { CommonResponse } from "../type";
import { openInform } from "../../reducers/inform";
import fetchBaseQuery from "../../base-query";

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    uploadMedias: builder.mutation<string[], File[]>({
      query(files: File[]) {
        const body = new FormData();
        files.forEach((file) => {
          body.append("files", file);
        });
        return {
          url: `media/upload`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body,
          formData: true,
        };
      },
      transformResponse: (response: CommonResponse<string[]>) => response.data,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(
            openInform({ show: true, type: "error", message: "The max file size is 5 mb." }),
          );
        }
      },
    }),
  }),
});

export const { useUploadMediasMutation } = mediaApi;
