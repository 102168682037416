import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { User } from "@app/types/user";
import type { SigninResponse } from "../services/auth/type";
import { Role } from "@app/enums";

export interface AuthState {
  profile: User;
  token?: string | null;
}

const initialState: AuthState = {
  profile: {
    dob: "",
    email_address: "",
    first_name: "",
    gender: "",
    id: 0,
    last_name: "",
    phone: "",
    phone_code: "",
    identify_card: [],
    identity_verified: false,
    is_active: false,
    is_deleted: false,
    isPassword: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<SigninResponse>) => {
      state.token = action.payload.token;
      state.profile = action.payload;
    },
    logout: (state) => {
      state.profile = initialState.profile;
      state.token = null;
    },
    setIsPassword: (state, action: PayloadAction<boolean>) => {
      state.profile.isPassword = action.payload;
    },
    updateProfile: (state, action: PayloadAction<Partial<User>>) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLogin, logout, updateProfile, setIsPassword } = authSlice.actions;

export default authSlice.reducer;
