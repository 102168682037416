import { ReactNode, DetailedHTMLProps, ButtonHTMLAttributes, AriaAttributes } from "react";
import clsx from "clsx";
import { Spinner } from "./Spinner";

interface Props
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    AriaAttributes {
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "link"
    | "rounded"
    | "outline-primary"
    | "outline-shadow-primary"
    | "slide"
    | "blue"
    | "outline-border"
    | "outline-white"
    | "tab"
    | "static";
  layout?: "rounded" | "rectangled";
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export const Button = ({
  variant = "default",
  layout = "rounded",
  children,
  className = "",
  isLoading,
  ...props
}: Props) => {
  return (
    <button
      className={clsx(
        "md:text-16 text-14 border relative",
        layout === "rounded" && "rounded-full",
        layout === "rectangled" && "rounded-10",
        variant === "rounded" &&
          "text-white border-transparent md:min-w-120 md:py-10px min-w-100 py-10px font-semibold hover:bg-primary",
        variant === "default" &&
          "text-black border-transparent md:min-w-120 md:py-13 min-w-100 py-10px hover:text-primary",
        variant === "primary" &&
          "bg-primary text-white md:min-w-120 py-10px min-w-100 font-semibold hover:bg-white hover:text-primary border-primary",
        variant === "link" &&
          "text-primary border-transparent min-w-0 py-0 font-semibold hover:text-orange-600",
        variant === "secondary" &&
          "bg-buttonGrey border-transparent text-black md:min-w-120 md:py-2 min-w-100 py-2 px-4",
        variant === "outline-primary" &&
          "bg-white text-primary border-primary hover:text-white hover:bg-primary md:min-w-120 md:py-10px min-w-100 py-10px",
        variant === "outline-white" &&
          "text-white border-white md:min-w-120 md:py-10px min-w-100 py-10px",
        variant === "outline-shadow-primary" &&
          "bg-white text-primary border-white drop-shadow hover:text-white hover:bg-primary md:min-w-120 md:py-10px min-w-100 py-10px",
        variant === "blue" &&
          "bg-blue text-white md:min-w-120 md:py-10px min-w-100 py-1 font-semibold hover:bg-white hover:text-blue border-blue",
        variant === "outline-border" &&
          "bg-white text-blue md:min-w-120 md:py-10px min-w-100 py-1 font-semibold hover:bg-white hover:text-blue border-buttonGrey",
        variant === "tab" &&
          "bg-white text-lightGrey md:min-w-120 md:py-10px min-w-100 py-1 font-medium hover:bg-white hover:text-lightGrey border-lightGrey px-4",
        variant === "slide" && "md:min-w-120 md:py-10px min-w-100 py-1",
        props.disabled && "!bg-lightGrey !border-lightGrey",
        className,
      )}
      {...props}
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {children}
    </button>
  );
};

// interface IconButtonProps
//   extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
//     AriaAttributes {
//   variant?: "default" | "primary";
//   children: ReactNode;
//   className?: string;
// }

export const IconButton = ({
  variant = "default",
  children,
  className,
  layout = "rounded",
  ...prop
}: Props) => {
  return (
    <button
      className={clsx(
        layout === "rounded" && "rounded-full",
        layout === "rectangled" && "rounded-10",
        variant === "primary" && "bg-primary p-1",
        className,
      )}
      {...prop}
    >
      {children}
    </button>
  );
};

export interface ChipButtonProps extends Omit<Props, "variant" | "layout"> {
  variant?: "default" | "blank" | "outline-primary" | "outline" | "static" | "slide" | "primary";
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  layout?: "rounded" | "rectangled" | "static";
  size?: "normal" | "big" | "medium";
}

export const ChipButton = ({
  variant = "default",
  children,
  className = "",
  startIcon,
  endIcon,
  layout = "rounded",
  size = "normal",
  ...props
}: ChipButtonProps) => {
  return (
    <button
      className={clsx(
        layout === "rounded" && "rounded-full",
        layout === "rectangled" && "rounded-10",
        size === "big" && "md:min-w-120 md:py-10px min-w-100 py-10px",
        size === "medium" && "md:min-w-120",
        "inline-flex flex-row items-center justify-center",
        variant === "default" &&
          "text-black bg-white md:text-16 text-14 text-left md:p-15 p-2 hover:bg-primary hover:text-white min-w-fit",
        variant === "outline-primary" &&
          "border border-primary text-primary hover:text-white hover:bg-primary",
        variant === "outline" &&
          "border border-timeAgo text-timeAgo hover:text-white hover:bg-primary",
        variant === "slide" && "bg-buttonGrey border-transparent text-black md:py-2 py-2",
        variant === "primary" && "bg-primary border border-primary text-white",
        className,
      )}
      {...props}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
};
