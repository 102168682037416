import { Typography } from "@app/components/atoms";
import { Spinner } from "@app/components/atoms/Spinner";
import { BackButton } from "@app/components/common";
import { useGetNewsQuery } from "@app/store/services/news";
import { useParams } from "react-router-dom";
import { NewsForm } from "@app/components/organisms";

export default function AdminNewsAction() {
  const { id } = useParams<{ id?: string }>();
  const { data, isLoading } = useGetNewsQuery(+(id || ""), {
    skip: !id,
  });

  return (
    <>
      <div className="mb-8">
        <BackButton />
        <Typography tag="h2" className="mt-6">
          News
        </Typography>
      </div>
      {isLoading ? <Spinner /> : <NewsForm defaultValues={data} />}
    </>
  );
}
