import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export type RangeSliderProps = {
  min?: number;
  max?: number;
  maxText?: string;
  minText?: string;
  onChange?: (response: number | number[]) => void;
  value?: number | number[];
};

export const RangeSlider = ({ min = 0, max = 10, onChange, value = [0, 10] }: RangeSliderProps) => {
  const onChangeComplete = (data: number | number[]) => {
    onChange?.(data);
  };
  return (
    <div className="range_container">
      <Slider range defaultValue={value} min={min} max={max} onChangeComplete={onChangeComplete} />
    </div>
  );
};
