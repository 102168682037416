import { Link } from "react-router-dom";
import { Typography, Button, IconButton } from "@app/components/atoms";
import { SlideSection } from "@app/components/organisms";

export default function Home() {
  return (
    <>
      <div
        className="bg-center bg-no-repeat bg-cover px-6 py-8 lg:min-h-561 lg:pt-150 md:-mt-105 md:pt-105"
        style={{ backgroundImage: "url(/imgs/banner.jpg)" }}
      >
        <div className="">
          <div className="grid md:grid-cols-2">
            <div>
              <Typography tag="h1" className="text-white mb-4 font-Salsa">
                Create and Explore with BLIST
              </Typography>
              <Typography className="text-white mb-8">
                Your Bucket List Redefined. For those seeking unique experiences and creators
                sharing their passions, we&apos;re your connection. Embark on adventures, and profit
                from your creativity. Your journey to unforgettable moments and connections begins
                here!
              </Typography>
              <Button variant="primary">Get Started</Button>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-4 px-4 md:px-0 md:py-8">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <div className="grid grid-cols-2 md:col-span-6 gap-5">
            <div className="rounded-10 mt-16 relative ">
              <img
                src="/imgs/image-1.jpg"
                className="rounded-10 w-full"
                alt="home 1"
                width={292}
                height={408}
              />
            </div>
            <div className="rounded-10 relative ">
              <img
                src="/imgs/image-2.png"
                className="rounded-10 w-full"
                alt="home 2"
                width={292}
                height={408}
              />
            </div>
          </div>
          <div className="lg:col-span-5 lg:col-start-8 md:col-span-6 md:pt-6 pt-10">
            <Typography tag="h2" className="pb-2">
              What is Blist
            </Typography>
            <Typography className="pb-2">
              Blist revolutionizes the way people discover and participate in unique experiences. By
              enabling individuals to create, share, and monetize their own experiences, Blist
              empowers users to unlock a world of adventure, connection, and personal growth.
            </Typography>
            <Typography className="pb-2">
              With its user-centric approach, Blist transforms the concept of experiential
              engagement, offering a platform where anyone can unleash their creativity
            </Typography>
            <Link to="/about-us" className="inline-flex flex-row items-center text-primary">
              About Us{" "}
              <img
                src="/imgs/about-us.svg"
                width={26}
                height={26}
                alt="about us"
                className="ml-2"
              />
            </Link>
          </div>
        </div>
      </div>
      <SlideSection />
      <div className="px-4 md:px-0">
        <div className=" lg:py-8 py-4">
          <div className="grid lg:grid-cols-5 gap-5">
            <div className="lg:col-span-2 lg:pt-4">
              <Typography tag="h2" className="pb-2">
                BLIST STORY
              </Typography>
              <Typography className="pb-2">{""}</Typography>
              <Typography className="pb-2">{""}</Typography>
            </div>
            <div className="grid md:grid-cols-3 col-span-3 gap-5">
              <div className="col-span-2">
                <div className="rounded-10 relative">
                  <iframe
                    width="100%"
                    height="338"
                    src="https://www.youtube.com/embed/Ivaa6y7ZGHU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className="rounded-10"
                  ></iframe>
                  {/* <a
                    href="#"
                    className="absolute z-10 inset-0 flex flex-row justify-center items-center"
                    style={{ background: "url('/imgs/play.svg') no-repeat center" }}
                  />
                  <img
                    src="/imgs/image-6.png"
                    className="rounded-10 max-h-338 h-full"
                    alt="avatar 6"
                  /> */}
                </div>
              </div>
              <img
                src="/imgs/image-7.png"
                className="rounded-10 md:max-h-338 h-full md:block hidden"
                alt="avatar 7"
              />
            </div>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 gap-10 mt-16 text-center">
            <div className="flex flex-col items-center">
              <div className="rounded-10 relative">
                <img
                  src="/imgs/ultimate.svg"
                  className="rounded-10"
                  alt="ultimate"
                  width={62}
                  height={62}
                />
              </div>
              <Typography tag="h5" className="mt-4 mb-2">
                Ultimate flexibility
              </Typography>
              <Typography className="text-center">
                You’re in control, with free cancellation and payment options to satisfy any plan or
                budget.
              </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="rounded-10 relative">
                <img
                  src="/imgs/memorable.svg"
                  className="rounded-10"
                  alt="memorable"
                  width={80}
                  height={62}
                />
              </div>
              <Typography tag="h5" className="mt-4 mb-2">
                Memorable experiences
              </Typography>
              <Typography className="text-center">
                Browse and book tours and activities so incredible, you’ll want to tell your
                friends.
              </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="rounded-10 relative">
                <img
                  src="/imgs/quality.svg"
                  className="rounded-10"
                  alt="memorable"
                  width={62}
                  height={62}
                />
              </div>
              <Typography tag="h5" className="mt-4 mb-2">
                Quality at our core
              </Typography>
              <Typography className="text-center">
                High quality standards. Millions of reviews. A Tripadvisor company.
              </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="rounded-10 relative">
                <img
                  src="/imgs/support.svg"
                  className="rounded-10"
                  alt="memorable"
                  width={62}
                  height={62}
                />
              </div>
              <Typography tag="h5" className="mt-4 mb-2">
                Award-winning support
              </Typography>
              <Typography className="text-center">
                New price? New plan? No problem. We’re here to help, 24/7.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-bottom bg-no-repeat bg-cover md:px-0 px-4 md:pt-12 pt-8 md-10 bg-footerBg"
        style={{ backgroundImage: "url(/imgs/bg-footer.png)" }}
      >
        <div className=" flex flex-col items-center">
          <Typography tag="h2" className="md:mb-6 mb-2">
            Download BLIST APP
          </Typography>
          <div className="bg-no-repeat md:vector-background bg-none text-center bg-left-top-25 md:px-32 bg-none">
            <div className="flex flex-row items-center md:mb-6 mb-2 md:px-0 px-16">
              <IconButton className="mx-2">
                <img
                  src="/imgs/ios-download.png"
                  width={232}
                  height={74}
                  alt="ios dowload"
                  className="rounded-full mr-2"
                />
              </IconButton>
              <IconButton className="mx-2">
                <img
                  src="/imgs/google-download.png"
                  width={232}
                  height={74}
                  alt="ios dowload"
                  className="rounded-full"
                />
              </IconButton>
            </div>
            <img
              src="/imgs/phone.png"
              alt="phone"
              className="w-4/6 md:w-auto mx-auto"
              width={586}
              height={465}
            />
          </div>
        </div>
      </div>
    </>
  );
}
