import { Typography } from "@app/components/atoms";
import {
  DoughnutChart,
  LineChart,
  AreaChart,
  DemographicData,
  AnalyticsSlide,
  ExperienceRating,
} from "@app/components/organisms";

export default function Messages() {
  return (
    <div className="px-6">
      <div className=" py-2">
        <Typography tag="h2" className="mb-4">
          Analytics
        </Typography>
        <div className="grid lg:grid-cols-9 md:grid-cols-2 grid-cols-1 gap-4 mb-4">
          <div className="lg:col-span-2">
            <DoughnutChart />
          </div>
          <div className="lg:col-span-3">
            <LineChart />
          </div>
          <div className="lg:col-span-4 md:col-span-2">
            <AreaChart />
          </div>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
          <div className="lg:col-span-2">
            <AnalyticsSlide />
            <DemographicData />
          </div>
          <div>
            <ExperienceRating />
          </div>
        </div>
      </div>
    </div>
  );
}
