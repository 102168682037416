import { Store, configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./reducers/auth";
import inform from "./reducers/inform";
import experience from "./reducers/experience";
import category from "./reducers/category";
import settings from "./reducers/settings";
import favorite from "./reducers/favorite";
import { authApi } from "./services/auth";
import { categoryApi } from "./services/category";
import { experienceApi } from "./services/experience";
import { mediaApi } from "./services/media";
import { userApi } from "./services/user";
import { bookingApi } from "./services/booking";
import { messageApi } from "./services/message";
import { feedbackApi } from "./services/feedback";
import { postApi } from "./services/post";
import { promoteApi } from "./services/promote";
import { apprenticeApi } from "./services/apprentice";
import { reviewApi } from "./services/review";
import { trackingApi } from "./services/tracking";
import { wishlistApi } from "./services/wishlist";
import { newsApi } from "./services/news";
import { favoriteApi } from "./services/favorite";
import { analyticsApi } from "./services/analytics";
import { holidayApi } from "./services/holiday";
import { verifyAccountApi } from "./services/verify-account";
import { stripePriceApi } from "./services/stripe-price";
import { invoiceApi } from "./services/invoice";
import { topicApi } from "./services/topic";
import { categoryTopicApi } from "./services/category-topic";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "favorite"],
};

const RootReducer = combineReducers({
  auth,
  inform,
  experience,
  category,
  settings,
  favorite,
  [authApi.reducerPath]: authApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [experienceApi.reducerPath]: experienceApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [messageApi.reducerPath]: messageApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [promoteApi.reducerPath]: promoteApi.reducer,
  [apprenticeApi.reducerPath]: apprenticeApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [trackingApi.reducerPath]: trackingApi.reducer,
  [wishlistApi.reducerPath]: wishlistApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [favoriteApi.reducerPath]: favoriteApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [holidayApi.reducerPath]: holidayApi.reducer,
  [verifyAccountApi.reducerPath]: verifyAccountApi.reducer,
  [stripePriceApi.reducerPath]: stripePriceApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [topicApi.reducerPath]: topicApi.reducer,
  [categoryTopicApi.reducerPath]: categoryTopicApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store: Store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      categoryApi.middleware,
      experienceApi.middleware,
      mediaApi.middleware,
      userApi.middleware,
      bookingApi.middleware,
      messageApi.middleware,
      feedbackApi.middleware,
      postApi.middleware,
      promoteApi.middleware,
      reviewApi.middleware,
      apprenticeApi.middleware,
      trackingApi.middleware,
      wishlistApi.middleware,
      newsApi.middleware,
      favoriteApi.middleware,
      analyticsApi.middleware,
      holidayApi.middleware,
      verifyAccountApi.middleware,
      stripePriceApi.middleware,
      invoiceApi.middleware,
      topicApi.middleware,
      categoryTopicApi.middleware,
    ),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
