import { useRef, useState } from "react";
import Slider from "react-slick";
import {
  IconButton,
  ChevronCircleRight,
  ChevronCircleLeft,
  PlayFillSvg,
  CloseSquareSvg,
  Modal,
} from "@app/components/atoms";
import { useGetApprenticeQuery } from "@app/store/services/apprentice";
import { useParams } from "react-router-dom";
import { s3Url } from "@app/config";

type ItemProps = {
  src: string;
};

const ProfileVideoItem = ({ src }: ItemProps) => {
  const [isOpen, setOpen] = useState<boolean>();
  const onClick = () => {
    setOpen(true);
  };

  return (
    <div className="px-2 ">
      <Modal show={isOpen} onClose={() => setOpen(false)} isCustom>
        <IconButton className="absolute top-4 right-4" onClick={() => setOpen(false)}>
          <CloseSquareSvg />
        </IconButton>
        <video className="max-w-700 max-h-400 h-full w-full" controls>
          <source src={src} type="video/mp4" />
          <source src={src} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </Modal>
      <div className="relative">
        <div
          className="absolute left-0 right-0 top-0 bottom-0 bg-blue/[.6] flex justify-center items-center cursor-pointer z-10"
          onClick={onClick}
        >
          <PlayFillSvg />
        </div>
        <video src={src} className="h-320 w-full" />
      </div>
    </div>
  );
};

export const ProfileVideoSlide = () => {
  const sliderRef = useRef<Slider>(null);
  const { apprenticeId } = useParams<{ apprenticeId: string }>();
  const { data } = useGetApprenticeQuery(apprenticeId || "", { skip: !apprenticeId });
  if (data?.videos && data?.videos?.length < 4) {
    return (
      <div className="grid grid-cols-4 gap-5">
        {data?.videos.map((item, key) => <ProfileVideoItem src={`${s3Url}${item}`} key={key} />)}
      </div>
    );
  }
  return (
    <div className="flex flex-row items-center justify-between md:mb-6 mb-4 w-full relative">
      <IconButton onClick={() => sliderRef?.current?.slickPrev()} className="absolute z-20">
        <ChevronCircleLeft className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
      <div className="w-full px-10">
        <Slider
          ref={sliderRef}
          dots={false}
          infinite
          speed={500}
          slidesToShow={4}
          slidesToScroll={1}
          arrows={false}
          responsive={[
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {data?.videos.map((item, key) => <ProfileVideoItem src={`${s3Url}${item}`} key={key} />)}
        </Slider>
      </div>
      <IconButton onClick={() => sliderRef?.current?.slickNext()} className="absolute z-20 right-0">
        <ChevronCircleRight className="w-40 h-40 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
      </IconButton>
    </div>
  );
};
