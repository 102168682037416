import { useState } from "react";
import {
  Button,
  CalendarFieldSvg,
  Typography,
  LocationSvg,
  ChipButton,
  SlideButtonLoading,
} from "../atoms";
import { HCheckbox, HRangeSlider, HDatePicker, HGoogleAddress, HTextField } from "../hookform";
import { useForm, SubmitHandler, Control, useController } from "react-hook-form";
import { ExpCreatorType, ExpKind, ExpType } from "@app/enums";
import { useFetchCategoriesQuery } from "@app/store/services/category";
import { includes } from "lodash";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import qs from "qs";

type Props = { onClose: () => void };

const ExpTypeSlider = ({ control }: { control: Control<any> }) => {
  const { data, isLoading } = useFetchCategoriesQuery({
    is_active: true,
  });

  const { field } = useController({
    name: "category_ids",
    control,
  });

  const onClick = (id: number) => {
    if (includes(field?.value, id)) {
      field.onChange(field?.value?.filter((item: number) => item !== id));
    } else {
      field.onChange([...(field.value || []), id]);
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex items-end">
          <Typography tag="h6" className="text-blue">
            Experience Type
          </Typography>
        </div>
      </div>
      <div className="flex flex-row flex-wrap">
        {isLoading ? (
          <SlideButtonLoading />
        ) : (
          data?.map((item, index) => (
            <div key={index} className="mr-4 mb-4">
              <ChipButton
                variant={includes(field.value, item.id) ? "primary" : "slide"}
                className="md:py-2 py-2 px-3"
                onClick={() => onClick(item.id)}
                type="button"
              >
                {item.name}
              </ChipButton>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

type FilterSearchPayload = {
  address: string;
  amount: number[];
  category_ids: number[];
  creator_type: ExpCreatorType;
  end_date: Date;
  kind: ExpKind;
  location: { lat: number; lng: number };
  start_date: Date;
  type: ExpType;
};

export const FilterSearch = ({ onClose }: Props) => {
  const { handleSubmit, control } = useForm<FilterSearchPayload>();
  const [, setSearchParams] = useSearchParams();

  const onSubmit: SubmitHandler<FilterSearchPayload> = (data) => {
    setSearchParams(
      qs.stringify({
        min_amount: data.amount[0],
        max_amount: data.amount[1],
        category_ids: data.category_ids,
        creator_type: data.creator_type,
        end_date: data.end_date && moment(data.end_date).format("YYYY-MM-DD"),
        kind: data.kind,
        lat: data.location?.lat,
        lng: data.location?.lng,
        start_date: data.start_date && moment(data.start_date).format("YYYY-MM-DD"),
        type: data.type,
      }),
    );
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-6">
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue">
          Kind
        </Typography>
        <div className="flex flex-row items-center mt-4">
          <HCheckbox
            className="mr-20"
            label="Online"
            name="kind"
            value={ExpKind.ONLINE}
            control={control}
            isMulti
          />
          <HCheckbox
            className="mr-20"
            label="In-person"
            name="kind"
            value={ExpKind.INPERSION}
            control={control}
            isMulti
          />
          {/* <HCheckbox
            className="mr-20"
            label="VR"
            name="kind"
            value={ExpKind.VR}
            control={control}
          />
          <HCheckbox label="Metaverse" name="kind" value={ExpKind.METAVERSE} control={control} /> */}
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue">
          Type
        </Typography>
        <div className="flex flex-row items-center mt-4">
          <HCheckbox
            className="mr-20"
            label="Private"
            name="type"
            value={ExpType.PRIVATE}
            control={control}
            isMulti
          />
          <HCheckbox
            className="mr-20"
            label="Group"
            name="type"
            value={ExpType.GROUP}
            control={control}
            isMulti
          />
          <HCheckbox label="Both" name="type" value={ExpType.BOTH} control={control} isMulti />
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue">
          Creator Type
        </Typography>
        <div className="flex flex-row items-center justify-between mt-4">
          <HCheckbox
            label="Individual Creators"
            name="creator_type"
            control={control}
            value={ExpCreatorType.INDIVIDUAL}
            isMulti
          />
          <HCheckbox
            label="Venues"
            name="creator_type"
            control={control}
            value={ExpCreatorType.COLLABORATING}
            isMulti
          />
        </div>
      </div>
      <div className="border-t border-border2 py-8 px-8">
        <Typography tag="h6" className="text-blue">
          Price
        </Typography>
        <div className="mb-12 ">
          <HRangeSlider
            minText="Free"
            maxText="$1000+"
            min={0}
            max={100}
            name="amount"
            control={control}
            prefix="$"
          />
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <Typography tag="h6" className="text-blue mb-4">
          Date
        </Typography>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <Typography className="text-blue">From</Typography>
            <HDatePicker
              layout="rounded"
              variant="filter"
              endIcon={<CalendarFieldSvg />}
              control={control}
              name="start_date"
            />
          </div>
          <div>
            <Typography className="text-blue">To</Typography>
            <HDatePicker
              layout="rounded"
              variant="filter"
              endIcon={<CalendarFieldSvg />}
              control={control}
              name="end_date"
            />
          </div>
        </div>
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <HGoogleAddress
          label="Location"
          variant="outlined-black"
          border="full"
          name="location"
          types={["(cities)"]}
          placeholder="Enter creator ID here"
          endIcon={<LocationSvg />}
          control={control}
        />
      </div>
      <div className="border-t border-border2 py-6 px-8">
        <ExpTypeSlider control={control} />
      </div>
      <div className="px-8">
        <Button type="submit" variant="primary" className="w-full">
          Apply
        </Button>
      </div>
    </form>
  );
};

type FilterOtherForm = {
  creator_type: ExpCreatorType;
  user_id: number;
  title: string;
  location: { lat: number; lng: number };
};

export const FilterOther = ({ onClose }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const { handleSubmit, control } = useForm<FilterOtherForm>();

  const onSubmit: SubmitHandler<FilterOtherForm> = ({ location, ...data }) => {
    setSearchParams(
      qs.stringify({
        ...data,
        lat: location.lat,
        lng: location.lng,
      }),
    );
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-8">
      <div className="flex flex-row items-center justify-between">
        <HCheckbox
          label="Individual Creators"
          name="creator_type"
          control={control}
          value={ExpCreatorType.INDIVIDUAL}
        />
        <HCheckbox
          label="Venues"
          name="creator_type"
          control={control}
          value={ExpCreatorType.COLLABORATING}
        />
      </div>
      <HTextField
        variant="outlined-black"
        border="full"
        name="user_id"
        placeholder="Enter creator ID here"
        className="mt-8 mb-6"
        customLabel={
          <Typography className="text-blue mb-4" isColor>
            Search By Creator ID
          </Typography>
        }
        control={control}
      />
      <HTextField
        variant="outlined-black"
        border="full"
        name="title"
        placeholder="Enter keyword here"
        className="mb-6"
        customLabel={
          <Typography className="text-blue mb-4" isColor>
            Search By Keyword
          </Typography>
        }
        control={control}
      />
      <HGoogleAddress
        variant="outlined-black"
        border="full"
        name="location"
        types={["(cities)"]}
        placeholder="Enter creator ID here"
        className="mb-8"
        endIcon={<LocationSvg />}
        customLabel={
          <Typography className="text-blue mb-4" isColor>
            Add Location
          </Typography>
        }
        control={control}
      />
      <Button type="submit" variant="primary" className="w-full">
        Apply
      </Button>
    </form>
  );
};

export const ExperienceFilter = ({ onClose }: Props) => {
  const [isBasic, setIsBasic] = useState<boolean>(true);
  return (
    <div>
      <div className="py-4 px-8">
        <Button
          variant={isBasic ? "blue" : "outline-border"}
          className="mr-4"
          onClick={() => setIsBasic(true)}
        >
          Filter Search
        </Button>
        <Button variant={!isBasic ? "blue" : "outline-border"} onClick={() => setIsBasic(false)}>
          Other
        </Button>
      </div>
      {isBasic ? <FilterSearch onClose={onClose} /> : <FilterOther onClose={onClose} />}
    </div>
  );
};
