import { Button, Typography } from "../atoms";
import { upperFirst } from "lodash";
import { Booking } from "@app/types";
import { s3Url } from "@app/config";
import { useUpdateBookingMutation } from "@app/store/services/booking";
import { BookingStatus } from "@app/enums";
import moment from "moment";
import { useInform } from "@app/store/hooks";

type Props = {
  item: Booking;
};

export const InquiryRequestItem = ({ item }: Props) => {
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();
  const { openInform } = useInform();

  const onClick = (status: BookingStatus) => {
    updateBooking({
      id: item.id,
      status,
    })
      .unwrap()
      .then(() => {
        openInform({ show: true, type: "success", message: "Confirmed Request" });
      })
      .catch();
  };

  const nights = 1;

  return (
    <div>
      <div className="flex md:flex-row flex-col justify-between items-center mb-4">
        <div className="flex md:flex-row flex-col items-center md:mb-0 mb-3">
          <div className="mr-2">
            <img
              src={
                item.customer.avatar
                  ? `${s3Url}${item.customer.avatar}`
                  : "/imgs/default-avatar.png"
              }
              className="w-60 h-60"
              alt={item.customer.first_name}
            />
          </div>
          <Typography tag="h5" className="!font-inter !font-normal">
            <span className="text-primary">{upperFirst(item.status)}</span> -{" "}
            {item.experience.title}
          </Typography>
        </div>
        <Typography tag="body1" className="text-blue/[.6]">
          {moment(item?.date).format("MMM DD")} -{" "}
          {Number(item.adults) + Number(item.children) + Number(item.infant)} Guest -
          <span className="text-blue/[.9] font-bold">
            ${Number(item?.experience.amount || 0) * Number(nights)}
          </span>
        </Typography>
      </div>
      {item?.notes?.map((it, key) => (
        <Typography key={key} tag="h5" className="!font-inter !font-normal mb-5">
          {it}
        </Typography>
      ))}

      {item.status === BookingStatus.COMPLETED_PAYMENT && (
        <div>
          <Button
            type="button"
            variant="primary"
            layout="rectangled"
            className="md:mr-3 mr-1"
            isLoading={isLoading}
            onClick={() => onClick(BookingStatus.APPROVED)}
          >
            Approve
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            layout="rectangled"
            className="md:mr-3 mr-1"
            isLoading={isLoading}
            onClick={() => onClick(BookingStatus.DECLINED)}
          >
            Decline
          </Button>
          <Button
            onClick={() => (window.location.href = `/messages?userId=${item.customer_id}`)}
            type="button"
            variant="outline-primary"
            layout="rectangled"
            isLoading={isLoading}
          >
            Respond
          </Button>
        </div>
      )}
    </div>
  );
};
