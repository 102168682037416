import { useMemo } from "react";
import { Label, Typography } from "../atoms";
import { Control, useController, useFormContext } from "react-hook-form";
import moment from "moment";
import clsx from "clsx";

type TimeSlotProps = {
  control: Control<any>;
  name: string;
  availabilityTime: string[];
};

export const HTimeSlot = ({ control, name, availabilityTime }: TimeSlotProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { watch } = useFormContext();
  const date: Date = watch("date");

  const timeSlots = useMemo(() => {
    if (!date) return [];
    return availabilityTime
      .map((item) => {
        const splitItem = item.split(":");
        const curMoment = moment(date)
          .set("hour", Number(splitItem[0]))
          .set("minute", Number(splitItem[1]));

        if (curMoment.isBefore(moment())) {
          return null;
        }

        return curMoment;
      })
      .filter((item) => !!item);
  }, [availabilityTime, date]);

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        {timeSlots.map((item, key) => (
          <div
            key={key}
            className={clsx(
              "border border-solid border-[rgba(51, 51, 50, 0.1)] mb-4 mr-4 py-4 px-4 rounded-10 cursor-pointer",
              item?.format("HH:mm") === field.value ? "bg-primary text-white" : "bg-white",
            )}
            onClick={() => field.onChange(item?.format("HH:mm"))}
          >
            <Typography tag="h6">{item?.format("hh:mm a")}</Typography>
          </div>
        ))}
      </div>
      {error && <Label variant="error">{error?.message}</Label>}
    </div>
  );
};
