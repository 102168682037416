import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../";
import { logout, AuthState, setLogin, updateProfile, setIsPassword } from "../reducers/auth";
import { SigninResponse } from "../services/auth/type";
import { User } from "@app/types";

interface AuthProps extends AuthState {
  logout: () => void;
  isLoggedIn: boolean;
  setLogin: () => void;
  updateProfile: (data: Partial<User>) => void;
  setIsPassword: (data: boolean) => void;
}

export const useAuth = (): AuthProps => {
  const allState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  return {
    ...allState,
    isLoggedIn: !!allState.token,
    logout: () => dispatch(logout()),
    setLogin: (data: SigninResponse) => dispatch(setLogin(data)),
    updateProfile: (data: Partial<User>) => dispatch(updateProfile(data)),
    setIsPassword: (data: boolean) => dispatch(setIsPassword(data)),
  };
};
