export const limitItem = 10;
export const defaultLocation = { lat: 37.09024, lng: -95.712891 };
export const defaultRadius = 50000;
export const creatorMenu = [
  { text: "Dashboard", link: "/creator-dashboard" },
  { text: "Membership", link: "/account/membership" },
  { text: "Calendar", link: "/creator/calendar" },
  { text: "Analytics", link: "/analytics" },
  { text: "Request Inquiries", link: "/request-inquiries" },
  { text: "Help", link: "/get-help" },
  { text: "About Us", link: "/about-us" },
];

export const userMenu = [
  { text: "Profile", link: "/account/profile" },
  { text: "Help", link: "/get-help" },
  { text: "About Us", link: "/about-us" },
  { text: "Your Favorite", link: "/favorite" },
  { text: "Calendar", link: "/user/calendar" },
];
