import { Label } from "./Label";
import { forwardRef, LegacyRef, ReactNode } from "react";
import clsx from "clsx";
import { InputMask, MaskProps } from "@react-input/mask";

export type MaskInputProps = MaskProps & {
  placeHolder?: string;
  variant?: "static" | "outlined" | "filled" | "filter" | "outlined-black" | "none";
  layout?: "rounded" | "static" | "rectangled";
  layoutType?: "row" | "column";
  error?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  label?: string;
  disabled?: boolean;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  inputClass?: string;
  selectsRange?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  onChange?: (event: any) => void;
  value?: string;
};

export const MaskInput = forwardRef(
  (
    {
      placeHolder,
      variant,
      startIcon,
      endIcon,
      layout,
      disabled,
      inputClass,
      layoutType,
      label,
      ...props
    }: MaskInputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <div className={clsx(layoutType === "column" ? "" : "flex flex-row items-center")}>
        {label && (
          <Label className={clsx(layoutType === "column" ? "mb-4" : "!mb-0 mr-2")}>{label}</Label>
        )}
        <div className={clsx(layoutType === "column" ? "" : "")}>
          <div
            className={clsx(
              "flex flex-row items-center py-2",
              !startIcon && "pl-2",
              !endIcon && "pr-2",
              layout === "rectangled" && "rounded-10",
              layout === "rounded" && "rounded-full",
              variant === "outlined" && "border border-timeAgo overflow-hidden",
              variant === "static" && "border-t-0 border-l-0 border-r-0 border-timeAgo border-b",
              variant === "filter" && "border border-buttonGrey py-4 px-4",
              variant === "outlined-black" && "border border-lightGrey py-4 px-4",
              inputClass,
            )}
          >
            {startIcon && <div>{startIcon}</div>}
            <InputMask ref={ref as any} {...props} />
            {endIcon}
          </div>
        </div>
      </div>
    );
  },
);
