import { Line } from "react-chartjs-2";
import { Typography, ArrowDownSvg } from "../atoms";
import { useGetLast30DaysQuery } from "@app/store/services/analytics";
import { range, find } from "lodash";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: (value: number) => {
          return "$" + value;
        },
      },
      border: {
        display: false,
      },
    },
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

export const LineChart = () => {
  const { data } = useGetLast30DaysQuery({});

  return (
    <div className="bg-bg3 rounded-10 px-5 py-3">
      <div className="flex flex-row items-center justify-between mb-12">
        <Typography className="text-primary font-semibold">Last 30 days</Typography>
        <div className="flex flex-row items-center justify-between">
          <select>
            <option>0$</option>
          </select>
          <ArrowDownSvg className="px-2 box-content" />
        </div>
      </div>
      <div>
        <Line
          options={options as any}
          data={{
            labels: range(1, 31),
            datasets: [
              {
                label: "Profit",
                data: range(1, 31).map((item) => ({
                  x: item,
                  y: Number(
                    find(data?.data ?? [], (it) => Number(it.group) === item)?.total_amount || 0,
                  ),
                })),
                borderColor: "#F86A27",
                backgroundColor: "#F86A27",
                borderWidth: 1,
                pointBackgroundColor: "#fff",
              },
            ],
          }}
          height={320}
        />
      </div>
    </div>
  );
};
