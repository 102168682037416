import { Conversation } from "@app/types";
import { IconButton, SendSvg, Typography } from "../atoms";
import { ChatContent } from "../molecules";
import { filter, last } from "lodash";
import { HTextField } from "../hookform";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAdminReplyMutation } from "@app/store/services/message";
import { useEffect, useRef, useState } from "react";
import { db } from "@app/utils";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

export const ChatTitle = ({ message }: { message: Conversation }) => {
  const userMessage = filter(message.messages, (item) => !item.isAdmin);
  const lastMessage = last(userMessage);
  const user = message.messages.find((item) => !item.isAdmin);
  return (
    <div className="bg-blue1 px-4 md:py-4 py-2">
      <Typography tag="body1" isColor className="text-blue">
        {user?.name}
      </Typography>
      <Typography tag="h5">{lastMessage?.content || lastMessage?.note}</Typography>
    </div>
  );
};

export const AdminChatArea = ({ id }: { id: string }) => {
  const { control, handleSubmit, reset } = useForm<{ content: string }>();
  const [sendMessage] = useAdminReplyMutation();
  const listRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<Conversation>();

  const getMessage = async () => {
    const docRef = doc(db, "message", id);
    const docSnap = await getDoc(docRef);
    docSnap && setMessage(docSnap.data() as Conversation);
  };

  useEffect(() => {
    listRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "message", id), (doc) => {
      doc && setMessage(doc.data() as Conversation);
    });
    getMessage();

    return () => {
      unsub();
    };
  }, [id]);

  const onSubmit: SubmitHandler<{ content: string }> = (data) => {
    sendMessage({
      id: id,
      isAdmin: true,
      content: data.content,
    })
      .unwrap()
      .finally(() => reset({ content: "" }));
  };

  return (
    <>
      <div className="px-4 pt-4 pb-3 flex flex-col w-full grow admin-scroll overflow-y-scroll h-360">
        {message?.messages.map((item, key) => {
          return <ChatContent key={key} {...item} />;
        })}
        <div ref={listRef} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row">
        <HTextField
          name="content"
          control={control}
          variant="filled"
          border="rectangled"
          placeholder="Type a Message..."
          className="flex-1"
          layout="static"
          autoComplete="off"
        />
        <IconButton type="submit" layout="rectangled" className="bg-primary ml-2 w-40 text-center">
          <SendSvg className="text-white mx-auto" />
        </IconButton>
      </form>
    </>
  );
};
