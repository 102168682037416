import { yupResolver } from "@hookform/resolvers/yup";
import { Gender, CreatorType, Role } from "@app/enums";
import * as yup from "yup";

export const authValidation = yupResolver(
  yup.object({
    email_address: yup.string().required(),
    password: yup.string().required(),
  }),
);

export const signupValidation = yupResolver(
  yup.object({
    email_address: yup.string().required(),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), ""], "Passwords must match"),
    gender: yup.string().required().oneOf([Gender.male, Gender.female]),
    role: yup.string().required().oneOf([Role.creator, Role.user]),
    creator_type: yup.string().oneOf([CreatorType.individualCreator, CreatorType.venue]),
  }),
);

export const signupDefaultvalue = {
  email_address: "",
  password: "",
  confirm_password: "",
  role: Role.user,
  gender: Gender.male,
  creator_type: CreatorType.venue,
};

export const forgotPasswordValidation = yupResolver(
  yup.object({
    email_address: yup.string().required(),
  }),
);

export const resetPasswordValidation = yupResolver(
  yup.object({
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
    confirm_password: yup.string().required(),
  }),
);

export const registerValidation = yupResolver(
  yup.object({
    email_address: yup.string().required(),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
  }),
);

export const changePasswordValidation = yupResolver(
  yup.object({
    current_password: yup.string().nullable(),
    new_password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
    confirm_password: yup.string().required(),
  }),
);
