import { Typography, SlideLoading } from "@app/components/atoms";
import { MapView } from "../common";
import { useGetTrackingQuery } from "@app/store/services/tracking";
import { useParams } from "react-router-dom";
import { Marker } from "@react-google-maps/api";
import { useGetBookingQuery } from "@app/store/services/booking";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@app/utils";

export const TrackingUserBanner = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data, isLoading } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const { data: booking } = useGetBookingQuery(Number(bookingId), { skip: !bookingId });
  const [coords, setCoords] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "tracking", `${bookingId}`), (doc) => {
      setCoords(doc.data() as any);
    });
    return () => unsub();
  }, []);

  if (isLoading) return <SlideLoading />;

  return (
    <div className="mb-6">
      <Typography tag="h2" className="mb-4 text-center">
        Safety Features
      </Typography>
      <div className="h-430 mt-4 relative rounded-20 overflow-hidden">
        <MapView>
          {!!data?.auto_track && (
            <Marker
              icon="/imgs/marker.png"
              position={{ lat: coords?.lat || 0, lng: coords?.lng || 0 }}
            />
          )}
          {booking?.experience.addresses?.map((item, i) => (
            <Marker key={i} icon="/imgs/marker.png" position={{ lat: item.lat, lng: item.lng }} />
          ))}
        </MapView>
      </div>
    </div>
  );
};
