import { useSettings } from "@app/store/hooks";
import { Spinner } from "../atoms";
import { MapView } from "../common";
import { ExperienceMarker } from "../molecules";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import { defaultLocation, defaultRadius } from "@app/config";

type Props = {
  category_id?: number;
};

export const HomeMap = ({ category_id }: Props) => {
  const { current_location } = useSettings();
  const { data, isLoading, isSuccess } = useFetchExperienceQuery(
    {
      category_ids: category_id && category_id !== 1 ? [category_id] : undefined,
    },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <>
      {isLoading && <Spinner />}
      <MapView center={current_location || defaultLocation}>
        {isSuccess &&
          data.data.map((item, key) => (
            <ExperienceMarker
              {...item}
              key={key}
              addresses={item.addresses}
              image={item.photos?.[0]}
            />
          ))}
      </MapView>
    </>
  );
};
