import { Typography } from "@app/components/atoms";
import { Spinner } from "@app/components/atoms/Spinner";
import { BackButton } from "@app/components/common";
import { WishlistForm } from "@app/components/organisms";
import { useGetWishlistQuery } from "@app/store/services/wishlist";
import { useParams } from "react-router-dom";

export default function AdminWishlistAction() {
  const { id } = useParams<{ id?: string }>();
  const { data, isLoading } = useGetWishlistQuery(+(id || ""), {
    skip: !id,
  });

  return (
    <>
      <div className="mb-8">
        <BackButton />
        <Typography tag="h2" className="mt-6">
          Wishlist
        </Typography>
      </div>
      {isLoading ? <Spinner /> : <WishlistForm defaultValues={data} />}
    </>
  );
}
