import { CreateExperienceForm } from "@app/store/services/experience/type";
import { Experience } from "@app/types";
import moment from "moment";

export const convertExpToFormData = (item: Experience): CreateExperienceForm => {
  const {
    category_ids,
    creator_ids,
    title,
    kind,
    type,
    addresses,
    is_willing_to_travel,
    willing_to_travel,
    language_offered,
    is_date,
    start_date,
    end_date,
    duration,
    availability_time,
    available_days,
    summary,
    description,
    atmosphere,
    creator_type,
    creator_presence,
    creator_provide,
    guest_bring,
    clothing_recommendation,
    age_min,
    age_max,
    guest_min,
    guest_max,
    amount,
    pet_allowed,
    handicap_accessible,
    level_expected,
    photos,
    cancellation_policy,
    platform,
  } = item;
  return {
    category_ids: (category_ids || []).map((item) => item.id),
    creator_ids: creator_ids || [],
    title,
    kind,
    type,
    addresses: addresses.map((item) => ({
      street_address: item.street_address,
      zip_code: item.zip_code,
      state: item.state,
      country: item.country,
      city: item.city,
      lat: item.lat,
      lng: item.lng,
      address: `${item.street_address}, ${item.city} ${item.state},${item.country} ${item.zip_code}`,
    })),
    is_willing_to_travel,
    willing_to_travel,
    language_offered,
    is_date,
    start_date: moment(start_date).toDate(),
    end_date: moment(end_date).toDate(),
    duration,
    availability_time: availability_time.map((item) => moment(item, "hh:mm:ss+00").toDate()),
    available_days,
    summary,
    description,
    atmosphere,
    creator_type,
    creator_presence,
    creator_provide,
    guest_bring,
    clothing_recommendation,
    age_min,
    age_max,
    guest_min,
    guest_max,
    amount,
    pet_allowed,
    handicap_accessible,
    level_expected,
    photos,
    cancellation_policy,
    platform,
  };
};
