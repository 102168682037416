import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const createCategoryValidate = yupResolver(
  yup.object({
    name: yup.string().required(),
  }),
);

export const categoryDefaultValue = {
  sliders: [{ content: "" }],
};
