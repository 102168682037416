import { useNavigate } from "react-router-dom";
import {
  Typography,
  IconButton,
  ChevronCircleRight,
  ChevronCircleLeft,
  MsgNotifSvg,
  HeartOutlineSvg,
  ChipButton,
  FilterSvg,
} from "../atoms";
import { useAuth } from "@app/store/hooks";
import { useUpdateUserMutation } from "@app/store/services/user";
import { Role } from "@app/enums";

type SlideTitleProps = {
  title?: string;
  onNext?: () => void;
  onPrevious?: () => void;
};

export const SlideTitle = ({ title, onNext, onPrevious }: SlideTitleProps) => {
  return (
    <div className="flex flex-row justify-between items-center mb-4">
      <Typography tag="h2">{title}</Typography>
      <div className="flex mt-2.5">
        <IconButton onClick={onPrevious}>
          <ChevronCircleLeft className="md:w-35 md:h-35 w-25 h-25 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
        </IconButton>
        <IconButton className="md:ml-6 ml-3" onClick={onNext}>
          <ChevronCircleRight className="md:w-35 md:h-35 w-25 h-25 bg-white border-lightWhite hover:bg-primary hover:border-primary hover:text-white" />
        </IconButton>
      </div>
    </div>
  );
};

type SlideTitleFilterProps = SlideTitleProps & {
  onFilter?: () => void;
};

export const SlideTitleFilter = ({ title, onFilter }: SlideTitleFilterProps) => {
  const { profile, isLoggedIn, updateProfile } = useAuth();
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();

  const onUpdateStatus = (role: Role) => {
    updateUser({
      id: profile.id,
      role,
    })
      .unwrap()
      .then((response) => {
        updateProfile(response.data);
        if (role === Role.creator) {
          navigate("/creator-dashboard");
        } else {
          navigate("/");
        }
      })
      .catch(() => {});
  };

  return (
    <div className="flex flex-row justify-between items-center mb-2">
      <div className="md:flex items-end">
        <Typography tag="h2">{title}</Typography>
        <p className="text-blue underline md:pl-2 mb-2 md:ml-2">All categories</p>
      </div>
      {isLoggedIn && (
        <div className="flex flex-row justify-end mt-3">
          {profile.role === Role.creator ? (
            <IconButton
              className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
              onClick={() => onUpdateStatus(Role.user)}
            >
              Create
            </IconButton>
          ) : (
            profile.role === Role.user && (
              <IconButton
                className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
                onClick={() => onUpdateStatus(Role.creator)}
              >
                Experience
              </IconButton>
            )
          )}
        </div>
      )}
      <div className="flex mt-2.5">
        {isLoggedIn && (
          <div className="inline-flex justify-center items-center">
            <IconButton
              className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
              onClick={() => navigate("/messages")}
            >
              <MsgNotifSvg className="mr-2 stroke-primary" /> Inbox
            </IconButton>
            <IconButton
              className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center ml-4"
              onClick={() => navigate("/favorite")}
            >
              <HeartOutlineSvg className="mr-2 fill-primary" /> Favorite
            </IconButton>
          </div>
        )}
        <IconButton onClick={onFilter}>
          <ChipButton
            startIcon={<FilterSvg className="mr-4" />}
            variant="static"
            className="border border-lightGrey ml-4 px-5 py-3 md:py-4"
          >
            Filter
          </ChipButton>
        </IconButton>
      </div>
    </div>
  );
};
