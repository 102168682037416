import { useForm, SubmitHandler } from "react-hook-form";
import { Typography, Button, Lock } from "@app/components/atoms";
import { HTextField } from "@app/components/hookform";
import { resetPasswordValidation } from "@app/validations";
import { useResetPasswordMutation } from "@app/store/services/auth";
import { ResetPasswordPayload } from "@app/store/services/auth/type";
import { useQuery } from "@app/utils";

type FormData = Omit<ResetPasswordPayload, "token">;

export default function ResetPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const { handleSubmit, control } = useForm<FormData>({
    resolver: resetPasswordValidation,
  });
  const query = useQuery();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    resetPassword({ ...data, token: query.get("token") || "" });
  };

  return (
    <div className="flex-1">
      <div className="md:px-0 px-4 my-16">
        <div className="">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="md:w-1/2 p-6 mx-auto border rounded-10 border-black/[.07]"
          >
            <div className="-mx-6 border-b border-black/[.07] text-center pb-6">
              <Typography tag="body1" className="font-bold">
                Reset Password
              </Typography>
            </div>
            <div className="pt-6">
              <HTextField
                name="password"
                type="password"
                control={control}
                startIcon={<Lock className="text-timeAgo" />}
                placeholder="Password"
              />
              <HTextField
                name="confirm_password"
                type="password"
                control={control}
                startIcon={<Lock className="text-timeAgo" />}
                placeholder="Confirm Password"
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              layout="rectangled"
              className="w-full"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
