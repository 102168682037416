import clsx from "clsx";
import { Typography } from "../atoms";
import { webUrl } from "@app/config";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@app/utils";

type Props = {
  title: string;
  link: string;
  markAsRead: boolean;
  id: string;
};
export const NotificationItem = ({ id, title, link, markAsRead }: Props) => {
  const onClick = async () => {
    await updateDoc(doc(db, "alert", id), {
      markAsRead: true,
    });
    window.location.href = `${webUrl}${link}`;
  };

  return (
    <div
      className={clsx(
        "rounded-10 shadow-2 px-4 py-3 relative",
        markAsRead ? "bg-lineBreak" : "bg-grey10",
      )}
      onClick={onClick}
    >
      <Typography tag="body1" className={clsx("mr-4", "text-blue")}>
        {title}
      </Typography>
    </div>
  );
};
