import { ReactElement, cloneElement } from "react";
import Styled from "styled-components";
import {
  HomeSvg,
  LogoutSvg,
  SettingSvg,
  Typography,
  LogoSvg,
  DocumentTextSvg,
  MoneySvg,
  Star2Svg,
  MsgNotifSvg,
  Profile2UserSvg,
  CategoryIconSvg,
} from "../atoms";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@app/store/hooks";
import clsx from "clsx";

const StyleWrapper = Styled.div`
  width: 300px;
  @media (max-width: 992px) {
    width: 50px;
    padding: 5px;

    .menu-text {
      display: none;
    }

    .logo-admin {
      svg {
        width: 40px;
      }
    }
  }
`;

const items = [
  { text: "Home", icon: <HomeSvg />, route: "/admin" },
  {
    text: "Categories Management",
    icon: <CategoryIconSvg className="stroke-white w-24 h-24" />,
    route: "/admin/category",
  },
  {
    text: "User Management",
    icon: <Profile2UserSvg className="stroke-white w-24 h-24" />,
    route: "/admin/user",
  },
  { text: "Experiences", icon: <Star2Svg />, route: "/admin/experiences" },
  { text: "Blist Board", icon: <DocumentTextSvg />, route: "/admin/blist-board" },
  { text: "Payment", icon: <MoneySvg />, route: "/admin/invoice" },
  { text: "Feedback", icon: <MsgNotifSvg className="stroke-white" />, route: "/admin/feedback" },
  { text: "Q&A", icon: <MsgNotifSvg className="stroke-white" />, route: "/admin/topic" },
  { text: "Message", icon: <MsgNotifSvg className="stroke-white" />, route: "/admin/message" },
];

type MenuItemProps = {
  icon: ReactElement;
  text: string;
  onClick: () => void;
  route?: string;
};

const MenuItem = ({ icon, text, onClick, route }: MenuItemProps) => {
  const location = useLocation();

  return (
    <button
      className={clsx(
        "flex lg:flex-row flex-col lg:px-3 py-3 items-center w-full",
        location.pathname === route && "bg-white",
      )}
      onClick={onClick}
    >
      <div>
        {cloneElement(icon, {
          className: location.pathname === route ? "stroke-blue w-25" : "stroke-white w-25",
        })}
      </div>
      <Typography
        className={clsx(
          "ml-5  menu-text",
          location.pathname === route ? "text-blue" : "text-white",
        )}
      >
        {text}
      </Typography>
    </button>
  );
};

export const LeftSideBar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <StyleWrapper className="bg-blue pt-8 pb-4 flex flex-col px-4 overflow-auto">
      <div className="md:flex-1">
        <Link to="/admin" className="logo-admin">
          <LogoSvg width={91} height={47} />
        </Link>
        <div className="lg:pt-12 pt-2">
          {items.map((item) => (
            <MenuItem
              icon={item.icon}
              text={item.text}
              onClick={() => navigate(item.route)}
              route={item.route}
            />
          ))}
        </div>
      </div>
      <div>
        <MenuItem
          icon={<SettingSvg />}
          text="Setting"
          onClick={() => navigate("/admin/#")}
          route="/admin/#"
        />
        <MenuItem icon={<LogoutSvg />} text="Logout" onClick={() => logout()} />
      </div>
    </StyleWrapper>
  );
};
