import { useEffect, useMemo, useState } from "react";
import { Category } from "@app/types";
import {
  LovelySvg,
  Typography,
  Button,
  ArrowLeftSvg,
  ArrowRightSvg,
  CloseSquareSvg,
} from "../atoms";
import { s3Url } from "@app/config";
import { isImage } from "@app/utils";

type Props = Category & {
  onClose: () => void;
};

export const CategoryIntroduce = ({ name, onClose, icon, sliders }: Props) => {
  const [readmore, setMore] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setMore(true);

    return () => {
      setMore(true);
    };
  }, [name]);

  const onChange = (isNext: boolean) => {
    if (isNext) {
      setIndex((prev) => {
        if (prev + 1 === sliders?.length) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    } else {
      setIndex((prev) => {
        if (prev === 0) {
          return (sliders?.length || 1) - 1;
        } else {
          return prev - 1;
        }
      });
    }
  };

  const renderMedia = useMemo(() => {
    if (!sliders?.length) return;
    const media = sliders?.[index].media;
    if (!media) return;

    if (isImage(media)) {
      return (
        <img
          src={`${s3Url}${media}`}
          alt="banner"
          className="rounded-20 md:h-320 h-170px object-cover object-center"
          width="100%"
        />
      );
    }
    return (
      <video
        src={`${s3Url}${media}`}
        autoPlay
        controls
        className={"w-full rounded-20 md:h-430 h-200"}
      />
    );
  }, [index, sliders]);

  return (
    <div className="rounded-20 grid md:gap-7 gap-4 md:grid-cols-2 place-items-center border border-border2 bg-headerBg p-3 relative">
      {!!sliders?.length ? (
        <>
          {renderMedia}
          <div className="w-full">
            {icon ? (
              <img src={`${s3Url}${icon}`} alt={name} className="h-50 image-primary" />
            ) : (
              <LovelySvg width="50" className="stroke-primary" />
            )}
            <Typography tag="h2" className="mb-6">
              {name}
            </Typography>
            <Typography className="text-lightGrey md:mb-4 mb-2" isColor>
              {readmore ? sliders[index].content?.slice(0, 200) : sliders[index].content}
            </Typography>
            <Button variant="primary" onClick={() => setMore(false)}>
              Learn More
            </Button>
          </div>
          <div className="absolute flex items-center right-4 top-4">
            <ArrowLeftSvg
              onClick={() => onChange(false)}
              className="fill-black stroke-black w-19 h-19 cursor-pointer"
            />
            <ArrowRightSvg
              onClick={() => onChange(true)}
              className="fill-black stroke-black w-19 h-19 cursor-pointer"
            />
            <CloseSquareSvg onClick={onClose} className="cursor-pointer" />
          </div>
        </>
      ) : (
        <div className="w-full">
          <Typography tag="h2" className="mb-6">
            {name}
          </Typography>
        </div>
      )}
    </div>
  );
};
