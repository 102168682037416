import { Typography, ListItemLoading } from "@app/components/atoms";
import { ExperienceItem } from "@app/components/molecules";
import { useFetchFavoriteQuery } from "@app/store/services/favorite";

export default function Favorite() {
  const { data, isLoading } = useFetchFavoriteQuery({});
  return (
    <div className="px-4 md:px-0 md:pt-16">
      <div className=" pb-8">
        <div className="md:mb-8 mb-6">
          <Typography tag="h2" className="text-center">
            Your Favorite List
          </Typography>
        </div>
        {isLoading ? (
          <ListItemLoading />
        ) : (
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
            {data?.data.map((item, key) => (
              <ExperienceItem
                title={item.experience.title}
                content={item.experience.summary}
                url={item?.experience?.photos?.length ? item.experience.photos[0] : undefined}
                key={key}
                id={item.experience.id}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
