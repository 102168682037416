import { Controller, Control } from "react-hook-form";
import { IncreaseField, Label } from "../atoms";
import type { TextfieldProps } from "../atoms";

interface Props extends TextfieldProps {
  control: Control<any>;
  name: string;
}

export const HIncreaseField = ({ control, name, defaultValue, ...props }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
        <div>
          <IncreaseField
            onDecrease={() => value > 0 && field.onChange(value - 1)}
            onIncrease={() => field.onChange(value + 1)}
            value={value}
            {...props}
          />
          {error?.message && <Label variant="error">{error?.message}</Label>}
        </div>
      )}
    />
  );
};
