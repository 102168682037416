import { Typography } from "@app/components/atoms";

export default function PrivacyPolicy() {
  return (
    <div className="px-4 md:px-0">
      <div className="container-layout">
        <div className=" lg:py-8 py-4">
          <Typography tag="h2" className="md:pb-8 pb-2">
            Privacy Policy
          </Typography>
          <Typography className="pb-2">
            This Privacy Policy describes Our policies and procedures on the collection, use and
            disclosure of Your information when You use the Service and tells You about Your privacy
            rights and how the law protects You. We use Your Personal data to provide and improve
            the Service. By using the Service, You agree to the collection and use of information in
            accordance with this Privacy Policy.
          </Typography>
          <Typography className="pb-2" tag="h4">
            INTERPRETATION AND DEFINITIONS
          </Typography>
          <Typography className="pb-2" tag="h3">
            INTERPRETATION
          </Typography>
          <Typography className="pb-2">
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.
          </Typography>
          <Typography className="pb-2" tag="h3">
            DEFINITIONS
          </Typography>
          <Typography className="pb-2">
            For the purposes of this Privacy Policy: Account means a unique account created for You
            to access our Service or parts of our Service. Affiliate means an entity that controls,
            is controlled by or is under common control with a party, where “control” means
            ownership of 50% or more of the shares, equity interest or other securities entitled to
            vote for election of directors or other managing authority. Application means the
            software program provided by the Company downloaded by You on any electronic device,
            named INIT Company (referred to as either “the Company”, “We”, “Us” or “Our” in this
            Agreement) refers to INIT. Country refers to: Netherlands Device means any device that
            can access the Service such as a computer, a cellphone or a digital tablet. Personal
            Data is any information that relates to an identified or identifiable individual.
            Service refers to the Application. Service Provider means any natural or legal person
            who processes the data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to provide the Service on
            behalf of the Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. Usage Data refers to data collected
            automatically, either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit). You means the
            individual accessing or using the Service, or the company, or other legal entity on
            behalf of which such individual is accessing or using the Service, as applicable.
          </Typography>
          <Typography className="pb-2" tag="h4">
            COLLECTING AND USING YOUR PERSONAL DATA
          </Typography>
          <Typography className="pb-2" tag="h3">
            TYPES OF DATA COLLECTED
          </Typography>
          <Typography className="pb-2" tag="h5">
            PERSONAL DATA
          </Typography>
          <Typography className="pb-2" tag="h3">
            While using Our Service, We may ask You to provide Us with certain personally
            identifiable information that can be used to contact or identify You. Personally
            identifiable information may include, but is not limited to: Email address First name
            and last name Usage Data USAGE DATA Usage Data is collected automatically when using the
            Service. Usage Data may include information such as Your Device’s Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages of our Service that
            You visit, the time and date of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data. When You access the Service by or through a
            mobile device, We may collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of mobile Internet
            browser You use, unique device identifiers and other diagnostic data. We may also
            collect information that Your browser sends whenever You visit our Service or when You
            access the Service by or through a mobile device.
          </Typography>
          <Typography className="pb-2" tag="h3">
            USE OF YOUR PERSONAL DATA
          </Typography>
          The Company may use Personal Data for the following purposes:
          <ul>
            <li>
              To provide and maintain our Service, including to monitor the usage of our Service.
            </li>
            <li>
              To manage Your Account: to manage Your registration as a user of the Service. The
              Personal Data You provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </li>
            <li>
              For the performance of a contract: the development, compliance and undertaking of the
              purchase contract for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </li>
            <li>
              To contact You: To contact You by email, telephone calls, SMS, or other equivalent
              forms of electronic communication, such as a mobile application’s push notifications
              regarding updates or informative communications related to the functionalities,
              products or contracted services, including the security updates, when necessary or
              reasonable for their implementation.
            </li>
            <li>
              To provide You with news, special offers and general information about other goods,
              services and events which we offer that are similar to those that you have already
              purchased or enquired about unless You have opted not to receive such information.
            </li>
            <li>To manage Your requests: To attend and manage Your requests to Us.</li>
            <li>
              For business transfers: We may use Your information to evaluate or conduct a merger,
              divestiture, restructuring, reorganization, dissolution, or other sale or transfer of
              some or all of Our assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by Us about our
              Service users is among the assets transferred.
            </li>
            <li>
              For other purposes: We may use Your information for other purposes, such as data
              analysis, identifying usage trends, determining the effectiveness of our promotional
              campaigns and to evaluate and improve our Service, products, services, marketing and
              your experience.
            </li>
          </ul>
          We may share Your personal information in the following situations:
          <ul>
            <li>
              With Service Providers: We may share Your personal information with Service Providers
              to monitor and analyze the use of our Service, to contact You.
            </li>

            <li>
              For business transfers: We may share or transfer Your personal information in
              connection with, or during negotiations of, any merger, sale of Company assets,
              financing, or acquisition of all or a portion of Our business to another company.
            </li>
            <li>
              With Affiliates: We may share Your information with Our affiliates, in which case we
              will require those affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners or other companies
              that We control or that are under common control with Us.
            </li>
            <li>
              With business partners: We may share Your information with Our business partners to
              offer You certain products, services or promotions.
            </li>
            <li>
              With other users: when You share personal information or otherwise interact in the
              public areas with other users, such information may be viewed by all users and may be
              publicly distributed outside.
            </li>
            <li>
              With Your consent: We may disclose Your personal information for any other purpose
              with Your consent.
            </li>
          </ul>
          <Typography className="pb-2" tag="h3">
            RETENTION OF YOUR PERSONAL DATA
          </Typography>
          <Typography className="pb-2">
            The Company will retain Your Personal Data only for as long as is necessary for the
            purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
            the extent necessary to comply with our legal obligations (for example, if we are
            required to retain your data to comply with applicable laws), resolve disputes, and
            enforce our legal agreements and policies.
          </Typography>
          <Typography className="pb-2">
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this data is used to
            strengthen the security or to improve the functionality of Our Service, or We are
            legally obligated to retain this data for longer time periods.
          </Typography>
          <Typography className="pb-2" tag="h3">
            TRANSFER OF YOUR PERSONAL DATA
          </Typography>
          <Typography className="pb-2">
            Your information, including Personal Data, is processed at the Company’s operating
            offices and in any other places where the parties involved in the processing are
            located. It means that this information may be transferred to — and maintained on —
            computers located outside of Your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from Your
            jurisdiction.
          </Typography>
          <Typography className="pb-2">
            Your consent to this Privacy Policy followed by Your submission of such information
            represents Your agreement to that transfer.
          </Typography>
          <Typography className="pb-2">
            The Company will take all steps reasonably necessary to ensure that Your data is treated
            securely and in accordance with this Privacy Policy and no transfer of Your Personal
            Data will take place to an organization or a country unless there are adequate controls
            in place including the security of Your data and other personal information.
          </Typography>
          <Typography className="pb-2" tag="h4">
            DISCLOSURE OF YOUR PERSONAL DATA
          </Typography>
          <Typography className="pb-2" tag="h3">
            BUSINESS TRANSACTIONS
          </Typography>
          <Typography className="pb-2">
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
            may be transferred. We will provide notice before Your Personal Data is transferred and
            becomes subject to a different Privacy Policy.
          </Typography>
          <Typography className="pb-2" tag="h3">
            LAW ENFORCEMENT
          </Typography>
          <Typography className="pb-2">
            Under certain circumstances, the Company may be required to disclose Your Personal Data
            if required to do so by law or in response to valid requests by public authorities (e.g.
            a court or a government agency).
          </Typography>
          <Typography className="pb-2" tag="h3">
            OTHER LEGAL REQUIREMENTS
          </Typography>
          <Typography className="pb-2">
            The Company may disclose Your Personal Data in the good faith belief that such action is
            necessary to:
          </Typography>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
            <li>SECURITY OF YOUR PERSONAL DATA</li>
            <li>
              The security of Your Personal Data is important to Us, but remember that no method of
              transmission over the Internet, or method of electronic storage is 100% secure. While
              We strive to use commercially acceptable means to protect Your Personal Data, We
              cannot guarantee its absolute security.
            </li>
          </ul>
          <Typography className="pb-2" tag="h4">
            CHILDREN’S PRIVACY
          </Typography>
          <Typography className="pb-2">
            Our Service does not address anyone under the age of 13. We do not knowingly collect
            personally identifiable information from anyone under the age of 13. If You are a parent
            or guardian and You are aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data from anyone under
            the age of 13 without verification of parental consent, We take steps to remove that
            information from Our servers.
          </Typography>
          <Typography className="pb-2">
            If We need to rely on consent as a legal basis for processing Your information and Your
            country requires consent from a parent, We may require Your parent’s consent before We
            collect and use that information.
          </Typography>
          <Typography className="pb-2" tag="h4">
            LINKS TO OTHER WEBSITES
          </Typography>
          <Typography className="pb-2">
            Our Service may contain links to other websites that are not operated by Us. If You
            click on a third party link, You will be directed to that third party’s site. We
            strongly advise You to review the Privacy Policy of every site You visit.
          </Typography>
          <Typography className="pb-2">
            We have no control over and assume no responsibility for the content, privacy policies
            or practices of any third party sites or services.
          </Typography>
          <Typography className="pb-2" tag="h4">
            CHANGES TO THIS PRIVACY POLICY
          </Typography>
          <Typography className="pb-2">
            We may update Our Privacy Policy from time to time. We will notify You of any changes by
            posting the new Privacy Policy on this page.
          </Typography>
          <Typography className="pb-2">
            We will let You know via email and/or a prominent notice on Our Service, prior to the
            change becoming effective and update the “Last updated” date at the top of this Privacy
            Policy.
          </Typography>
          <Typography className="pb-2">
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </Typography>
        </div>
      </div>
    </div>
  );
}
