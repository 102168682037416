import { ReactElement, cloneElement } from "react";
import { IDCardSvg, Integration, PasswordSvg, UserSvg, FaFileInvoiceSvg } from "../atoms";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";

const MenuLink = ({
  isActive,
  text,
  icon,
  to,
}: {
  isActive?: boolean;
  text: string;
  icon: ReactElement;
  to: string;
}) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={to}
      className={clsx(
        "px-6 py-4 flex flex-row",
        pathname === to && "bg-bg4/[0.87] border-r-6 border-primary",
      )}
    >
      {cloneElement(icon, { className: "w-21px text-30 mr-4 text-primary" })}{" "}
      <span
        className={clsx(
          "md:text-22 md:leading-27 text-20 leading-22 font-medium self-center",
          pathname === to ? "text-primary" : "text-blue",
        )}
      >
        {text}
      </span>
    </Link>
  );
};

export const LeftMenu = () => {
  return (
    <div className="border-2 border-border4 rounded-5 overflow-hidden">
      <MenuLink text="Profile" icon={<UserSvg />} to="/account/profile" />
      <MenuLink text="Password" icon={<PasswordSvg />} to="/account/password" />
      <MenuLink text="ID Verification" icon={<IDCardSvg />} to="/account/verify-card" />
      <MenuLink text="Your Invoices" icon={<FaFileInvoiceSvg />} to="/account/invoices" />
      <MenuLink
        text="Integration"
        icon={<Integration className="stroke-primary" />}
        to="/account/integration"
      />
    </div>
  );
};
