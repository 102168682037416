import { Link } from "react-router-dom";
import { Typography, FbSvg, InsSvg, TwiSvg } from "../atoms";

export const Footer = () => {
  return (
    <div className="bg-primary md:px-0 px-8 hidden md:block">
      <div className="container-layout pt-1 pb-0">
        <div className="md:flex md:flex-row flex-col justify-between items-center md:pb-2">
          <div className="flex flex-row items-cetner md:justify-start justify-between">
            <img src="/imgs/logo-white.svg" width={60} height={30} alt="logo bg" />

            <Typography className="text-white font-medium uppercase ml-2 mt-2">
              Copyright © 2023 Blist. All rights reserved.
            </Typography>
          </div>
          <div className="md:flex md:flex-row justify-end items-center grid grid-cols-2 mt-2">
            <Link className="text-white font-medium mr-6 hover:text-orange-900" to="/">
              Invest with BLIST
            </Link>
            <Link className="text-white font-medium mr-6 hover:text-orange-900" to="/about-us">
              About Us
            </Link>
            <Link className="text-white font-medium mr-6 hover:text-orange-900" to="/">
              Founders
            </Link>
            <Link
              className="text-white font-medium mr-6 hover:text-orange-900"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            <Link className="text-white font-medium hover:text-orange-900" to="/terms-conditions">
              Terms & Conditions
            </Link>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="flex flex-row items-center">
              <FbSvg className="fill-white hover:fill-orange-900" />
              <InsSvg className="fill-white hover:fill-orange-900 mx-2" />
              <TwiSvg className="fill-white hover:fill-orange-900" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
