import { useRef, useEffect, useState } from "react";
import { where, onSnapshot, query } from "firebase/firestore";
import { messageCollection } from "@app/utils";
import { IconButton, DirectUpSvg, Typography, CloseCircle2Svg, Message2Svg } from "../atoms";
import { HTextField } from "@app/components/hookform";
import { useAuth } from "@app/store/hooks";
import { Conversation } from "@app/types";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSendAdminMutation } from "@app/store/services/message";
import { s3Url } from "@app/config";

export const StickMessage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { profile } = useAuth();
  const [message, setMessage] = useState<Conversation | null>(null);
  const { control, handleSubmit, reset } = useForm<{ content: string }>();
  const [sendMessage] = useSendAdminMutation();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    listRef.current?.scrollIntoView();
  }, []);

  const onSubmit: SubmitHandler<{ content: string }> = (data) => {
    sendMessage({
      content: data.content,
    })
      .unwrap()
      .finally(() => reset({ content: "" }));
  };

  useEffect(() => {
    const queryRef = query(
      messageCollection,
      where(`user_ids.${profile.id}`, "==", true),
      where("isAdmin", "==", true),
    );

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        const snapshotData: Conversation = doc.data() as any;
        setMessage({
          ...snapshotData,
          id: doc.id,
        });
      });
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="fixed inline-block right-4 bottom-4 z-20">
      <div className="relative">
        <div ref={ref} className="absolute hidden bottom-20 right-0">
          <div className="text-right">
            <IconButton
              onClick={() => {
                if (!ref.current) {
                  return;
                }
                ref.current.classList.toggle("hidden");
              }}
            >
              <CloseCircle2Svg />
            </IconButton>
          </div>

          <div className="rounded-10 overflow-hidden bg-white h-400 w-276 flex flex-col">
            <div className="bg-blue px-4 py-2">
              <Typography className="text-white font-semibold">Blist Support</Typography>
              <p className="text-12 text-white">We typically reply in a few hours</p>
            </div>
            <div className="flex flex-col flex-1">
              <div className="grow h-220px overflow-y-auto">
                {/* <div className="flex justify-center items-center mb-2">
                  <div className="text-12 text-lightGrey rounded-full bg-grey5 px-2 py-1">
                    Today
                  </div>
                </div> */}
                {message?.messages?.map((it, key) =>
                  it.isAdmin ? (
                    <div key={key} className="px-4 flex flex-row mb-2">
                      <div className="bg-primary rounded-full w-30 h-30 mr-2">
                        <img src="/imgs/logo-white.svg" className="w-30 h-30" alt="admin" />
                      </div>
                      <div className="text-12 text-lightGrey bg-bg1 px-2 py-2 rounded-10 flex-1">
                        {it.content}
                      </div>
                    </div>
                  ) : (
                    <div key={key} className="px-4 flex flex-row  mb-2">
                      <div className="text-12 text-lightGrey bg-bg1 px-2 py-2 rounded-10 flex-1">
                        {it.content}
                      </div>
                      <div className="bg-primary rounded-full w-30 h-30 ml-2">
                        <img
                          src={
                            profile?.avatar
                              ? `${s3Url}${profile?.avatar}`
                              : "/imgs/default-avatar.png"
                          }
                          className="w-30 h-30"
                          alt="admin"
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mx-1 flex-none relative">
                <HTextField
                  name="content"
                  control={control}
                  type="text"
                  className="rounded-10 bg-buttonGrey border border-lightGrey/[.1] w-full !mb-0 pl-2 text-12 placeholder:text-lightGrey"
                  placeholder="Write a message"
                  autoComplete="off"
                />
                <div className="absolute right-0 inset-y-0 flex flex-row items-center">
                  <IconButton className="rounded-full bg-blue p-1 ">
                    <Message2Svg />
                  </IconButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <IconButton
          onClick={() => {
            if (!ref.current) {
              return;
            }
            ref.current.classList.toggle("hidden");
          }}
          className="rounded-full bg-blue p-2"
        >
          <DirectUpSvg />
        </IconButton>
      </div>
    </div>
  );
};
