import { Typography } from "@app/components/atoms";

export default function TermCondition() {
  return (
    <div className="px-4 md:px-0">
      <div className="container-layout">
        <div className=" lg:py-8 py-4">
          <Typography tag="h2" className="md:pb-8 pb-2">
            Terms & Conditions
          </Typography>
          <Typography className="pb-2">
            BEFORE CLICKING ON THE “ACCEPT” BUTTON OR ACCESSING ANY SERVICES PROVIDED BY BLIST, LLC
            AND ANY OF ITS SUBSIDIARIES AND AFFILIATES (COLLECTIVELY OR INDIVIDUALLY REFERRED TO AS
            “BLIST”), YOU (“YOU”, “CUSTOMER”) MUST CAREFULLY READ THE FOLLOWING TERMS AND
            CONDITIONS. BY CLICKING ON THE “ACCEPT” BUTTON OR USING THE SERVICES, YOU AGREE TO BE
            LEGALLY BOUND BY THESE TERMS AND CONDITIONS (“AGREEMENT”). IF YOU DO NOT AGREE WITH
            THESE TERMS, YOU MUST NOT USE THE SERVICES.
          </Typography>
          <Typography className="pb-2">
            IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF AN ORGANIZATION OR ENTITY, YOU
            REPRESENT THAT YOU HAVE THE AUTHORITY TO LEGALLY BIND THAT ENTITY. IF YOU DO NOT HAVE
            SUCH AUTHORITY, YOU MUST NOT ACCEPT THIS AGREEMENT ON BEHALF OF THAT ENTITY.
          </Typography>
          <Typography className="pb-2">### 1. **Services and Licensing**</Typography>
          <Typography className="pb-2">
            1.1 **Who the Services Are For.** The Services provided by Blist are intended for use by
            individuals and entities who seek to utilize Blist’s platform for creating, hosting, and
            participating in various experiences, events, and activities (“Experiences”). “You” or
            “Customer” refers to any individual or entity using the Services, unless otherwise
            specified.
          </Typography>
          <Typography className="pb-2">
            1.2 **License to Use the Services.** Subject to your compliance with this Agreement,
            Blist grants you a limited, non-transferable, non-exclusive, worldwide, revocable
            license to access and use the Services and any associated content (“Content”) for your
            personal or business use.
          </Typography>
          <Typography className="pb-2">
            1.3 **License to Use the App.** Blist grants you a limited, non-exclusive,
            non-transferable license to download, install, and use the Blist mobile application (the
            “App”) solely for accessing the Services under this Agreement.
          </Typography>
          <Typography>1.4 **Restrictions.** You agree not to:</Typography>
          <Typography>
            - (i) Copy, modify, or create derivative works of the Services or Content;
          </Typography>
          <Typography>
            - (ii) Decompile, reverse engineer, or attempt to extract the source code of the
            Services or App;
          </Typography>
          <Typography>
            - (iii) Sublicense, transfer, or assign any rights under this Agreement;
          </Typography>
          <Typography>
            - (iv) Use the Services or Content to create a competing product or service;
          </Typography>
          <Typography>
            - (v) Disclose any performance information or analysis of the Services to third parties;
          </Typography>
          <Typography className="pb-2">
            - (vi) Use the Services or App in a manner that violates any applicable law or
            regulation.
          </Typography>
          <Typography className="pb-2">
            1.5 **Electronic Signature and Records.** Your acceptance of this Agreement constitutes
            a valid electronic signature under applicable electronic signature laws (e.g., the U.S.
            E-SIGN Act). Electronic records of this Agreement are valid and enforceable.
          </Typography>
          ---
          <Typography className="pb-2">### 2. **Customer Data and Privacy**</Typography>
          <Typography className="pb-2">
            2.1 **Ownership and License of Customer Data.** You retain ownership of any data,
            information, or materials you upload or otherwise provide through the Services
            (“Customer Data”). By using the Services, you grant Blist a non-exclusive, worldwide,
            perpetual, irrevocable, royalty-free license to use, store, and process Customer Data
            for the purposes of providing and improving the Services.
          </Typography>
          <Typography className="pb-2">
            2.2 **Compliance with Laws.** You represent and warrant that your Customer Data complies
            with all applicable laws and does not infringe on any third-party rights. You must
            ensure that you have obtained all necessary consents from individuals whose personal
            data is included in your Customer Data.
          </Typography>
          <Typography className="pb-2">
            2.3 **Data Security and Breach Notification.** Blist will implement and maintain
            reasonable security measures to protect Customer Data against unauthorized access,
            disclosure, or loss. In the event of a data breach, Blist will notify you promptly and
            cooperate with any necessary remediation efforts.
          </Typography>
          <Typography className="pb-2">
            2.4 **Privacy Policy.** Your use of the Services is also governed by Blist’s Privacy
            Policy, which is incorporated by reference into this Agreement. By using the Services,
            you agree to the terms of the Privacy Policy.
          </Typography>
          <Typography className="pb-2">
            2.5 **Data Protection Compliance.** Blist complies with all applicable data protection
            regulations, including but not limited to the General Data Protection Regulation (GDPR)
            and the California Consumer Privacy Act (CCPA). You have the right to access, correct,
            or delete your personal data as outlined in these regulations. Blist will assist in
            fulfilling these rights in accordance with its legal obligations.
          </Typography>
          ---
          <Typography className="pb-2">### 3. **Fees and Payment Terms**</Typography>
          <Typography className="pb-2">
            3.1 **Fees.** You agree to pay all fees associated with your use of the Services in
            accordance with Blist’s current pricing schedule or as specified in an order form. All
            fees are non-refundable unless otherwise stated in this Agreement.
          </Typography>
          <Typography className="pb-2">
            3.2 **Fee Changes.** Blist reserves the right to modify its fee schedule at any time.
            You will be notified of any fee changes at least thirty (30) days in advance. If you do
            not agree to the new fees, you may terminate your use of the Services before the new
            fees take effect.
          </Typography>
          <Typography className="pb-2">
            3.3 **Taxes.** You are responsible for paying any taxes (other than taxes on Blist’s net
            income) related to your use of the Services, including sales, use, and value-added
            taxes.
          </Typography>
          <Typography className="pb-2">
            3.4 **Refunds.** Blist does not handle or manage payments between users and creators of
            Experiences. Any refunds or payment disputes are strictly between the creator and the
            user. Blist is not responsible for any issues related to payments, refunds, or financial
            transactions between users and Experience creators.
          </Typography>
          <Typography className="pb-2">
            3.5 **Suspension and Termination for Non-Payment.** If you fail to pay any fees when
            due, Blist may suspend or terminate your access to the Services. Continued non-payment
            may result in additional legal action to recover outstanding amounts.
          </Typography>
          ---
          <Typography className="pb-2">### 4. **Your Responsibilities**</Typography>
          <Typography className="pb-2">
            4.1 **Account Security.** You are responsible for maintaining the security of your
            account, including safeguarding your password and other credentials. You must notify
            Blist immediately if you suspect any unauthorized access to your account. Blist is not
            liable for any losses arising from unauthorized use of your account.
          </Typography>
          <Typography className="pb-2">
            4.2 **Prohibited Activities.** You agree not to use the Services for any illegal or
            unauthorized purpose, including but not limited to violating any intellectual property
            rights, transmitting malicious software, or engaging in any activity that could harm
            Blist or other users.
          </Typography>
          <Typography className="pb-2">
            4.3 **Compliance with Laws.** You must comply with all applicable local, state,
            national, and international laws and regulations in your use of the Services. You are
            solely responsible for ensuring that your use of the Services complies with all
            applicable laws.
          </Typography>
          <Typography className="pb-2">
            4.4 **Third-Party Links and Content.** The Services may contain links to third-party
            websites or content. Blist does not control or endorse these third-party sites or
            content and is not responsible for any issues, damages, or disputes arising from your
            use of them.
          </Typography>
          <Typography className="pb-2">
            4.5 **User Feedback and Suggestions.** Any feedback, suggestions, or ideas provided by
            users become the property of Blist without compensation. Blist reserves the right to use
            such feedback to improve its Services and operations.
          </Typography>
          ---
          <Typography className="pb-2">### 5. **Blist as a Third-Party Host**</Typography>
          <Typography className="pb-2">
            5.1 **Hosting Experiences.** Blist provides a platform that allows users to create,
            host, and participate in Experiences. Blist does not create, organize, or endorse any
            specific Experience. Blist is not responsible for the quality, safety, legality, or
            accuracy of any Experience or the conduct of any participant.
          </Typography>
          <Typography className="pb-2">
            5.2 **User Responsibility.** You acknowledge that Blist is a third-party host and that
            your participation in any Experience is at your own risk. You agree to release and hold
            harmless Blist and its affiliates from any claims, damages, or losses arising from or
            related to your participation in any Experience.
          </Typography>
          <Typography className="pb-2">
            5.3 **No Liability for Experience Issues.** Blist is not liable for any issues,
            disputes, or problems arising from Experiences, including but not limited to
            cancellations, refunds, injuries, or damages. Any disputes related to an Experience must
            be resolved directly between the participants and the host.
          </Typography>
          ---
          <Typography className="pb-2">### 6. **Intellectual Property**</Typography>
          <Typography className="pb-2">
            6.1 **Ownership of IP.** All intellectual property rights in and to the Services, App,
            and Content are owned by Blist and its licensors. This Agreement does not grant you any
            rights to such intellectual property, except for the limited license provided here in.
          </Typography>
          <Typography className="pb-2">
            6.2 **Trademarks.** The trademarks, logos, and service marks displayed on the Services
            are the property of Blist or other third parties. You are not permitted to use these
            marks without the prior written consent of Blist or the respective owner.
          </Typography>
          <Typography className="pb-2">
            6.3 **Infringement Claims.** If you believe that your intellectual property rights have
            been infringed by content or activity on the Services, you must notify Blist in writing.
            Blist reserves the right to remove any content that it believes may infringe on
            third-party rights.
          </Typography>
          ---
          <Typography className="pb-2">### 7. **Warranties and Disclaimers**</Typography>
          <Typography className="pb-2">
            7.1 **No Warranty on Services.** The Services, App, and Content are provided “as is” and
            “as available.” Blist disclaims all warranties, express or implied, including but not
            limited to implied warranties of merchantability, fitness for a particular purpose, and
            non-infringement. This disclaimer does not affect any statutory warranties or rights
            that cannot be excluded under applicable law.
          </Typography>
          <Typography className="pb-2">
            7.2 **Service Availability.** Blist does not warrant that the Services will be
            uninterrupted, error-free, or secure. Blist is not responsible for any interruptions or
            errors and will not be liable for any damages resulting from such interruptions or
            errors.
          </Typography>
          <Typography className="pb-2">
            7.3 **User-Generated Content.** Blist is not responsible for the content posted by users
            on the Services. Blist does not endorse or guarantee the accuracy, completeness, or
            usefulness of any user-generated content.
          </Typography>
          <Typography className="pb-2">
            7.4 **Force Majeure.** Blist will not be liable for any failure to perform its
            obligations under this Agreement if such failure results from any cause beyond Blist’s
            reasonable control, including but not limited to acts of God, war, terrorism, strikes,
            internet outages, pandemics, governmental restrictions, or any other event of force
            majeure.
          </Typography>
          ---
          <Typography className="pb-2">### 8. **Limitation of Liability**</Typography>
          <Typography className="pb-2">
            8.1 **Exclusion of Certain Damages.** To the fullest extent permitted by law, Blist will
            not be liable for any indirect, incidental, special, consequential, or punitive damages,
            including but not limited to loss of profits, data, or goodwill, arising from or related
            to your use of the Services.
          </Typography>
          <Typography className="pb-2">
            8.2 **Liability Cap.** Blist’s total liability to you for any claims arising from or
            related to this Agreement will not exceed the amount you paid to Blist in the twelve
            (12) months preceding the claim.
          </Typography>
          <Typography className="pb-2">
            8.3 **Jurisdictional Limitations.** Some jurisdictions do not allow the exclusion or
            limitation of certain damages. In such cases, the exclusions and limitations set forth
            in this Agreement will apply to the fullest extent permitted by applicable law.
          </Typography>
          ---
          <Typography className="pb-2">### 9. **Indemnification**</Typography>
          <Typography className="pb-2">
            9.1 **Your Indemnification Obligations.** You agree to indemnify, defend, and hold
            harmless Blist and its affiliates, officers, directors, employees, and agents from any
            claims, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees)
            arising from your use of the Services, violation of this Agreement, or infringement of
            any third-party rights.
          </Typography>
          ---
          <Typography className="pb-2">### 10. **Dispute Resolution and Arbitration**</Typography>
          <Typography className="pb-2">
            10.1 **Arbitration Agreement.** Any dispute, claim, or controversy arising out of or
            relating to this Agreement or the breach, termination, enforcement, interpretation, or
            validity thereof, including the determination of the scope or applicability of this
            agreement to arbitrate, shall be determined by arbitration in Las Vegas, Nevada, before
            a single arbitrator. The arbitration shall be administered by the American Arbitration
            Association (AAA) under its Consumer Arbitration Rules. Judgment on the award may be
            entered in any court having jurisdiction. This clause shall not preclude parties from
            seeking provisional remedies in aid of arbitration from a court of appropriate
            jurisdiction.
          </Typography>
          <Typography className="pb-2">
            10.2 **Class Action Waiver.** You agree that any arbitration under this Agreement will
            take place on an individual basis; class arbitrations and class actions are not
            permitted. You agree to waive your right to participate in a class action or class-wide
            arbitration.
          </Typography>
          ---
          <Typography className="pb-2">### 11. **Governing Law and Jurisdiction**</Typography>
          <Typography className="pb-2">
            11.1 **Governing Law.** This Agreement shall be governed by and construed in accordance
            with the laws of the State of Nevada, without regard to its conflict of law principles.
          </Typography>
          <Typography className="pb-2">
            11.2 **Jurisdiction.** You agree that any legal action or proceeding arising under or
            related to this Agreement shall be brought exclusively in the courts located in Las
            Vegas, Nevada, and you hereby consent to the personal jurisdiction and venue therein.
          </Typography>
          ---
          <Typography className="pb-2">### 12. **Severability**</Typography>
          <Typography className="pb-2">
            12.1 **Severability Clause.** If any provision of this Agreement is found to be invalid
            or unenforceable by a court of competent jurisdiction, such provision shall be severed
            from the remainder of the Agreement, which will remain in full force and effect.
          </Typography>
          ---
          <Typography className="pb-2">### 13. **Notice Requirements**</Typography>
          <Typography className="pb-2">
            13.1 **Notice to Users.** Blist may provide notices to you under this Agreement by
            posting updates on the Services or by sending an email to the email address associated
            with your account. Notices shall be deemed received twelve (12) hours after they are
            posted or sent.
          </Typography>
          <Typography className="pb-2">
            13.2 **Notice to Blist.** Any notices to Blist under this Agreement should be sent via
            email to [insert contact email] or by mail to Blist’s registered office.
          </Typography>
          ---
          <Typography className="pb-2">
            This finalized version includes all the necessary clauses and protections to fully
            safeguard Blist. It is now comprehensive, consistent, and ready to be used on your
            website. Let me know if you need any further adjustments!
          </Typography>
        </div>
      </div>
    </div>
  );
}
