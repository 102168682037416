import { ItemLoading, Typography } from "../atoms";
import { EmercyContact } from "../molecules";
import { useCreateTrackingMutation, useGetTrackingQuery } from "@app/store/services/tracking";
import { useParams } from "react-router-dom";

export const EmergencyContactSection = ({ isNotRemove }: { isNotRemove?: boolean }) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data, isLoading } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const [createTracking] = useCreateTrackingMutation();

  const onRemove = (key: number) => {
    if (!bookingId) return;

    createTracking({
      booking_id: Number(bookingId),
      emergency_contacts: data?.emergency_contacts.filter((it, i) => i !== key),
    });
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 gap-5">
        <ItemLoading />
        <ItemLoading />
        <ItemLoading />
        <ItemLoading />
      </div>
    );
  }

  return (
    <div>
      <Typography tag="h4" className="mb-6">
        Emergency contacts
      </Typography>
      <div className="grid md:grid-cols-2 gap-5">
        {data?.emergency_contacts?.map((item, key) => (
          <EmercyContact
            name={item.name}
            email={item.email}
            key={key}
            phone={`${item.phone_code} ${item.phone}`}
            onRemove={() => onRemove(key)}
            isNotRemove={isNotRemove}
          />
        ))}
      </div>
    </div>
  );
};
