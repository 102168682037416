import clsx from "clsx";
import { ReactNode } from "react";
import ReactModal from "react-modal";
import { CloseSquareSvg } from "./icons";
import { IconButton } from "./Button";
type Props = {
  show?: boolean;
  onClose?: () => void;
  isCustom?: boolean;
  children?: ReactNode;
  size?: "small" | "normal" | "big";
};

export const Modal = ({ show, children, onClose, isCustom, size = "normal" }: Props) => {
  return (
    <ReactModal
      isOpen={!!show}
      onRequestClose={onClose}
      className={clsx("flex items-center justify-center h-full")}
      overlayClassName="fixed z-40 bg-black/[.5] inset-0"
    >
      {isCustom ? (
        children
      ) : (
        <div
          className={clsx(
            "rounded-20 bg-white w-full relative py-2 pr-1",
            size === "normal" && "max-w-720",
            size === "small" && "max-w-360",
            size === "big" && "max-w-1350",
          )}
        >
          <div className="modal-scroll overflow-y-auto max-h-80vh py-6">
            <IconButton className="absolute top-4 right-4" onClick={onClose}>
              <CloseSquareSvg />
            </IconButton>
            {children}
          </div>
        </div>
      )}
    </ReactModal>
  );
};
