import { createApi } from "@reduxjs/toolkit/query/react";
import type { Category } from "@app/types/category";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { CreateCategoryPayload, FetchCategoryPayload, SortCategoryPayload } from "./types";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    fetchCategories: builder.query<Category[], FetchCategoryPayload>({
      query: (params) => {
        return {
          url: "category",
          params,
        };
      },
      transformResponse: (response: CommonResponse<Category[]>) => response?.data,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }: Category) => ({ type: "Category" as const, id })), "Category"]
          : ["Category"],
    }),
    createCategory: builder.mutation<CommonResponse<Category>, CreateCategoryPayload>({
      query(body) {
        return {
          url: `category`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Create successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Category"],
    }),
    getCategory: builder.query<Category, number>({
      query: (id: number) => `category/${id}`,
      transformResponse: (response: CommonResponse<Category>) => response?.data,
      providesTags: (result, error, id) => [{ type: "Category", id }],
    }),
    updateCategory: builder.mutation<
      CommonResponse<Category>,
      Partial<CreateCategoryPayload> & { id: number }
    >({
      query({ id, ...body }) {
        return {
          url: `category/${id}`,
          method: "PATCH",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Update successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Category", id: arg.id }],
    }),
    removeCategory: builder.mutation<CommonResponse<Category>, number>({
      query(id: number) {
        return {
          url: `category/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Remove successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Category"],
    }),
    sortCategory: builder.mutation<CommonResponse<Category>, SortCategoryPayload>({
      query(body) {
        return {
          url: `category/sort`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useFetchCategoriesQuery,
  useLazyFetchCategoriesQuery,
  useCreateCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  useSortCategoryMutation,
} = categoryApi;
