import Slider from "react-slick";
import { useRef } from "react";
import { SlideTitle } from "../molecules";

export const NewWeekSection = () => {
  const sliderRef = useRef<Slider>(null);

  return (
    <div>
      <div className=" md:py-8 pt-4 pb-2">
        <SlideTitle
          title="New this week"
          onNext={() => sliderRef?.current?.slickNext()}
          onPrevious={() => sliderRef?.current?.slickPrev()}
        />
        <div className="pt-10">
          <Slider
            ref={sliderRef}
            dots={false}
            infinite
            speed={500}
            slidesToShow={2}
            slidesToScroll={1}
            centerMode
            centerPadding={"60"}
            arrows={false}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  centerPadding: "10",
                },
              },
            ]}
          >
            {/* <NewWeekItem title="Dating around the world" url="/imgs/image-19.jpg" />
            <NewWeekItem title="Donating Food" url="/imgs/image-20.jpg" />
            <NewWeekItem title="Food & Drinks" url="/imgs/image-19.jpg" />
            <NewWeekItem title="Sports" url="/imgs/image-20.jpg" />
            <NewWeekItem title="Dating around the world" url="/imgs/image-19.jpg" /> */}
          </Slider>
        </div>
      </div>
    </div>
  );
};
