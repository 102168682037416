import { Star, RegStar } from "@app/components/atoms";
import clsx from "clsx";

export type RatingProps = {
  value: number;
  onChange?: (value: number) => void;
  iconClassName?: string;
};

export const Rating = ({ value, onChange, iconClassName }: RatingProps) => {
  return (
    <div className="flex flex-row items-center">
      <div onClick={() => onChange?.(1)} className="cursor-pointer">
        {value >= 1 ? (
          <Star className={clsx("text-yellow1", iconClassName)} />
        ) : (
          <RegStar className={clsx("text-grey13", iconClassName)} />
        )}
      </div>
      <div onClick={() => onChange?.(2)} className="cursor-pointer">
        {value >= 2 ? (
          <Star className={clsx("text-yellow1", iconClassName)} />
        ) : (
          <RegStar className={clsx("text-grey13", iconClassName)} />
        )}
      </div>
      <div onClick={() => onChange?.(3)} className="cursor-pointer">
        {value >= 3 ? (
          <Star className={clsx("text-yellow1", iconClassName)} />
        ) : (
          <RegStar className={clsx("text-grey13", iconClassName)} />
        )}
      </div>
      <div onClick={() => onChange?.(4)} className="cursor-pointer">
        {value >= 4 ? (
          <Star className={clsx("text-yellow1", iconClassName)} />
        ) : (
          <RegStar className={clsx("text-grey13", iconClassName)} />
        )}
      </div>
      <div onClick={() => onChange?.(5)} className="cursor-pointer">
        {value >= 5 ? (
          <Star className={clsx("text-yellow1", iconClassName)} />
        ) : (
          <RegStar className={clsx("text-grey13", iconClassName)} />
        )}
      </div>
    </div>
  );
};
