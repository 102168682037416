import { AdminWishlist, AdminForum, AdminBlistUpdate } from "@app/components/organisms";

export default function AdminBlistBoard() {
  return (
    <>
      <AdminWishlist />
      <AdminForum />
      <AdminBlistUpdate />
    </>
  );
}
