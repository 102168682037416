import { UserChat } from "../molecules";
import { PaperclipSvg, Typography, SendSvg, IconButton, BookingLoading, Button } from "../atoms";
import { ChatRow, ExperienceChatContent, ExperienceHeaderTitle } from "../molecules";
import { Conversation, Message, User } from "@app/types";
import { useAuth } from "@app/store/hooks";
import { BookingStatus, Role } from "@app/enums";
import { s3Url } from "@app/config";
import { useGetBookingQuery, useUpdateBookingMutation } from "@app/store/services/booking";
import { useForm, SubmitHandler } from "react-hook-form";
import { HTextField } from "../hookform";
import { useSendMessageMutation } from "@app/store/services/message";
import { useEffect, useMemo, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type UserChatListProps = {
  data: Conversation[];
  onSelected: (id: string) => void;
  selected: string | null;
};

export const UserChatList = ({ data, onSelected, selected }: UserChatListProps) => {
  return (
    <div className="px-2 overflow-y-auto custom-scroll md:h-680 h-360">
      {data.map((item, key) => (
        <UserChat
          isSelected={selected === item.id}
          conversation={item}
          latest={item.messages[item.messages.length - 1]}
          onSelected={() => onSelected(item.id)}
          key={key}
        />
      ))}
    </div>
  );
};

type MemberDetailProps = {
  name: string;
  avatar?: string;
  status?: string;
};

export const MemberDetail = ({ name, avatar, status }: MemberDetailProps) => {
  return (
    <div className="flex flex-row pt-5 px-5 pb-4">
      <div className="mr-4">
        <img
          src={avatar ? `${s3Url}${avatar}` : "/imgs/default-avatar.png"}
          className="w-56 h-56 rounded-full"
          alt="experience"
        />
      </div>
      <div>
        <Typography tag="body1" className="text-blue font-semibold" isColor>
          {name}
        </Typography>
        <Typography tag="body" className="text-lightGrey" isColor>
          Online - Last seen, 2:02pm
        </Typography>
      </div>
    </div>
  );
};

type MessageListProps = {
  messages: Message[];
  conversationId?: string;
  onSetBookingId: (id: number) => void;
};

export const MessageList = ({ messages, conversationId, onSetBookingId }: MessageListProps) => {
  const { profile } = useAuth();
  const { control, handleSubmit, reset } = useForm<{ content: string }>();
  const [sendMessage] = useSendMessageMutation();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    listRef.current?.scrollIntoView();
  }, []);

  const onSubmit: SubmitHandler<{ content: string }> = (data) => {
    sendMessage({
      content: data.content,
      name: `${profile.first_name} ${profile.last_name}`,
      userId: profile.id,
      id: conversationId || "",
      avatar: profile.avatar,
    })
      .unwrap()
      .finally(() => reset({ content: "" }));
  };

  return (
    <div className="flex flex-col justify-between px-6 py-2 flex-1 box-border h-full">
      <div className="h-400 grow overflow-y-scroll py-2 px-1 no-scrollbar">
        {messages.map((item, key) => (
          <ChatRow
            isOwner={profile.id === item.userId}
            content={item.content}
            note={item.note}
            media={item.media}
            date={item.createdAt}
            bookingId={item.booking_id}
            name={item.name}
            key={key}
            onSetBookingId={onSetBookingId}
            isAdmin={item.isAdmin}
            avatar={item.avatar}
          />
        ))}
        <div ref={listRef} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row">
        <HTextField
          name="content"
          control={control}
          variant="filled"
          border="rectangled"
          placeholder="Type a Message..."
          startIcon={<PaperclipSvg />}
          className="flex-1"
          layout="static"
        />
        <IconButton type="submit" layout="rectangled" className="bg-primary ml-2 w-40 text-center">
          <SendSvg className="text-white mx-auto" />
        </IconButton>
      </form>
    </div>
  );
};

export const ExperienceChatList = ({
  id,
  conversationId,
}: {
  id?: number;
  conversationId?: string;
}) => {
  const { data, isLoading } = useGetBookingQuery(id || 0, { skip: !id });
  const [updateBooking, { isLoading: isUpdating }] = useUpdateBookingMutation();
  const { profile } = useAuth();
  const navigate = useNavigate();
  const [sendMessage] = useSendMessageMutation();

  const onApproveCancelBooking = () => {
    if (!id) return;
    updateBooking({
      id,
      status: BookingStatus.CANCEL,
    });
  };

  const onDenyBooking = () => {
    if (!id) return;
    updateBooking({
      id,
      status: BookingStatus.COMPLETED_PAYMENT,
    });
  };

  const onApproveModifyBooking = () => {
    if (!id) return;
    updateBooking({
      id,
      status: BookingStatus.COMPLETED_PAYMENT,
      date: moment(data?.date_request).format("YYYY-MM-DD"),
      availability_time: ((data?.time_request as any) || "").slice(11, 16),
    });
  };

  const onCheckinBooking = () => {
    if (!id) return;
    updateBooking({
      id,
      status: BookingStatus.CHECK_IN,
    });
  };

  const onRequestSafety = () => {
    sendMessage({
      content: `I want to access your safety page of the ${data?.experience.title} booking`,
      name: `${profile.first_name} ${profile.last_name}`,
      userId: profile.id,
      id: conversationId || "",
      avatar: profile.avatar,
    });
  };

  if (!id) return null;

  return (
    <div className="pl-4 pr-1 py-3">
      <div>
        {isLoading ? (
          <BookingLoading />
        ) : (
          <div className="pb-4 mb-4">
            <ExperienceHeaderTitle
              url={data?.experience?.photos?.[0]}
              title={data?.experience.title || ""}
            />
            <ExperienceChatContent
              datetime={`${moment(data?.date).format("DD MMM YYYY")} ${
                data?.availability_time &&
                moment(data?.availability_time, "HH:mm:ss+00").format("h:mm:ss a")
              }`}
              addresses={data?.experience.addresses}
              summary={data?.experience.summary}
              price={data?.experience.amount}
              guest_bring={data?.experience.guest_bring}
              userAmount={(data?.adults || 0) + (data?.children || 0) + (data?.infant || 0)}
            />
            {profile.role === Role.creator && (
              <>
                {data?.status === BookingStatus.USER_REQUEST_CANCEL && (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      Customer requested to cancel this booking
                    </Typography>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      className="mr-1"
                      isLoading={isUpdating}
                      onClick={onApproveCancelBooking}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      isLoading={isUpdating}
                      onClick={onDenyBooking}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {data?.status === BookingStatus.USER_REQUEST_MODIFY && (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      Customer requested to modify this booking
                    </Typography>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      className="mr-1"
                      isLoading={isUpdating}
                      onClick={onApproveModifyBooking}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      isLoading={isUpdating}
                      onClick={onDenyBooking}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!!(data?.tracking?.authorized_people || [])?.find(
                  (item) => item.id === profile.id,
                ) ? (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      See the safety page
                    </Typography>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      onClick={() => navigate(`/tracking/${id}`)}
                    >
                      The Safety Page
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      Request to access the safety page
                    </Typography>
                    <Button variant="primary" layout="rectangled" onClick={onRequestSafety}>
                      Request
                    </Button>
                  </div>
                )}
              </>
            )}

            {profile.role === Role.user && (
              <>
                {data?.status === BookingStatus.CREATOR_REQUEST_CANCEL && (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      Customer requested to cancel this booking
                    </Typography>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      className="mr-1"
                      isLoading={isUpdating}
                      onClick={onApproveCancelBooking}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      isLoading={isUpdating}
                      onClick={onDenyBooking}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {data?.status === BookingStatus.CREATOR_REQUEST_MODIFY && (
                  <div>
                    <Typography tag="h6" className="mb-2">
                      Customer requested to modify this booking
                    </Typography>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      className="mr-1"
                      isLoading={isUpdating}
                      onClick={onApproveModifyBooking}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="primary"
                      layout="rectangled"
                      isLoading={isUpdating}
                      onClick={onDenyBooking}
                    >
                      Cancel
                    </Button>
                  </div>
                )}

                <div>
                  <Button
                    variant="primary"
                    layout="rectangled"
                    onClick={onCheckinBooking}
                    className="mr-1"
                    disabled={
                      !(!data?.tracking?.auto_check_in && data?.status === BookingStatus.APPROVED)
                    }
                  >
                    Check-in
                  </Button>
                  <Button
                    variant="primary"
                    layout="rectangled"
                    onClick={() => navigate(`/safety/${id}`)}
                  >
                    The Safety Page
                  </Button>

                  <Button
                    variant="primary"
                    layout="rectangled"
                    onClick={() => navigate(`/review/${id}`)}
                    className="ml-1"
                    disabled={data?.status !== BookingStatus.CHECK_IN}
                  >
                    Review
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
