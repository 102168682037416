import { Typography, Radio, QuestionCircleSvg, SlideLoading, Spinner } from "@app/components/atoms";
import { MapView } from "../common";
import { useGeolocated } from "react-geolocated";
import { useCreateTrackingMutation, useGetTrackingQuery } from "@app/store/services/tracking";
import { useParams } from "react-router-dom";
import { Marker } from "@react-google-maps/api";
import { useEffect } from "react";
import { useGetBookingQuery, useUpdateBookingMutation } from "@app/store/services/booking";
import { useAuth } from "@app/store/hooks";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@app/utils";
import moment from "moment";
import { useGetExperienceQuery } from "@app/store/services/experience";
import { BookingStatus } from "@app/enums";

export const TrackingBanner = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data, isLoading } = useGetTrackingQuery(bookingId || "", { skip: !bookingId });
  const { data: booking } = useGetBookingQuery(Number(bookingId), { skip: !bookingId });
  const { data: experience } = useGetExperienceQuery(String(booking?.experience.id), {
    skip: !booking?.experience.id,
  });
  const [createTracking, { isLoading: isUpdating }] = useCreateTrackingMutation();
  const [updateBooking] = useUpdateBookingMutation();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 10000,
  });
  const { profile } = useAuth();

  const onChange = (key: string, value: boolean) => {
    if (!bookingId) return;
    createTracking({
      booking_id: Number(bookingId),
      [key]: value,
    });
  };

  useEffect(() => {
    // auto update latlng
    if (coords?.latitude && coords?.longitude) {
      const onUpdateLatLng = async () => {
        const docRef = doc(db, "tracking", `${bookingId}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const bookingRef = doc(db, "tracking", `${bookingId}`);
          updateDoc(bookingRef, {
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
        }
      };

      onUpdateLatLng();
    }
  }, [coords]);

  useEffect(() => {
    // checkin when the user want to use auto check
    if (data?.auto_check_in && booking) {
      const splitedDate = booking.date.split("T");
      const splitedTime = booking.availability_time.split("+");
      const startTime = moment(`${splitedDate?.[0]} ${splitedTime?.[0]}`);
      const endTime = startTime.add("minutes", +booking.experience.duration);
      const currentTime = moment();

      // if current time is in duration time
      if (currentTime.isSameOrAfter(startTime) && currentTime.isSameOrBefore(endTime)) {
        let service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [{ lat: coords?.latitude || 0, lng: coords?.longitude || 0 }],
            destinations: [
              {
                lat: booking?.experience.addresses?.[0].lat || 0,
                lng: booking?.experience.addresses?.[0].lng || 0,
              },
            ],
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status !== "OK") {
              console.log("Error was: " + status);
            } else {
              if ((response?.rows?.[0].elements?.[0].distance?.value || 100) < 50) {
                updateBooking({
                  id: Number(bookingId),
                  status: BookingStatus.CHECK_IN,
                });
              }
            }
          },
        );
      }
    }
  }, [data, coords, experience]);

  if (isLoading) return <SlideLoading />;

  return (
    <div className="mb-6">
      {isUpdating && (
        <div className="fixed w-screen h-screen flex items-center justify-center z-10 inset-0">
          <Spinner />
        </div>
      )}

      <Typography tag="h2" className="mb-4 text-center">
        Safety Features
      </Typography>
      {booking?.customer_id === profile.id && (
        <div className="flex md:flex-row flex-col md:justify-around mx-auto">
          <div>
            <Typography tag="h6" className="text-blue mb-4 inline-flex flex-row">
              Auto Check-in
              <QuestionCircleSvg />
            </Typography>
            <div className="mb-6 flex flex-row">
              <Radio
                label="Yes"
                className="mr-4"
                disabled={!isGeolocationAvailable && !isGeolocationEnabled}
                checked={data?.auto_check_in}
                onChange={() => onChange("auto_check_in", true)}
              />
              <Radio
                label="Manual"
                checked={!data?.auto_check_in}
                onChange={() => onChange("auto_check_in", false)}
              />
            </div>
          </div>
          <div>
            <Typography tag="h6" className="text-blue mb-4 inline-flex flex-row">
              Experience Auto Track
              <QuestionCircleSvg />
            </Typography>
            <div className="mb-6 flex flex-row">
              <Radio
                label="Yes"
                className="mr-4"
                checked={data?.auto_track}
                onChange={() => onChange("auto_track", true)}
              />
              <Radio
                label="No"
                checked={!data?.auto_track}
                onChange={() => onChange("auto_track", false)}
              />
            </div>
          </div>
        </div>
      )}

      <div className="h-430 mt-4 relative rounded-20 overflow-hidden">
        <MapView>
          {!!data?.auto_track && (
            <Marker
              icon="/imgs/marker.png"
              position={{ lat: coords?.latitude || 0, lng: coords?.longitude || 0 }}
            />
          )}
          {experience?.addresses?.map((item, i) => (
            <Marker key={i} icon="/imgs/marker.png" position={{ lat: item.lat, lng: item.lng }} />
          ))}
        </MapView>
      </div>
    </div>
  );
};
