import { createApi } from "@reduxjs/toolkit/query/react";
import type { Feedback } from "@app/types";
import type { CommonResponse, ErrorResponse } from "../type";
import fetchBaseQuery from "../../base-query";
import { openInform } from "../../reducers/inform";
import type { QueryFeedbackParams, SendFeedbackPayload } from "./types";

export const feedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Feedback"],
  endpoints: (builder) => ({
    fetchFeedback: builder.query<CommonResponse<Feedback[]>, QueryFeedbackParams>({
      query: (params) => {
        return {
          url: "feedback",
          params,
        };
      },
      transformResponse: (response: CommonResponse<Feedback[]>) => response,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: Feedback) => ({ type: "Feedback" as const, id })),
              "Feedback",
            ]
          : ["Feedback"],
    }),
    sendFeedback: builder.mutation<CommonResponse<Feedback>, SendFeedbackPayload>({
      query(body) {
        return {
          url: `feedback`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({
              show: true,
              type: "success",
              message: "We received your feedback. We will reply soon.",
            }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const { useSendFeedbackMutation, useFetchFeedbackQuery } = feedbackApi;
