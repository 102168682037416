import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { QueryExperienceParams } from "../services/experience/type";

export interface ExperienceState {
  filter?: QueryExperienceParams;
}

const initialState: ExperienceState = {
  filter: {},
};

export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<QueryExperienceParams>) => {
      state.filter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilter } = experienceSlice.actions;

export default experienceSlice.reducer;
