import { useSelector } from "react-redux";
import { RootState } from "../";
import { FavoriteState } from "../reducers/favorite";

interface FavoriteProps extends FavoriteState {}

export const useFavorite = (): FavoriteProps => {
  const allState = useSelector((state: RootState) => state.favorite);

  return {
    ...allState,
  };
};
