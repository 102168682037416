import { Marker } from "@react-google-maps/api";
import { Typography } from "../../atoms";
import { MapView } from "../../common";

type Props = {
  position?: { lat: number; lng: number }[];
};

export const MapReview = ({ position = [] }: Props) => {
  if (!position.length) return null;
  return (
    <div className="">
      <Typography tag="h4" className="leading-none text-28 text-blue mb-4">
        Location
      </Typography>
      <div className="mx-auto h-400 mb-6">
        <MapView center={position[0]}>
          {position.map((item, key) => (
            <Marker icon="/imgs/select-marker.png" key={key} position={item} />
          ))}
        </MapView>
      </div>
    </div>
  );
};
