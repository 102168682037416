import {
  CreatorPresence,
  ExpBusiness,
  ExpCreatorType,
  ExpKind,
  ExpLevel,
  ExpType,
  Language,
} from "@app/enums/experience";
import { User } from "@app/types/user";
import { defaultLocation } from "@app/config";

export type CreateExperiencePayload = {
  category_ids: number[];
  creator_ids: number[];
  title: string;
  kind: ExpKind[];
  platform?: string;
  type: ExpType;
  addresses: {
    lat: number;
    lng: number;
    street_address: string;
    zip_code: string;
    country: string;
    state: string;
    city: string;
  }[];
  is_willing_to_travel: boolean;
  willing_to_travel?: string;
  language_offered: Language[];
  is_date: boolean;
  start_date?: string;
  end_date?: string;
  duration?: string;
  availability_time?: string[];
  available_days: string[];
  summary: string;
  description: string;
  atmosphere: string;
  creator_type: ExpCreatorType;
  creator_presence: CreatorPresence;
  creator_provide: string;
  guest_bring: string;
  clothing_recommendation: string;
  age_min?: number;
  age_max?: number;
  guest_min?: number;
  guest_max?: number;
  amount?: number;
  pet_allowed: boolean;
  handicap_accessible: boolean;
  level_expected: ExpLevel;
  photos?: string[];
  videos?: string[];
  business?: ExpBusiness;
  pet_description?: string;
  handicap_accessible_description?: string;
  is_free?: boolean;
  cancellation_policy?: string;
  is_blist?: boolean;

  youtube_url?: string;
  tiktok_url?: string;
  instagram_url?: string;
  x_url?: string;

  is_eventbrite?: boolean;
  is_meetup?: boolean;
};

export type CreateExperienceForm = Omit<
  CreateExperiencePayload,
  "availability_time" | "start_date" | "end_date" | "creator_ids" | "addresses"
> & {
  availability_time: Date[];
  start_date?: Date;
  end_date?: Date;
  creator_ids: User[];
  addresses: {
    lat: number;
    lng: number;
    street_address: string;
    zip_code: string;
    country: string;
    state: string;
    city: string;
  }[];
};

export type UpdateExperiencePayload = Partial<Omit<CreateExperiencePayload, "addresses">> & {
  is_active?: boolean;
  addresses: {
    id?: number;
    lat: number;
    lng: number;
    street_address: string;
    zip_code: string;
    country: string;
    state: string;
    city: string;
  }[];
};

export type QueryExperienceParams = {
  is_blist?: boolean;
  is_ads?: boolean;
  category_ids?: number[];
  user_id?: number;
  creator_type?: ExpCreatorType;
  radius?: number;
  lat?: number;
  lng?: number;
  address?: string;
  title?: string;
  start_date?: string;
  end_date?: string;
  min_amount?: number;
  max_amount?: number;
  kind?: ExpKind;
  type?: ExpType;
  is_active?: boolean;
  business?: ExpBusiness;
  is_available?: boolean;
  is_top?: boolean;
  is_all?: boolean;
};

export const InitCreateExperience: CreateExperienceForm = {
  title: "",
  category_ids: [],
  creator_ids: [],
  language_offered: [],
  kind: [],
  type: ExpType.PRIVATE,
  is_willing_to_travel: true,
  creator_type: ExpCreatorType.INDIVIDUAL,
  creator_presence: CreatorPresence.ENJOY_ALONE,
  pet_allowed: true,
  handicap_accessible: true,
  level_expected: ExpLevel.NONE,
  available_days: [],
  summary: "",
  description: "",
  atmosphere: "",
  creator_provide: "",
  guest_bring: "",
  clothing_recommendation: "",
  availability_time: [new Date()],
  is_date: true,
  addresses: [
    {
      street_address: "",
      zip_code: "",
      state: "",
      country: "",
      city: "",
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
    },
  ],
  is_eventbrite: false,
  is_meetup: false,
};
