import {
  Typography,
  Button,
  TextField,
  SearchNormalSvg,
  BookingLoading,
} from "@app/components/atoms";
import { Pagination } from "@app/components/common";
import { UserItem } from "@app/components/molecules";
import { useFetchUserQuery, useLazyFetchUserQuery } from "@app/store/services/user";
import { FetchUserPayload } from "@app/store/services/user/type";
import { useState, ChangeEvent } from "react";
import clsx from "clsx";
import { limitItem, s3Url } from "@app/config";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

export default function AdminUser() {
  const [content, setContent] = useState<string | undefined>();
  const navigate = useNavigate();
  const onSeach = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }, 500);
  const [tab, setTab] = useState<{ tab: string; query: FetchUserPayload }>({
    tab: "active",
    query: { is_active: true },
  });
  const [page, setPage] = useState(1);
  const [triggerFetchUser] = useLazyFetchUserQuery();
  const { data, isLoading } = useFetchUserQuery({
    limit: limitItem,
    page,
    name: content,
    ...tab.query,
  });
  return (
    <>
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">User Management</Typography>
        <div className="flex md:flex-row flex-col">
          <TextField
            variant="outlined-black"
            border="full"
            name="id"
            placeholder="Search by name"
            endIcon={<SearchNormalSvg />}
            className="!mb-0"
            onChange={onSeach}
          />
          <Button
            variant={tab.tab === "active" ? "primary" : "outline-border"}
            className={clsx(
              "mx-2",
              tab.tab === "active" ? "hover:!bg-primary hover:!text-white" : "border-lightGrey",
            )}
            onClick={() => {
              triggerFetchUser({
                limit: limitItem,
                page,
                is_active: true,
                name: content,
              });
              setTab({
                tab: "active",
                query: { is_active: true },
              });
            }}
          >
            Activated
          </Button>
          <Button
            variant={tab.tab === "inactive" ? "primary" : "outline-border"}
            className={clsx(
              "mr-2",
              tab.tab === "inactive" ? "hover:!bg-primary hover:!text-white" : "border-lightGrey",
            )}
            onClick={() => {
              triggerFetchUser({
                limit: limitItem,
                page,
                is_active: false,
                name: content,
              });
              setTab({
                tab: "inactive",
                query: { is_active: false },
              });
            }}
          >
            Deactivated
          </Button>
          <Button
            variant={tab.tab === "deleted" ? "primary" : "outline-border"}
            className={clsx(
              "mr-2",
              tab.tab === "deleted" ? "hover:!bg-primary hover:!text-white" : "border-lightGrey",
            )}
            onClick={() => {
              triggerFetchUser({
                limit: limitItem,
                page,
                is_deleted: true,
                name: content,
              });
              setTab({
                tab: "deleted",
                query: { is_deleted: true },
              });
            }}
          >
            Deleted
          </Button>
          <Button
            variant="primary"
            className="hover:!bg-primary hover:!text-white"
            onClick={() => navigate("/admin/user/add")}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-4 mb-6">
        {isLoading ? (
          <>
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
            <BookingLoading />
          </>
        ) : (
          data?.data?.map((item, key) => (
            <UserItem
              key={key}
              url={item.avatar ? `${s3Url}${item.avatar}` : "/imgs/default-avatar.png"}
              title={`${item.first_name} ${item.last_name}`}
              description=""
              isActive={item.is_active}
              isDeleted={item.is_deleted}
              id={item.id}
              isNotVerify={item.identity_verified}
            />
          ))
        )}
      </div>
      <div className="flex flex-row justify-end">
        <Pagination setPage={setPage} page={page} total={data?.total} />
      </div>
    </>
  );
}
