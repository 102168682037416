import { useFieldArray, Control } from "react-hook-form";
import { AddSquareSvg, IconButton, MinusSquareSvg, UploadFile } from "../atoms";
import { HTextArea, HUpload } from "../hookform";
import clsx from "clsx";

interface Props {
  control: Control<any>;
  name: string;
  className?: string;
  maxRows?: number;
}

export const HCategorySlider = ({ control, name, className, maxRows = 999 }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div
          className={clsx(
            className,
            "flex flex-row items-center mb-2 border border-1 rounded-10 p-3",
          )}
          key={field.id}
        >
          <div className="flex-1">
            <HUpload control={control} name={`${name}.${index}.media`}>
              <UploadFile />
            </HUpload>
          </div>
          <div className="flex-1 mx-2">
            <HTextArea
              control={control}
              name={`${name}.${index}.content`}
              label="Type Details"
              subtitle="(500 words max)"
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
            />
          </div>
          {fields.length < maxRows && (
            <IconButton type="button" onClick={() => append(new Date())}>
              <AddSquareSvg />
            </IconButton>
          )}
          {fields.length > 1 && (
            <IconButton type="button" onClick={() => remove(index)}>
              <MinusSquareSvg />
            </IconButton>
          )}
        </div>
      ))}
    </>
  );
};
