import { useRef, useEffect, useState } from "react";
import { ArrowDownSvg, IconButton, NotificationSvg, ProfileSvg, Button } from "../atoms";
import { useAuth } from "@app/store/hooks";
import { useNavigate } from "react-router-dom";
import { s3Url } from "@app/config";
import { where, onSnapshot, query } from "firebase/firestore";
import { Role } from "@app/enums";
import { useUpdateUserMutation } from "@app/store/services/user";
import { alertCollection } from "@app/utils";
import { Alert } from "@app/types";

type Props = {
  isBoardButton?: boolean;
};

export const AdminUserMenu = ({ isBoardButton = true }: Props) => {
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();
  const { profile, logout, isLoggedIn, updateProfile } = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const onToggle = () => {
    if (!ref.current) {
      return;
    }
    ref.current.classList.toggle("hidden");
  };
  const [data, setData] = useState<Alert[]>([]);

  useEffect(() => {
    const queryRef = query(
      alertCollection,
      where("isAdmin", "==", true),
      where("markAsRead", "==", false),
    );

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        const snapshotData: Alert = doc.data() as any;
        setData(() => [
          ...data,
          {
            ...snapshotData,
            id: doc.id,
          },
        ]);
      });
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        ref.current.classList.add("hidden");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onClickMenu = () => {
    ref?.current?.classList.add("hidden");
  };

  const onUpdateStatus = (role: Role) => {
    updateUser({
      id: profile.id,
      role,
    })
      .unwrap()
      .then((response) => {
        updateProfile(response.data);
      })
      .catch(() => {});
  };

  return (
    <div className="relative">
      <div className="text-right md:mr-0 md:mr-4 flex flex-row items-center">
        {isBoardButton && (
          <Button
            layout="rounded"
            variant="blue"
            className="px-4 md:px-6 h-46 md:h-56 uppercase"
            onClick={() => navigate("/wishlist")}
          >
            Blist Board
          </Button>
        )}
        {isLoggedIn ? (
          <div className="flex">
            <IconButton
              className="relative md:mx-4 mx-1"
              onClick={() => navigate("/admin/message?tab=notification")}
            >
              <div className="text-white bg-primary rounded-full text-12 h-15 min-w-21 absolute -right-2 top-2 leading-16">
                {data.length}
              </div>
              <NotificationSvg />
            </IconButton>
            <IconButton onClick={onToggle} className="flex flex-row items-center">
              <img
                src={profile.avatar ? `${s3Url}${profile.avatar}` : "/imgs/default-avatar.png"}
                alt="avatar"
                className="border rounded-full border-blue mr-2 w-56 h-56"
              />
              <ArrowDownSvg className="md:block hidden" />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={() => navigate("/signin")} className="flex flex-row items-center">
            <ProfileSvg className="stroke-black w-30 h-30" />
          </IconButton>
        )}
      </div>
      <div
        ref={ref}
        className="flex flex-col absolute z-10 rounded-10 py-4 bg-white shadow right-0 w-240 hidden"
      >
        <span
          onClick={() => navigate("/admin/change-password")}
          className="px-4 py-2 hover:bg-lightWhite text-14 cursor-pointer"
        >
          Change Password
        </span>
        <span
          onClick={() => {
            logout();
            onClickMenu();
          }}
          className="px-4 py-2 hover:bg-lightWhite text-14 cursor-pointer"
        >
          Logout
        </span>
      </div>
    </div>
  );
};
