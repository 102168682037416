import { Button, Typography } from "@app/components/atoms";
import { HTextField } from "@app/components/hookform";
import { AccountDetailLayout } from "@app/components/common";
import { useChangePasswordMutation } from "@app/store/services/auth";
import { ChangePasswordPayload } from "@app/store/services/auth/type";
import { changePasswordValidation } from "@app/validations";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAuth } from "@app/store/hooks";

export default function AccountPassword() {
  const { profile, setIsPassword } = useAuth();
  const [update, { isLoading }] = useChangePasswordMutation();
  const { handleSubmit, control } = useForm<ChangePasswordPayload>({
    resolver: changePasswordValidation,
  });
  const onSubmit: SubmitHandler<ChangePasswordPayload> = (data) => {
    update(data)
      .unwrap()
      .then(() => {
        setIsPassword(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="border-2 rounded-5 border-border5 p-4 mb-4">
        <Typography
          tag="static"
          className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-semibold"
        >
          Password
        </Typography>
        <Typography
          tag="static"
          className="md:text-26 md:leading-31 text-22 leading-22 text-grey10"
        >
          **********
        </Typography>
      </div>
      <div className="border-2 rounded-5 border-border5 p-4">
        <Typography
          tag="static"
          className="md:text-26 md:leading-31 text-22 leading-22 text-blue font-semibold mb-3"
        >
          Password
        </Typography>
        <Typography tag="static" className="md:text-18 md:leading-22 leading-22 text-grey10">
          Password must contain at least one special character, one number, and one uppercase
          letter.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className="md:w-1/2 mt-6">
          {profile.isPassword ? (
            <HTextField
              name="current_password"
              placeholder="Current Password"
              variant="outlined-grey1"
              className="mb-8"
              type="password"
              control={control}
            />
          ) : (
            <Typography tag="static" className="md:text-18 md:leading-22 leading-22 mb-3">
              You registered this account by Google or Facebook or Apple account. Please update your
              password if you want to sign in manually.
            </Typography>
          )}
          <HTextField
            name="new_password"
            placeholder="New Password"
            variant="outlined-grey1"
            type="password"
            control={control}
          />
          <HTextField
            name="confirm_password"
            placeholder="Confirm Password"
            variant="outlined-grey1"
            type="password"
            control={control}
          />
          <div className="pt-7">
            <Button
              isLoading={isLoading}
              type="submit"
              layout="rectangled"
              variant="primary"
              className="px-2"
            >
              Save Password
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
