import {
  Typography,
  Button,
  Select,
  ItemLoading,
  EditSvg,
  CloseCircleSvg,
  TrashSvg,
  AnnouncementSvg,
  RadioCheckedSvg,
} from "@app/components/atoms";
import { Pagination } from "@app/components/common";
import { ExperienceItem } from "@app/components/molecules";
import { RemovePopup, DeactivePopup, CustomPopup } from "@app/components/organisms";
import { limitItem, s3Url } from "@app/config";
import {
  useFetchExperienceQuery,
  useLazyFetchExperienceQuery,
} from "@app/store/services/experience";
import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useUpdateExperienceMutation,
  useRemoveExperienceMutation,
} from "@app/store/services/experience";
import { Experience } from "@app/types";

export default function AdminExperiences() {
  const [page, setPage] = useState<number>(1);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [selected, setSelected] = useState<Experience | null>(null);
  const [onRefetch] = useLazyFetchExperienceQuery();
  const { data, isLoading } = useFetchExperienceQuery({
    is_active: isActive,
    page,
    limit: limitItem,
  });
  const navigate = useNavigate();
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const [isShowActive, setIsShowActive] = useState(false);
  const [isShowBlist, setIsShowBlist] = useState(false);
  const [updateExp, { isLoading: isUpdating }] = useUpdateExperienceMutation();
  const [removeExp, { isLoading: isDeleting }] = useRemoveExperienceMutation();

  const menuDeactiveList = (exp: Experience) => [
    {
      text: "Edit Experiences",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        navigate(`/admin/experiences/update/${exp.id}`);
      },
    },
    {
      text: "Deactivate",
      icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowDeactive(true);
      },
    },
    {
      text: "Promote",
      icon: <AnnouncementSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {},
    },
    {
      text: "Add To Blist Featured",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowBlist(true);
      },
    },
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowRemove(true);
      },
    },
  ];

  const menuActiveList = (exp: Experience) => [
    {
      text: "Edit Experiences",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        navigate(`/admin/experiences/update/${exp.id}`);
      },
    },
    {
      text: "Activate",
      icon: <RadioCheckedSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowActive(true);
      },
    },
    {
      text: "Promote",
      icon: <AnnouncementSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {},
    },
    {
      text: "Add To Blist Featured",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowBlist(true);
      },
    },
    {
      text: "Remove",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowRemove(true);
      },
    },
  ];

  const onRemove = () => {
    if (!selected?.id) return;
    removeExp(selected?.id)
      .unwrap()
      .then(() => setIsShowRemove(false))
      .catch(() => {});
  };

  const onDeactive = () => {
    if (!selected?.id) return;
    updateExp({
      id: selected?.id,
      is_active: false,
    })
      .unwrap()
      .then(() => setIsShowDeactive(false))
      .catch(() => {});
  };

  const onActive = () => {
    if (!selected?.id) return;
    updateExp({
      id: selected?.id,
      is_active: true,
    })
      .unwrap()
      .then(() => setIsShowActive(false))
      .catch(() => {});
  };

  const onAddBlistFeatured = () => {
    if (!selected?.id) return;
    updateExp({
      id: selected?.id,
      is_blist: true,
    })
      .unwrap()
      .then(() => setIsShowBlist(false))
      .catch(() => {});
  };

  return (
    <>
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={selected?.title}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={selected?.title}
      />
      <CustomPopup
        isShow={isShowBlist}
        isLoading={isUpdating}
        onClose={() => setIsShowBlist(false)}
        onSubmit={onAddBlistFeatured}
        question={`Do you want to add ${selected?.title} to Blist Featured?`}
      />
      <CustomPopup
        isShow={isShowActive}
        isLoading={isUpdating}
        onClose={() => setIsShowActive(false)}
        onSubmit={onActive}
        question={`Do you want to active ${selected?.title}?`}
      />
      <div className="md:mb-8 mb-4 flex lg:flex-row flex-col lg:justify-between lg:items-center">
        <Typography tag="h2">Experiences</Typography>
        <div className="flex md:flex-row flex-col">
          <Select layout="static" placeholder="Select" className="md:w-300 w-full md:mb-0 mb-2" />
          <Button
            variant={isActive ? "primary" : "outline-border"}
            className={clsx(
              "ml-2",
              !isActive ? "border-lightGrey" : "hover:!bg-primary hover:!text-white",
            )}
            onClick={() => {
              setIsActive(true);
              onRefetch({
                is_active: true,
                page,
                limit: limitItem,
              });
            }}
          >
            Activated
          </Button>
          <Button
            variant={!isActive ? "primary" : "outline-border"}
            className={clsx(
              "ml-2",
              isActive ? "border-lightGrey" : "hover:!bg-primary hover:!text-white",
            )}
            onClick={() => {
              setIsActive(false);
              onRefetch({
                is_active: false,
                page,
                limit: limitItem,
              });
            }}
          >
            Deactivated
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-4">
        {isLoading ? (
          <>
            <ItemLoading />
            <ItemLoading />
            <ItemLoading />
            <ItemLoading />
            <ItemLoading />
          </>
        ) : (
          data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.description}
              url={item.photos?.[0]}
              menuVariant="blue"
              isEdit
              key={key}
              id={item.id}
              menuList={isActive ? menuDeactiveList(item) : menuActiveList(item)}
            />
          ))
        )}
      </div>
      <div className="flex flex-row justify-end">
        <Pagination setPage={setPage} page={page} total={data?.total} />
      </div>
    </>
  );
}
