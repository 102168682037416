import clsx from "clsx";
import {
  ReactNode,
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  Ref,
  ChangeEvent,
} from "react";
import { Typography } from "./typography";
import { Label } from "./Label";

export interface TextfieldProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  name: string;
  error?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?:
    | "static"
    | "outlined"
    | "filled"
    | "outlined-black"
    | "outlined-grey"
    | "outlined-grey1"
    | "none";
  layout?: "vertical" | "horizontal" | "static";
  border?: "full" | "static" | "rectangled";
  subtitle?: string;
  customLabel?: ReactNode;
  inputClass?: string;
  autoComplete?: string;
}

export const TextField = forwardRef(
  (
    {
      label,
      name,
      startIcon,
      endIcon,
      error,
      variant = "static",
      layout = "horizontal",
      border = "static",
      className,
      subtitle,
      customLabel,
      inputClass,
      required,
      maxLength,
      onChange,
      ...props
    }: TextfieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const onChangeCustom = (e: ChangeEvent<HTMLInputElement>) => {
      if (maxLength && e.target.value.split(" ").length > maxLength) return;
      onChange?.(e);
    };

    return (
      <div
        className={clsx(
          layout === "horizontal" && "mb-4",
          layout === "vertical" && "flex flex-row items-center",
          className,
        )}
      >
        {label && (
          <div
            className={clsx(
              "flex flex-row items-center mb-4",
              variant === "outlined-grey" && "!mb-2",
            )}
          >
            <Typography tag="h6" className="text-blue mr-2">
              {label} {required && <span className="text-20 text-primary">*</span>}
            </Typography>
            {subtitle && <p className="text-placeholder1 font-medium">{subtitle}</p>}
          </div>
        )}
        {customLabel}
        <div className="w-full">
          <div
            className={clsx(
              "flex flex-row items-center w-full",
              variant === "static" && "border-t-0 border-l-0 border-r-0 border-timeAgo border-b",
              variant === "outlined" && "border border-timeAgo rounded-10 overflow-hidden",
              variant === "outlined-black" && "border-lightGrey border bg-white p-2",
              border === "full" && "rounded-full bg-buttonGrey pl-2",
              border === "rectangled" && "rounded-10 bg-buttonGrey pl-2",
              variant === "outlined-grey" && "border-2 rounded-5 border-border5 bg-white",
              variant === "outlined-grey1" && "border-2 rounded-5 border-border5 bg-white",
            )}
          >
            {startIcon}
            <input
              id={name}
              ref={ref}
              {...props}
              onChange={onChangeCustom}
              className={clsx(
                "block flex-1 p-2 font-inter font-extralight md:text-16 text-14 w-full",
                variant === "outlined-black" && "placeholder:text-placeholder1",
                variant === "outlined-grey" && "py-4 px-4 text-22 text-blue",
                variant === "outlined-grey1" && "!py-2.5 px-4 text-18 text-blue",
                inputClass,
              )}
            />
            {endIcon}
          </div>
          {error && <Label variant="error">{error}</Label>}
        </div>
      </div>
    );
  },
);
