import clsx from "clsx";
import CreatableSelect from "react-select/creatable";
import { components, SelectComponentsConfig } from "react-select";
import type {
  Props,
  ControlProps,
  PlaceholderProps,
  ClassNamesConfig,
  GroupBase,
  IndicatorsContainerProps,
  ValueContainerProps,
} from "react-select";
import { ArrowDownSvg } from "./icons";
import { Typography } from "./typography";
import { ReactNode } from "react";

export interface CreatableProps extends Props {
  label?: string;
  layout?: "row" | "column" | "static";
  variant?: "static" | "underline" | "outlined" | "smOutlined" | "outlineRectangled" | "rectangled";
  subtitle?: string;
  customLabel?: ReactNode;
}

const Control = ({ children, ...props }: ControlProps) => (
  <components.Control {...props} className={clsx("!rounded-full !border-lightGrey p-2")}>
    {children}
  </components.Control>
);

const ControlRectangled = ({ children, ...props }: ControlProps) => (
  <components.Control {...props} className={clsx("!rounded-10 !border-lightGrey p-2")}>
    {children}
  </components.Control>
);

const SmControl = ({ children, ...props }: ControlProps) => (
  <components.Control {...props} className={clsx("!rounded-full !border-lightGrey !min-h-32")}>
    {children}
  </components.Control>
);

const RectangledControl = ({ children, ...props }: ControlProps) => (
  <components.Control {...props} className={clsx("!rounded-5 !border-2 !border-border5")}>
    {children}
  </components.Control>
);

const ControlUnderline = ({ children, ...props }: ControlProps) => (
  <div
    {...props}
    className="flex flex-row flex-wrap border-t-0 border-l-0 border-r-0 border-timeAgo border-b min-h-38 justify-between items-center relative "
  >
    {children}
  </div>
);

const ValueContainerUnderline = ({ children, ...props }: ValueContainerProps) => (
  <div {...props} className="flex flex-row flex-wrap justify-between items-center relative ">
    {children}
  </div>
);

const Placeholder = ({ children, ...props }: PlaceholderProps) => (
  <components.Placeholder {...props}>
    <Typography className="text-lightWhite">{children}</Typography>
  </components.Placeholder>
);

const SmPlaceholder = ({ children, ...props }: PlaceholderProps) => (
  <components.Placeholder {...props}>
    <Typography className="text-blue">{children}</Typography>
  </components.Placeholder>
);

const PlaceholderUnderline = ({ children, ...props }: PlaceholderProps) => (
  <components.Placeholder {...props}>
    <Typography className="text-timeAgo">{children}</Typography>
  </components.Placeholder>
);

const IndicatorsContainer = ({ children, ...props }: IndicatorsContainerProps) => (
  <components.IndicatorsContainer {...props}>
    <ArrowDownSvg className="px-2 box-content" />
  </components.IndicatorsContainer>
);

const Config: {
  [key: string]: {
    classNames?: ClassNamesConfig<unknown, boolean, GroupBase<unknown>>;
    components?: Partial<SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>>;
  };
} = {
  smOutlined: {
    classNames: {
      indicatorSeparator: () => "opacity-0",
      container: () => "!flex-1",
      input: () => "!my-0 !py-0",
    },
    components: {
      Control: SmControl,
      Placeholder: SmPlaceholder,
      IndicatorsContainer,
    },
  },
  outlineRectangled: {
    classNames: {
      indicatorSeparator: () => "opacity-0",
      container: () => "!flex-1",
      input: () => "!my-0 !py-0",
    },
    components: {
      Control: RectangledControl,
      Placeholder: SmPlaceholder,
      IndicatorsContainer,
    },
  },
  outlined: {
    classNames: {
      indicatorSeparator: () => "opacity-0",
      container: () => "!flex-1",
    },
    components: {
      Control,
      Placeholder,
      IndicatorsContainer,
    },
  },
  underline: {
    classNames: {
      indicatorSeparator: () => "opacity-0",
    },
    components: {
      Control: ControlUnderline,
      Placeholder: PlaceholderUnderline,
      IndicatorsContainer,
      ValueContainer: ValueContainerUnderline,
    },
  },
  rectangled: {
    classNames: {
      indicatorSeparator: () => "opacity-0",
      container: () => "!flex-1",
      menuList: () => "!p-3 !max-h-none",
      menu: () => "!shadow-menulist !relative",
      option: ({ isSelected }) =>
        clsx(
          "rounded-10 !text-blue !text-18 !py-4 !px-3",
          isSelected ? "!bg-grey12" : "hover:bg-grey12",
        ),
    },
    components: {
      Control: ControlRectangled,
      Placeholder,
      IndicatorsContainer,
    },
  },
};

export const Creatable = ({
  label,
  layout = "row",
  variant = "outlined",
  defaultValue = null,
  className,
  subtitle,
  customLabel,
  ...props
}: CreatableProps) => {
  return (
    <div
      className={clsx(
        layout === "column" && "flex flex-col mb-2",
        layout === "row" && "flex flex-row justify-between items-center mb-2",
        className,
      )}
    >
      {label && (
        <div
          className={clsx(
            "flex flex-row items-center",
            layout === "row" && "mr-4",
            layout === "column" && "mb-4",
          )}
        >
          <Typography tag="h6" className="text-blue mr-2">
            {label}
          </Typography>
          {subtitle && <p className="text-placeholder1 font-medium">{subtitle}</p>}
        </div>
      )}
      {customLabel}
      <CreatableSelect
        classNames={Config[variant]?.classNames}
        components={Config[variant]?.components}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};
