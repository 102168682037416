import { useRef } from "react";
import { Button, CloseSquareSvg, IconButton, SearchSvg } from "../atoms";
import { HGoogleAddress, HTextField, HDatePicker } from "../hookform";
import { useForm, SubmitHandler } from "react-hook-form";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@app/store/hooks";
import { Role } from "@app/enums";
import qs from "qs";

type FormData = {
  title?: string;
  location?: { lat: number; lng: number };
  date?: {
    start: Date;
    end: Date;
  };
};

const MobileSearch = () => {
  const [, setSearchParams] = useSearchParams();
  const { profile } = useAuth();
  const searchRef = useRef<HTMLDivElement | null>(null);
  const { handleSubmit, control, reset } = useForm<FormData>();
  const onClick = () => {
    if (!searchRef.current) {
      return;
    }
    searchRef.current?.classList.toggle("hidden");
  };

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    navigate(profile.role === Role.creator ? "/result" : "/");
    setSearchParams(
      qs.stringify({
        lat: data.location?.lat,
        lng: data.location?.lng,
        title: data.title,
        start_date: data.date?.start && moment(data.date?.start).format("YYYY-MM-DD"),
        end_date: data.date?.end && moment(data.date?.end).format("YYYY-MM-DD"),
      }),
    );
  };

  return (
    <div className="lg:hidden flex items-center justify-end pr-1 h-full">
      <IconButton onClick={onClick} variant="primary" className="relative w-9 h-9">
        <SearchSvg className="fill-white absolute left-2.5 top-2.5" />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          ref={searchRef}
          className="hidden absolute bg-bg2 left-0 right-0 top-0 bottom-0 flex flex-col z-20"
        >
          <div className="flex-1">
            <div className="px-4 py-3">
              <IconButton type="button" onClick={onClick}>
                <CloseSquareSvg />
              </IconButton>
            </div>
            <div className="mx-4 p-3 bg-white shadow-4x rounded-10 mb-4">
              <label className="text-sm font-semibold text-gray-900 block">Search</label>
              <HTextField
                name="title"
                control={control}
                placeholder="Search by keywords"
                className="placeholder:text-lightGrey"
                layout="static"
                border="static"
                variant="none"
                inputClass="!p-0"
              />
            </div>
            <div className="mx-4 p-3 bg-white shadow-4x rounded-10 mb-4">
              <label className="text-sm font-medium text-gray-900 block">Location?</label>
              <HGoogleAddress
                name="location"
                control={control}
                placeholder="Search here for location"
                className="placeholder:text-lightGrey"
                layout="static"
                border="static"
                variant="none"
                inputClass="!p-0"
                types={["(cities)"]}
              />
            </div>
            <div className="mx-4 p-3 bg-white shadow-4x rounded-10">
              <label className="text-sm font-medium text-gray-900 block">Date</label>
              <HDatePicker
                name="date"
                control={control}
                placeholder="Add dates"
                layout="static"
                variant="none"
                inputClass="!p-0"
                selectsRange
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center bg-white p-4 border-t border-border-2">
            <Button onClick={() => reset()} type="button" variant="link">
              Clear all
            </Button>
            <Button type="submit" variant="primary">
              Search
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const DesktopSearch = () => {
  const [, setSearchParams] = useSearchParams();
  const { handleSubmit, control } = useForm<FormData>();
  const navigate = useNavigate();
  const { profile } = useAuth();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    navigate(profile.role === Role.creator ? "/result" : "/");
    setSearchParams(
      qs.stringify({
        lat: data.location?.lat,
        lng: data.location?.lng,
        title: data.title,
        start_date: data.date?.start && moment(data.date?.start).format("YYYY-MM-DD"),
        end_date: data.date?.end && moment(data.date?.end).format("YYYY-MM-DD"),
      }),
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white border border-border2 rounded-full relative mx-2 h-full hidden lg:block"
    >
      <div className="grid grid-cols-8 py-1.5 divide-x">
        <div className="col-span-3 pl-4 pr-2">
          <label className="text-sm font-semibold text-gray-900 block">Search</label>
          <HTextField
            name="title"
            control={control}
            placeholder="Search by keywords"
            className="placeholder:text-lightGrey"
            layout="static"
            border="static"
            variant="none"
            inputClass="!p-0"
          />
        </div>
        <div className="col-span-3 px-3">
          <label className="text-sm font-medium text-gray-900 block">Location?</label>
          <HGoogleAddress
            name="location"
            control={control}
            placeholder="Search here for location"
            className="placeholder:text-lightGrey"
            layout="static"
            border="static"
            variant="none"
            inputClass="!p-0"
            types={["(cities)"]}
          />
        </div>
        <div className="col-span-2 px-3">
          <label className="text-sm font-medium text-gray-900 block">Date</label>
          <HDatePicker
            name="date"
            control={control}
            placeholder="Add dates"
            layout="static"
            variant="none"
            inputClass="!p-0"
            selectsRange
          />
        </div>
      </div>
      <IconButton type="submit" variant="primary" className="absolute z-10 right-2 top-2 w-9 h-9">
        <SearchSvg className="fill-white absolute left-2.5 top-2.5" />
      </IconButton>
    </form>
  );
};

export const SearchMultiple = () => {
  return (
    <div className="flex-1">
      <MobileSearch />
      <DesktopSearch />
    </div>
  );
};
