import {
  TrackingBanner,
  TrackingFormSection,
  EmergencyContactSection,
  TrackingIntroduce,
  TrackingAuthorized,
} from "@app/components/organisms";
import { useAuth } from "@app/store/hooks";
import { useGetBookingQuery } from "@app/store/services/booking";
import { useParams } from "react-router-dom";
import NoPermissionPage from "../no-permission-page";

export default function SafetyPage() {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data: booking } = useGetBookingQuery(Number(bookingId), { skip: !bookingId });
  const { profile } = useAuth();

  if (booking?.customer_id !== profile.id) return <NoPermissionPage />;

  return (
    <div className="px-6">
      <div className=" py-8">
        <TrackingBanner />
        {booking?.customer_id === profile.id && <TrackingAuthorized />}
        {booking?.customer_id === profile.id && <TrackingFormSection />}
        <EmergencyContactSection />
      </div>
      <TrackingIntroduce />
    </div>
  );
}
