import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { format } from "date-fns";
import { Typography, ArrowLeftSvg, ArrowRightSvg } from "../atoms";

export type CalendarProps = ReactDatePickerProps & {
  onChange: (data: Date | null) => void;
  value?: Date;
};

export const Calendar = ({ onChange, value, ...props }: CalendarProps) => {
  return (
    <DatePicker
      {...props}
      selected={value}
      onChange={onChange}
      inline
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
      calendarClassName="w-full custom-calendar border-0"
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex flex-row justify-between items-center mb-6">
          <button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="ml-2"
          >
            <ArrowLeftSvg className="w-15 h-15 fill-primary stroke-primary" />
          </button>
          <Typography>{format(date, "MMMM")}</Typography>
          <button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="mr-2"
          >
            <ArrowRightSvg className="w-15 h-15 fill-primary stroke-primary" />
          </button>
        </div>
      )}
    />
  );
};
