import { Link, redirect } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Typography, Button, MailOutline } from "@app/components/atoms";
import { HTextField } from "@app/components/hookform";
import { forgotPasswordValidation } from "@app/validations";
import { useForgotPasswordMutation } from "@app/store/services/auth";
import type { ForgotPasswordPayload } from "@app/store/services/auth/type";

export default function ForgotPassword() {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { handleSubmit, control } = useForm<ForgotPasswordPayload>({
    resolver: forgotPasswordValidation,
  });
  const onSubmit: SubmitHandler<ForgotPasswordPayload> = async (data) => {
    await forgotPassword(data);
    redirect("/signin");
  };

  return (
    <div className="md:px-0 px-4 my-16">
      <div className="">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="md:w-1/2 p-6 mx-auto border rounded-10 border-black/[.07]"
        >
          <div className="-mx-6 border-b border-black/[.07] text-center pb-6">
            <Typography tag="body1" className="font-bold">
              Forgot Password
            </Typography>
          </div>
          <div className="pt-6">
            <HTextField
              name="email_address"
              control={control}
              startIcon={<MailOutline className="text-timeAgo" />}
              placeholder="Email Address"
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            layout="rectangled"
            className="w-full"
            isLoading={isLoading}
          >
            Send
          </Button>
          <div className="text-right mt-8">
            <Link to="/signin">
              <Typography>Signin</Typography>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
