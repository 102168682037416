import { Link } from "react-router-dom";
import clsx from "clsx";
import { LogoSvg } from "../../atoms";
import { SearchMultiple, UserMenu } from "../../molecules";
import { useAuth } from "@app/store/hooks";
import { Role } from "@app/enums";

export const Header = () => {
  const { profile } = useAuth();

  return (
    <div className="container-layout">
      <div
        className={clsx(
          "sm:bg-headerBg border border-border2 md:rounded-full md:mt-4 z-30 md:pl-6 md:pr-4 py-2",
        )}
      >
        <div className={clsx("flex flex-row justify-between")}>
          <div className={clsx("flex flex-row items-center")}>
            <Link
              to={
                profile.role === Role.admin
                  ? "/admin"
                  : profile.role === Role.creator
                  ? "/creator-dashboard"
                  : "/"
              }
            >
              <LogoSvg width={91} height={47} />
            </Link>
          </div>
          <SearchMultiple />
          <div className={clsx("flex flex-row justify-end items-center")}>
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};
