import { useState } from "react";
import {
  Typography,
  IconButton,
  Calendar2Svg,
  ChartSvg,
  MsgNotifSvg,
  Button,
  LocationSvg,
  Modal,
  EditSvg,
  CloseCircleSvg,
  TrashSvg,
  AnnouncementSvg,
  ListItemLoading,
  WishlistLoading,
  RadioCheckedSvg,
} from "../atoms";
import { HTextField } from "../hookform";
import { CreatorIntroduce, ExperienceItem, PromoteForm } from "../molecules";
import { Article } from "../common";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useFetchExperienceQuery } from "@app/store/services/experience";
import { useAuth } from "@app/store/hooks";
import { Experience } from "@app/types";
import {
  useUpdateExperienceMutation,
  useRemoveExperienceMutation,
} from "@app/store/services/experience";
import { RemovePopup, DeactivePopup, CustomPopup } from "@app/components/organisms";
import { ExpBusiness, ExpCreatorType, Role } from "@app/enums";
import clsx from "clsx";
import { useFetchWishlistQuery } from "@app/store/services/wishlist";
import moment from "moment";
import { FetchWishlistPayload } from "@app/store/services/wishlist/types";
import { useUpdateUserMutation } from "@app/store/services/user";

type Props = {
  title?: string;
};

export const CreatorBanner = () => {
  const { profile, isLoggedIn, updateProfile } = useAuth();
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();

  const onUpdateStatus = (role: Role) => {
    updateUser({
      id: profile.id,
      role,
    })
      .unwrap()
      .then((response) => {
        updateProfile(response.data);
        if (role === Role.creator) {
          navigate("/creator-dashboard");
        } else {
          navigate("/");
        }
      })
      .catch(() => {});
  };

  return (
    <div className="pt-6 mb-3">
      <div className="flex md:flex-row flex-col md:justify-between md:items-end mb-4">
        <Typography tag="h2">Creator Dashboard</Typography>
        {isLoggedIn && (
          <div className="flex flex-row justify-end mt-3">
            {profile.role === Role.creator ? (
              <IconButton
                className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
                onClick={() => onUpdateStatus(Role.user)}
              >
                Create
              </IconButton>
            ) : (
              profile.role === Role.user && (
                <IconButton
                  className="md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
                  onClick={() => onUpdateStatus(Role.creator)}
                >
                  Experience
                </IconButton>
              )
            )}
          </div>
        )}
        <div className="flex md:justify-between md:items-end flex-wrap">
          <IconButton
            onClick={() => navigate("/messages")}
            className="my-1 bg-headerBg text-blue border border-border2 flex flex-row py-1 px-2 items-center mr-2"
          >
            <MsgNotifSvg className="mr-2 stroke-blue" /> Inbox
          </IconButton>
          <IconButton
            onClick={() => navigate("/creator/calendar")}
            className="my-1 bg-headerBg text-blue border border-border2 flex flex-row py-1 px-2 items-center mr-2"
          >
            <Calendar2Svg className="mr-2" /> Calendar
          </IconButton>
          <IconButton
            onClick={() => navigate("/analytics")}
            className="my-1 bg-headerBg text-blue border border-border2 flex flex-row py-1 px-2 items-center"
          >
            <ChartSvg className="mr-2" /> Data Analytics
          </IconButton>
        </div>
      </div>
      <CreatorIntroduce />
    </div>
  );
};

export const CreatorExperience = ({ title }: Props) => {
  const [isShowPromote, setIsShowPromote] = useState<boolean>(false);
  const [isShowRemove, setIsShowRemove] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const [isShowActive, setIsShowActive] = useState(false);
  const [selected, setSelected] = useState<Experience | null>(null);
  const [creatorType, setCreatorType] = useState<ExpBusiness>(ExpBusiness.INDIVIDUAL);

  const { profile } = useAuth();
  const { data, isLoading } = useFetchExperienceQuery({
    limit: 5,
    user_id: profile.id,
    business: creatorType,
    is_all: true,
  });
  const navigate = useNavigate();
  const [updateExp, { isLoading: isUpdating }] = useUpdateExperienceMutation();
  const [removeExp, { isLoading: isDeleting }] = useRemoveExperienceMutation();

  const menuActiveList = (exp: Experience) => [
    {
      text: "Edit Experience",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => navigate(`/update-experience/${exp.id}`),
    },
    {
      text: "Active",
      icon: <RadioCheckedSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowActive(true);
      },
    },
    {
      text: "Promote",
      icon: <AnnouncementSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowPromote(true);
      },
    },
    {
      text: "Delete",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowRemove(true);
      },
    },
  ];

  const menuDeactiveList = (exp: Experience) => [
    {
      text: "Edit Experience",
      icon: <EditSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => navigate(`/update-experience/${exp.id}`),
    },
    {
      text: "Deactivate",
      icon: <CloseCircleSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowDeactive(true);
      },
    },
    {
      text: "Promote",
      icon: <AnnouncementSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowPromote(true);
      },
    },
    {
      text: "Delete",
      icon: <TrashSvg className="mr-2 stroke-white w-19 h-19" />,
      action: () => {
        setSelected(exp);
        setIsShowRemove(true);
      },
    },
  ];

  const onRemove = () => {
    if (!selected?.id) return;
    removeExp(selected?.id)
      .unwrap()
      .then(() => setIsShowRemove(false))
      .catch(() => {});
  };

  const onDeactive = () => {
    if (!selected?.id) return;
    updateExp({
      id: selected?.id,
      is_active: false,
    })
      .unwrap()
      .then(() => setIsShowDeactive(false))
      .catch(() => {});
  };

  const onActive = () => {
    if (!selected?.id) return;
    updateExp({
      id: selected?.id,
      is_active: true,
    })
      .unwrap()
      .then(() => setIsShowActive(false))
      .catch(() => {});
  };

  return (
    <div className="md:pb-8 pb-4">
      <Modal show={isShowPromote} onClose={() => setIsShowPromote(false)}>
        <PromoteForm onClose={() => setIsShowPromote(false)} experienceId={selected?.id} />
      </Modal>
      <RemovePopup
        isShow={isShowRemove}
        isLoading={isDeleting}
        onClose={() => setIsShowRemove(false)}
        onSubmit={onRemove}
        name={selected?.title}
      />
      <DeactivePopup
        isShow={isShowDeactive}
        isLoading={isUpdating}
        onClose={() => setIsShowDeactive(false)}
        onSubmit={onDeactive}
        name={selected?.title}
      />
      <CustomPopup
        question="Do you want to active this Experience?"
        isShow={isShowActive}
        isLoading={isUpdating}
        onClose={() => setIsShowActive(false)}
        onSubmit={onActive}
        name={selected?.title}
      />
      {title && (
        <div className="md:mb-8 mb-4 flex md:flex-row flex-col justify-between items-center">
          <Typography tag="h2">{title}</Typography>
          <div>
            <Button
              variant={creatorType === ExpBusiness.INDIVIDUAL ? "primary" : "outline-border"}
              className={clsx("mr-2", creatorType === ExpBusiness.INDIVIDUAL && "border-lightGrey")}
              onClick={() => setCreatorType(ExpBusiness.INDIVIDUAL)}
            >
              Individual
            </Button>
            <Button
              variant={creatorType === ExpBusiness.VENUE ? "primary" : "outline-border"}
              className={clsx(creatorType === ExpBusiness.VENUE && "border-lightGrey")}
              onClick={() => setCreatorType(ExpBusiness.VENUE)}
            >
              Venue
            </Button>
          </div>
        </div>
      )}
      {isLoading ? (
        <ListItemLoading />
      ) : (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.data.map((item, key) => (
            <ExperienceItem
              title={item.title}
              content={item.summary}
              url={item.photos?.length ? item.photos[0] : undefined}
              key={key}
              id={item.id}
              isEdit
              menuList={item.is_active ? menuDeactiveList(item) : menuActiveList(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const CreatorWishlist = () => {
  const [params, setParams] = useState<FetchWishlistPayload>({});
  const { data, isLoading } = useFetchWishlistQuery(params);
  const { handleSubmit, control } = useForm<FetchWishlistPayload>();

  const onSubmit: SubmitHandler<FetchWishlistPayload> = (data) => {
    setParams(data);
  };

  return (
    <div>
      <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-4">
        <Typography tag="h2">Wishlist</Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-row flex-wrap w-full md:w-auto justify-center md:justify-inherit"
        >
          <HTextField
            variant="outlined-black"
            border="full"
            name="content"
            placeholder="Search by keyword"
            endIcon={<LocationSvg />}
            className="mr-2 md:mb-0 mb-2 w-full md:w-auto"
            layout="vertical"
            control={control}
          />
          <HTextField
            variant="outlined-black"
            border="full"
            name="location"
            placeholder="Search by location"
            endIcon={<LocationSvg />}
            className="mr-2 w-full md:w-auto"
            layout="vertical"
            control={control}
          />
          <Button type="submit" className="mt-3 lg:mt-0" variant="primary">
            Search
          </Button>
        </form>
      </div>
      <div className="grid md:grid-cols-3 gap-5 max-h-320 scroll-smooth overflow-auto custom-scroll pt-4">
        {isLoading ? (
          <WishlistLoading />
        ) : (
          data?.data.map((item, key) => (
            <Article
              isPopular={item.is_popular}
              author={item.owner.first_name}
              content={item.content}
              key={key}
              time={moment(item.createdAt).fromNow()}
            />
          ))
        )}
      </div>
    </div>
  );
};
