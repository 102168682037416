import { Typography } from "@app/components/atoms";
import { Spinner } from "@app/components/atoms/Spinner";
import { BackButton } from "@app/components/common";
import { CategoryForm } from "@app/components/organisms";
import { useGetCategoryQuery } from "@app/store/services/category";
import { useParams } from "react-router-dom";

export default function AdminCategoryAction() {
  const { id } = useParams<{ id?: string }>();
  const { data, isLoading } = useGetCategoryQuery(+(id || ""), {
    skip: !id,
  });

  return (
    <>
      <div className="mb-8">
        <BackButton />
        <Typography tag="h2" className="mt-6">
          Categories
        </Typography>
      </div>
      {isLoading ? <Spinner /> : <CategoryForm defaultValues={data} />}
    </>
  );
}
