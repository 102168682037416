import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div
      id="error-page"
      className="bg-blue flex flex-col justify-center items-center w-full h-screen "
    >
      <h1 className="text-white">Oops!</h1>
      <p className="text-white">Sorry, an unexpected error has occurred.</p>
      <Link to="/" className="text-white">
        Back to Homepage
      </Link>
    </div>
  );
}
