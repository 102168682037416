import clsx from "clsx";
import { ReactNode } from "react";

type Props = {
  variant?: "default" | "primary" | "danger";
  text?: string;
  startIcon?: ReactNode;
  className?: string;
};
export const Chip = ({ variant = "default", text, startIcon, className }: Props) => {
  return (
    <div
      className={clsx(
        "inline-flex justify-center items-center rounded-full px-2 py-1 md:text-14 text-10",
        variant === "danger" && "bg-chipDangerBg text-chipDangerText",
        variant === "primary" && "bg-primary text-white",
        className,
      )}
    >
      {startIcon} {text}
    </div>
  );
};
