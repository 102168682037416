import { Typography } from "./typography";
import { Button } from "./Button";
import { ImageFillSvg, UploadCloudSvg } from "./icons";
import { ExportSvg } from "./icons";

export const UploadFile = () => {
  return (
    <div className="border-dashed border-blue bg-bg2 border rounded-10 py-6 bg-white flex flex-col justify-center items-center w-full h-full">
      <ExportSvg />
      <p className="text-blue text-20 my-4">
        Drag & drop or <span className="font-semibold">choose</span> image
      </p>
      <p className="text-lightGrey text-16 mb-3">Supported formats:</p>
      <div>
        <span className="inline-block border border-lightGrey rounded-5 text-lightGrey text-16 px-2 mx-2">
          .jpeg
        </span>
        <span className="inline-block border border-lightGrey rounded-5 text-lightGrey text-16 px-2 mx-2">
          .jpeg
        </span>
        <span className="inline-block border border-lightGrey rounded-5 text-lightGrey text-16 px-2 mx-2">
          .jpeg
        </span>
      </div>
    </div>
  );
};

export const UploadMultiFile = () => {
  return (
    <div className="border-dashed border-border2 border rounded-10 py-6 bg-white flex flex-col justify-center items-center">
      <UploadCloudSvg className="mb-4" />
      <Typography className="mb-2 font-semibold text-blue">
        Select a file or drag and drop here
      </Typography>
      <Typography className="mb-4" tag="body">
        JPG, PNG or PDF, file size no more than 10MB
      </Typography>
      <Button type="button" variant="outline-border" layout="rounded">
        Select File
      </Button>
    </div>
  );
};

export const UploadMultiFileSecond = ({ text, subtext }: { text: string; subtext: string }) => {
  return (
    <div className="border-dashed border-border2 border rounded-10 py-6 bg-white flex flex-col justify-center items-center">
      <UploadCloudSvg className="mb-4" />
      <Typography className="mb-2 font-semibold text-blue">
        Select a file or drag and drop here
      </Typography>
      <Typography className="mb-4" tag="body">
        {text}
      </Typography>
      <Button type="button" variant="outline-border" layout="rounded" className="mb-4">
        Select File
      </Button>
      <Typography tag="body">{subtext}</Typography>
    </div>
  );
};

export const UploadImage = ({ text }: { text: string }) => {
  return (
    <div className="border-dashed border-border5 bg-bg2 border-2 rounded-5 py-6 bg-white flex flex-col justify-center items-center w-full h-full">
      <ImageFillSvg className="w-34 h-34 fill-blue/[.67]" />
      <p className="text-blue text-16 my-4">{text}</p>
      <p className="text-blue/[.72] text-14 w-2/3 mx-auto text-center">
        Upload the front side of your document. Supports JPG, PNG PDF.
      </p>
    </div>
  );
};
