import { Message } from "@app/types";
import { MoreSvg, Typography } from "../atoms";
import moment from "moment";
import { s3Url } from "@app/config";

export const ChatMessage = ({
  content,
  media,
  note,
}: {
  content?: string;
  media?: string;
  note?: string;
}) => {
  return (
    <div className="flex flex-row mb-2">
      <div className="bg-msBG text-blue/[.7] text-16 md:px-4 md:py-3 p-1 rounded-10 rounded-tl-none">
        {content || note}
      </div>
      <MoreSvg className="stroke-black fill-black" />
    </div>
  );
};

export const ChatMessageAdrmin = ({
  content,
  media,
  note,
}: {
  content?: string;
  media?: string;
  note?: string;
}) => {
  return (
    <div className="flex flex-row mb-2">
      <MoreSvg className="stroke-black fill-black" />
      <div className="bg-msBG text-blue/[.7] text-16 md:px-4 md:py-3 p-1 rounded-10 rounded-tl-none">
        {content || note}
      </div>
    </div>
  );
};

export const ChatContent = ({
  isAdmin,
  name,
  content,
  media,
  note,
  createdAt,
  avatar,
}: Message) => {
  if (isAdmin) {
    return (
      <div className="flex flex-row justify-end">
        <div>
          <div className="flex flex-row justify-end items-end ">
            <p className="text-grey6 text-14 font-medium md:mr-2">
              {moment(createdAt.seconds * 1000).format("hh:mm a")}
            </p>
            <Typography tag="h5">You</Typography>
          </div>
          <ChatMessageAdrmin content={content} media={media} note={note} />
        </div>
        <div className="md:mr-4 ml-2">
          <div className="rounded-full bg-primary">
            <img
              src="/imgs/logo-white.svg"
              alt="avatar"
              className="rounded-full md:w-40 md:h-40 w-25 h-25"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row">
      <div className="md:mr-4 mr-2">
        <img
          src={avatar ? `${s3Url}${avatar}` : "/imgs/default-avatar.png"}
          alt="avatar"
          className="rounded-full md:w-40 md:h-40 w-25 h-25"
        />
      </div>
      <div>
        <div className="md:flex md:flex-row md:items-end ">
          <Typography tag="h5">{name}</Typography>
          <p className="text-grey6 text-14 font-medium md:ml-2">
            {moment(createdAt.seconds * 1000).format("hh:mm a")}
          </p>
        </div>
        <ChatMessage content={content} media={media} note={note} />
      </div>
    </div>
  );
};
